import React, { forwardRef, useImperativeHandle } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertMessage = forwardRef(({ ...props }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("false");
  const [messageType, setMessageType] = React.useState("success");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    triggerAlert(data) {
      const tempData = JSON.parse(data);
      setOpen(true);
      setMessage(tempData.message);
      setMessageType(tempData.messageType);
    },
  }));

  return (
    // <Snackbar
    //   open={open}
    //   autoHideDuration={3000}
    //   onClose={handleClose}
    //   anchorOrigin={{ vertical: "top", horizontal: "right" }}
    // >
    //   <Alert onClose={handleClose} severity={messageType} sx={{ width: "100%" }}>
    //     {message}
    //   </Alert>
    // </Snackbar>
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      style={{ marginTop: "50px" }}
    >
      <Alert
        onClose={handleClose}
        severity={messageType}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
});
