import React, { useRef, useState, forwardRef, useEffect } from "react";
import { Grid, TextField, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useImperativeHandle } from "react";
import { config, holidaydetailsAPI } from "../config";
import { AlertMessage } from "../components/AlertMessage";

// Add custom format for DD-MM-YYYY
const formatDate = (date) => dayjs(date).format("DD-MM-YYYY");

export const AddHoliday = forwardRef((props, ref) => {
  const validationSchema = Yup.object().shape({
    txtHolidayReason: Yup.string().required("Holiday reason is required"),
  });

  const [date, setDate] = useState(formatDate(dayjs()));
  const [holidayId, setHolidayId] = useState(null);
  const [update, setUpdate] = useState("");

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, setValue, formState, watch } =
    useForm(formOptions);
  const { errors } = formState;

  const childRef = useRef();
  const reasonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    setData(data) {
      const tempData = JSON.parse(data);
      const parsedDate = dayjs(tempData.txtHolidayDate, "DD-MM-YYYY");

      setHolidayId(tempData.intHolidayId);
      setDate(formatDate(parsedDate));
      setValue("txtHolidayDate", formatDate(parsedDate));
      setValue("txtHolidayReason", tempData.txtHolidayReason);
      setUpdate(tempData.update);
      reasonRef.current?.focus();
    },
  }));

  useEffect(() => {
    setValue("txtHolidayReason", watch("txtHolidayReason"));
  }, [watch("txtHolidayReason"), setValue]);

  const onSubmit = (data) => {
    if (!data.txtHolidayReason) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Holiday Reason Can't be empty",
          messageType: "error",
        })
      );
      return false;
    }

    let data1 = JSON.stringify({
      txtHolidayDate: dayjs(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      txtHolidayReason: data.txtHolidayReason,
      intHolidayId: holidayId,
      intType: update === "yes" ? 4 : 1,
      intOffset: 0,
      intLimit: 0,
    });

    config.method = "POST";
    config.data = data1;
    config.url = holidaydetailsAPI;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: response.data["code"] == "201" ? "success" : "error",
          })
        );

        if (response.data.code >= 200 && response.data.code < 400) {
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch(function (error) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
  };

  return (
    <>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item className="form-label" xs={12} lg={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="Holiday Date"
                  value={dayjs(date, "DD-MM-YYYY")}
                  inputFormat="DD-MM-YYYY"
                  fullWidth
                  onChange={(newValue) => {
                    const formattedDate = formatDate(newValue);
                    setDate(formattedDate);
                    setValue("txtHolidayDate", formattedDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={errors.txtHolidayDate?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item className="form-label" xs={12} lg={6} md={6}>
              <TextField
                name="txtHolidayReason"
                type="text"
                label="Reason"
                fullWidth
                inputRef={reasonRef}
                onChange={(e) => {
                  setValue("txtHolidayReason", e.target.value);
                }}
                {...register("txtHolidayReason")}
                error={!!errors.txtHolidayReason}
                helperText={errors.txtHolidayReason?.message}
              />
            </Grid>
          </Grid>
          <FormControl sx={{ m: 0, width: "100%" }} variant="standard">
            <Grid container className="submit-button" alignItems="center">
              <Grid item xs={12} lg={12} md={12}>
                <LoadingButton
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </FormControl>
        </form>
      </Grid>
    </>
  );
});

