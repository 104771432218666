const baseURL = "https://controller.kgbuildtechapp.com";
export const FrontentUrl = "https://kgbuildtechapp.com";

// export const baseURL = "http://localhost:4500";
// export const FrontentUrl = "http://localhost:3000";

export const ImageUploadAPI = baseURL + "/image-upload";

export const loginAPI = baseURL + "/login/pin-validate";
export const adminLoginAPI = baseURL + "/login/password-validate";
export const resetPasswordEmailAPI = baseURL + "/login/reset-password-email";
export const resetPasswordAPI = baseURL + "/login/reset-password";
export const verifyOTPAPI = baseURL + "/login/verify-otp";
export const handleHrMasterAPI = baseURL + "/hr-master/";
export const hrMasterListAPI = baseURL + "/hr-master/list";
export const positionMasterAPI = baseURL + "/position-master/";
export const roleMasterInsertOrUpdateAPI = baseURL + "/role-master/insert";
export const projectMasterAPI = baseURL + "/project-master";
export const clientMasterAPI = baseURL + "/client-master";
export const forgotPasswordAPI = baseURL + "/forgot-password";
export const advancePaymentAPI = baseURL + "/advance-payments";
export const advancePaymentDetailsAPI = baseURL + "/advance-payment-details";
export const payslipAPI = baseURL + "/payslip";
export const statusMasterAPI = baseURL + "/status-master";
export const departmentMasterAPI = baseURL + "/department-master/";
export const payslipDetailsAPI = baseURL + "/payslip-details";
export const holidaydetailsAPI = baseURL + "/holiday-details";
export const paymentRequestAPI = baseURL + "/payment-request";
export const paymentRequestApprovalAPI = baseURL + "/payment-request/approval";
export const paymentRequestProcessAPI = baseURL + "/payment-request/process";
export const contractorAPI = baseURL + "/contractor";
export const attandenceAPI = baseURL + "/attandence";
export const employeeAttandenceAPI = baseURL + "/attandence-logout";
export const vendorAPI = baseURL + "/vendor";
export const termsAndConditionsAPI = baseURL + "/terms-conditions";
export const calculatePOAPI = baseURL + "/purchase-order/calculate-po";
export const LabourAttandenceAPI = baseURL + "/attandence/labour";
export const purchaseOrderAPI = baseURL + "/purchase-order";
export const purchaseOrderItemsAPI = baseURL + "/purchase-order/items";
export const dashboardAPI = baseURL + "/dashboard";
// configuration > manageCompanies
export const manageCompaniesAPI = baseURL + "/manage-companies";
// master > invoice
export const manageInvoiceAPI = baseURL + "/invoice/create";
export const invoiceItemsAPI = baseURL + "/invoice/items";

// invoice terms and conditions
export const manageInvoiceTermsAndConditionsAPI =
  baseURL + "/invoice-terms-and-conditions";
export const manageStoreMasterAPI = baseURL + "/store";
export const labourWagesAPI = baseURL + "/labour-wages";
export const reportsAPI = baseURL + "/reports";
export const createPdfAPI = baseURL + "/create-pdf";
export const getPdfAPI = baseURL + "/fetch-pdf";
export const leaveAPI = baseURL + "/leave";
export const announcementAPI = baseURL + "/announcement";

export const userData = JSON.parse(localStorage.getItem("user"));
export const phoneRegExp = /^[0-9]{10}$/;
export const pincodeRegExp = /^[0-9]{6}$/;
export const bankaccountnumberRegExp = /^\d{9,18}$/;
export const ifscCodeRegExp = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const panCodeRegExp = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
export const gstNumberRegExp =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
export const emailRegExp = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
// export const urlRegExp = /^(https?|ftp):\/\/[^\s$.?#].[^\s]*$/i;
export const urlRegExp =
  /^(https?:\/\/)?(www\.)?[a-z0-9.-]+\.[a-z]{2,}(\/.*)?$/i;

const bearerToken = userData === null ? "" : userData.token;
export const config = {
  method: "",
  url: "",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + bearerToken,
  },
  data: [],
};

export const _numberOnly = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
