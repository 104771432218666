import React, { useEffect, useMemo, useRef, useState } from "react";
//design imports from material
import {
  Grid,
  Tooltip,
  TextField,
  FormControl,
  Typography,
  Button,
  FormHelperText,
  IconButton,
} from "@mui/material";
//layout import
import Layout from "../../../layout/Layout";

//form imports
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

import axios from "axios";
import { config, _numberOnly, statusMasterAPI } from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import { _getDropdownListData } from "../../../service";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import { AddPosition } from "../../../components/AddPosition";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Tooltip as ReactTooltip } from "react-tooltip";

function Configuration() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [parentids, setParentIds] = useState([]);
  const [parentid, setParentId] = useState(0);
  const [fetchDataList, setFetchDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateObj, setUpdateObj] = useState({
    id: "",
    name: "",
  });
  const [selectedStatusName, setSelectedStatusName] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const childRef = useRef();
  let tempRefs = useRef(false);

  const validationSchema = Yup.object().shape({
    txtName: Yup.string().required("Name is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { formState } = useForm(formOptions);
  const { errors } = formState;

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchResult(page, rowsPerPage, parentid);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!fetchDataList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchResult(1, newRowsPerPage, parentid);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleEditConfig = (id, name) => {
    setName(name);
    setUpdateObj({
      id: id,
      name: name,
    });
    setIsUpdate(true);
  };

  const handleDeleteConfig = async (id) => {
    const confirm = window.confirm("Are you sure want to delete this?");
    if (!confirm) return;

    const data = {
      intType: 9,
      intStatusId: id,
    };

    config.method = "POST";
    config.data = data;
    config.url = statusMasterAPI;

    axios(config)
      .then(function (response) {
        const messageType = response.data.code === 500 ? "warning" : "success";
        if (response.data.message === "Data deleted") {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: `${selectedStatusName}'s item has been deleted successfully`,
              messageType: messageType,
            })
          );
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: `${selectedStatusName} item deletion is unsuccessful`,
              messageType: "warning",
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = async () => {
    if (isUpdate == true) {
      const data = JSON.stringify({
        txtName: updateObj.name,
        intStatusId: updateObj.id,
        intType: 8,
      });

      if ("" == name) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Name is required",
            messageType: "error",
          })
        );
        return false;
      }

      config.method = "POST";
      config.data = data;
      config.url = statusMasterAPI;
      axios(config)
        .then(function (response) {
          console.log(response.data.message);

          const messageType =
            response.data.code === 500 ? "warning" : "success";
          if (response.data.message === "Data Updated") {
            childRef.current.triggerAlert(
              JSON.stringify({
                message: `${selectedStatusName}'s item has been updated successfully`,
                messageType: messageType,
              })
            );
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          } else {
            childRef.current.triggerAlert(
              JSON.stringify({
                message: `${selectedStatusName} item is already exists`,
                messageType: "warning",
              })
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const data = JSON.stringify({
        txtName: name,
        intParentId: parentid,
        intType: 1,
      });

      if ("" == name) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Name is required",
            messageType: "error",
          })
        );
        return false;
      }

      config.method = "POST";
      config.data = data;
      config.url = statusMasterAPI;
      axios(config)
        .then(function (response) {
          const messageType =
            response.data.code === 500 ? "warning" : "success";
          if (response.data.message === "SUCCESS") {
            childRef.current.triggerAlert(
              JSON.stringify({
                message: `New ${selectedStatusName}'s item has been added successfully`,
                messageType: messageType,
              })
            );
          } else {
            childRef.current.triggerAlert(
              JSON.stringify({
                // message: response.data.message,
                message: `${selectedStatusName} item is already exists`,
                messageType: "warning",
              })
            );
          }
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (tempRefs.current === false) {
      let data = {
        requestData: {
          intType: 4,
          intParentId: 0,
        },
        API: statusMasterAPI,
        setData: setParentIds,
      };
      _getDropdownListData(data);
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const fetchResult = (page, rowsPerPage, e) => {
    setParentId(e);

    const data = {
      intType: 10,
      intParentId: e,
      intLimit: rowsPerPage || 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
    };

    config.method = "POST";
    config.data = data;
    config.url = statusMasterAPI;
    axios(config)
      .then(function (response) {
        console.log({ status: response.data.result });
        setFetchDataList(response.data.result);
        setTotalCount(response.data.totalCount);
        fetchName(e);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchName = (id) => {
    const data = {
      intType: 7,
      intStatusId: id,
    };

    config.method = "POST";
    config.data = data;
    config.url = statusMasterAPI;
    axios(config)
      .then(function (response) {
        setSelectedStatusName(response.data.result[0].name);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const handleEditPositions = (
  //   id,
  //   name
  // ) => {
  //   const data = {
  //     intStatusId: id,
  //       txtName: name,
  //       intType: 6,
  //   }

  //   config.method = "POST";
  //   config.data = data;
  //   config.url = statusMasterAPI;
  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data.result);
  //       // setFetchDataList(response.data.result)
  //       // setTotalCount(response.data.totalCount);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const columns = useMemo(() => [
    {
      name: `${selectedStatusName} List`,
      field: "id",
      sortable: true,
      selector: (row) => row.name,
      width: "70%",
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <Tooltip title={`Edit this ${selectedStatusName} type`} arrow>
          <IconButton
            color="success"
            onClick={() => handleEditConfig(row.id, row.name)}
            aria-label="add an alarm"
          >
            <EditLocationOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
      width: "15%",
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <Tooltip title={`Delete this ${selectedStatusName} type`} arrow>
          <IconButton
            color="error"
            onClick={() => handleDeleteConfig(row.id)}
            aria-label="add an alarm"
          >
            <DeleteTwoToneIcon />
          </IconButton>
        </Tooltip>
      ),
      width: "15%",
    },
  ]);

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <AlertMessage ref={childRef} />
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Status
            </Typography>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={12}
            md={12}
          ></Grid>
        </Grid>

        <Grid container spacing={2}></Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl fullWidth error={errors.intParentId ? true : false}>
                <InputLabel id="demo-simple-select-label">
                  Choose configuration
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Parent Id"
                  id="demo-simple-select"
                  onChange={(e) => fetchResult(1, rowsPerPage, e.target.value)}
                >
                  {parentids.length != 0 &&
                    parentids.map((row) => {
                      return (
                        <MenuItem key={row.id} value={row.id}>
                          {row.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            {parentid != 0 && (
              <>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="text"
                    label="Add New"
                    fullWidth
                    onChange={(e) => {
                      setName(e.target.value);
                      setUpdateObj((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    value={name}
                  />
                </Grid>

                <Grid
                  textAlign="center"
                  item
                  className="form-lable"
                  xs={12}
                  lg={12}
                  md={12}
                >
                  <Grid item xs={12} md={12} lg={12} textAlign="right">
                    <Button
                      variant="primary"
                      className={`btn-primary`}
                      type="submit"
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            {parentid != 0 && (
              <Grid
                className="small_table"
                xs={12}
                lg={12}
                md={12}
                style={{ marginTop: "1rem" }}
              >
                <DataTable
                  pagination
                  paginationServer
                  fixedHeader
                  fixedHeaderScrollHeight="550px"
                  columns={columns}
                  data={fetchDataList}
                  progressPending={loading}
                  paginationTotalRows={totalCount}
                  paginationDefaultPage={currentPage}
                  paginationRowsPerPageOptions={[1, 10, 25, 50, 100]}
                  paginationPerPage={rowsPerPage}
                  onChangePage={handlePageChange}
                  // onSort={handleSort}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Configuration;
