import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import Layout from "../../layout/Layout";
import { config, manageInvoiceAPI, purchaseOrderAPI } from "../../config";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import moment from "moment/moment";
import VisibilityIcon from "@mui/icons-material/Visibility";

let field = "id",
  orderBy = "desc";

function InvoiceList() {
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState(0);
  const [clientMasterList, setClientMasterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnName, setColumnName] = useState(0);
  const [sortDirection, setSortDirection] = useState(0);

  let tempRefs = useRef(false);

  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (
    page,
    rowsPerPage,
    columnName = "",
    sortDirection = ""
  ) => {
    // if (tempRefs.current === false) {
    // Set loading to true and clear the existing data
    setLoading(true);
    setClientMasterList([]);

    const data = JSON.stringify({
      txtColumnName: columnName,
      txtSortDirection: sortDirection,
      txtInvoiceType: orderType,
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      intType: 16,
    });

    config.method = "POST";
    config.data = data;
    config.url = manageInvoiceAPI;

    axios(config)
      .then(function (response) {
        console.log(
          "🚀💻 ~ file: InvoiceList.jsx:69 ~ response:",
          response.data
        );

        setClientMasterList(response.data.data);
        setTotalCount(response.data.total_count);
        setLoading(false); // Set loading to false after data is received
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false); // Set loading to false in case of error
      });
    // }
    // tempRefs.current = true;
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!clientMasterList.length) return; // Avoid fetching if there's no data initially
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const [orderType, setOrderType] = useState(0);

  const [ioTypeList, setIoTypeList] = useState([
    { label: "All", value: 0 },
    { label: "Invoice", value: 1 },
    { label: "Proforma", value: 2 },
    { label: "Credit Note", value: 3 },
  ]);

  function handleInvoiceType(invoiceType) {
    if (invoiceType == 1) {
      return "Invoice";
    } else if (invoiceType == 2) {
      return "Proforma";
    } else if (invoiceType == 3) {
      return "Credit Note";
    }
  }

  const handleEditRole = (id) => {
    navigate(`/invoice/view/${id}`);
  };

  const handleEditInvoice = (id) => {
    navigate(`/invoice/edit/${id}`);
  };

  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  const columns = useMemo(
    () => [
      {
        name: "Invoice No.",
        field: "id",
        sortable: true,
        selector: (row) => row.invoice_no,
        width: "10%",
      },
      {
        name: "Company Name",
        field: "id",
        sortable: true,
        selector: (row) => row.company_name,
        width: "16%",
      },
      {
        name: "Created At",
        field: "id",
        sortable: true,
        width: "17%",
        selector: (row) =>
          row.createdAt
            ? moment(row.createdAt)
                .subtract(2.3, "hours")
                .format("DD-MM-YYYY HH:mm a")
            : "-",
      },
      {
        name: "Invoice Type",
        field: "id",
        sortable: true,
        selector: (row) => handleInvoiceType(row.invoice_type),
      },
      {
        name: "P.O ref. No.",
        field: "id",
        width: "19%",
        selector: (row) => row.po_ref_no,
      },
      // {
      //   name: "Status",
      //   field: "id",
      //   sortable: true,
      //   selector: (row) => row.status,
      // },
      {
        name: "Total Amount with GST",
        field: "id",
        selector: (row) => twoDecimal(row.total_with_gst),
        width: "15%",
      },
      {
        name: "Action",
        field: "id",
        cell: (row) => (
          <IconButton
            color="success"
            onClick={() => handleEditRole(row.invoiceId)}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      },
      {
        name: "Edit",
        field: "id",
        cell: (row) => (
          <IconButton
            color="warning"
            onClick={() => handleEditInvoice(row.invoiceId)}
          >
            <EditLocationOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    []
  );

  const handleClear = async () => {
    if (orderType != 0) window.location.reload();
  };

  const handleSort = (column, sortDirection) => {
    console.log("handleSort called", column, sortDirection); // Debugging line

    if (column.name) {
      let columnName = column.name.toLowerCase();
      setColumnName(columnName);
      setSortDirection(sortDirection);
      console.log(columnName, sortDirection);

      fetchUsers(1, rowsPerPage, columnName, sortDirection); // Ensure fetchUsers is being called
    }
  };

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Invoice List
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Invoice</Typography>
              <Typography color="text.primary">List</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Invoice Type"
                    value={orderType}
                    onChange={(e) => setOrderType(e.target.value)}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end" sx={{ mr: 2 }}>
                    //       <IconButton onClick={handleClear}>
                    //         <ClearIcon sx={{ color: "#ff0a54" }} />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  >
                    {ioTypeList.map((row, index) => (
                      <MenuItem value={row.value} key={index}>
                        {row.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                spacing={1}
                justifyContent="flex-start"
                alignItems={`center`}
              >
                <Grid item>
                  <LoadingButton
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      tempRefs.current = false;
                      fetchUsers(1);
                    }}
                  >
                    Search
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  size="medium"
                  variant="contained"
                  color="success"
                  className="button_first"
                  href="/invoice/create"
                  startIcon={<AddIcon />}
                >
                  Add Invoice
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(-1)}
                  startIcon={<ArrowBackIosIcon />}
                >
                  Back
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <Grid>
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={clientMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 20, 50, 100, 500, 1000]}
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

// function InvoiceList() {
//   const navigate = useNavigate();

//   const [totalCount, setTotalCount] = useState(0);
//   const [clientMasterList, setClientMasterList] = useState([]);

//   const [loading, setLoading] = useState(false);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);

//   let tempRefs = useRef(false);
//   useEffect(() => {
//     if (tempRefs.current === false) {
//       fetchUsers(1);
//     }
//   }, []);

//   const fetchUsers = async (page, rowsPerPage) => {
//     if (tempRefs.current === false) {
//       // Set loading to true and clear the existing data
//       setLoading(true);
//       setClientMasterList([]);

//       const data = JSON.stringify({
//         txtInvoiceType: orderType,
//         intLimit: rowsPerPage ? rowsPerPage : 10,
//         intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
//         intType: 2,
//       });

//       config.method = "POST";
//       config.data = data;
//       config.url = manageInvoiceAPI;

//       axios(config)
//         .then(function (response) {
//           console.log(
//             "🚀💻 ~ file: InvoiceList.jsx:69 ~ response:",
//             response.data
//           );

//           setClientMasterList(response.data.result);
//           setTotalCount(response.data.totalCount);
//           setLoading(false); // Set loading to false after data is received
//         })
//         .catch(function (error) {
//           console.log(error);
//           setLoading(false); // Set loading to false in case of error
//         });
//     }
//     tempRefs.current = true;
//   };

//   const handlePageChange = (page) => {
//     tempRefs.current = false;
//     fetchUsers(page, rowsPerPage);
//     setCurrentPage(page);
//   };

//   const handleRowsPerPageChange = async (newRowsPerPage) => {
//     if (!clientMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
//     tempRefs.current = false;
//     fetchUsers(1, newRowsPerPage);
//     setRowsPerPage(newRowsPerPage);
//     setCurrentPage(1);
//   };

//   // const handleSort = (column, sortDirection) => {
//   //   setLoading(true);
//   //   setTimeout(() => {
//   //     field = column.field;
//   //     orderBy = sortDirection;
//   //     fetchUsers(1);
//   //     setLoading(false);
//   //   }, 100);
//   // };
//   //

//   const [orderType, setOrderType] = useState(0);

//   const [ioTypeList, setIoTypeList] = useState([
//     { label: "All", value: 0 },
//     { label: "Invoice", value: 1 },
//     { label: "Proforma", value: 2 },
//     { label: "Credit Note", value: 3 },
//   ]);

//   function handleInvoiceType(invoiceType) {
//     if (invoiceType == 1) {
//       return "Invoice";
//     } else if (invoiceType == 2) {
//       return "Proforma";
//     } else if (invoiceType == 3) {
//       return "Credit Note";
//     }
//   }

//   const handleEditRole = (id) => {
//     navigate(`/invoice/view/${id}`);
//   };

//   const handleEditInvoice = (id) => {
//     navigate(`/invoice/edit/${id}`);
//   };

//   const twoDecimal = (x) => {
//     return Number.parseFloat(x).toFixed(2);
//   };

//   const columns = useMemo(() => [
//     {
//       name: "Invoice No.",
//       field: "id",
//       sortable: true,
//       selector: (row) => row.invoice_no,
//     },
//     {
//       name: "Company Name",
//       field: "id",
//       sortable: true,
//       selector: (row) => row.company_name,
//     },
//     {
//       name: "Created At",
//       field: "id",
//       sortable: true,
//       selector: (row) =>
//         row.createdAt
//           ? moment(row.createdAt)
//               .subtract(2.3, "hours")
//               .format("DD-MM-YYYY HH:mm a")
//           : "-",
//     },
//     {
//       name: "Invoice Type",
//       field: "id",
//       sortable: true,
//       selector: (row) => handleInvoiceType(row.invoice_type),
//     },
//     {
//       name: "P.O ref. No.",
//       field: "id",
//       cell: (row) => row.po_ref_no,
//     },
//     {
//       name: "Status",
//       field: "id",
//       sortable: true,
//       selector: (row) => row.status,
//     },
//     {
//       name: "Total Amount with GST",
//       field: "id",
//       cell: (row) => twoDecimal(row.total_with_gst),
//     },
//     // {
//     //   name: "Amount",
//     //   field: "id",
//     //   cell: (row) => row.total_with_gst,
//     // },
//     {
//       name: "Action",
//       field: "id",
//       cell: (row) => (
//         // <Button
//         //   onClick={() => handleEditRole(row.invoiceId)}
//         //   className="align-cancelbutton button-33"
//         // >
//         //   View
//         // </Button>

//         <IconButton
//           color="success"
//           onClick={() => handleEditRole(row.invoiceId)}
//         >
//           <VisibilityIcon />
//         </IconButton>
//       ),
//     },
//     {
//       name: "Edit",
//       field: "id",
//       cell: (row) => (
//         <IconButton
//           color="warning"
//           onClick={() => handleEditInvoice(row.invoiceId)}
//         >
//           <EditLocationOutlinedIcon />
//         </IconButton>
//       ),
//     },
//   ]);

//   const handleClear = async () => {
//     orderType != 0 && window.location.reload();
//   };

//   return (
//     <Layout>
//       <Grid className="employee-form bold-header">
//         <Grid container spacing={3}>
//           <Grid
//             textAlign="center"
//             item
//             className="form-lable"
//             xs={12}
//             lg={6}
//             md={6}
//           >
//             <Typography className="page_title" textAlign="left">
//               Invoice List
//             </Typography>
//             <Breadcrumbs aria-label="breadcrumb">
//               <Link underline="hover" color="inherit" href="/dashboard">
//                 Dashboard
//               </Link>
//               <Typography color="text.primary">Invoice</Typography>
//               <Typography color="text.primary">List</Typography>
//             </Breadcrumbs>
//           </Grid>
//         </Grid>

//         <Grid container spacing={2} sx={{ mt: 2 }}>
//           <Grid item xs={12} md={6}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     select
//                     label="Invoice Type"
//                     value={orderType}
//                     onChange={(e) => setOrderType(e.target.value)}
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end" sx={{ mr: 2 }}>
//                           <IconButton onClick={handleClear}>
//                             <ClearIcon sx={{ color: "#ff0a54" }} />
//                           </IconButton>
//                         </InputAdornment>
//                       ),
//                     }}
//                   >
//                     {ioTypeList.map((row, index) => (
//                       <MenuItem value={row.value} key={index}>
//                         {row.label}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                 </FormControl>
//               </Grid>

//               <Grid
//                 container
//                 item
//                 xs={12}
//                 md={6}
//                 spacing={1}
//                 justifyContent="flex-start"
//                 alignItems={`center`}
//               >
//                 <Grid item>
//                   <LoadingButton
//                     size="large"
//                     variant="contained"
//                     color="primary"
//                     onClick={() => {
//                       tempRefs.current = false;
//                       fetchUsers(1);
//                     }}
//                   >
//                     Search
//                   </LoadingButton>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>

//           {/* Add PO and Back buttons container */}
//           <Grid item xs={12} md={6}>
//             <Grid container spacing={2} justifyContent="flex-end">
//               <Grid item>
//                 <LoadingButton
//                   size="medium"
//                   variant="contained"
//                   color="success"
//                   className="button_first"
//                   href="/invoice/create"
//                   startIcon={<AddIcon />}
//                 >
//                   Add Invoice
//                 </LoadingButton>
//               </Grid>
//               <Grid item>
//                 <LoadingButton
//                   size="medium"
//                   variant="contained"
//                   color="primary"
//                   onClick={() => navigate(-1)}
//                   startIcon={<ArrowBackIosIcon />}
//                 >
//                   Back
//                 </LoadingButton>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid className="form_design">
//           <Grid>
//             <DataTable
//               pagination
//               paginationServer
//               fixedHeader
//               fixedHeaderScrollHeight="550px"
//               columns={columns}
//               data={clientMasterList}
//               progressPending={loading}
//               paginationTotalRows={totalCount}
//               paginationDefaultPage={currentPage}
//               paginationRowsPerPageOptions={[1, 10, 25, 50, 100]}
//               paginationPerPage={rowsPerPage}
//               onChangePage={handlePageChange}
//               onChangeRowsPerPage={handleRowsPerPageChange}
//             />
//           </Grid>
//         </Grid>
//       </Grid>
//     </Layout>
//   );
// }

export default InvoiceList;
