import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";

import Layout from "../../layout/Layout";

import { config, payslipAPI } from "../../config";
import axios from "axios";
import dayjs from "dayjs";

import companyLogo from "./../../assets/images/Logo/logo.png";

import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";

import PaySlipPDF from "./pdf/PaySlipPdf";

function PaySlip() {
  const { employee_id, date } = useParams();

  const navigate = useNavigate();

  const [lstPayslip, setlstPayslip] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = {
      intType: 2,
      intEmployeeID: employee_id,
      txtPayslipFromDate: dayjs(date).format("YYYY-MM-DD"),
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = payslipAPI;

    await axios(config)
      .then(function (response) {
        setlstPayslip(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ref = React.createRef();

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid
          textAlign="center"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
          <Typography className="page_title" textAlign="left">
            PaySlip
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography color="text.primary">payslip</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          textAlign="right"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
          <LoadingButton
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </LoadingButton>
        </Grid>
      </Grid>
      <div className="empty-space-40"></div>
      <Grid container spacing={2} className="form_design">
        <Grid item xs={12} md={12} lg={12}>
          {lstPayslip.map((row, key) => (
            <>
              <Grid
                className="element-to-print"
                paddingLeft={`55px`}
                paddingTop={`55px`}
                paddingRight={`55px`}
                ref={ref}
              >
                <Grid className="page" width={`100%`}>
                  <table>
                    <tr>
                      <td colSpan={4}>
                        <table>
                          <tr>
                            <td colSpan={3} width={`75%`} className="no-border">
                              <table>
                                <tr>
                                  <td width={`35%`} className="no-border">
                                    <Typography className={`td text-bold`}>
                                      Employee Name
                                    </Typography>
                                  </td>
                                  <td width={`65%`} className="no-border">
                                    <Typography className={`td`}>
                                      {row.employee_name}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      Designation
                                    </Typography>
                                  </td>
                                  <td className="no-border">
                                    <Typography className={`td`}>
                                      {row.position}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      Company Name
                                    </Typography>
                                  </td>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      KG BUILD TECH
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      Department
                                    </Typography>
                                  </td>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      {row.department_name}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      Employee Number
                                    </Typography>
                                  </td>
                                  <td className="no-border">
                                    <Typography className={`td `}>
                                      {row.employee_no}
                                    </Typography>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td className="no-border" colSpan={2} width={`25%`}>
                              <Typography className={`td text-bold`}>
                                Date
                              </Typography>
                              <Typography className={`td`}>
                                {row.payslip_date}
                              </Typography>
                            </td>

                            <td width={`10%`} className="no-border">
                              <img
                                src={companyLogo}
                                alt="KG"
                                width={`200px`}
                                height={`auto`}
                              />
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td width={`35%`}>
                        <Typography className={`td text-center text-bold`}>
                          Emoluments{" "}
                        </Typography>
                      </td>
                      <td width={`15%`}>
                        <Typography className={`td text-center text-bold`}>
                          Amount Rs.{" "}
                        </Typography>
                      </td>
                      <td width={`35%`}>
                        <Typography className={`td text-center text-bold`}>
                          Deductions{" "}
                        </Typography>
                      </td>
                      <td width={`15%`}>
                        <Typography className={`td text-center text-bold`}>
                          Amount Rs.{" "}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className={`td`}>
                          House Rent Allowance{" "}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.house_allowance.toLocaleString()}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>
                          {/* General Provident Fund{" "} */}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {/* {row.provident_fund.toLocaleString()} */}
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Typography className={`td`}>
                          Dearness Allowance{" "}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.dearness_allowance.toLocaleString()}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>
                          Salary Advance{" "}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.salary_advance.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className={`td`}></Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}></Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>
                          Personal Advance
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right `}>
                          {row.personal_advance.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className={`td`}></Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}></Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>RD /ESI /EPFO</Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.rd_esi.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className={`td`}>
                          Medical Allowance{" "}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.medical_allowance.toLocaleString()}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>
                          Life Insurance Corporation
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.life_insurance.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Typography className={`td`}>
                          Vehicle Allowance{" "}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.vehicle_allowance.toLocaleString()}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>
                          Postal Life Insurance
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.personal_life_insurance.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Typography className={`td`}>
                          Other Allowance
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.other_allowance.toLocaleString()}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>
                          Income Tax(TDS)
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.income_tax.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Typography className={`td`}></Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}></Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>Loss of Pay</Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.leave_amount.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className={`td`}>Days Paid</Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.no_of_days_working.toLocaleString()}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td`}>
                          No of Days leave taken
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.no_of_days_leave_taken.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className={`td`}>Basic Pay</Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right`}>
                          {row.basic_pay.toLocaleString()}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-bold`}>
                          Total Deductions
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right text-bold`}>
                          {row.total_deductions.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Typography className={`td text-bold`}>
                          Gross Pay
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right text-bold`}>
                          {" "}
                          {row.gross_pay.toLocaleString()}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-bold`}>
                          Net Pay
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`td text-right text-bold`}>
                          {row.net_pay.toLocaleString()}
                        </Typography>
                      </td>
                    </tr>
                  </table>
                </Grid>
              </Grid>

              {/* <Grid
                container
                spacing={2}
                justifyContent={`flex-end`}
                sx={{ my: 2 }}
              >
                <Pdf
                  targetRef={ref}
                  filename={`Pay Slip-${moment()
                    .locale("en-in")
                    .format("LLL")} .pdf`}
                  y={7}
                  x={4}
                  scale={0.7}
                >
                  {({ toPdf }) => (
                    <Button
                      className="button"
                      onClick={toPdf}
                      variant="contained"
                    >
                      <PictureAsPdfIcon />
                      Generate Pdf
                    </Button>
                  )}
                </Pdf>
              </Grid> */}
              <Grid
                container
                spacing={2}
                justifyContent={`flex-end`}
                sx={{ my: 2 }}
              >
                <Grid item sx={{ mr: 6.2 }}>
                  <PaySlipPDF
                    paySlip={lstPayslip[0]}
                    companyLogo={companyLogo}
                  />
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
}
export default PaySlip;
