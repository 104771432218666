import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import Layout from "../../layout/Layout";
import { Grid, Button, FormControl, Typography } from "@mui/material";

// FORM VALIDATION IMPORTS

import {
  config,
  paymentRequestApprovalAPI,
} from "../../config";
import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";
import moment from "moment";

function PaymentRequestView() {
  const { status, requestID } = useParams();
  const navigate = useNavigate();

  const [paymentStatus, setPaymentStatus] = useState(0)
  let tempRef = useRef(false);
  useEffect(() => {

    if (tempRef.current === false) {
      const data = {
        intType: 2,
        intPaymentRequestID: requestID,
        intStatus: status,
        txtAdminApproval: 1,
        txtAdminApprovalDateTime: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = paymentRequestApprovalAPI;

      axios(config)
        .then(function (response) {
          setPaymentStatus(response.data.result[0])
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  return (
    <Layout>
      <Grid className="login-text-background list_advance">
        {0 === paymentStatus.status ?
          navigate("/adminlogin") :
          <Typography variant="h5" textAlign="center">
            Request {paymentStatus.status == 58 ? "Declined" : paymentStatus.status == 56 ? "Approved" : ""}. For more information kindly login to KG Build Tech
          </Typography>
        }

      </Grid>
    </Layout>
  );
}

export default PaymentRequestView;

