import React, { useState, useEffect, useRef } from "react";

//design imports from material
import {
  Grid,
  FormLabel,
  TextField,
  Typography,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";

//layout import
import Layout from "../../../layout/Layout";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StateList, { stateList } from "../../../components/StateList";

//date picker
import dayjs from "dayjs";

import axios from "axios";
import {
  config,
  labourWagesAPI,
  manageCompaniesAPI,
  statusMasterAPI,
  userData,
} from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import { useParams, useNavigate } from "react-router-dom";
import { _getDropdownListData } from "../../../service";
// import { useNavigate } from "react-router-dom";

function LabourWagesEdit() {
  const navigate = useNavigate();
  const { labourId } = useParams();
  const validationSchema = Yup.object().shape({
    // txtCompanyName: Yup.string().required("Company Name is required"),
    // txtAddress: Yup.string().required("Address is required"),
    // txtCity: Yup.string().required("Town / City is required"),
    // txtState: Yup.string().required("State is required"),
    // intPincode: Yup.string().required("Pin Code is required"),
    // txtPrimaryPhoneNumber: Yup.string().required("Primary Contact is required"),
    // txtSecondaryPhoneNumber: Yup.string().required("Secondary Contact is required"),
    // txtEmailId: Yup.string().required("Email Id is required"),
    // txtWebsiteLink: Yup.string().required("Website URL is required"),
    // txtPanNo: Yup.string().required("PAN Number is required"),
    // txtGstNo: Yup.string().required("GST Number is required"),
    // txtTanNo: Yup.string().required("TAN Number is required"),
    // txtBankName: Yup.string().required("Bank Name is required"),
    // txtBranch: Yup.string().required("Branch Name is required"),
    // txtAccountName: Yup.string().required("Account Name is required"),
    // txtAccountNo: Yup.string().required("Account Number is required"),
    // txtIfscCode: Yup.string().required("IFSC Code is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  let assetsRef = useRef(false);
  // useEffect(() => {
  //   if (assetsRef.current === false) {
  //     const data = JSON.stringify({
  //       intCompanyId: parseInt(companyId),
  //       intType: 4,
  //     });

  //     config.method = "POST";
  //     config.data = data;
  //     config.url = manageCompaniesAPI;
  //     // console.log({refData: data});
  //     // return;
  //     axios(config)
  //       .then(function (response) {
  //         setStateName(response.data.result);
  //         handleFormReset(response.data.result[0]);
  //         console.log(response.data.result[0]);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });

  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  // 13/07/23 - create labour edit
  const [labourTypesList, setLabourTypesList] = React.useState([]);
  const [statusList, setStatusList] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [labourType, setLabourType] = React.useState("");

  useEffect(() => {
    let labourData = {
      requestData: {
        intType: 4,
        intParentId: 46,
      },
      API: statusMasterAPI,
      setData: setLabourTypesList,
    };
    _getDropdownListData(labourData);
  }, []);

  useEffect(() => {
    if (assetsRef.current === false) {
      const data = JSON.stringify({
        intID: parseInt(labourId),
        intType: 7,
      });

      config.method = "POST";
      config.data = data;
      config.url = labourWagesAPI;

      axios(config)
        .then(function (response) {
          // setStateName(response.data.result);
          handleFormReset(response.data.result[0]);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        assetsRef.current = true;
      };
    }
  }, []);

  useEffect(() => {
    let statusData = {
      requestData: {
        intType: 2,
        intParentId: 1,
      },
      API: statusMasterAPI,
      setData: setStatusList,
    };
    _getDropdownListData(statusData);
  }, []);

  const handleFormReset = (data) => {
    let defaultValues = {};
    setLabourType(data.labour_type);
    setStatus(data.status);

    defaultValues.intLabourType = data.labour_type;
    defaultValues.txtLabourName = data.labour_name;
    defaultValues.intFullDayAmount = data.full_day_amount;
    defaultValues.intHalfDayAmount = data.half_day_amount;
    defaultValues.intMobileNumber = data.mobile_number;
    defaultValues.intStatus = data.status;
    reset({ ...defaultValues });
  };

  function onSubmit(data) {
    const updateData = JSON.stringify({
      intLabourType: data.intLabourType,
      txtLabourName: data.txtLabourName,
      intFullDayAmount: data.intFullDayAmount,
      intHalfDayAmount: data.intHalfDayAmount,
      intMobileNumber: data.intMobileNumber,
      intStatus: data.intStatus,
      intUserID: userData.emp_id,
      intType: 6,
      intID: parseInt(labourId),
    });

    config.method = "POST";
    config.data = updateData;
    config.url = labourWagesAPI;

    axios(config)
      .then(function (response) {
        // handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          navigate("/config/labour-wages");
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
  }

  const childRef = useRef();

  return (
    <>
      <Layout>
        <Grid className="employee-form">
          <AlertMessage ref={childRef} />
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Edit Labour Wages
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Typography color="text.primary">Configuration</Typography>
                <Typography color="text.primary">Labour-wages</Typography>
                <Typography color="text.primary">Edit</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="secondary"
                className="button_first"
                href="/config/labour-wages"
              >
                List Labour Wages
              </LoadingButton>
              <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>

          <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
            {/* Labour Wages Details */}
            <h3>Labour Wages Details</h3>
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                {/* labour type */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Labour Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Labour Type"
                    id="demo-simple-select"
                    value={labourType}
                    onChange={(e) => {
                      setLabourType(e.target.value);
                      setValue("intLabourType", e.target.value);
                    }}
                    // {...register("intLabourType")}
                  >
                    {labourTypesList.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <FormHelperText className="invalid-feedback">
                    {errors.intLabourType?.message}
                  </FormHelperText> */}
                </FormControl>
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  type="text"
                  focused
                  fullWidth
                  label="Labour Name"
                  error={errors.txtLabourName ? true : false}
                  {...register("txtLabourName")}
                  helperText={errors.txtLabourName?.message}
                />
              </Grid>
            </Grid>

            <div className="empty-space-20"></div>

            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="full day amount"
                  type="number"
                  label="Full Day Amount"
                  fullWidth
                  focused
                  {...register("intFullDayAmount")}
                  error={errors.intFullDayAmount ? true : false}
                  helperText={errors.intFullDayAmount?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  type="number"
                  focused
                  fullWidth
                  label="Half Day Amount"
                  error={errors.intHalfDayAmount ? true : false}
                  {...register("intHalfDayAmount")}
                  helperText={errors.intHalfDayAmount?.message}
                />
              </Grid>
            </Grid>

            <div className="empty-space-20"></div>

            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  type="number"
                  focused
                  fullWidth
                  label="Mobile Number"
                  error={errors.intMobileNumber ? true : false}
                  {...register("intMobileNumber")}
                  helperText={errors.intMobileNumber?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="State"
                    focused
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setValue("intStatus", e.target.value);
                    }}
                  >
                    {statusList.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <div className="empty-space-20"></div>

            {/* Button */}
            <Grid container className="submit-button" alignItems="center">
              <Grid item xs={12} lg={12} md={12}>
                <LoadingButton
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Layout>
    </>
  );
}

export default LabourWagesEdit;
