import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Breadcrumbs,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  config,
  reportsAPI,
  projectMasterAPI,
  leaveAPI,
  handleHrMasterAPI,
  manageCompaniesAPI,
} from "../../config";
import DataTable from "react-data-table-component";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as XLSX from "xlsx/xlsx.mjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import Charts2 from "../../components/charts/Charts2";
import AssessmentTwoToneIcon from "@mui/icons-material/AssessmentTwoTone";
import UpgradeTwoToneIcon from "@mui/icons-material/UpgradeTwoTone";
import { _getDropdownListData } from "../../service";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MuiLink from "@mui/material/Link";
import { Tooltip as ReactTooltip } from "react-tooltip";
function AttendanceReport() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  const handlePageChange = (page) => {
    tempRefs.current = false;
    getAttendanceReport(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    tempRefs.current = false;
    getAttendanceReport(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  // const handleSort = (column, sortDirection) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     field = column.field;
  //     orderBy = sortDirection;
  //     setLoading(false);
  //   }, 100);
  // };

  const columns = useMemo(
    () => [
      {
        name: "Employee name",
        field: "id",
        sortable: true,
        selector: (row) => (
          <span>{row.employee_name ? row.employee_name : "-"}</span>
        ),
        width: "20%",
      },
      {
        name: "Company name",
        field: "id",
        sortable: true,
        selector: (row) => (
          <span>{row.company_name ? row.company_name : "-"}</span>
        ),
        width: "18%",
      },
      {
        name: "Date",
        field: "id",
        sortable: true,
        selector: (row) =>
          row.date ? moment(row.date).format("DD-MM-YYYY") : "-",
      },
      {
        name: "Start Time",
        field: "id",
        selector: (row) =>
          row.start_time ? moment(row.start_time).format("hh:mm A") : "-",
        center: true,
      },
      {
        name: "End Time",
        field: "id",
        selector: (row) =>
          row.end_time ? moment(row.end_time).format("hh:mm A") : "-",
        center: true,
      },

      {
        name: "Working Hours",
        field: "id",
        sortable: true,
        selector: (row) => (row.working_hours ? row.working_hours : "-"),
        center: true,
      },
      {
        name: "Shift",
        field: "id",
        sortable: true,
        selector: (row) => {
          switch (parseInt(row.Shift)) {
            case 1:
              return "Day Shift";
            case 2:
              return "Night Shift";
            default:
              return "-";
          }
        },
        center: true,
      },
      {
        name: "Attendance Status",
        field: "id",
        sortable: true,
        selector: (row) => {
          switch (row.attandence_status) {
            case 1:
              return "Full Day";
            case 2:
              return "Half Day";
            case 3:
              return "Leave";
            default:
              return "-";
          }
        },
        center: true,
      },
    ],
    []
  );

  const downloadExcel = (page, rowsPerPage) => {
    const data = {
      intEmployeeID: employeeID,
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      txtFromDate: fromDate,
      txtToDate: toDate,
      intType: 9,
    };
    config.method = "POST";
    config.data = data;
    config.url = reportsAPI;

    axios(config)
      .then(function (response) {
        const newData = response.data["result"].map(
          ({ id, employee_id, created_at, ...row }) => {
            // Modify start_time and end_time fields to show only time
            row.start_time = row.start_time
              ? moment(row.start_time).format("hh:mm A")
              : "-";
            row.end_time = row.end_time
              ? moment(row.end_time).format("hh:mm A")
              : "-";

            // Modify attendance_status field based on status value
            switch (row.attandence_status) {
              case 1:
                row.attandence_status = "Full day";
                break;
              case 2:
                row.attandence_status = "Half day";
                break;
              case 3:
                row.attandence_status = "Leave";
                break;
              default:
                row.attandence_status = "-";
                break;
            }

            // Modify shift field based on shift value
            row.Shift =
              row.Shift == 1
                ? "Day shift"
                : row.Shift == 2
                ? "Night shift"
                : "-";

            return { ...row, employee_no: row.employee_no };
          }
        );

        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "report");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `KG-employee-attendance-report-${moment().format(
            "DD/MM/YYYY, h:mm:ss"
          )} hrs.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  const [attendanceReport, setAttendanceReport] = useState([]);
  const [fromDate, setFromDate] = React.useState(dayjs());
  const [toDate, setToDate] = React.useState(dayjs());
  const [totalCount, setTotalCount] = React.useState(0);

  const getAttendanceReport = (page, rowsPerPage) => {
    const data = {
      intEmployeeID: employeeID,
      intCompanyName: companyName,
      txtFromDate: fromDate.format("YYYY-MM-DD"),
      txtToDate: toDate.format("YYYY-MM-DD"),
      intLimit: rowsPerPage || 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      intType: 9,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = reportsAPI;
    console.log(config.data);
    axios(config)
      .then(function (response) {
        setAttendanceReport(response.data.result);
        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRefs1 = useRef(false);

  useEffect(() => {
    if (tempRefs1.current === false) {
      getAttendanceReport(1, rowsPerPage);
      return () => {
        tempRefs1.current = true;
      };
    }
  }, []);

  const [employeeID, setEmployeeID] = useState(0);
  const [EmployeeList, setEmployeeList] = useState([]);
  let tempRefs2 = useRef(false);

  useEffect(() => {
    if (tempRefs2.current === false) {
      const data = {
        intType: 7,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          setEmployeeList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs2.current = true;
      };
    }
  }, []);

  // Getting Company List
  const [companyList, setComanyList] = useState([]);
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    let data = {
      requestData: {
        intType: 6,
      },
      API: manageCompaniesAPI,
      setData: setComanyList,
    };

    _getDropdownListData(data);
  }, []);

  const [isChartButtonClicked, setChartButtonClicked] = useState(false);
  const [bar, setBar] = useState(false);
  const [pie, setPie] = useState(false);
  const [line, setLine] = useState(false);
  const [area, setArea] = useState(false);
  const [radar, setRadar] = useState(false);
  const [doughnut, setDoughnut] = useState(false);
  const [polarArea, setPolarArea] = useState(false);
  const [bubble, setBubble] = useState(false);

  const handleClick = (name) => {
    switch (name) {
      case "bar": {
        setPie(false);
        setLine(false);
        setArea(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setBar(true);
        break;
      }
      case "pie": {
        setBar(false);
        setLine(false);
        setArea(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setPie(true);
        break;
      }
      case "line": {
        setBar(false);
        setPie(false);
        setArea(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setLine(true);
        break;
      }
      case "area": {
        setBar(false);
        setPie(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setLine(false);
        setArea(true);
        break;
      }
      case "radar": {
        setBar(false);
        setPie(false);
        setLine(false);
        setArea(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setRadar(true);
        break;
      }
      case "doughnut": {
        setBar(false);
        setPie(false);
        setLine(false);
        setArea(false);
        setRadar(false);
        setPolarArea(false);
        setBubble(false);
        setDoughnut(true);
        break;
      }
      case "pool": {
        setBar(false);
        setPie(false);
        setLine(false);
        setArea(false);
        setRadar(false);
        setDoughnut(false);
        setBubble(false);
        setPolarArea(true);
        break;
      }
      case "bubble": {
        setBar(false);
        setPie(false);
        setLine(false);
        setArea(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(true);
        break;
      }

      default:
        break;
    }
  };

  const chartContainerStyle = {
    width: "full", // Set your desired width
    height: "800px", // Set your desired height
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
  };

  const buttonStyle = {
    margin: "5px 3px",
    padding: "0.5rem",
    borderRadius: "0.3em",
  };

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Employee Attendance Report
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MuiLink underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </MuiLink>
                <Typography color="text.primary">Reports</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <div className="empty-space-40"></div>
        <Grid
          href="#top"
          container
          spacing={2}
          margin={`15px 0px`}
          padding={`10px 15px 0px 0px`}
        >
          <Grid item xs={12} lg={3} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Employee List
              </InputLabel>
              <Select
                labelId="Select Project"
                id="demo-simple-select"
                value={employeeID}
                label="Employee List"
                onChange={(e) => {
                  setEmployeeID(e.target.value);
                }}
              >
                {EmployeeList.map((row, index) => (
                  <MenuItem value={row.id} key={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Company Name
              </InputLabel>
              <Select
                labelId="Select Project"
                id="demo-simple-select"
                value={companyName}
                label="Company name"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              >
                {companyList.map((row, index) => (
                  <MenuItem value={row.id} key={index}>
                    {row.company_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select From Date"
                value={fromDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setFromDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select To Date"
                value={toDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setToDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} lg={3} md={3}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                getAttendanceReport(1, rowsPerPage);
              }}
            >
              Search
            </LoadingButton>

            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setEmployeeID(0);
                setToDate(dayjs());
                setFromDate(dayjs());
                setCompanyName("");
              }}
            >
              Reset
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid className="form_design" id="table">
          <Grid>
            {attendanceReport.length > 0 && (
              <>
                {/* <Grid
                  container
                  spacing={2}
                  margin={`15px 0px`}
                  padding={`10px 15px 0px 0px`}
                >
                  <Grid item xs={12} lg={4} md={4}>
                    <Typography>
                      Total Worked Time:{" "}
                      {`${totalHours} hours & ${totalMinutes} minutes`}
                    </Typography>
                    <Typography>{`${workingDays} days (8 hrs as 1 day)`}</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <Typography>Total Amount Spent: {totalAmount}</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <Typography>Total Amount Received: </Typography>
                  </Grid>
                </Grid> */}
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} lg={6} md={6}></Grid>
                  <Grid item xs={12} lg={6} md={6} textAlign="right">
                    <Button
                      href="#search"
                      color="success"
                      variant="contained"
                      onClick={() => downloadExcel(currentPage, rowsPerPage)}
                      startIcon={<DescriptionIcon />}
                      sx={{
                        marginTop: "1rem",
                        marginRight: "1rem", // Add margin to create space between buttons
                        borderRadius: "5px !important",
                      }}
                    >
                      Export to Excel
                    </Button>

                    <Button
                      href="#chart"
                      color="success"
                      variant="contained"
                      onClick={() => setChartButtonClicked(true)}
                      startIcon={<DescriptionIcon />}
                      sx={{
                        marginTop: "1rem",
                        borderRadius: "5px !important",
                      }}
                    >
                      View in chart
                    </Button>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                ``
              </>
            )}
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={attendanceReport}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[
                1, 10, 25, 50, 100, 500, 1000, 10000, 50000, 100000,
              ]}
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>

          {isChartButtonClicked && (
            <>
              <Grid className="form_design" id="chart">
                <Charts2 chartData={attendanceReport} />
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
                }}
              >
                <Button size="small" href="#top" className="btn-primary">
                  <UpgradeTwoToneIcon fontSize="small" /> Back To Top
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}

export default AttendanceReport;
