import React, { useEffect, useRef, useState, useMemo } from "react";
import { Grid, Typography, TextField, InputAdornment } from "@mui/material";
import axios from "axios";
import Layout from "../../../layout/Layout";
import { config, vendorAPI } from "../../../config";
import { useNavigate } from "react-router-dom";
import { _getDropdownListData } from "../../../service";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";

let field = "id",
  orderBy = "desc";

function VendorList() {
  const navigate = useNavigate();

  const [VendorList, setVendorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState("");

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (
    page,
    rowsPerPage,
    searchString = "",
    columnName = "",
    sortDirection = ""
  ) => {
    const data = JSON.stringify({
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      txtSearchString: searchString,
      txtColumnName: columnName,
      txtSortDirection: sortDirection,
      intType: 2,
    });

    config.method = "POST";
    config.data = data;
    config.url = vendorAPI;

    axios(config)
      .then(function (response) {
        setVendorList(response.data["result"]);
        console.log(response.data["result"]);
        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSearch = (searchString) => {
    console.log(searchString);
    setSearchString(searchString);
    fetchUsers(1, rowsPerPage, searchString);
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage, searchString);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!VendorList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage, searchString);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    console.log(column.name, sortDirection);
    fetchUsers(1, rowsPerPage, searchString, column.name, sortDirection);
  };

  const handleEditRole = (id) => {
    navigate(`/vendor/edit/${id}`);
  };

  const columns = useMemo(() => [
    {
      name: "Vendor Name",
      field: "id",
      sortable: true,
      width: "25%",
      selector: (row) => row.name,
    },
    {
      name: "Company Name",
      field: "id",
      sortable: true,
      width: "15%",
      selector: (row) => row.company_name,
    },
    {
      name: "Contact Person",
      field: "id",
      sortable: true,
      width: "32%",
      selector: (row) => row.contact_person,
    },
    {
      name: "Phone",
      field: "id",
      sortable: true,
      width: "12%",
      selector: (row) => row.mobile_no,
    },
    {
      name: "GST Number",
      field: "id",
      sortable: true,
      width: "15%",
      selector: (row) => row.gst,
    },
    // {
    //   name: "Position",
    //   field: "id",
    //   sortable: true,
    //   selector: (row) => (
    //     <Tooltip title={row.contact_person} arrow>
    //       <span>{row.contact_person}</span>
    //     </Tooltip>
    //   ),
    // },
    {
      name: "Status",
      field: "id",
      width: "15%",
      cell: (row) => (
        <>
          <Typography
            className={`${
              row.status === 2 ? "active-status" : "inactive-status"
            } `}
          >
            {row.status === 2 ? `Active` : "Inactive"}
          </Typography>
        </>
      ),
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <IconButton
          color="success"
          onClick={() => handleEditRole(row.id)}
          aria-label="add an alarm"
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              List Vendor
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">List Vendor</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="success"
              className="button_first"
              href="/vendor/create"
              startIcon={<AddIcon />}
            >
              Add Vendor
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{ mr: 4, mt: 2, justifyContent: `center` }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id="myTextField"
              label="Search"
              placeholder="Search"
              onKeyUp={(e) => handleSearch(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "40px",
                  paddingLeft: "20px",
                },
                "& .MuiInputBase-input": {
                  textAlign: "center",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid className="form_design">
          <Grid>
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={VendorList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item className="form-lable" xs={12} lg={4} md={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Company</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Company"
              id="demo-simple-select"
            >
              {lstCompany.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item className="form-lable" xs={12} lg={4} md={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Vendor Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Company"
              id="demo-simple-select"
            >
              {lstVendorType.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item className="form-lable" xs={12} lg={4} md={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Status"
            >
              <MenuItem value="2">Active</MenuItem>
              <MenuItem value="3">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        margin={`15px 0px`}
        padding={`10px 15px 0px 0px`}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          textAlign={`right`}
          display={`flex`}
          justifyContent={`flex-end`}
        >
          <Suspense fallback={<div></div>}>
            <Pagination
              totalCount={totalCount}
              handleReportPagination={handleReportPagination}
            />
          </Suspense>
        </Grid>
      </Grid>

      <div className={`table-responsive`}>
        <table className={`table`}>
          <thead>
            <th>
              <Typography variant="h6">S.No</Typography>
            </th>
            <th>
              <Typography variant="h6">Company Name </Typography>
            </th>
            <th>
              <Typography variant="h6">Vendor Name</Typography>
            </th>
            <th>
              <Typography variant="h6">Vendor Type</Typography>
            </th>
            <th>
              <Typography variant="h6">Mobile No </Typography>
            </th>

            <th>
              <Typography variant="h6">Contact Person</Typography>
            </th>

            <th>
              <Typography variant="h6">Status </Typography>
            </th>
            <th>
              <Typography variant="h6">Action </Typography>
            </th>
          </thead>
          <tbody>
            {vendorList.length > 0 ? (
              vendorList.map((row, index) => {
                return (
                  <tr key={index}>
                    <td className={`text-center`}>
                      <Typography>{index + 1}</Typography>
                    </td>

                    <td>
                      <Typography>{row.company_name}</Typography>
                    </td>
                    <td>
                      <Typography>{row.name}</Typography>
                    </td>
                    <td>
                      <Typography>{row.vendor_type}</Typography>
                    </td>
                    <td>
                      <Typography>{row.mobile_no}</Typography>
                    </td>
                    <td>
                      <Typography>{row.contact_person}</Typography>
                    </td>

                    <td className={`text-center`}>
                      <Typography
                        className={`${
                          row.status === 2 ? `active-status` : `inactive-status`
                        } `}
                      >
                        {row.status_name}
                      </Typography>
                    </td>
                    <td className={`text-center`}>
                      <Tooltip title={`Edit Role`}>
                        <Create
                          cursor={`pointer`}
                          onClick={() => handleEditRole(row.id)}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className={`text-center`}>
                  <NotFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div> */}
    </Layout>
  );
}

export default VendorList;
