import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import {
  Grid,
  Tooltip,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { config, termsAndConditionsAPI } from "../../../config";
import NotFound from "../../../components/NotFount";
import { Create } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Layout from "../../../layout/Layout";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";
import { Tooltip as ReactTooltip } from "react-tooltip";

let field = "id",
  orderBy = "desc";

function TermsConditionsList() {
  const navigate = useNavigate();
  const [lstCompany, setCompanyList] = useState([]),
    [lstVendorType, setVendorTypeList] = useState([]);

  let tempRefs = useRef(false);

  useEffect(() => {
    if (tempRefs.current === false) {
      // let requestData = {
      //   intType: 2,
      //   intParentId: 38,
      // };
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  let intOffset = 0;
  const limit = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [termsandConditions, setTermsandConditions] = useState([]);

  const [columnName, setColumnName] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  // const handleReportPagination = (e) => {
  //   console.log(e.target.textContent);
  //   // setIsDataLoading(true);
  //   intOffset =
  //     e.target.innerText === "1"
  //       ? 0
  //       : (parseInt(e.target.innerText) - 1) * limit;

  //   fetchData(2);
  // };

  let assetsRef = useRef(false);
  const childRef = useRef();

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  const handleEditTermsAndCondition = (id) => {
    navigate(`/termsandconditions/edit/${id}`);
  };

  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1, rowsPerPage, 3);
    }
  }, []);

  const fetchUsers = async (
    page,
    rowsPerPage,
    type = 16,
    columnName = "",
    sortDirection = ""
  ) => {
    // if (tempRefs.current === false) {
    const data = JSON.stringify({
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      txtColumnName: columnName,
      txtSortDirection: sortDirection,
      intType: type,
    });

    console.log(data);

    config.method = "POST";
    config.data = data;
    config.url = termsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        setTermsandConditions(response.data["result"]);
        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
    // }
    // tempRefs.current = true;
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage, 16, columnName, sortDirection);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!termsandConditions.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchAttendanceData again

    fetchUsers(1, newRowsPerPage, 16, columnName, sortDirection);
    // downloadExcel(1, rowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };
  const handleSort = (column, sortDirection) => {
    console.log("handleSort called", column, sortDirection); // Debugging line

    if (column.name) {
      let columnName = column.name.toLowerCase();
      setColumnName(columnName);
      setSortDirection(sortDirection);
      console.log(columnName, sortDirection);

      fetchUsers(1, rowsPerPage, 16, columnName, sortDirection); // Ensure fetchAttendanceData is being called
    }
  };

  const handleEditRole = (id) => {
    navigate(`/employee/view/${id}`);
  };

  const columns = useMemo(() => [
    {
      name: "Title",
      field: "id",
      sortable: true,
      selector: (row) => row.title,
    },
    {
      name: "Status",
      field: "id",
      sortable: true,
      cell: (row) => (
        <Typography
          className={`${
            row.status === 2 ? `active-status` : `inactive-status`
          } `}
        >
          {row.status_name}
        </Typography>
      ),
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <IconButton
          color="success"
          onClick={() => handleEditTermsAndCondition(row.id)}
          aria-label="add an alarm"
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);

  // const fetchData = (action) => {
  //   if (action === 1) {
  //     intOffset = 0;
  //   }

  //   const data = JSON.stringify({
  //     intLimit: limit,
  //     intOffset: intOffset,
  //     intType: 3,
  //   });

  //   config.method = "POST";
  //   config.data = data;
  //   config.url = termsAndConditionsAPI;

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data);
  //       setvendorList(response.data["result"]);
  //       setTotalCount(Math.round(response.data.totalCount / limit) + 1);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Terms and Conditions
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Terms and Conditions</Typography>
              <Typography color="text.primary">List</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="success"
              className="button_first"
              href="/termsandconditions/add"
              startIcon={<AddIcon />}
            >
              Add
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <Grid className="small_table">
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={termsandConditions}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* <div className={`table-responsive`}>
        <table className={`table`}>
          <thead>
            <th>
              <Typography variant="h6">S.No</Typography>
            </th>
            <th>
              <Typography variant="h6">Title</Typography>
            </th>
            <th>
              <Typography variant="h6">Status </Typography>
            </th>
            <th>
              <Typography variant="h6">Action </Typography>
            </th>
          </thead>
          <tbody>
            {vendorList.length > 0 ? (
              vendorList.map((row, index) => {
                return (
                  <tr key={index}>
                    <td className={`text-center`}>
                      <Typography>{index + 1}</Typography>
                    </td>
                    <td>
                      <Typography>{row.title}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography
                        className={`${row.status === 2 ? `active-status` : `inactive-status`
                          } `}
                      >
                        {row.status_name}
                      </Typography>
                    </td>
                    <td className={`text-center`}>
                      <Tooltip title={`Edit Terms & Conditions`}>
                        <a href={`./edit/` + row.id}>
                          <Create
                            cursor={`pointer`}
                          // onClick={() => handleEditTermsAndCondition(row.id)}
                          /></a>
                      </Tooltip>

                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className={`text-center`}>
                  <NotFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div> */}
    </Layout>
  );
}

export default TermsConditionsList;

// ! OLD -> Deprecated on 02-09-24
// import React, {
//   useEffect,
//   useRef,
//   useState,
//   Suspense,
//   lazy,
//   useMemo,
// } from "react";
// import {
//   Grid,
//   Tooltip,
//   Typography,
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
// } from "@mui/material";
// import axios from "axios";
// import { config, termsAndConditionsAPI } from "../../../config";
// import NotFound from "../../../components/NotFount";
// import { Create } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import Layout from "../../../layout/Layout";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { LoadingButton } from "@mui/lab";
// import AddIcon from "@mui/icons-material/Add";
// import DataTable from "react-data-table-component";
// import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
// import IconButton from "@mui/material/IconButton";
// import { Tooltip as ReactTooltip } from "react-tooltip";

// let field = "id",
//   orderBy = "desc";

// function TermsConditionsList() {
//   const navigate = useNavigate();
//   const [lstCompany, setCompanyList] = useState([]),
//     [lstVendorType, setVendorTypeList] = useState([]);

//   let tempRefs = useRef(false);

//   useEffect(() => {
//     if (tempRefs.current === false) {
//       // let requestData = {
//       //   intType: 2,
//       //   intParentId: 38,
//       // };
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   let intOffset = 0;
//   const limit = 10;
//   const [totalCount, setTotalCount] = useState(0);
//   const [termsandConditions, setTermsandConditions] = useState([]);

//   // const handleReportPagination = (e) => {
//   //   console.log(e.target.textContent);
//   //   // setIsDataLoading(true);
//   //   intOffset =
//   //     e.target.innerText === "1"
//   //       ? 0
//   //       : (parseInt(e.target.innerText) - 1) * limit;

//   //   fetchData(2);
//   // };

//   let assetsRef = useRef(false);
//   const childRef = useRef();

//   // useEffect(function () {
//   //   if (assetsRef.current === false) {
//   //     fetchData(1);
//   //     return () => {
//   //       assetsRef.current = true;
//   //     };
//   //   }
//   // }, []);

//   const handleEditTermsAndCondition = (id) => {
//     navigate(`/termsandconditions/edit/${id}`);
//   };

//   const [loading, setLoading] = useState(false);
//   const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     if (tempRefs.current === false) {
//       fetchUsers(1);
//     }
//   }, []);

//   const fetchUsers = async (page, rowsPerPage) => {
//     if (tempRefs.current === false) {
//       const data = JSON.stringify({
//         intLimit: rowsPerPage ? rowsPerPage : 10,
//         intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
//         intType: 3,
//       });

//       config.method = "POST";
//       config.data = data;
//       config.url = termsAndConditionsAPI;

//       axios(config)
//         .then(function (response) {
//           setTermsandConditions(response.data["result"]);
//           setTotalCount(response.data.totalCount);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//     }
//     tempRefs.current = true;
//   };

//   const handlePageChange = (page) => {
//     tempRefs.current = false;
//     fetchUsers(page, rowsPerPage);
//     setCurrentPage(page);
//   };

//   const handleRowsPerPageChange = async (newRowsPerPage) => {
//     if (!termsandConditions.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
//     tempRefs.current = false;
//     fetchUsers(1, newRowsPerPage);
//     setRowsPerPage(newRowsPerPage);
//     setCurrentPage(1);
//   };

//   const handleSort = (column, sortDirection) => {
//     setLoading(true);
//     setTimeout(() => {
//       field = column.field;
//       orderBy = sortDirection;
//       fetchUsers(1);
//       setLoading(false);
//     }, 100);
//   };

//   const handleEditRole = (id) => {
//     navigate(`/employee/view/${id}`);
//   };

//   const columns = useMemo(() => [
//     {
//       name: "Title",
//       field: "id",
//       sortable: true,
//       selector: (row) => (
//         <Tooltip title={row.title} arrow>
//           <span>{row.title}</span>
//         </Tooltip>
//       ),
//     },
//     {
//       name: "Status",
//       field: "id",
//       cell: (row) => (
//         <Typography
//           className={`${
//             row.status === 2 ? `active-status` : `inactive-status`
//           } `}
//         >
//           {row.status_name}
//         </Typography>
//       ),
//     },
//     {
//       name: "Action",
//       field: "id",
//       cell: (row) => (
//         <IconButton
//           color="success"
//           onClick={() => handleEditTermsAndCondition(row.id)}
//           aria-label="add an alarm"
//         >
//           <EditLocationOutlinedIcon />
//         </IconButton>
//       ),
//     },
//   ]);

//   // const fetchData = (action) => {
//   //   if (action === 1) {
//   //     intOffset = 0;
//   //   }

//   //   const data = JSON.stringify({
//   //     intLimit: limit,
//   //     intOffset: intOffset,
//   //     intType: 3,
//   //   });

//   //   config.method = "POST";
//   //   config.data = data;
//   //   config.url = termsAndConditionsAPI;

//   //   axios(config)
//   //     .then(function (response) {
//   //       console.log(response.data);
//   //       setvendorList(response.data["result"]);
//   //       setTotalCount(Math.round(response.data.totalCount / limit) + 1);
//   //     })
//   //     .catch(function (error) {
//   //       console.log(error);
//   //     });
//   // };

//   return (
//     <Layout>
//       <Grid className="employee-form bold-header">
//         <Grid container spacing={3}>
//           <Grid
//             textAlign="center"
//             item
//             className="form-lable"
//             xs={12}
//             lg={6}
//             md={6}
//           >
//             <Typography className="page_title" textAlign="left">
//               Terms and Conditions
//             </Typography>
//             <Breadcrumbs aria-label="breadcrumb">
//               <Link underline="hover" color="inherit" href="/dashboard">
//                 Dashboard
//               </Link>
//               <Typography color="text.primary">Terms and Conditions</Typography>
//               <Typography color="text.primary">List</Typography>
//             </Breadcrumbs>
//           </Grid>
//           <Grid
//             textAlign="right"
//             item
//             className="form-lable"
//             xs={12}
//             lg={6}
//             md={6}
//           >
//             <LoadingButton
//               size="medium"
//               variant="contained"
//               color="success"
//               className="button_first"
//               href="/termsandconditions/add"
//               startIcon={<AddIcon />}
//             >
//               Add
//             </LoadingButton>
//             <LoadingButton
//               size="medium"
//               variant="contained"
//               color="primary"
//               onClick={() => navigate(-1)}
//               startIcon={<ArrowBackIosIcon />}
//             >
//               Back
//             </LoadingButton>
//           </Grid>
//         </Grid>
//         <Grid className="form_design">
//           <Grid className="small_table">
//             <DataTable
//               pagination
//               paginationServer
//               fixedHeader
//               fixedHeaderScrollHeight="550px"
//               columns={columns}
//               data={termsandConditions}
//               progressPending={loading}
//               paginationTotalRows={totalCount}
//               paginationDefaultPage={currentPage}
//               paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
//               paginationPerPage={rowsPerPage}
//               onChangePage={handlePageChange}
//               // onSort={handleSort}
//               onChangeRowsPerPage={handleRowsPerPageChange}
//             />
//           </Grid>
//         </Grid>
//       </Grid>

//       {/* <div className={`table-responsive`}>
//         <table className={`table`}>
//           <thead>
//             <th>
//               <Typography variant="h6">S.No</Typography>
//             </th>
//             <th>
//               <Typography variant="h6">Title</Typography>
//             </th>
//             <th>
//               <Typography variant="h6">Status </Typography>
//             </th>
//             <th>
//               <Typography variant="h6">Action </Typography>
//             </th>
//           </thead>
//           <tbody>
//             {vendorList.length > 0 ? (
//               vendorList.map((row, index) => {
//                 return (
//                   <tr key={index}>
//                     <td className={`text-center`}>
//                       <Typography>{index + 1}</Typography>
//                     </td>
//                     <td>
//                       <Typography>{row.title}</Typography>
//                     </td>
//                     <td className={`text-center`}>
//                       <Typography
//                         className={`${row.status === 2 ? `active-status` : `inactive-status`
//                           } `}
//                       >
//                         {row.status_name}
//                       </Typography>
//                     </td>
//                     <td className={`text-center`}>
//                       <Tooltip title={`Edit Terms & Conditions`}>
//                         <a href={`./edit/` + row.id}>
//                           <Create
//                             cursor={`pointer`}
//                           // onClick={() => handleEditTermsAndCondition(row.id)}
//                           /></a>
//                       </Tooltip>

//                     </td>
//                   </tr>
//                 );
//               })
//             ) : (
//               <tr>
//                 <td colSpan={4} className={`text-center`}>
//                   <NotFound />
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div> */}
//     </Layout>
//   );
// }

// export default TermsConditionsList;
