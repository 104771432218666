import React, { useEffect, useRef, useState, useMemo } from "react";
import { Grid, TextField, Typography, Button, Tooltip } from "@mui/material";
import { AlertMessage } from "../../../components/AlertMessage";
//layout import
import Layout from "../../../layout/Layout";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
// import NotFound from "../../../components/NotFount";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import RunningWithErrorsRoundedIcon from "@mui/icons-material/RunningWithErrorsRounded";

// date
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import axios from "axios";
import { config, leaveAPI, userData } from "../../../config";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip as ReactTooltip } from "react-tooltip";

let field = "id",
  orderBy = "desc";

function ApplyLeave() {
  const childRef = useRef();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    txtLeaveReason: Yup.string().required("Leave Reason is required"),
    txtLeaveSubject: Yup.string().required("Leave Subject is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  // form dropdown
  const [LeaveFrom, setLeaveFrom] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [LeaveTo, setLeaveTo] = React.useState(dayjs().format("YYYY-MM-DD"));

  function onSubmit(data) {
    data.intType = 1;
    data.intEmployeeID = userData.emp_id;
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = leaveAPI;
    axios(config)
      .then(function (response) {
        handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
  }

  let assetsRef = useRef(false);
  // let intOffset = 0;
  // const limit = 10;

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  const [positionMasterList, setpositionMasterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);
  const [UserId, setUserId] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setUserId(user.emp_id);
    return () => true;
  }, []);

  const fetchUsers = async (page, rowsPerPage, e) => {
    const data = JSON.stringify({
      intLimit: rowsPerPage,
      intOffset: (page - 1) * rowsPerPage,
      intEmployeeID: user.emp_id,
      intType: 4,
    });

    config.method = "POST";
    config.data = data;
    config.url = leaveAPI;
    axios(config)
      .then(function (response) {
        setpositionMasterList(response.data["result"]);
        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!positionMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again

    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const handleEditRole = (id) => {
    navigate(`/employee/view/${id}`);
  };

  const columns = useMemo(() => [
    {
      name: "From Date",
      field: "id",
      sortable: true,
      cell: (row) => row.leave_from_date,
    },
    {
      name: "To Date",
      field: "id",
      sortable: true,
      cell: (row) => row.leave_to_date,
    },
    {
      name: "Subject",
      field: "id",
      sortable: true,
      cell: (row) => row.leave_subject,
    },
    {
      name: "Reason",
      field: "id",
      sortable: true,
      cell: (row) => row.leave_reason,
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <>
          {row.leave_status == 85 ? (
            <Tooltip title="Leave Accepted">
              <DoneIcon className="correct" />
            </Tooltip>
          ) : row.leave_status == 86 ? (
            <Tooltip title="Leave Declined">
              <CloseIcon className="wrong" />
            </Tooltip>
          ) : (
            <Tooltip title="Waiting for Admin Approval">
              <RunningWithErrorsRoundedIcon className="icon_colorpending" />
            </Tooltip>
          )}
        </>
      ),
    },
  ]);

  // const fetchData = (action) => {
  //   if (action === 1) {
  //     intOffset = 0;
  //   }

  //   const data = JSON.stringify({
  //     intType: 2,
  //   });
  //   config.method = "POST";
  //   config.data = data;
  //   config.url = leaveAPI;

  //   axios(config)
  //     .then(function (response) {
  //       setpositionMasterList(response.data["result"]);
  //       setTotalCount(Math.round(response.data.totalCount / limit));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const handleFormReset = () => {
    let defaultValues = {};
    reset({ ...defaultValues });
  };

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Apply Leave
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">apply leave</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} lg={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      format="YYYY-MM-DD"
                    >
                      <MobileDatePicker
                        label="From Date"
                        value={LeaveFrom}
                        inputFormat="YYYY-MM-DD"
                        fullWidth
                        {...register("txtLeaveFromDate")}
                        onChange={(newValue) => {
                          setLeaveFrom(newValue);
                          setValue(
                            "txtLeaveFromDate",
                            dayjs(newValue).format("YYYY-MM-DD")
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            helperText={errors.txtLeaveFromDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      format="YYYY-MM-DD"
                    >
                      <MobileDatePicker
                        label="To Date"
                        value={LeaveTo}
                        inputFormat="YYYY-MM-DD"
                        fullWidth
                        {...register("txtLeaveToDate")}
                        onChange={(newValue) => {
                          setLeaveTo(newValue);
                          setValue(
                            "txtLeaveToDate",
                            dayjs(newValue).format("YYYY-MM-DD")
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={errors.txtLeaveToDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <TextField
                      label="Leave Subject"
                      fullWidth
                      {...register("txtLeaveSubject")}
                      error={errors.txtLeaveSubject ? true : false}
                      helperText={errors.txtLeaveSubject?.message}
                    />
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <TextField
                      label="Leave Reason"
                      fullWidth
                      {...register("txtLeaveReason")}
                      error={errors.txtLeaveReason ? true : false}
                      helperText={errors.txtLeaveReason?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} textAlign="right">
                    <Button
                      variant="primary"
                      className={`btn-primary`}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid>
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={positionMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
        {/* <Grid className={`table-responsive`}>
          <table className={`table`}>
            <thead>
              <th>
                <Typography variant="h6">S.No</Typography>
              </th>
              <th>
                <Typography variant="h6">From Date</Typography>
              </th>
              <th>
                <Typography variant="h6">To Date</Typography>
              </th>
              <th>
                <Typography variant="h6">Reason</Typography>
              </th>
              <th>
                <Typography variant="h6">Subject</Typography>
              </th>
              <th>
                <Typography variant="h6">Status</Typography>
              </th>
            </thead>
            <tbody>
              {positionMasterList.length > 0 ? (
                positionMasterList.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td className={`text-center`}>
                        <Typography>{index + 1}</Typography>
                      </td>
                      <td className={`text-center`}>
                        <Typography>{row.leave_from_date}</Typography>
                      </td>
                      <td className={`text-center`}>
                        <Typography>{row.leave_to_date}</Typography>
                      </td>
                      <td className={`text-center`}>
                        <Typography>{row.leave_reason}</Typography>
                      </td>
                      <td className={`text-center`}>
                        <Typography>{row.leave_subject}</Typography>
                      </td>
                      <td className={`text-center`}>
                        <Typography
                          className={`${
                            row.leave_status === 86
                              ? "inactive-status"
                              : row.leave_status === 85
                              ? "active-status"
                              : "pending-status"
                          } `}
                        >
                          {row.leave_status === 86
                            ? `Declined`
                            : row.leave_status === 85
                            ? `Approved`
                            : "Pending"}
                        </Typography>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className={`text-center`}>
                    <NotFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Grid> */}
      </Grid>
    </Layout>
  );
}
export default ApplyLeave;
