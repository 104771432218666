import React, { useEffect, useRef } from "react";

//design imports from material
import {
  Grid,
  FormLabel,
  TextField,
  Typography,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";

//layout import
import Layout from "../../../layout/Layout";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StateList, { stateList } from "../../../components/StateList";

//date picker
import dayjs from "dayjs";

import axios from "axios";
import {
  config,
  clientMasterAPI,
  statusMasterAPI,
  manageStoreMasterAPI,
  pincodeRegExp,
} from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import { useParams, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

function StoreMasterEdit() {
  const navigate = useNavigate();
  const { storeId } = useParams();
  const validationSchema = Yup.object().shape({
    txtStoreName: Yup.string().required("Store Name is required"),
    txtAddress: Yup.string().required("Address is required"),
    txtCity: Yup.string().required("State / City is required"),
    intPincode: Yup.string()
      .required("Pincode is required")
      .min(6, "Pincode must be at least 6 digits")
      .max(6, "Pincode cannot be more than 6 digits")
      .matches(pincodeRegExp, "Please enter a valid Pincode"),
    // txtState: Yup.string().required("State is required"),
  });

  const [stateName, setStateName] = React.useState("");

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    data.intType = 2;
    // data.intStatus = clientStatus;
    data.txtState = stateName;
    data.intStoreID = storeId;
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = manageStoreMasterAPI;

    axios(config)
      .then(function (response) {
        // handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
        setTimeout(function () {
          navigate("/store/list");
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let assetsRef = useRef(false);
  const clientId = parseInt(storeId, 10);

  useEffect(() => {
    if (assetsRef.current === false) {
      const data = JSON.stringify({
        intStoreID: storeId,
        intType: 4,
      });

      config.method = "POST";
      config.data = data;
      config.url = manageStoreMasterAPI;

      axios(config)
        .then(function (response) {
          setStateName(response.data.result);
          handleFormReset(response.data.result[0]);
          console.log(response.data.result[0], "edit values");
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        assetsRef.current = true;
      };
    }
  }, []);

  const handleFormReset = (data) => {
    let defaultValues = {};
    setStateName(data.state);
    // setClientStatus(parseInt(data.status))
    defaultValues.txtStoreName = data.store_name;
    defaultValues.txtAddress = data.address;
    defaultValues.txtCity = data.city;
    defaultValues.intPincode = data.pin_code;
    reset({ ...defaultValues });
  };

  // const [clientStatusList, setClientStatusList] = React.useState([]);
  // const [clientStatus, setClientStatus] = React.useState("");

  // useEffect(() => {
  //     if (assetsRef.current === false) {
  //         const data = {
  //             intType: 2,
  //             intParentId: 1,
  //         };

  //         config.method = "POST";
  //         config.data = JSON.stringify(data);
  //         config.url = statusMasterAPI;

  //         axios(config)
  //             .then(function (response) {
  //                 // console.log({ "response": response.data.result.slice(2,4) })
  //                 setClientStatusList(response.data.result);
  //                 // console.log(clientStatusList);
  //                 // return;
  //             })
  //             .catch(function (error) {
  //                 console.log(error);
  //             });

  //         return () => {
  //             assetsRef.current = true;
  //         }
  //     }
  // }, [])

  const childRef = useRef();

  return (
    <Layout>
      <Grid className="employee-form">
        <AlertMessage ref={childRef} />
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Edit Store
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Store</Typography>
              <Typography color="text.primary">Edit</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} lg={12} md={12}>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="txtStoreName"
                    type="text"
                    label="Store Name"
                    focused
                    fullWidth
                    {...register("txtStoreName")}
                    error={errors.txtStoreName ? true : false}
                    helperText={errors.txtStoreName?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="address"
                    type="text"
                    label="Street address"
                    fullWidth
                    focused
                    {...register("txtAddress")}
                    error={errors.txtAddress ? true : false}
                    helperText={errors.txtAddress?.message}
                  />
                </Grid>
              </Grid>
              <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="address"
                    type="text"
                    label="Town / City"
                    fullWidth
                    focused
                    {...register("txtCity")}
                    error={errors.txtCity ? true : false}
                    helperText={errors.txtCity?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={stateName}
                      label="State"
                      focused
                      onChange={(e) => {
                        setStateName(e.target.value);
                        setValue("txtState", e.target.value);
                      }}
                    >
                      {stateList.map((row) => (
                        <MenuItem value={row.state_name}>
                          {row.state_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="address"
                    type="text"
                    label="Pincode"
                    fullWidth
                    focused
                    {...register("intPincode")}
                    error={errors.intPincode ? true : false}
                    helperText={errors.intPincode?.message}
                  />
                </Grid>
              </Grid>

              {/* Client status */}
              <div className="empty-space-20"></div>
              {/* <Grid container spacing={2}>
                                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                                    <FormControl
                                        fullWidth
                                        error={errors.intClientStatus ? true : false}
                                    >
                                        <InputLabel id="demo-simple-select-label">Client Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            label="Client Status"
                                            id="demo-simple-select"
                                            {...register("intClientStatus")}
                                            value={clientStatus}
                                            onChange={(e) => {
                                                setClientStatus(e.target.value);
                                                setValue("intClientStatus", e.target.value);
                                            }}
                                        >
                                            {clientStatusList.map((row) => (
                                                <MenuItem key={row.id} value={row.id}>
                                                    {row.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText className="invalid-feedback">
                                            {errors.intClientStatus?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid> */}

              <Grid
                item
                className="form-lable"
                xs={12}
                lg={12}
                md={12}
                marginTop="20px"
                textAlign="right"
              >
                <Button className={`btn-primary`} type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
}

export default StoreMasterEdit;
