import React, { useEffect, useMemo, useRef, useState } from "react";
import Layout from "../../layout/Layout";
import {
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Tab,
  Tabs,
  IconButton,
  useTheme,
  useMediaQuery,
  Tooltip,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { announcementAPI } from "../../config";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { AlertMessage } from "../../components/AlertMessage";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import { MaterialReactTable } from "material-react-table";
import AddNewForm from "./components/Add";
import Chip from "@mui/material/Chip"; // Import Chip component
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";
import dayjs from "dayjs";

const AnnouncementMasterAdd = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const alertRef = useRef();
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");

  const [tabValue, setTabValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [announcementListData, setAnnouncementListData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [editingAnnouncement, setEditingAnnouncement] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === "2") {
      setEditingAnnouncement(null); // Reset editing state when switching to "Add New"
    }
  };

  const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const truncateDescription = (description, wordLimit) => {
    const words = description.split(/\s+/);
    if (words.length <= wordLimit) {
      return description;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  // -> fetch List Data
  async function fetchAnnouncement() {
    const shouldSetLoading = pageIndex !== 0 || pageSize !== 10;

    if (shouldSetLoading) {
      setLoading(true);
    }
    const data = {
      pageIndex,
      pageSize,
      filters,
      sorting,
      intType: 2,
    };

    try {
      const response = await axios.post(announcementAPI, data);
      setAnnouncementListData(response.data.result || []);
      setTotalCount(response.data.totalCount || 0);
      setLoading(false);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      console.error("Error fetching users:", error);
    } finally {
      if (shouldSetLoading) {
        setLoading(false);
      }
    }
  }

  const columns = useMemo(() => [
    {
      accessorKey: "company_names",
      header: "Company",
      size: 150,
    },
    {
      accessorKey: "department_names",
      header: "Department",
    },
    {
      accessorKey: "position_names",
      header: "Position",
    },
    {
      accessorKey: "title",
      header: "Title",
      size: 100,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 100,
      Cell: ({ cell }) => {
        const description = cell.getValue();
        const strippedDescription = stripHTML(description);
        const truncatedDescription = truncateDescription(
          strippedDescription,
          10
        );

        return (
          <Tooltip title={strippedDescription} arrow>
            <span>{truncatedDescription}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "from_date",
      header: "From Date",
      size: 100,
      Cell: ({ cell }) => dayjs(cell.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "to_date",
      header: "To Date",
      size: 100,
      Cell: ({ cell }) => dayjs(cell.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "status_name",
      header: "Status",
      filterVariant: "select",
      Cell: ({ cell }) => {
        const status = cell.getValue();
        const getChipStyle = (status_name) => {
          switch (status_name.toLowerCase()) {
            case "active":
              return {
                backgroundColor: "#38B000",
                color: "#FFF0F3",
                fontWeight: "bold",
              };
            case "inactive":
              return {
                backgroundColor: "#C9184A",
                color: "#FFF0F3",
                fontWeight: "bold",
              };
            default:
              return {
                backgroundColor: "#000",
                color: "#e0e0e0",
                fontWeight: "bold",
              };
          }
        };
        return <Chip label={status} size="medium" sx={getChipStyle(status)} />;
      },
      filterSelectOptions: [
        { label: "Active", title: "Active", value: 2 },
        { label: "Inactive", title: "Inactive", value: 3 },
      ],
      size: 100,
    },
    {
      accessorKey: "edit",
      header: "Action",
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <IconButton
          color="warning"
          onClick={() => handleEditClick(row.original)}
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
      size: 50,
    },
  ]);

  const handleEditClick = (announcement) => {
    setEditingAnnouncement(announcement);
    setTabValue("2");
  };

  // -> useEffects
  useEffect(() => {
    fetchAnnouncement();
  }, [pageIndex, pageSize, filters, sorting]);

  const handleExportData = async () => {
    try {
      const payload = {
        columnFilters: filters,
        sorting: sorting,
        isExport: true,
      };

      const response = await axios.post(announcementAPI, payload);
      const data = response.data;
      if (data?.success) {
        const newData = data.result.map((row) => {
          return {
            Company: row.company_names || "-",
            Department: row.department_names || "-",
            Position: row.position_names || "-",
            Title: row.title || "-",
            Description: stripHTML(row.description) || "-",
            "From Date": row.from_date || "-",
            "To Date": row.to_date || "-",
            Status: row.status_name || "-",
          };
        });

        const worksheet = XLSX.utils.json_to_sheet(newData);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `Announcement`);

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });

        saveAs(
          blob,
          `KG-Announcements-${moment().format("DD-MM-YYYY_hh:mm_a")}.xlsx`
        );
      } else {
        alert("No data available for export");
      }
    } catch (error) {
      console.error("Error during export:", error);
      alert("Error exporting data. Please try again.");
    }
  };

  return (
    <Layout>
      <AlertMessage ref={alertRef} />
      <Box sx={{ ml: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ ml: 2 }}>
            <Grid container spacing={3}>
              <Grid textAlign="center" item xs={12} lg={6} md={6}>
                <Typography className="page_title" textAlign="left">
                  Announcement
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/dashboard">
                    Dashboard
                  </Link>
                  <Typography color="text.primary">Announcement</Typography>
                </Breadcrumbs>
              </Grid>
              <Grid textAlign="right" item xs={12} lg={6} md={6}>
                <LoadingButton
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(-1)}
                  startIcon={<ArrowBackIosIcon />}
                >
                  Back
                </LoadingButton>
              </Grid>
            </Grid>

            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 3 }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="announcement tabs"
                  >
                    <Tab label="List" value="1" />
                    <Tab label="Add New" value="2" />
                  </Tabs>
                </Box>

                {/* Filter With List */}
                <TabPanel value="1">
                  <MaterialReactTable
                    columns={columns}
                    data={announcementListData}
                    isLoading={loading}
                    enableGlobalFilter={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    rowNumberMode="original"
                    manualFiltering
                    manualPagination
                    manualSorting
                    initialState={{
                      columnPinning: isLargeScreen
                        ? { right: ["status_name", "edit"] }
                        : {},
                    }}
                    muiToolbarAlertBannerProps={
                      isError
                        ? {
                            color: "error",
                            children: "Error loading data",
                          }
                        : undefined
                    }
                    onColumnFiltersChange={setFilters}
                    onPaginationChange={(updater) => {
                      const paginationState = updater({
                        pageIndex,
                        pageSize,
                      });
                      const { pageIndex: newPageIndex, pageSize: newPageSize } =
                        paginationState;
                      setPageIndex(newPageIndex);
                      setPageSize(newPageSize);
                    }}
                    onSortingChange={setSorting}
                    rowCount={totalCount}
                    state={{
                      pagination: { pageIndex, pageSize },
                      sorting,
                      columnFilters: filters,
                      isLoading: loading,
                      showAlertBanner: isError,
                    }}
                    renderTopToolbarCustomActions={({ table }) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "1rem",
                            p: "0.5rem",
                            flexWrap: "wrap",
                          }}
                        >
                          <Button
                            onClick={handleExportData}
                            style={{ background: "#1a73e829" }}
                            startIcon={<DescriptionIcon />}
                            sx={{ px: 4 }}
                          >
                            Export to Excel
                          </Button>
                        </Box>
                      );
                    }}
                    enableColumnActions={false}
                    muiTableBodyCellProps={{
                      sx: {
                        padding: "3px 8px",
                        border: "1px solid #ccc",
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        padding: "8px",
                        border: "1px solid #ccc",
                        color: "#fff !important",
                        fontWeight: "bold",
                        "& svg": {
                          color: "#fff",
                        },
                        "& .MuiTableSortLabel-root": {
                          color: "#fff !important",
                          "&.Mui-active": {
                            color: "#fff !important",
                            "& .MuiTableSortLabel-icon": {
                              color: "#fff !important",
                            },
                          },
                        },
                        "& .MuiTableSortLabel-icon": {
                          color: "#fff !important",
                        },
                      },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                      sx: {
                        borderBottom: "1px solid #ccc",
                        backgroundColor:
                          row.index % 2 === 0 ? "#ffffff" : "#e3f2fd",
                      },
                    })}
                    muiTableHeadCellFilterTextFieldProps={{
                      sx: {
                        "& input": {
                          color: "#fff !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#fff !important",
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottomColor: "#fff !important",
                          },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#fff !important",
                        },
                      },
                    }}
                  />
                </TabPanel>

                {/* ADD ANNOUNCEMENT */}
                <TabPanel value="2">
                  <AddNewForm
                    loading={loading}
                    setLoading={setLoading}
                    setTabValue={setTabValue}
                    alertRef={alertRef}
                    editingAnnouncement={editingAnnouncement}
                    fetchAnnouncement={fetchAnnouncement}
                  />
                </TabPanel>
              </TabContext>
            )}
          </Box>
        </LocalizationProvider>
      </Box>
    </Layout>
  );
};

export default AnnouncementMasterAdd;
