import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Breadcrumbs,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { config, reportsAPI, projectMasterAPI } from "../../config";
import DataTable from "react-data-table-component";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as XLSX from "xlsx/xlsx.mjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import DescriptionIcon from "@mui/icons-material/Description";

import { Tooltip as ReactTooltip } from "react-tooltip";

import MuiLink from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function ProjectReport() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  const handlePageChange = (page) => {
    tempRefs.current = false;
    getProjectReport(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    tempRefs.current = false;
    getProjectReport(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  // const handleSort = (column, sortDirection) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     field = column.field;
  //     orderBy = sortDirection;
  //     setLoading(false);
  //   }, 100);
  // };

  const columns = useMemo(() => [
    {
      name: "Employee Name",
      field: "id",
      sortable: true,
      selector: (row) => (row.employee_name ? row.employee_name : "-"),
      // center: true,
    },
    {
      name: "Start Time",
      field: "id",
      // sortable: true,
      selector: (row) => (row.start_time ? row.start_time : "-"),
      center: true,
    },
    {
      name: "End Time",
      field: "id",
      // sortable: true,
      center: true,
      selector: (row) => (row.end_time ? row.end_time : "-"),
    },
    {
      name: "Worked Hours",
      field: "id",
      // sortable: true,
      selector: (row) => {
        const [hours, minutes, seconds] = row.hours_of_working
          ? row.hours_of_working.split(":")
          : "";
        return (
          <Grid>
            <Typography>{`${hours} hours & ${minutes} minutes`}</Typography>
          </Grid>
        );
      },
      center: true,
    },
    // {
    //   name: "Amount",
    //   field: "id",
    //   sortable: true,
    //   selector: (row) => (row.amount ? row.amount : "-"),
    //   center: true,
    // },
  ]);

  const downloadExcel = () => {
    const data = {
      intProjectID: projectId ? projectId : projectID,
      txtFromDate: fromDate.format("YYYY-MM-DD"),
      txtToDate: toDate.format("YYYY-MM-DD"),
      intLimit: rowsPerPage || 10,
      intOffset: rowsPerPage ? (currentPage - 1) * rowsPerPage : 0,
      intType: 2,
    };
    config.method = "POST";
    config.data = data;
    config.url = reportsAPI;

    axios(config)
      .then(function (response) {
        const newData = response.data["result"].map((row) => {
          delete row.total_amount_spent;
          delete row.total_time;
          row.amount = row.amount ? row.amount : "-";
          return row;
        });

        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Report");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `KG-project-timeline-report-${moment().format(
            "DD/MM/YYYY, h:mm:ss"
          )} hrs.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  const [projectReport, setProjectReport] = useState([]);
  const [fromDate, setFromDate] = React.useState(dayjs());
  const [toDate, setToDate] = React.useState(dayjs());
  const [totalCount, setTotalCount] = React.useState(0);

  const calculateTotalHoursWorked = (data) => {
    let totalHours = 0;
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "INR",
    });
    let totalAmount = 0;

    for (const item of data) {
      const [hours, minutes, seconds] = item.hours_of_working
        .split(":")
        .map(Number);
      totalHours += hours + minutes / 60 + seconds / 3600;
      if (item.amount && item.amount != null) totalAmount += item.amount;
    }

    const hours = Math.floor(totalHours);
    const minutes = Math.floor((totalHours % 1) * 60);
    const seconds = Math.floor(((totalHours * 60) % 1) * 60);

    //  calculation for total amount

    const formattedAmount = formatter.format(totalAmount);
    setTotalAmount(formattedAmount);

    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const getProjectReport = (page, rowsPerPage) => {
    const data = {
      intProjectID: projectId ? projectId : projectID,
      txtFromDate: fromDate.format("YYYY-MM-DD"),
      txtToDate: toDate.format("YYYY-MM-DD"),
      intLimit: rowsPerPage || 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      intType: 2,
    };
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = reportsAPI;

    axios(config)
      .then(function (response) {
        setProjectReport(response.data.result);
        calculateTotal(calculateTotalHoursWorked(response.data.result));
        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRefs1 = useRef(false);

  useEffect(() => {
    if (tempRefs1.current === false) {
      getProjectReport(1, rowsPerPage);
      return () => {
        tempRefs1.current = true;
      };
    }
  }, []);

  const [projectID, setProjectID] = useState(0);
  const [ProjectNameList, setProjectNameList] = useState([]);
  let tempRefs2 = useRef(false);

  useEffect(() => {
    if (tempRefs2.current === false) {
      const data = {
        intType: 6,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          setProjectNameList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs2.current = true;
      };
    }
  }, []);

  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [workingDays, setWorkingDays] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const calculateTotal = (data) => {
    if (data) {
      const duration = moment.duration(data); // create a Moment.js duration object
      const working_days = Math.floor(duration.asHours() / 8);

      const [total_hours, total_minutes, seconds] = data.split(":");
      setTotalHours(total_hours);
      setTotalMinutes(total_minutes);
      setWorkingDays(working_days);
    }
  };

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Project Timeline Report
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MuiLink underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </MuiLink>
                <Typography color="text.primary">Reports</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <div className="empty-space-40"></div>
        <Grid
          container
          spacing={2}
          margin={`15px 0px`}
          padding={`10px 15px 0px 0px`}
        >
          <Grid item xs={12} lg={3} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Project
              </InputLabel>
              <Select
                labelId="Select Project"
                id="demo-simple-select"
                value={projectID}
                label="Maristal Status"
                onChange={(e) => {
                  setProjectID(e.target.value);
                }}
              >
                {ProjectNameList.map((row, index) => (
                  <MenuItem value={row.id} key={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select From Date"
                value={fromDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setFromDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select To Date"
                value={toDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setToDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                getProjectReport(1, rowsPerPage);
              }}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid className="form_design">
          <Grid>
            {projectReport.length > 0 && (
              <>
                <Grid
                  container
                  spacing={3}
                  margin={`15px 0px`}
                  padding={`10px 15px 0px 0px`}
                >
                  <Grid item xs={12} md={6}>
                    <Typography>
                      Total Worked Time:{" "}
                      {`${totalHours} hours & ${totalMinutes} minutes`}
                    </Typography>
                    <Typography>{`${workingDays} days (8 hrs as 1 day)`}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>Total Amount Spent: {totalAmount}</Typography>
                  </Grid>
                  {/* <Grid item xs={12} lg={4} md={4}>
                    <Typography>Total Amount Received: </Typography>
                  </Grid> */}
                </Grid>

                <Grid
                  container
                  justifyContent="center" // Center the content horizontally
                  item
                  xs={12} // Takes up the full width of the container
                  sx={{ my: 2 }}
                  alignItems={`center`}
                >
                  <Grid item xs={6} md={3}>
                    {" "}
                    {/* Adjusted the grid size */}
                    <Button
                      fullWidth // Adjusted
                      onClick={downloadExcel}
                      variant="contained" // Added
                      color="success" // Added
                      startIcon={<DescriptionIcon />}
                      sx={{ borderRadius: "5px !important" }}
                    >
                      Export to Excel
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={projectReport}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[
                1, 10, 25, 50, 100, 500, 1000, 10000, 50000, 100000,
              ]}
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ProjectReport;
