import axios from "axios";
import { config, labourWagesAPI } from "./config";

export const _getDropdownListData = (data) => {
  config.method = "POST";
  config.data = JSON.stringify(data.requestData);
  config.url = data.API;

  axios(config)
    .then(function (response) {
      data.setData(response.data.result);
    })
    .catch(function (error) {
      console.log(error);
    });
};
