import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import Layout from "../../../layout/Layout";
import axios from "axios";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// FORM VALIDATION IMPORTS
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Avatar,
} from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AlertMessage } from "../../../components/AlertMessage";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import StateList, { stateList } from "../../../components/StateList";

// FORM POPUP
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  config,
  statusMasterAPI,
  handleHrMasterAPI,
  positionMasterAPI,
  departmentMasterAPI,
  ifscCodeRegExp,
  bankaccountnumberRegExp,
  manageCompaniesAPI,
  baseURL,
  ImageUploadAPI,
} from "../../../config";
import { _getDropdownListData } from "../../../service";
import Employee from "../../../assets/images/employee.png";

let defaultValues = {};

function ViewEmployee() {
  const nav = useNavigate();
  const [btnFlag, setBtnFlag] = useState(false);

  const { employeeid } = useParams();
  const validationSchema = Yup.object().shape({
    txtEmployeeName: Yup.string().required("Employee Name is required"),
    txtDob: Yup.string().required("Date of Birth is required"),
    txtDateofJoin: Yup.string().required("Date of Join is required"),
    txtEmail: Yup.string()
      .required("Email Id is required")
      .email("Must be a valid email"),
    intPhone: Yup.string().matches(
      "^[0-9]{10}$",
      "Please Enter Valid Mobile Number"
    ),
    intPayScale: Yup.string().matches("^[0-9]", "Please Enter Valid Pay Scale"),
    txtAddress: Yup.string().required("Street Address is required"),
    txtCity: Yup.string().required("Town/City is required"),
    txtState: Yup.string().required("State is required"),
    intPincode: Yup.string().required("Pincode is required"),
    txtPosition: Yup.string().required("Position is required"),
    txtDepartment: Yup.string().required("Department is required"),
    txtRecruitmentSource: Yup.string().required(
      "Recruitment Source is required"
    ),
    txtQualification: Yup.string().required("Qualification is required"),
    txtWorkExperience: Yup.string().required("Work Experience is required"),
    txtBankName: Yup.string().required("Bank Name is required"),
    txtIfscCode: Yup.string()
      .required("IFSC Code is required")
      .min(11, "IFSC Code must be 11 digits")
      .max(11, "IFSC Code must be 11 digits")
      .matches(ifscCodeRegExp, "Please enter a valid IFSC Code"),
    txtBranch: Yup.string().required("Branch is required"),
    txtAccountNumber: Yup.string()
      .required("Account Number is required")
      .matches(bankaccountnumberRegExp, "Please enter a valid Account Number"),
    intAadharno: Yup.string().required("Aadhar Number is required"),
    txtBloodGroup: Yup.string().required("Blood Group is required"),
    txtGuardianName: Yup.string().required("Famliy Name is required"),
    txtRelationship: Yup.string().required("Relationship is required"),
    intRelationNumber: Yup.string().matches(
      "^[0-9]{10}$",
      "Phone number is not valid"
    ),
    txtGender: Yup.string().required("Gender is required"),
  });

  const [errMessage, setErrMessage] = useState("");
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  let intOffset = 0;
  const limit = 10;
  const [hrMasterEditPersonal, setHrMasterEditPersonal] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  let hrMasterDetailsRef = useRef(false);
  useEffect(function () {
    if (hrMasterDetailsRef.current === false) {
      const data = JSON.stringify({
        intEmployeeAutoID: parseInt(employeeid),
        intType: 3,
      });
      config.method = "POST";
      config.data = data;
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          const responseTempData = response.data.result[0];

          console.log(
            "⛔ ➡️ file: ViewEmployee.jsx:133 ➡️ responseTempData:",
            responseTempData
          );

          // const imageURL = `${baseURL}/${responseTempData.photo.replace(
          //   /\\/g,
          //   "/"
          // )}`;

          // responseTempData.photo = imageURL;
          handleFormReset(responseTempData);
        })

        .catch(function (error) {
          console.log(error);
        });

      return () => {
        hrMasterDetailsRef.current = true;
      };
    }
  }, []);

  // image Conver to base64
  const [baseImage, setBaseImage] = useState(Employee);

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [txtRecruitmentSource, setRecruitmentSource] = React.useState("");

  const handleChangeSource = (event) => {
    setRecruitmentSource(event.target.value);
  };
  const [Gender, setGender] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [BloodGroup, setTxtBloodGroup] = useState("");
  const [Department, setTxtDepartment] = useState("");
  const [Position, setTxtPosition] = useState("");
  const [leaveEligible, setLeaveEligible] = useState("");

  // Getting Company List
  const [companyList, setComanyList] = useState([]);
  const [txtCompanyName, setTxtCompanyName] = useState("");

  useEffect(() => {
    let data = {
      requestData: {
        intType: 5,
      },
      API: manageCompaniesAPI,
      setData: setComanyList,
    };

    _getDropdownListData(data);
  }, []);

  const handleFormReset = (data) => {
    const data1 = {
      intDepartmentID: data.department,
      intType: 4,
    };

    config.method = "POST";
    config.data = JSON.stringify(data1);
    config.url = positionMasterAPI;

    axios(config)
      .then(function (response) {
        setLstPosition(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });

    setRecruitmentSource(data.recruit_source);
    setGender(data.gender);
    setMaritalStatus(data.mariatal_status);
    setTxtBloodGroup(data.blood_group);
    setTxtDepartment(data.department);
    setTxtPosition(data.position);
    setStateName(data.state);
    setEmployeeStatus(data.present_employee);
    setLeaveEligible(data.leave_eligible);
    setTxtCompanyName(data.company_name);
    setDateofBirth(data.dob);
    setDateofJoin(data.date_of_join);

    if (data.photo && data.photo != "") {
      setBaseImage(data.photo);
    }
    defaultValues.intId = data.ID;
    defaultValues.txtEmployeeNumber = data.employee_no;
    defaultValues.txtEmployeeName = data.employee_name;
    defaultValues.txtEmail = data.email;
    defaultValues.intPhone = data.phone;
    defaultValues.intSecondaryNumber = data.secondary_number;
    defaultValues.txtDob = data.dob;
    defaultValues.txtAddress = data.address;
    defaultValues.txtCity = data.city;
    defaultValues.txtState = data.state;
    defaultValues.intPincode = data.pincode;
    defaultValues.txtGender = data.gender;
    defaultValues.txtBloodGroup = data.blood_group;
    defaultValues.txtMaritalStatus = data.mariatal_status;
    defaultValues.intAadharno = data.aadhar_no;
    defaultValues.txtQualification = data.qualification;

    defaultValues.txtEmployeeStatus = data.present_employee;

    defaultValues.txtWorkExperience = data.work_experience;
    defaultValues.txtGuardianName = data.guardian_name;
    defaultValues.intRelationNumber = data.guardian_number;
    defaultValues.txtRelationship = data.guardian_relation;
    defaultValues.txtDateofJoin = data.date_of_join;
    defaultValues.txtCompanyName = data.company_name;

    defaultValues.txtLeaveEligible = data.leave_eligible;

    defaultValues.txtDepartment = data.department;
    defaultValues.txtPosition = data.position;
    defaultValues.intPayScale = data.pay_sclae;
    defaultValues.txtRecruitmentSource = data.recruit_source;
    defaultValues.txtRdEsi = data.rd_esi;
    defaultValues.intRdEsiNo = data.rd_esi_no;
    defaultValues.txtBankName = data.bank_name;
    defaultValues.txtIfscCode = data.ifsc_code;
    defaultValues.txtBranch = data.branch;
    defaultValues.txtAccountNumber = data.account_number;
    defaultValues.txtBasicSalery = data.salary_type;
    defaultValues.txtRejoinedyear = data.rejoined_year;
    defaultValues.txtPresentEmployee = data.present_employee;
    defaultValues.txtReasonforRelieve = data.reason_for_relieve;
    defaultValues.txtRelieveDate = data.relieving_date;
    defaultValues.txtTensure = data.tensure;
    defaultValues.txtAttritionRate = data.attrition_rate;
    defaultValues.txtTotalWorkExperience = data.total_work_experience;
    reset({ ...defaultValues });
  };

  const childRef = useRef();

  const [changeImage, setChangeImage] = useState(false);

  // Login
  async function onSubmit(data) {
    // setLodingSubmit(true);
    const data1 = JSON.stringify({
      intEmployeeAutoID: data.intId,
      txtEmployeeStatus: "",
      txtEmployeeName: data.txtEmployeeName,
      txtEmail: data.txtEmail,
      txtPhone: data.intPhone,
      intSecondaryNumber: data.intSecondaryNumber,
      intAadharno: data.intAadharno,
      txtGender: data.txtGender,
      txtDob: DateofBirth,
      txtMaritalStatus: data.txtMaritalStatus,
      txtAddress: data.txtAddress,
      txtCity: data.txtCity,
      txtState: data.txtState,
      intPincode: data.intPincode,
      txtBloodGroup: data.txtBloodGroup,
      txtPosition: data.txtPosition,
      txtDateofJoin: DateofJoin,
      txtProfile: baseImage,
      txtCompanyName: txtCompanyName,

      txtLeaveEligible: leaveEligible,

      txtDepartment: data.txtDepartment,
      txtRecruitmentSource: data.txtRecruitmentSource,
      txtQualification: data.txtQualification,
      txtWorkExperience: data.txtWorkExperience,
      txtEmployeeStatus: data.txtEmployeeStatus,
      txtGuardianName: data.txtGuardianName,
      txtRelationship: data.txtRelationship,
      intRelationNumber: data.intRelationNumber,
      intPayScale: data.intPayScale,
      txtReasonforRelieve: data.txtReasonforRelieve,

      intRdEsiNo: data.intRdEsiNo,
      txtRdEsi: data.txtRdEsi,
      txtBankName: data.txtBankName,
      txtAccountNumber: data.txtAccountNumber,
      txtIfscCode: data.txtIfscCode,
      txtBranch: data.txtBranch,
      intType: 4,
      intOffset: 0,
      intLimit: 0,
      txtPassword: "",
      intEmployeeAutoID: parseInt(employeeid),
    });

    config.method = "POST";
    config.data = data1;
    config.url = handleHrMasterAPI;

    axios(config)
      .then(function (response) {
        //handleFormReset(response.data.result);
        //window.location.reload(false);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      })
      .finally(function () {
        setBtnFlag(false);
      });
    // Add setTimeout to navigate after 2 seconds
    setTimeout(function () {
      nav("/employee/list");
    }, 2000);
  }

  const [handleResetPin, setHandleResetPin] = React.useState(false);

  const [userInfo, setuserInfo] = useState({
    file: [],
    filepreview: null,
  });

  const handleInputChange = (event) => {
    setuserInfo({
      ...userInfo,
      file: event.target.files[0],
      filepreview: URL.createObjectURL(event.target.files[0]),
    });
  };
  const [stateName, setStateName] = React.useState("");

  // form dropdown
  const [DateofBirth, setDateofBirth] = React.useState(
    dayjs() //.format("YYYY-MM-DD")
  );
  const [DateofJoin, setDateofJoin] = React.useState(dayjs());

  const [DateofRelievingDate, setDateofRelievingDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );

  //Gender Details
  const [lstGender, setLstGender] = useState([]);

  let tempRef = useRef(false);
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intType: 2,
        intParentId: 4,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setLstGender(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  //Marital Details
  const [lstMaritalDetails, setLstMarritalDetails] = useState([]);
  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 17,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setLstMarritalDetails(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  //Blood Details
  const [lstBloogGroup, setlstBloogGroup] = useState([]);
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 8,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setlstBloogGroup(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  //Department Details
  const [lstDepartment, setLstDepartment] = useState([]);
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 3,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = departmentMasterAPI;

      axios(config)
        .then(function (response) {
          setLstDepartment(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  //Position Details
  const [lstPosition, setLstPosition] = useState([]);
  const setDepartmentValues = (e) => {
    setTxtDepartment(e.target.value);
    setValue("txtDepartment", e.target.value);

    tempRefs.current = false;

    if (tempRefs.current === false) {
      const data = {
        intDepartmentID: e.target.value,
        intType: 4,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = positionMasterAPI;

      axios(config)
        .then(function (response) {
          setLstPosition(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  };

  //Popup
  const [open, setOpen] = React.useState(false);
  const [openFileds, setOpenFileds] = React.useState(false);

  const deactivateProfile = () => {
    setOpen(true);
  };

  const handleprofileClick = () => {
    setOpenFileds(true);
  };

  const handleOpenFields = () => {
    setOpenFileds(true);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [leavingReason, setLeavingReason] = useState("");

  //Deactivate the profile
  const handleProfileDeactivate = () => {
    setOpen(false);
    tempRefs.current = false;

    if (tempRefs.current === false) {
      const data = {
        intEmployeeAutoID: parseInt(employeeid),
        intType: 5,
        txtReasonforRelieve: leavingReason,
        txtRelieveDate: DateofRelievingDate,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: response.data.message,
              messageType: "success",
            })
          );
          localStorage.setItem("profile_status", 2);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "Something went wrong",
              messageType: "error",
            })
          );
        });
      return () => {
        tempRefs.current = true;
      };
    }
  };

  //Activate the profile
  const handleProfileActivate = () => {
    setOpen(false);
    tempRefs.current = false;

    if (tempRefs.current === false) {
      const data = {
        intEmployeeAutoID: parseInt(employeeid),
        intType: 6,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: response.data.message,
              messageType: "success",
            })
          );
          localStorage.setItem("profile_status", 1);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "Something went wrong",
              messageType: "error",
            })
          );
        });
      return () => {
        tempRefs.current = true;
      };
    }
  };

  //Reset Pin
  const resetPin = () => {
    tempRefs.current = false;

    if (tempRefs.current === false) {
      const data = {
        intEmployeeAutoID: parseInt(employeeid),
        intType: 7,
        intPin: "",
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: response.data.message,
              messageType: "success",
            })
          );
          localStorage.setItem("profile_status", 1);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "Something went wrong",
              messageType: "error",
            })
          );
        });
      return () => {
        tempRefs.current = true;
      };
    }
  };

  const [statusList, setStatusList] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState(0);

  // Getting Status List
  useEffect(() => {
    let data = {
      requestData: {
        intType: 2,
        intParentId: 1,
      },
      API: statusMasterAPI,
      setData: setStatusList,
    };

    _getDropdownListData(data);
  }, []);

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Edit Employee
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Edit Employee</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            {defaultValues.txtPresentEmployee == 3 ? (
              <LoadingButton
                size="medium"
                variant="contained"
                color="success"
                className="button_first"
                onClick={deactivateProfile}
                // startIcon={<AddIcon />}
              >
                Activate Profile
              </LoadingButton>
            ) : (
              <></>
            )}
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => nav(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid className="employee-form-struct">
            {defaultValues.txtPresentEmployee == 3 ? (
              <>
                <Grid>
                  <Typography className="activate_text" textAlign="center">
                    <WarningAmberIcon /> Profile is currently Deactivated.
                    Kindly Activate the profile to proceed further
                  </Typography>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <HighlightOffIcon
                        color="disabled"
                        sx={{ fontSize: 60 }}
                      />
                    </DialogTitle>
                    <DialogTitle id="alert-dialog-title">
                      Are You Sure?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you really want to Activate this profile?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button
                        onClick={handleProfileActivate}
                        autoFocus
                        color="error"
                      >
                        Activate
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </>
            ) : (
              <>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <HighlightOffIcon color="disabled" sx={{ fontSize: 60 }} />
                  </DialogTitle>
                  <DialogTitle id="alert-dialog-title">
                    Are You Sure?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you really want to deactivate this profile?
                      {/* This process cannot be undone. */}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      onClick={handleProfileDeactivate}
                      autoFocus
                      color="error"
                    >
                      Deactivate
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            <Grid container spacing={2}>
              <Grid
                textAlign="center"
                item
                className="form-lable"
                xs={12}
                lg={3}
                md={3}
              >
                <Grid
                  item
                  className="avatar-profile list_profile"
                  alignItems="center"
                >
                  <Grid alignItems="center">
                    <Grid className="profile_upload">
                      <Button variant="contained" component="label">
                        Upload
                        <input
                          {...register("txtProfile")}
                          onChange={(e) => {
                            uploadImage(e);
                            setChangeImage(true);
                          }}
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                        />
                      </Button>
                    </Grid>
                    <img className="avatar_img" src={baseImage} />
                    <h5 color="black">Upload Profile Image</h5>
                    <Typography className="note">
                      Allowed *.jpeg, *.jpg, *.png
                    </Typography>
                  </Grid>
                </Grid>
                {/* </Grid> */}
              </Grid>
              <Grid item xs={12} lg={9} md={9}>
                <Grid container spacing={2}>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <TextField
                      name="empname"
                      type="text"
                      label="Employee Name"
                      fullWidth
                      InputProps={{
                        readOnly:
                          localStorage.getItem("profile_status") == "2"
                            ? true
                            : false,
                      }}
                      focused
                      {...register("txtEmployeeName")}
                      error={errors.txtEmployeeName ? true : false}
                      helperText={errors.txtEmployeeName?.message}
                    />
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <TextField
                      name="emailid"
                      label="Email ID"
                      type="email"
                      fullWidth
                      InputProps={{
                        readOnly:
                          localStorage.getItem("profile_status") == "2"
                            ? true
                            : false,
                      }}
                      focused
                      {...register("txtEmail")}
                      error={errors.txtEmail ? true : false}
                      helperText={errors.txtEmail?.message}
                    />
                  </Grid>
                </Grid>
                <div className="empty-space-20"></div>
                <Grid container spacing={2}>
                  <Grid item className="form-lable" xs={12} lg={3} md={3}>
                    <TextField
                      name="phoneno"
                      label="Mobile Number 1"
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly:
                          localStorage.getItem("profile_status") == "2"
                            ? true
                            : false,
                      }}
                      focused
                      {...register("intPhone")}
                      error={errors.intPhone ? true : false}
                      helperText={errors.intPhone?.message}
                    />
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={3} md={3}>
                    <TextField
                      name="mobileno"
                      label="Mobile Number 2"
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly:
                          localStorage.getItem("profile_status") == "2"
                            ? true
                            : false,
                      }}
                      focused
                      {...register("intSecondaryNumber")}
                      error={errors.intSecondaryNumber ? true : false}
                      helperText={errors.intSecondaryNumber?.message}
                    />
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      format="DD-MM-YYYY"
                    >
                      <MobileDatePicker
                        label="Date of Birth"
                        value={DateofBirth}
                        inputFormat="DD/MM/YYYY"
                        fullWidth
                        focused
                        disabled={localStorage.getItem("profile_status") == "2"}
                        onChange={(newValue) => {
                          setDateofBirth(newValue);
                          setValue(
                            "txtDob",
                            dayjs(newValue).format("YYYY-MM-DD")
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={errors.txtDateofBirth?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <div className="empty-space-20"></div>
                <Grid container spacing={2}>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <TextField
                      name="address"
                      type="text"
                      label="Street address"
                      fullWidth
                      InputProps={{
                        readOnly:
                          localStorage.getItem("profile_status") == "2"
                            ? true
                            : false,
                      }}
                      focused
                      {...register("txtAddress")}
                      error={errors.txtAddress ? true : false}
                      helperText={errors.txtAddress?.message}
                    />
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <TextField
                      name="address"
                      type="text"
                      label="Town / City"
                      fullWidth
                      InputProps={{
                        readOnly:
                          localStorage.getItem("profile_status") == "2"
                            ? true
                            : false,
                      }}
                      focused
                      {...register("txtCity")}
                      error={errors.txtCity ? true : false}
                      helperText={errors.txtCity?.message}
                    />
                  </Grid>
                </Grid>
                <div className="empty-space-20"></div>
                <Grid container spacing={2}>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stateName}
                        label="State"
                        disabled={localStorage.getItem("profile_status") == "2"}
                        onChange={(e) => {
                          setStateName(e.target.value);
                          setValue("txtState", e.target.value);
                        }}
                      >
                        {stateList.map((row) => (
                          <MenuItem value={row.state_name}>
                            {row.state_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <TextField
                      name="address"
                      type="text"
                      label="State"
                      fullWidth
                      focused
                      {...register("txtState")}
                      error={errors.txtState ? true : false}
                      helperText={errors.txtState?.message}
                    /> */}
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <TextField
                      name="address"
                      type="text"
                      label="Pincode"
                      fullWidth
                      InputProps={{
                        readOnly:
                          localStorage.getItem("profile_status") == "2"
                            ? true
                            : false,
                      }}
                      focused
                      {...register("intPincode")}
                      error={errors.intPincode ? true : false}
                      helperText={errors.intPincode?.message}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Gender}
                    disabled={localStorage.getItem("profile_status") == "2"}
                    label="Gender"
                    onChange={(e) => {
                      setGender(e.target.value);
                      setValue("txtGender", e.target.value);
                    }}
                  >
                    {lstGender.map((row, index) => (
                      <MenuItem value={row.id} key={index}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtBloodGroup?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Blood Group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={BloodGroup}
                    label="Blood Group"
                    disabled={localStorage.getItem("profile_status") == "2"}
                    focused
                    onChange={(e) => {
                      setTxtBloodGroup(e.target.value);
                      setValue("txtBloodGroup", e.target.value);
                    }}
                  >
                    {lstBloogGroup.map((row, index) => (
                      <MenuItem value={row.name} key={index}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtBloodGroup?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Marital Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Marital Status"
                    id="demo-simple-select"
                    value={MaritalStatus}
                    disabled={localStorage.getItem("profile_status") == "2"}
                    focused
                    onChange={(e) => {
                      setMaritalStatus(e.target.value);
                      setValue("txtMaritalStatus", e.target.value);
                    }}
                  >
                    {lstMaritalDetails.map((row, index) => (
                      <MenuItem value={row.name} key={index}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="aadharnopassportno"
                  label="Aadhar / Passport Number"
                  type="text"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("intAadharno")}
                  error={errors.intAadharno ? true : false}
                  helperText={errors.intAadharno?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="qualification"
                  type="text"
                  label="Qualification"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtQualification")}
                  error={errors.txtQualification ? true : false}
                  helperText={errors.txtQualification?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="workexperience"
                  type="text"
                  label=" Work Experience"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtWorkExperience")}
                  error={errors.txtWorkExperience ? true : false}
                  helperText={errors.txtWorkExperience?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Employee Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Marital Status"
                    id="demo-simple-select"
                    value={employeeStatus}
                    disabled={localStorage.getItem("profile_status") == "2"}
                    focused
                    onChange={(e) => {
                      setEmployeeStatus(e.target.value);
                      setValue("txtEmployeeStatus", e.target.value);
                    }}
                  >
                    {statusList.map((row, index) => (
                      <MenuItem value={row.id} key={index}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                textAlign="center"
                item
                className="form-lable"
                xs={12}
                lg={12}
                md={12}
              >
                <h3>Family Information</h3>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="familyname"
                  type="text"
                  label="Name"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtGuardianName")}
                  error={errors.txtGuardianName ? true : false}
                  helperText={errors.txtGuardianName?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="relationship"
                  type="text"
                  label="Relationship"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtRelationship")}
                  error={errors.txtRelationship ? true : false}
                  helperText={errors.txtRelationship?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="RelationNumber"
                  label="Mobile Number"
                  type="text"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("intRelationNumber")}
                  error={errors.intRelationNumber ? true : false}
                  helperText={errors.intRelationNumber?.message}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                textAlign="center"
                item
                className="form-lable"
                xs={12}
                lg={12}
                md={12}
              >
                <h3>Work Information</h3>
              </Grid>
            </Grid>

            <div className="empty-space-20"></div>
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  format="DD-MM-YYYY"
                >
                  <MobileDatePicker
                    label="Date of Joining"
                    value={DateofJoin}
                    inputFormat="DD/MM/YYYY"
                    fullWidth
                    // disabled
                    focused
                    onChange={(newValue) => {
                      setDateofJoin(newValue);
                      setValue(
                        "txtDateofJoin",
                        dayjs(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        helperText={errors.txtDateofJoin?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Leave Eligibility
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={leaveEligible}
                    defaultValue="no"
                    label="Leave Eligibility"
                    fullWidth
                    disabled={localStorage.getItem("profile_status") == "2"}
                    onChange={(e) => {
                      setLeaveEligible(e.target.value);
                      setValue("txtLeaveEligible", e.target.value);
                    }}
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <div className="empty-space-20"></div>
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Company</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={txtCompanyName}
                    disabled={localStorage.getItem("profile_status") == "2"}
                    label="Company"
                    onChange={(e) => {
                      setTxtCompanyName(e.target.value);
                      setValue("txtCompanyName", e.target.value);
                    }}
                  >
                    {companyList.map((row, index) => (
                      <MenuItem value={row.id} key={index}>
                        {row.company_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtCompanyName?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Department
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Department}
                    disabled={localStorage.getItem("profile_status") == "2"}
                    label="Department"
                    focused
                    onChange={setDepartmentValues}
                  >
                    {lstDepartment.map((row, index) => (
                      <MenuItem value={row.id} key={index}>
                        {row.department_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtDepartment?.message}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <div className="empty-space-20"></div>
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Position
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Position}
                    disabled={localStorage.getItem("profile_status") == "2"}
                    label="Position"
                    focused
                    onChange={(e) => {
                      setTxtPosition(e.target.value);
                      setValue("txtPosition", e.target.value);
                    }}
                  >
                    {lstPosition.map((row, index) => (
                      <MenuItem value={row.id} key={index}>
                        {row.position_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtPosition?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="payscale"
                  type="text"
                  label="Pay Scale (CTC)"
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  fullWidth
                  focused
                  {...register("intPayScale")}
                  error={errors.intPayScale ? true : false}
                  helperText={errors.intPayScale?.message}
                />
              </Grid>
            </Grid>
            <div className="empty-space-20"></div>
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Recruitment Source
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={txtRecruitmentSource}
                    disabled={localStorage.getItem("profile_status") == "2"}
                    label="Recruitment Source"
                    {...register("txtRecruitmentSource")}
                    onChange={handleChangeSource}
                  >
                    <MenuItem value="job portal">Job Portal</MenuItem>
                    <MenuItem value="linked in">LinkedIn</MenuItem>
                    <MenuItem value="referral">Referral</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="rdesiepof"
                  type="text"
                  label="RD/ ESI/ EPOF (Rupees)"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtRdEsi")}
                  error={errors.txtRdEsi ? true : false}
                  helperText={errors.txtRdEsi?.message}
                />
              </Grid>
            </Grid>

            <div className="empty-space-20"></div>
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="rdesiepof"
                  type="text"
                  label="RD/ ESI/EPFO Account Number"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("intRdEsiNo")}
                  error={errors.intRdEsiNo ? true : false}
                  helperText={errors.intRdEsiNo?.message}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                textAlign="center"
                item
                className="form-lable"
                xs={12}
                lg={12}
                md={12}
              >
                <h3>Bank Information</h3>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="bankname"
                  type="text"
                  label="Bank Name"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtBankName")}
                  error={errors.txtBankName ? true : false}
                  helperText={errors.txtBankName?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="ifsccode"
                  type="text"
                  label="IFSC Code"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtIfscCode")}
                  error={errors.txtIfscCode ? true : false}
                  helperText={errors.txtIfscCode?.message}
                />
              </Grid>
            </Grid>
            <div className="empty-space-20"></div>
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="branch"
                  type="text"
                  label="Bank Branch"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtBranch")}
                  error={errors.txtBranch ? true : false}
                  helperText={errors.txtBranch?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="yearofcompletion"
                  type="text"
                  label="Bank Account Number"
                  fullWidth
                  InputProps={{
                    readOnly:
                      localStorage.getItem("profile_status") == "2"
                        ? true
                        : false,
                  }}
                  focused
                  {...register("txtAccountNumber")}
                  error={errors.txtAccountNumber ? true : false}
                  helperText={errors.txtAccountNumber?.message}
                />
              </Grid>
            </Grid>

            <div className="empty-space-40"></div>
            {openFileds ? (
              <>
                <Grid container spacing={2}>
                  <Grid
                    textAlign="center"
                    item
                    className="form-lable"
                    xs={12}
                    lg={12}
                    md={12}
                  >
                    <h3>Deactivate Account</h3>
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Reason
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Blood Group"
                        focused
                        onChange={(e) => {
                          setLeavingReason(e.target.value);
                          setValue("txtReason", e.target.value);
                        }}
                      >
                        <MenuItem value="Relieved">Relieved</MenuItem>
                        <MenuItem value="Terminated">Terminated</MenuItem>
                      </Select>
                      <Typography className="invalid-feedback">
                        {errors.txtReason?.message}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      format="DD-MM-YYYY"
                    >
                      <MobileDatePicker
                        label="Date of Relieving"
                        inputFormat="DD/MM/YYYY"
                        fullWidth
                        focused
                        value={DateofRelievingDate}
                        onChange={(newValue) => {
                          setDateofRelievingDate(newValue);
                          setValue(
                            "txtRelieveDate",
                            dayjs(newValue).format("YYYY-MM-DD")
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={errors.txtRelieveDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <div className="empty-space-40"></div>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <Button
                    variant="outlined"
                    className="align-cancelbutton"
                    color="error"
                    onClick={deactivateProfile}
                  >
                    Deactivate Profile
                  </Button>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <div className="empty-space-40"></div>
            <Grid item xs={12} lg={12} md={12} alignItems="center">
              <Grid container className="align-editbutton" spacing={3}>
                {defaultValues.txtPresentEmployee == 3 || openFileds ? (
                  <></>
                ) : (
                  <Button
                    variant="outlined"
                    className="align-cancelbutton"
                    color="error"
                    onClick={handleprofileClick}
                  >
                    Deactivate Profile
                  </Button>
                )}

                {!openFileds && (
                  <LoadingButton
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="align-cancelbutton"
                    disabled={formState.isSubmitting}
                  >
                    Update
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
}

export default ViewEmployee;
