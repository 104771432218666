import React, { useEffect, useRef } from "react";

//design imports from material
import {
  Grid,
  FormLabel,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  Button,
  MenuItem,
  FormHelperText,
} from "@mui/material";

//layout import
import Layout from "../../layout/Layout";

//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

//date picker
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import axios from "axios";
import {
  clientMasterAPI,
  config,
  convertBase64,
  projectMasterAPI,
  _numberOnly,
  statusMasterAPI,
} from "../../config";
import { AlertMessage } from "../../components/AlertMessage";

import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { _getDropdownListData } from "../../service";


let defaultValues = {};
function ProjectMasterEdit() {
  const { projectid } = useParams();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    // txtName: Yup.string().required("Project Name is required"),
    // txtLocation: Yup.string().required("Project Location is required"),
    // txtTypeOfProject: Yup.string().required("Type of Project is required"),
    // txtTypeOfActivity: Yup.string().required("Type of Activity is required"),
    // txtProjectAddress: Yup.string().required("Project Address is required"),
    // txtClientName: Yup.string().required("Client / Owner Name is required"),
    // // txtClientGst: Yup.string().required("Client GST No is required"),
    // // txtAgreement: Yup.string().required("Agreement No is required"),
    // txtStartDate: Yup.string().required("Start Date is required"),
    // txtEndDate: Yup.string().required("End Date is required"),
    // txtProjectValue: Yup.string().required("Project Value is required"),
    // txtPaymentStage: Yup.string().required("Payment Stages is required"),
  });

  const [txtState, setStateName] = useState("");
  const [txtTypeOfProject, setTypeOfProject] = useState("");
  const [txtTypeOfActivity, setTypeOfActivity] = useState("");

  //Set Client Name
  const [ClientName, setClientName] = useState("");
  const [clientList, setClientList] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [lstCompany, setCompanyList] = useState([]);

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  let tempRefs = useRef(false);

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 42,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setCompanyList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  let tempRef = useRef(false);
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intType: 5,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = clientMasterAPI;

      axios(config)
        .then(function (response) {
          setClientList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  // image Conver to base64
  const [basedrawing, setBaseDrawing] = useState("");
  const [basemeasurement, setBaseMeasurement] = useState("");
  const [baseuploadagreement, setBaseUploadAgreement] = useState("");

  const uploaddrawing = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseDrawing(base64);
  };
  const uploadmeasurement = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseMeasurement(base64);
  };
  const uploadagreement = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseUploadAgreement(base64);
  };

  function onSubmit(data) {
    console.log(data);
    return
    data.intType = 5;
    data.intStatus = 2;
    data.intProjectID = projectid;

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = projectMasterAPI;

    axios(config)
      .then(function (response) {
        // handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          navigate(`/project/list`);
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let assetsRef = useRef(false);
  useEffect(() => {
    if (assetsRef.current === false) {
      const data = JSON.stringify({
        intProjectID: projectid,
        intType: 7,
      });

      config.method = "POST";
      config.data = data;
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {

          setProjectDetails(response.data.result[0]);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        assetsRef.current = true;
      };
    }
  }, []);

  const [projectDetails, setProjectDetails] = useState([]);

  const [Date, setDate] = React.useState(dayjs().format("YYYY-MM-DD"));


  const childRef = useRef();

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Project Edit
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Project</Typography>
              <Typography color="text.primary">Add</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="success"
              className="button_first"
              href="/project/add"
              startIcon={<AddIcon />}
            >
              Add Project
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="txtName"
              type="text"
              label="Project Name"
              fullWidth
              value={projectDetails.name}
              focused
              readOnly
            />
          </Grid>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="txtName"
              type="text"
              label="Conpany Name"
              fullWidth
              value={projectDetails.company_name}
              focused
              readOnly
            />
          </Grid>
        </Grid>
        <div className="empty-space-20"></div>
        <Grid container spacing={2}>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="txtProjectValue"
              type="text"
              label="Project Value"
              fullWidth
              value={projectDetails.project_value}
              focused
            />
          </Grid>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="txtProjectValue"
              type="text"
              label="Client Name"
              fullWidth
              value={projectDetails.clientname}
              readOnly
              focused
            />
          </Grid>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="intPaymentStages"
              type="text"
              label="Payment Stages"
              fullWidth
              value={projectDetails.payment_stage}
              readOnly
              focused
            />
          </Grid>
        </Grid>
        <div className="empty-space-20"></div>
        <Grid container spacing={2}>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="txtAgreementNo"
              type="text"
              label="Agreement / PO Number"
              fullWidth
              value={projectDetails.agreement_no}
              readOnly
              focused
            />
          </Grid>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              type="text"
              label="Project Start Date"
              fullWidth
              value={projectDetails.project_start_date}
              readOnly
              focused
            />
          </Grid>
        </Grid>
        <div className="empty-space-20"></div>
        <Grid container spacing={2}>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              type="text"
              label="Project Type"
              fullWidth
              value={projectDetails.type_of_project}
              readOnly
              focused
            />
          </Grid>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              type="text"
              label="Type of Activity"
              fullWidth
              value={projectDetails.type_of_activity}
              readOnly
              focused
            />
          </Grid>
        </Grid>
        <div className="empty-space-20"></div>
        <Grid container spacing={2}>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="address"
              type="text"
              label="Project Address"
              fullWidth
              value={projectDetails.project_address}
              readOnly
              focused
            />
          </Grid>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="address"
              type="text"
              label="Town / City"
              fullWidth
              value={projectDetails.city}
              readOnly
              focused
            />
          </Grid>
        </Grid>
        <div className="empty-space-20"></div>
        <Grid container spacing={2}>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="address"
              type="text"
              label="State"
              fullWidth
              value={projectDetails.state}
              readOnly
              focused
            />
          </Grid>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="address"
              type="text"
              label="Pincode"
              fullWidth
              value={projectDetails.pincode}
              focused
              readOnly
            />
          </Grid>
          <Grid item className="form-lable" xs={12} lg={6} md={6}>
            <TextField
              name="address"
              type="text"
              label="Pincode"
              fullWidth
              value={projectDetails.pincode}
              focused
              readOnly
            />
          </Grid>
        </Grid>
        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid container spacing={3}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  format="DD-MM-YYYY"
                >
                  <MobileDatePicker
                    label="Date of Payment"
                    // value={txtDateofPayment}
                    {...register("txtDateofPayment")}
                    inputFormat="DD/MM/YYYY"
                    fullWidth
                    onChange={(newValue) => {
                      setDate(newValue);
                      setValue(
                        "txtDateofPayment",
                        dayjs(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        helperText={errors.txtDateofPayment?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid textAlign="center" item xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Mode of Payment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={paymentMode}
                    {...register("paymentMode")}
                    error={errors.paymentMode ? true : false}
                    helperText={errors.paymentMode?.message}
                    label="Mode of Payment"
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                  >
                    <MenuItem value="Cheque">Cheque</MenuItem>
                    <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="UPI">UPI</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid textAlign="center" item xs={12} lg={6} md={6}>
                <TextField
                  type="text"
                  fullWidth
                  label="Transaction ID"
                  // value={txtTransactionID}
                  {...register("txtTransactionID")}
                />
              </Grid>
              <Grid textAlign="center" item xs={12} lg={6} md={6}>
                <TextField
                  type="text"
                  fullWidth
                  label="Description"
                  {...register("txtDescription")}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className="form-lable"
            xs={12}
            lg={12}
            md={12}
            marginTop="20px"
            textAlign="right"
          >
            <Button className={`btn-primary`} type="submit">
              Submit
            </Button>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
}

export default ProjectMasterEdit;
