import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormControl, Box } from "@mui/material";
import Layout from "../../../../layout/Layout";
import {
  config,
  purchaseOrderAPI,
  purchaseOrderItemsAPI,
} from "../../../../config";
import Pdf from "react-to-pdf";
import companyLogo from "../../../../assets/images/Logo/logo.png";
import axios from "axios";
import NotFound from "../../../../components/NotFount";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams, useNavigate } from "react-router-dom";
// import numberToWords from "number-to-words";
import moment from "moment";
import "moment/locale/en-in";
import { ToWords } from "to-words";

import DOMPurify from "dompurify";

const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

function roundUpToInteger(value) {
  const roundedValue = Math.ceil(value);
  const formattedValue = roundedValue.toFixed(2);
  return formattedValue;
}

const handleNumberToWOrdCurrency = (v) => {
  return toWords.convert(roundUpToInteger(v));
};

const PrintComponent = ({ poDetails, paymentList, poid }) => {
  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `P.O.pdf`,
    onAfterPrint: () => alert("PDF generated successfully!"),
  });



  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  function roundUpToInteger(value) {
    const roundedValue = Math.ceil(value);

    const formattedValue = roundedValue.toFixed(2);

    return formattedValue;
  }

  function calculateRoundingDifference(value) {
    const roundedValue = Math.ceil(value);
    const difference = roundedValue - value;

    return difference.toFixed(2);
  }

  const setPOStatus = (status) => {
    const data = {
      intType: 2,
      intStatus: status,
      intPOId: poid,
    };
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = purchaseOrderAPI;

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
    return () => {
      // tempRef.current = true;
    };
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handlePrint}>
        Generate PDF
      </Button>

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        style={{ height: "100%" }}
        ref={ref}
        className="pdf-container"
      >
        <Grid className="element-to-po">
          <Grid className="page">
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#0066cc",
              }}
            >
              <h1>
                {poDetails.order_type === "1" ? "Purchase Order" : "Work Order"}
              </h1>
            </Typography>
            <table className="pdf-tablepad">
              <tbody>
                <tr className="pdf-no-border">
                  <td colSpan={4}>
                    <tr>
                      <td className="pdf-no-border">
                        <Typography
                          className={`td text-bold`}
                          style={{ fontSize: "15px" }}
                        >
                          Purchase Order No: {poDetails.po_no}
                        </Typography>
                      </td>
                    </tr>
                    <hr />
                    <tr className="pdf-no-border">
                      <td className="pdf-no-border imgtd">
                        <img
                          src={companyLogo}
                          alt="KG"
                          width="200px"
                          height="auto"
                        />
                      </td>
                      <td width="100%" className="pdf-no-border">
                        <table>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails.company_Name}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? poDetails.company_address
                                  : `Plot No 108, Perumal Koil Street, Alapakkam
                                Porur`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.company_city} - ${poDetails.company_pincode}`
                                  : `Chennai - 600116`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.company_state}, India`
                                  : `Tamil Nadu, India`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.company_primary_contact} / ${poDetails.company_secondary_contact}`
                                  : `044 2476 5544 / +91 95001 55544`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? poDetails.company_emailId
                                  : `venkat@kgbuildtech.com`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? poDetails.company_website
                                  : `https://kgbuildtech.com`}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <hr />
                    <table>
                      <tr>
                        <td colSpan={3} width={`50%`} className="no-border">
                          <table className="po-content">
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Bill To
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  className={`td text-bold companyna`}
                                >
                                  {poDetails.vendor_name}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography>
                                  {poDetails.vendor_mobile}
                                </Typography>
                              </td>
                            </tr>

                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Place of Supply :
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography>
                                  {poDetails.vendor_street}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography>
                                  {poDetails.vendor_pincode}{" "}
                                  {poDetails.vendor_state}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography>
                                  {poDetails.vendor_city}, India
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography>
                                  GSTIN {poDetails.vendor_gst}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  className={`td text-bold`}
                                ></Typography>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td width={`50%`} className="no-border imgtd">
                          <table className="po-content">
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Purchace Order No :
                                </Typography>
                              </td>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.po_no}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Mode/Terms of Payment :
                                </Typography>
                              </td>

                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.terms_of_payment}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Supplier Ref./Quotation No.:
                                </Typography>
                              </td>

                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.quotation_no}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Packing Charge :
                                </Typography>
                              </td>

                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.packing_charge}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Purchase Classification :
                                </Typography>
                              </td>

                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.purchase_classfication}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Purchace Date :
                                </Typography>
                              </td>

                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.po_date}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Site Engineer :
                                </Typography>
                              </td>

                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.site_eng_name}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Site Engineer Contact:
                                </Typography>
                              </td>

                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.site_eng_contact}
                                </Typography>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <Grid className="pdf-table_pdf">
              <table className="po-content">
                <thead>
                  <th>
                    <Typography>S.No</Typography>
                  </th>
                  <th>
                    <Typography>Description of Goods </Typography>
                  </th>
                  <th>
                    <Typography>Quantity (nos.)</Typography>
                  </th>
                  <th>
                    <Typography>Amount (₹)</Typography>
                  </th>
                  <th>
                    <Typography>Total Amount (₹)</Typography>
                  </th>
                  <th>
                    <Typography>GST </Typography>
                  </th>
                  {poDetails.total_cgst == 0 && poDetails.total_sgst == 0 ? (
                    <th>
                      <Typography>IGST </Typography>
                    </th>
                  ) : (
                    <>
                      <th>
                        <Typography>CGST </Typography>
                      </th>
                      <th>
                        <Typography>SGST </Typography>
                      </th>
                    </>
                  )}
                  <th>
                    <Typography>Total Amount + GST (INR)</Typography>
                  </th>
                </thead>
                <tbody>
                  {paymentList.length > 0 ? (
                    paymentList.map((row, index) => (
                      <tr className="pdf-table_padding">
                        <td className={`text-center`}>
                          <Typography>{index + 1}</Typography>
                        </td>
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {row.item_name}
                          </Typography>
                        </td>
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {twoDecimal(row.quantity)} {row.unit}
                          </Typography>
                        </td>
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {twoDecimal(row.amount)}
                          </Typography>
                        </td>
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {twoDecimal(row.total_amount_without_gst)}
                          </Typography>
                        </td>
                        <td className="po_leble">
                          <Typography textAlign="right">{row.gst}%</Typography>
                        </td>

                        {row.igst != 0 ? (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {twoDecimal(row.igst)}
                              </Typography>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {twoDecimal(row.cgst)}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {twoDecimal(row.sgst)}
                              </Typography>
                            </td>
                          </>
                        )}

                        <td className="po_leble">
                          <Typography textAlign="right">
                            {twoDecimal(row.total_amount_with_gst)}
                          </Typography>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
                <tbody className="tbody_col">
                  <tr className="table_padding_40">
                    {poDetails.total_cgst != 0 && poDetails.total_sgst != 0 ? (
                      <>
                        <td className={`text-center`}>
                          <Typography></Typography>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    <td colSpan={7}>
                      <Typography>Total</Typography>
                    </td>
                    <td>
                      <Typography>
                        {twoDecimal(poDetails.total_without_gst)}
                      </Typography>
                    </td>
                  </tr>
                  <tr className="table_padding_40">
                    {poDetails.total_cgst != 0 && poDetails.total_sgst != 0 ? (
                      <>
                        <td className={`text-center`}>
                          <Typography></Typography>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    {poDetails.total_cgst == 0 && poDetails.total_sgst == 0 ? (
                      <>
                        <td colSpan={7}>
                          <Typography>IGST </Typography>
                        </td>
                        <td>
                          <Typography>
                            {twoDecimal(poDetails.total_igst)}
                          </Typography>
                        </td>
                      </>
                    ) : (
                      <>
                        <td colSpan={7}>
                          <Typography>CGST </Typography>
                        </td>
                        <td>
                          <Typography>
                            {twoDecimal(poDetails.total_cgst)}
                          </Typography>
                        </td>
                      </>
                    )}
                  </tr>

                  {poDetails.total_sgst != 0 && (
                    <>
                      <tr className="table_padding_40">
                        <td className={`text-center`}>
                          <Typography></Typography>
                        </td>
                        <td colSpan={7}>
                          <Typography>SGST </Typography>
                        </td>
                        <td>
                          <Typography>
                            {twoDecimal(poDetails.total_sgst)}
                          </Typography>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr className="table_padding_40">
                    <td className={`text-center`}>
                      <Typography></Typography>
                    </td>
                    <td colSpan={2}>
                      <Typography>Transport Gst</Typography>
                    </td>

                    <td className="po_leble">
                      <Typography>{poDetails.transport_gst}% </Typography>
                    </td>
                    {poDetails.total_igst > 0 ? (
                      <td colSpan={3}>
                        <Typography>Transport charge (INR)</Typography>
                      </td>
                    ) : (
                      <td colSpan={4}>
                        <Typography>Transport charge (INR)</Typography>
                      </td>
                    )}

                    <td colSpan={2}>
                      <Typography>
                        {twoDecimal(poDetails.transport_charge)}{" "}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="table_padding_40">
                    {poDetails.total_cgst != 0 && poDetails.total_sgst != 0 ? (
                      <>
                        <td className={`text-center`}>
                          <Typography></Typography>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    <td colSpan={7}>
                      <Typography>Total amount with GST (INR)</Typography>
                    </td>
                    <td>
                      <Typography>
                        {twoDecimal(poDetails.total_with_gst)}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="table_padding_40">
                    {poDetails.total_cgst != 0 && poDetails.total_sgst != 0 ? (
                      <>
                        <td className={`text-center`}>
                          <Typography></Typography>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    <td colSpan={7}>
                      <Typography>Rounded Off </Typography>
                    </td>
                    <td>
                      <Typography>
                        {calculateRoundingDifference(poDetails.total_with_gst)}
                      </Typography>
                    </td>
                  </tr>

                  <tr className="table_padding_40">
                    {poDetails.total_cgst != 0 && poDetails.total_sgst != 0 ? (
                      <>
                        <td className={`text-center`}>
                          <Typography></Typography>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    <td colSpan={7}>
                      <Typography>Total Payable Amount</Typography>
                    </td>
                    <td>
                      <Typography>
                        {roundUpToInteger(poDetails.total_with_gst)}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid className="pdf-table_pdf">
              <table>
                <thead>
                  <tr>
                    <th>
                      <Typography>
                        Purchase Order Terms & Condition(s)
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="pdf-table_padding">
                    <td className="po_leble">
                      <Typography textAlign="center">
                        {poDetails.terms ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: poDetails.terms,
                            }}
                          />
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid className="pdf-table_pdf">
              <table>
                <thead>
                  <tr>
                    <b>TOTAL PURCHASE ORDER VALUE (IN WORDS)</b>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h5 style={{ marginTop: "10px", marginBottom: "1rem" }}>
                        {poDetails &&
                          poDetails.total_with_gst > 0 &&
                          `${handleNumberToWOrdCurrency(
                            poDetails.total_with_gst
                          )}`}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {poDetails.status === 91 && (
            <div
              style={{
                position: "absolute",
                right: "100px",
                paddingBottom: "2rem",
                marginTop: "3rem",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#2F58CD",
                  marginRight: "10px",
                  color: "white",
                }}
                onClick={() => setPOStatus(91)}
              >
                Approve
              </Button>
              <Button
                style={{ backgroundColor: "#2F58CD", color: "white" }}
                onClick={() => setPOStatus(92)}
              >
                Deny
              </Button>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default PrintComponent;
