import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import { Button, Grid, Typography, Tooltip, FormControl } from "@mui/material";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import * as XLSX from "xlsx/xlsx.mjs";
import {
  config,
  paymentRequestProcessAPI,
  paymentRequestAPI,
  paymentRequestApprovalAPI,
  userData,
  projectMasterAPI,
  leaveAPI,
  handleHrMasterAPI,
} from "../../config";
import DataTable from "react-data-table-component";
import NotFound from "../../components/NotFount";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AlertMessage } from "../../components/AlertMessage";
import SendIcon from "@mui/icons-material/Send";
import TaskAltSharpIcon from "@mui/icons-material/TaskAltSharp";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import RunningWithErrorsRoundedIcon from "@mui/icons-material/RunningWithErrorsRounded";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import "moment-timezone";
import DescriptionIcon from "@mui/icons-material/Description";
import { Tooltip as ReactTooltip } from "react-tooltip";

let field = "id",
  orderBy = "desc";

function ListPaymentRequest() {
  const navigate = useNavigate();
  const childRef = useRef();

  const [reason, setReason] = useState("");
  const declinePaymentReason = (e) => {
    setReason(e.target.value);
  };

  const [paymentRequests, setPaymentRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  const [employeeID, setEmployeeID] = useState(0);
  const [EmployeeList, setEmployeeList] = useState([]);
  const today = new Date();

  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);

  const [excelData, setExcelData] = React.useState([]);

  let tempRefs2 = useRef(false);
  useEffect(() => {
    if (tempRefs2.current === false) {
      const data = {
        intType: 7,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          response.data.result.unshift({ id: 0, name: "All" });
          setEmployeeList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs2.current = true;
      };
    }
  }, []);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
    tempRefs.current = true;
  }, []);

  const downloadExcel = (page, rowsPerPage) => {
    const data = {
      intEmployeeID: employeeID,
      txtFromDate: fromDate.format("YYYY-MM-DD"),
      txtToDate: toDate.format("YYYY-MM-DD"),
      intLimit: rowsPerPage || 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      mode: "pc",
      intType: 7,
    };
    config.method = "POST";
    config.data = data;
    config.url = paymentRequestAPI;
    axios(config)
      .then(function (response) {
        console.log(
          "⛔ ➡️ file: EmployeeAttendanceReport.jsx:165 ➡️ response:",
          response
        );
        const newData = response.data["result"].map(
          ({
            id,
            project_id,
            supervisor_id,
            nature_of_payment,
            amount,
            employee_description,
            admin_description,
            accountant_description,
            attachments,
            admin_approval_flag,
            admin_approver_id,
            admin_approval_datetime,
            accountant_paid_datetime,
            accountant_id,
            transaction_id,
            bill_balance_amount,
            bill_paid_amount,
            balance_amount,
            po_id,
            po_details,
            payment_request_attachment,
            status,
            payment_mode,
            bill_description,
            bill_attachment,
            created_at,
            project_name,
            nature_of_expense,
            status_label,
            status_id,
            supervisor_name,
            supervisor_number,
            admin_name,
            photo,
            accountant_name,
            accountant_number,
            po_name,
            ...row
          }) => {
            // Modify created_at field to show only date
            const formattedDate = moment(created_at).format("YYYY-MM-DD");
            switch (accountant_paid_datetime) {
              case null:
                accountant_paid_datetime = "-";
                break;
              case "":
                accountant_paid_datetime = "-";
                break;
              default:
                accountant_paid_datetime = accountant_paid_datetime;
                break;
            }

            if (paymentMode) {
              if (paymentMode == "" || paymentMode == null) {
                paymentMode = "-";
              }
            }

            return {
              // ...row,
              employee_no: supervisor_number,
              employee_name: supervisor_name,
              requested_date: formattedDate,
              accountant_paid_datetime: accountant_paid_datetime,
              paymentMode,
              amount,
              bill_balance_amount,
              bill_paid_amount,
              payment_request_attachment,
              nature_of_expense,
              admin_description,
              accountant_description,
              employee_description,
              status: status_label,
            };
          }
        );
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "report");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `KG-payment-report-${moment().format("DD/MM/YYYY, h:mm:ss")} hrs.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  const fetchUsers = async (page, rowsPerPage) => {
    if (tempRefs.current === false) {
      const data = JSON.stringify({
        intEmployeeID: employeeID,
        txtFromDate: fromDate != null ? fromDate.format("YYYY-MM-DD") : "",
        txtToDate: toDate != null ? toDate.format("YYYY-MM-DD") : "",
        intLimit: rowsPerPage || 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        mode: "pc",
        intType: 11,
      });

      config.method = "POST";
      config.data = data;
      config.url = paymentRequestAPI;

      axios(config)
        .then(function (response) {
          console.log(
            "⛔ ➡️ file: PaymentRequestList.jsx:129 ➡️ response:",
            response
          );

          setPaymentRequests(response.data.result);
          setTotalCount(response.data.totalCount);
          setExcelData(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true;
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!paymentRequests.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const paymentActions = (paymentRequestId, action) => {
    const data = JSON.stringify({
      intType: 2,
      intAdminID: userData.emp_id,
      intPaymentRequestID: paymentRequestId,
      txtAdminDescription: reason,
      txtAdminApproval: reason == "" ? "1" : "2",
      intStatus: action == "decline" ? 58 : 56,
    });

    config.method = "POST";
    config.data = data;
    config.url = paymentRequestApprovalAPI;

    axios(config)
      .then(function (response) {
        localStorage.setItem(
          "payment_approval",
          JSON.stringify(response.data.result[1])
        );
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [paymentDetailFields, setpaymentDetailFields] = React.useState(false);

  const handleactionOpen = () => {
    setpaymentDetailFields(true);
  };
  const handleactionClose = () => {
    setpaymentDetailFields(false);
  };

  const [paymentDeclineFields, setPaymentDeclineFields] = useState(false);
  const [declineRequestId, setDeclineRequestId] = useState(0);

  const declinePaymentFields = (row_id) => {
    setDeclineRequestId(row_id);
    setPaymentDeclineFields(true);
  };

  const [paymentRequestId, setPaymentRequestId] = useState(0);

  const ExpandedComponent = ({ data }) => {
    return (
      <table>
        <thead>
          <tr>
            <th>Admin Description</th>
            <th>Accountant Description</th>
            <th>User Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Typography className="tbl-data">
                {data.admin_description !== "" ? data.admin_description : "-"}
              </Typography>
            </td>
            <td>
              <Typography className="tbl-data">
                {data.accountant_description !== ""
                  ? data.accountant_description
                  : "-"}
              </Typography>
            </td>
            <td>
              <Typography className="tbl-data">
                {data.employee_description !== ""
                  ? data.employee_description
                  : "-"}
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const handleDownload = async (attachmentLink) => {
    try {
      const response = await fetch(attachmentLink);
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = attachmentLink.split("/").pop(); // Set the download attribute with the filename
      anchor.click();

      // Clean up
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const columns = useMemo(() => [
    {
      name: "Employee Name",
      field: "id",
      sortable: true,
      cell: (row) =>
        row.supervisor_name
          ? row.supervisor_name + " (" + row.supervisor_number + ")"
          : "-",
    },
    {
      name: "Requested date",
      field: "date",
      sortable: true,
      cell: (row) =>
        row.created_at
          ? moment(row.created_at)
              .subtract(2.3, "hours")
              .format("DD-MM-YYYY HH:mm a")
          : "-",
    },
    {
      name: "Accountant Paid Datetime",
      field: "id",
      sortable: true,
      cell: (row) =>
        row.accountant_paid_datetime
          ? moment(row.accountant_paid_datetime)
              .subtract(2.3, "hours")
              .format("DD-MM-YYYY HH:mm a")
          : "-",
    },
    {
      name: "Payment Mode",
      field: "id",
      sortable: true,
      cell: (row) => (row.payment_mode ? row.payment_mode : "-"),
    },
    {
      name: "Amount",
      field: "id",
      sortable: true,
      cell: (row) => (row.amount ? row.amount : "-"),
    },
    {
      name: "Bill Balance Amount",
      field: "id",
      sortable: true,
      cell: (row) => (row.bill_balance_amount ? row.bill_balance_amount : "-"),
    },
    {
      name: "Bill Paid Amount",
      field: "id",
      sortable: true,
      cell: (row) => (row.bill_paid_amount ? row.bill_paid_amount : "-"),
    },
    {
      name: "Payment Request Attachment",
      field: "id",
      sortable: true,
      cell: (row) =>
        row.payment_request_attachment ? (
          <button
            onClick={(e) => {
              handleDownload(row.payment_request_attachment);
            }}
          >
            Download
          </button>
        ) : (
          "-"
        ),
    },
    // {
    //   name: "Bill Attachment",
    //   field: "id",
    //   sortable: true,
    //   cell: (row) => (
    //     row.bill_attachment ? (
    //       <a href={ require(row.bill_attachment)} download="MyExampleDoc" target='_blank'>
    //       <Button>download</Button>
    //    </a>
    //     ) : (
    //       "-"
    //     )
    //   ),
    // },

    {
      name: "Nature of Expence",
      field: "id",
      sortable: true,
      cell: (row) => (row.nature_of_expense ? row.nature_of_expense : "-"),
    },
    {
      name: "Payment Status",
      field: "id",
      sortable: true,
      cell: (row) => (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent={`center`}
            spacing={2}
            sx={{ my: 1 }}
          >
            {userData.role_id != 36 ? (
              <>
                {userData.role_id == 34 && row.status_id === 55 ? (
                  <>
                    <Tooltip title="Accept the Payment Request">
                      <IconButton
                        variant="outlined"
                        className="align-cancelbutton"
                        color="success"
                        value={row.id}
                        onClick={() => paymentActions(row.id, "")}
                      >
                        <DoneIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Decline the Payment Request">
                      <IconButton
                        variant="outlined"
                        className="align-cancelbutton"
                        color="error"
                        onClick={() => declinePaymentFields(row.id)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {row.status_id === 56 && userData.role_id !== 35 && (
              <Typography>
                <Tooltip title="Admin Approved">
                  <TaskAltSharpIcon className="icon_colorpending" />
                </Tooltip>
              </Typography>
            )}
            {row.status_id === 57 && (
              <Typography>
                <Tooltip title="Payment Done !">
                  <DoneAllIcon className="icon_coloraccept" />
                </Tooltip>
              </Typography>
            )}
            {row.status_id === 58 && (
              <Typography>
                <Tooltip title="Admin Declined">
                  <HighlightOffRoundedIcon className="icon_colordeclined" />
                </Tooltip>
              </Typography>
            )}
            {row.status_id === 55 && userData.role_id !== 34 && (
              <Typography className="icon_colorpending">
                <Tooltip title="Waiting for Admin Approval">
                  <RunningWithErrorsRoundedIcon />
                </Tooltip>
              </Typography>
            )}

            {(userData.role_id == 35 || userData.role_id == 34) &&
              row.status_id == 56 && (
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<SendIcon />}
                  onClick={(e) => {
                    handleactionOpen();
                    setPaymentRequestId(row.id);
                  }}
                  sx={{ mx: 1 }}
                >
                  Action
                </Button>
              )}
          </Grid>
        </>
      ),
    },
  ]);
  let defaultValues = {
    txtName: "",
    txtLOB: "",
    txtStatus: "",
  };
  const handleEdit = (id) => {
    // defaultValues.txtName = `Central`;
    // defaultValues.txtLOB = `1`;
    // defaultValues.txtStatus = `2`;
    // reset({ ...defaultValues });
    // setIsEdit(true);
  };

  const [reference, setTransactionReference] = useState("");
  const [accountantDescription, setAccountantDescription] = useState("");
  const [dateofPayment, setDateofPayment] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentBankName, setPaymentBankName] = useState("");

  const accountantUpdate = (requestID) => {
    const data = JSON.stringify({
      intType: 3,
      intAccountantID: userData.emp_id,
      txtPaymentMode:
        paymentMode == "Bank Transfer" ? paymentBankName : paymentMode,
      txtAccountantPaidDatetime: dayjs(dateofPayment).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      intPaymentRequestID: requestID,
      txtAccountantDescription: accountantDescription,
      txtTransactionID: reference,
      intStatus: 57,
    });

    config.method = "POST";
    config.data = data;
    config.url = paymentRequestProcessAPI;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
        // window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRefs1 = useRef(false);

  //Projects List
  // const [ProjectNameList, setProjectNameList] = useState([]);
  // const [projectName, setProjectID] = useState(0);

  // useEffect(() => {
  //   if (tempRefs1.current === false) {
  //     const data = {
  //       intType: 6,
  //     };

  //     config.method = "POST";
  //     config.data = JSON.stringify(data);
  //     config.url = projectMasterAPI;

  //     axios(config)
  //       .then(function (response) {
  //         setProjectNameList(response.data.result);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //     return () => {
  //       tempRefs1.current = true;
  //     };
  //   }
  // }, []);

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <AlertMessage ref={childRef} />
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Payment Request(s) List
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" href="/payment/list">
                Payment Requests List
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <div className="empty-space-20"></div>
        <Grid
          container
          spacing={2}
          margin={`15px 0px`}
          padding={`10px 15px 0px 0px`}
        >
          <Grid item xs={12} lg={3} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Employee List
              </InputLabel>
              <Select
                labelId="Select Project"
                id="demo-simple-select"
                value={employeeID}
                label="Maristal Status"
                onChange={(e) => {
                  setEmployeeID(e.target.value);
                }}
              >
                {EmployeeList.map((row, index) => (
                  <MenuItem value={row.id} key={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select From Date"
                value={fromDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setFromDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select To Date"
                value={toDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setToDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              onClick={(e) => {
                tempRefs.current = false;
                fetchUsers(1, 10);
              }}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>

        {paymentRequests?.length > 0 && (
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} lg={3} md={3}>
              <LoadingButton
                href="#search"
                color="success"
                variant="contained"
                onClick={() => downloadExcel(currentPage, rowsPerPage)}
                startIcon={<DescriptionIcon />}
                sx={{
                  marginTop: "1rem",
                  marginRight: "1rem", // Add margin to create space between buttons
                  borderRadius: "5px !important",
                }}
              >
                Export to Excel
              </LoadingButton>
            </Grid>
          </Grid>
        )}

        <Grid className="form_design">
          <DataTable
            pagination
            paginationServer
            fixedHeader
            fixedHeaderScrollHeight="550px"
            columns={columns}
            data={paymentRequests}
            progressPending={loading}
            paginationTotalRows={totalCount}
            paginationDefaultPage={currentPage}
            paginationRowsPerPageOptions={[
              1, 10, 25, 50, 100, 500, 1000, 10000, 50000, 100000,
            ]}
            paginationPerPage={rowsPerPage}
            onChangePage={handlePageChange}
            onSort={handleSort}
            onChangeRowsPerPage={handleRowsPerPageChange}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
          />

          <Dialog open={paymentDetailFields}>
            <DialogTitle>Payment Details</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter Payment Transaction ID and Description
              </DialogContentText>
              <div className="empty-space-20"></div>
              <Grid container spacing={3}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    format="DD-MM-YYYY"
                  >
                    <MobileDatePicker
                      label="Date of Payment"
                      value={dateofPayment}
                      inputFormat="DD/MM/YYYY"
                      fullWidth
                      onChange={(newValue) => {
                        setDateofPayment(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid textAlign="center" item xs={12} lg={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mode of Payment
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={paymentMode}
                      label="Mode of Payment"
                      onChange={(e) => {
                        setPaymentMode(e.target.value);
                      }}
                    >
                      <MenuItem value="Cheque">Cheque</MenuItem>
                      <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                      <MenuItem value="Cash">Cash</MenuItem>
                      <MenuItem value="UPI">UPI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {paymentMode == "Bank Transfer" && (
                  <Grid textAlign="center" item xs={12} lg={6} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Name of Bank
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paymentBankName}
                        label="Mode of Payment"
                        onChange={(e) => {
                          setPaymentBankName(e.target.value);
                        }}
                      >
                        <MenuItem value="IOB TECH TRF">IOB TECH TRF</MenuItem>
                        <MenuItem value="KVB TECH TRF">KVB TECH TRF</MenuItem>
                        <MenuItem value="KG CONS TRF">KG CONS TRF</MenuItem>
                        <MenuItem value="KGF TRF">KGF TRF</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid textAlign="center" item xs={12} lg={6} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    label="Transaction ID"
                    onChange={(e) => setTransactionReference(e.target.value)}
                  />
                </Grid>
                <Grid textAlign="center" item xs={12} lg={6} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    label="Description"
                    onChange={(e) => setAccountantDescription(e.target.value)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ margin: "10px" }}
                className={`btn-primary`}
                type="submit"
                onClick={() => accountantUpdate(paymentRequestId)}
              >
                Update
              </Button>
              <Button
                style={{ margin: "10px" }}
                className={`btn-primary`}
                type="submit"
                // value={row.id}
                onClick={handleactionClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={paymentDeclineFields}>
            <DialogTitle>Decline Conformation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                This action can't be undone. Really want to decline the payment
                request?
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Reason"
                type="text"
                onChange={declinePaymentReason}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                style={{ margin: "10px" }}
                className={`btn-primary`}
                type="submit"
                onClick={() => {
                  paymentActions(declineRequestId, "decline");
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Layout>
  );
}
export default ListPaymentRequest;
