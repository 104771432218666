import Layout from "../../../layout/Layout";
import {
  Grid,
  FormLabel,
  TextField,
  FormControl,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { config, payslipDetailsAPI } from "../../../config";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { AlertMessage } from "../../../components/AlertMessage";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

let defaultValues = {};

function ManageAllowances() {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    intDearnessAllowance: Yup.string()
      .max(2, "Please Enter Valid Number")
      .matches("^[0-9]", "Please enter a number; do not enter % or alphabet."),
    intHouseRentAllowance: Yup.string()
      .max(2, "Please Enter Valid Number")
      .matches("^[0-9]", "Please enter a number; do not enter % or alphabet."),
    intVehicleAllowance: Yup.string()
      .max(2, "Please Enter Valid Number")
      .matches("^[0-9]", "Please enter a number; do not enter % or alphabet."),
    intOtherAllowance: Yup.string()
      .max(2, "Please Enter Valid Number")
      .matches("^[0-9]", "Please enter a number; do not enter % or alphabet."),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const childRef = useRef();

  // Login
  const [payslipDetails, setPayslipDetails] = useState([]);
  function onSubmit(data) {
    // setLodingSubmit(true);
    const data1 = JSON.stringify({
      intDearnessAllowance: data.intDearnessAllowance,
      intHouseRentAllowance: data.intHouseRentAllowance,
      intVehicleAllowance: data.intVehicleAllowance,
      intOtherAllowance: data.intOtherAllowance,
      intType: 1,
      intMedicalAllowance: data.intMedicalAllowance,
    });
    config.method = "POST";
    config.data = data1;
    config.url = payslipDetailsAPI;

    axios(config)
      .then(function (response) {
        setPayslipDetails(response.data.result);
        console.log(response.data)
        childRef.current.triggerAlert(
          JSON.stringify({
            // message: response.data.message,
            message: "Allowances data updated",
            messageType: "success",
          })
        );
         if (response.data.code >= 200 && response.data.code < 400) {
           setTimeout(() => {
             window.location.reload(false);
           }, 3000);
         }
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
    //   .finally(function () {
    //     setBtnFlag(false);
    //   });
  }

  let tempRefs = useRef(false);

  useEffect(() => {
    // // handleFormReset();
    const data1 = JSON.stringify({
      intDearnessAllowance: 0,
      intHouseRentAllowance: 0,
      intVehicleAllowance: 0,
      intOtherAllowance: 0,
      intType: 2,
      intOffset: 0,
      intLimit: 0,
      txtPassword: "",
    });
    config.method = "POST";
    config.data = data1;
    config.url = payslipDetailsAPI;
    axios(config)
      .then(function (response) {
        const responseTempData = response.data.result[0];
        handleFormReset(responseTempData);
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
  }, []);

  const handleFormReset = (data) => {
    defaultValues.intDearnessAllowance = data.dearness_allowance;
    defaultValues.intHouseRentAllowance = data.house_rent_allowance;
    defaultValues.intVehicleAllowance = data.vehicle_allowance;
    defaultValues.intOtherAllowance = data.other_allowance;
    defaultValues.intMedicalAllowance = data.medical_allowance;
    reset({ ...defaultValues });
  };

  return (
    <>
      <Layout>
        <AlertMessage ref={childRef} />
        <Grid className="employee-form">
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Manage Allowances
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Typography color="text.primary">Manage Allowances</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)} className="form_design">
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={12} md={12}>
                <Typography>
                  <p className="hint">
                    <span className="span_text">Note : </span>
                    Vehicle Allowance Eligible after 1 year from joining and
                    every 6 months thereafter.
                  </p>
                </Typography>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="dearnessallowance"
                  type="text"
                  label="Dearness Allowance(%)"
                  maxlength="2"
                  fullWidth
                  focused
                  {...register("intDearnessAllowance")}
                  error={errors.intDearnessAllowance ? true : false}
                  helperText={errors.intDearnessAllowance?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="houserentallowance"
                  type="text"
                  label="House Rent Allowance(%)"
                  fullWidth
                  focused
                  {...register("intHouseRentAllowance")}
                  error={errors.intHouseRentAllowance ? true : false}
                  helperText={errors.intHouseRentAllowance?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="MedicalAllowance"
                  type="text"
                  label="Medical Allowance(%)"
                  fullWidth
                  focused
                  {...register("intMedicalAllowance")}
                  error={errors.intMedicalAllowance ? true : false}
                  helperText={errors.intMedicalAllowance?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="other"
                  type="text"
                  label="Other Allowances(%)"
                  fullWidth
                  focused
                  {...register("intOtherAllowance")}
                  error={errors.intOtherAllowance ? true : false}
                  helperText={errors.intOtherAllowance?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="vehicleallowance"
                  type="text"
                  label="Vehicle Allowance(Rupees)"
                  fullWidth
                  focused
                  {...register("intVehicleAllowance")}
                  error={errors.intVehicleAllowance ? true : false}
                  helperText={errors.intVehicleAllowance?.message}
                />
              </Grid>
              <div className="empty-space-20"></div>
              <FormControl
                sx={{ m: 0, width: "-webkit-fill-available" }}
                variant="standard"
              >
                <Grid container className="submit-button" alignItems="center">
                  <Grid item xs={12} lg={12} md={12}>
                    <LoadingButton
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={formState.isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </form>
        </Grid>
      </Layout>
    </>
  );
}

export default ManageAllowances;
