import React, { useEffect, useRef, useState, useMemo, lazy } from "react";

import moment from "moment";

import { Grid, Tooltip, Typography } from "@mui/material";

import axios from "axios";

import Layout from "../../../layout/Layout";

import { config, holidaydetailsAPI } from "../../../config";
import { AddHoliday } from "../../../components/AddHoliday";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Tooltip as ReactTooltip } from "react-tooltip";

let field = "id",
  orderBy = "desc";

const Pagination = lazy(() => import("../../../components/ReactPagination"));

function AddHolidayDay() {
  const navigate = useNavigate();

  let intOffset = 0;
  const limit = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [HolidayList, setHolidayList] = useState([]);

  // const handleReportPagination = (e) => {
  //   console.log(e.target.textContent);
  //   intOffset =
  //     e.target.innerText === "1"
  //       ? 0
  //       : (parseInt(e.target.innerText) - 1) * limit;
  // };

  let assetsRef = useRef(false);
  const childRef = useRef();

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  const fetchData = (action) => {
    if (action === 1) {
      intOffset = 0;
    }
  };

  //   const data = JSON.stringify({
  //     intType: 3,
  //   });
  //   config.method = "POST";
  //   config.data = data;
  //   config.url = holidaydetailsAPI;

  //   axios(config)
  //     .then(function (response) {
  //       setpositionMasterList(response.data["result"]);
  //       // setTotalCount(response.data["count"]);
  //       setTotalCount(Math.round(response.data.totalCount / limit));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (page, rowsPerPage) => {
    if (tempRefs.current === false) {
      const data = JSON.stringify({
        intLimit: rowsPerPage ? rowsPerPage : 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        intType: 3,
      });

      config.method = "POST";
      config.data = data;
      config.url = holidaydetailsAPI;

      axios(config)
        .then(function (response) {
          console.log("====================================");
          console.log(response);
          console.log("====================================");
          setHolidayList(response.data["result"]);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true;
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!HolidayList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const columns = useMemo(() => [
    {
      name: "Holiday Date",
      field: "id",
      sortable: true,
      selector: (row) => row.holiday_date,
    },
    {
      name: "Reason",
      field: "id",
      sortable: true,
      selector: (row) => row.holiday_reason,
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <Tooltip title={`Edit position`} arrow>
          <IconButton
            color="success"
            onClick={() =>
              handleEditHolidays(row.id, row.holiday_date, row.holiday_reason)
            }
            aria-label="add an alarm"
          >
            <EditLocationOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <Tooltip title={`Delete position`} arrow>
          <IconButton
            color="error"
            onClick={() => handleRemoveHolidays(row.id)}
            aria-label="add an alarm"
          >
            <DeleteTwoToneIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ]);

  const handleEditHolidays = (id, holidayDate, holidayReason) => {
    childRef.current.setData(
      JSON.stringify({
        intHolidayId: id,
        txtHolidayDate: holidayDate,
        txtHolidayReason: holidayReason,
        intType: 3,
        update: "yes",
        // fetchUsers: fetchUsers(1),
      })
    );
  };

  const handleRemoveHolidays = (id) => {
    const confirm = window.confirm("Are you sure want to delete this?");
    if (!confirm) return;

    const data = JSON.stringify({
      intHolidayId: id,
      intType: 5,
    });

    config.method = "POST";
    config.data = data;
    config.url = holidaydetailsAPI;

    axios(config)
      .then(function (response) {
        alert("Holiday has been deleted");

        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })

      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
  };

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Add Holiday
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Holiday</Typography>
              <Typography color="text.primary">Add</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <AddHoliday ref={childRef} fetchData={fetchData} />
          <div className="empty-space-20"></div>
          <Grid className="small_table">
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={HolidayList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default AddHolidayDay;
