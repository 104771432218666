import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import {
  Grid,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import Layout from "../../../layout/Layout";
import { config, clientMasterAPI, manageStoreMasterAPI } from "../../../config";
import { useNavigate } from "react-router-dom";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import { Tooltip as ReactTooltip } from "react-tooltip";
import SearchIcon from "@mui/icons-material/Search";

const Pagination = lazy(() => import("../../../components/ReactPagination"));

let field = "id",
  orderBy = "desc";

function StoreMasterList() {
  const navigate = useNavigate();

  let intOffset = 0;
  const limit = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [clientMasterList, setClientMasterList] = useState([]);

  const handleEditStore = (id) => {
    navigate(`/store/edit/${id}`);
  };

  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState("");

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (
    page,
    rowsPerPage,
    searchString = "",
    columnName = "",
    sortDirection = ""
  ) => {
    const data = JSON.stringify({
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      txtSearchString: searchString,
      txtColumnName: columnName,
      txtSortDirection: sortDirection,
      intType: 3,
    });

    config.method = "POST";
    config.data = data;
    config.url = manageStoreMasterAPI;
    axios(config)
      .then(function (response) {
        setClientMasterList(response.data["result"]);

        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSearch = (searchString) => {
    console.log(searchString);
    setSearchString(searchString);
    fetchUsers(1, rowsPerPage, searchString);
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage, searchString);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!clientMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage, searchString);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    console.log(column.name, sortDirection);
    fetchUsers(1, rowsPerPage, searchString, column.name, sortDirection);
  };

  const handleEditRole = (id) => {
    navigate(`/employee/view/${id}`);
  };

  const statusStyle = {
    width: "5rem",
    align: "center",
  };

  const columns = useMemo(() => [
    {
      name: "Store Name",
      field: "id",
      sortable: true,
      width: "20%",
      selector: (row) => row.store_name,
      // cell: (row) => row.employee_no,
    },
    {
      name: "Address",
      field: "id",
      sortable: true,
      selector: (row) => (
        <Tooltip title={row.address} arrow>
          <span>{row.address}</span>
        </Tooltip>
      ),
      // cell: (row) => row.employee_name,
    },
    {
      name: "City",
      field: "id",
      sortable: true,
      width: "20%",
      selector: (row) => row.city,
      // cell: (row) => row.secondary_number,
    },
    {
      name: "State",
      field: "id",
      sortable: true,
      selector: (row) => (
        <Tooltip title={row.state} arrow>
          <span>{row.state}</span>
        </Tooltip>
      ),
      cell: (row) => row.state,
      width: "12%",
    },
    {
      name: "PIN Code",
      field: "id",
      sortable: true,
      selector: (row) => (
        <Tooltip title={row.pin_code} arrow>
          <span>{row.pin_code}</span>
        </Tooltip>
      ),
      width: "10%",
      // cell: (row) => row.position_name,
    },
    // {
    //     name: "Client Type",
    //     field: "id",
    //     cell: (row) => row.client_type,
    // },
    // {
    //     name: "Status",
    //     field: "id",
    //     sortable: true,
    //     selector: (row) => <>
    //         {
    //             row.status == 2
    //                 ? (
    //                     <Typography className={`active-status`} style={statusStyle} align="center">Active</Typography>
    //                 )
    //                 : row.status == 3
    //                     ? (
    //                         <Typography className={`inactive-status`} style={statusStyle} align="center">Inactive </Typography>
    //                     )
    //                     : ""
    //         }
    //     </>
    // },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <IconButton
          color="success"
          onClick={() => handleEditStore(row.id)}
          aria-label="add an alarm"
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              List Store
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Store</Typography>
              <Typography color="text.primary">List</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="success"
              className="button_first"
              href="/store/add"
              startIcon={<AddIcon />}
            >
              Add Store
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{ mr: 4, mt: 2, justifyContent: `center` }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id="myTextField"
              label="Search"
              placeholder="Search"
              onKeyUp={(e) => handleSearch(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "40px",
                  paddingLeft: "20px", // Adjust the padding if necessary
                },
                "& .MuiInputBase-input": {
                  textAlign: "center", // Center the placeholder text
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid className="form_design">
          <Grid>
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={clientMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 5, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onSort={handleSort}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default StoreMasterList;
