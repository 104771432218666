import React from "react";

// react router component
import { useEffect, useRef, useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

// pages
import Login from "./authentication/Login";
import AdminLogin from "./authentication/AdminLogin";

// Employee Master
import AddEmployee from "./pages/HrMaster/EmployeeMaster/AddEmployee";
import ListEmployee from "./pages/HrMaster/EmployeeMaster/ListEmployee";
import ViewEmployee from "./pages/HrMaster/EmployeeMaster/ViewEmployee";

// Advance Payment
import AdvancePayment from "./pages/HrMaster/AdvancePayment/AdvancePaymentAdd";
import AdvancePaymentEdit from "./pages/HrMaster/AdvancePayment/AdvancePaymentEdit";
import AdvancePaymentView from "./pages/HrMaster/AdvancePayment/AdvancePaymentEdit";
import AdvancePaymentList from "./pages/HrMaster/AdvancePayment/AdvancePaymentList";

// LeaveMaster
import ApplyLeave from "./pages/HrMaster/LeaveMaster/ApplyLeave";
import ApproveLeave from "./pages/HrMaster/LeaveMaster/ApproveLeave";

// Project Mater
import ProjectMasterAdd from "./pages/ProjectMaster/ProjectMasterAdd";
import ProjectMasterList from "./pages/ProjectMaster/ProjectMasterList";
import ProjectMasterEdit from "./pages/ProjectMaster/ProjectMasterEdit";

// Client Master
import ClientMasterAdd from "./pages/ClientMaster/ClientMasterAdd";
import ClientMasterList from "./pages/ClientMaster/ClientMasterList";
import ClientMasterEdit from "./pages/ClientMaster/ClientMasterEdit";

// PositionMaster
import AddPositons from "./pages/OtherMasters/PositionMaster/AddPositionMaster";

//DepartmentMaster
import AddDepartments from "./pages/OtherMasters/DepartmentMaster/AddDepartments";

//ContractorMaster
// import AddContract from "./pages/ContractorMaster/AddContractor";
// import ListContractor from "./pages/ContractorMaster/ListContractor";

//HolidayMaster
import AddHolidayDay from "./pages/OtherMasters/HolidayMaster/AddHoliday";

//Pomaster
import PurchaseOrderCreate from "./pages/VendorMaster/PoMaster/PurchaseOrder";
import PurchaseOrderView from "./pages/VendorMaster/PoMaster/PurchaseOrderView";
import PurchaseOrderList from "./pages/VendorMaster/PoMaster/PurchaceOrderList";
import PurchaseOrderEdit from "./pages/VendorMaster/PoMaster/PurchaseOrderEdit";

//VendorMaster
import VendorCreate from "./pages/VendorMaster/VendorMaster/VendorCreate";
import VendorList from "./pages/VendorMaster/VendorMaster/VendorList";
import VendorEdit from "./pages/VendorMaster/VendorMaster/VendorEdit";

//PaymentRequestProject
import PaymentRequest from "./pages/PaymentRequest/PaymentRequest";
import PaymentRequestApproval from "./pages/PaymentRequest/PaymentRequestApproval";
import ListPaymentRequest from "./pages/PaymentRequest/PaymentRequestList";
import PaymentRequestView from "./pages/PaymentRequest/PaymentRequestView";

//Terms and Conditions
import TermsConditions from "./pages/OtherMasters/TermsConditions/TermsConditions";
import TermsConditionsList from "./pages/OtherMasters/TermsConditions/TermsConditionsList";
import TermsConditionsEdit from "./pages/OtherMasters/TermsConditions/TermsConditionsEdit";

// Role Mater
import RequireAuth from "./authentication/RequireAuth";

//Pay Slip
import PaySlip from "./pages/PayslipMaster/PaySlip";
import PayslipReport from "./pages/PayslipMaster/payslipReport";

//Allowances
import ManageAllowances from "./pages/OtherMasters/ManageAllowances/ManageAllowances";

//Attendance Master
import EmployeeAttendance from "./pages/AttendanceMaster/employeeAttendance";
import EmployeeAttendanceReport from "./pages/AttendanceMaster/employeeAttendanceReport";

import LabourAttandence from "./pages/UserMaster/LabourAttandence";
import LabourAttend from "./pages/AttendanceMaster/labourAttendence";
//User Master
import UserAttendance from "./pages/UserMaster/UserAttendance";

//Dashboard
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/PageNotFound";

// Announcement
import Announcement from "./pages/AnnouncementMaster/AnnouncementMaster.jsx";

// css
import "./App.css";

// Other
import { userData, FrontentUrl } from "./config";

//Test
import Pagination from "./pages/Pagination";
import ProjectIncome from "./pages/ProjectMaster/ProjectIncome";
import Invoice from "./pages/InvoiceMaster/Invoice";
import ManageCoAdd from "./pages/ManageCompanies/ManageCoAdd";
import InvoiceTermsCondition from "./pages/InvoiceTermsAndConditions/InvoiceTermsCondition";
import InvoiceTermsConditionList from "./pages/InvoiceTermsAndConditions/InvoiceTermsConditionList";
import ManageCoList from "./pages/ManageCompanies/ManageCoList";
import ManageCoEdit from "./pages/ManageCompanies/ManageCoEdit";
import InvoiceTermsConditionEdit from "./pages/InvoiceTermsAndConditions/InvoiceTermsConditionEdit";
import InvoiceList from "./pages/InvoiceMaster/InvoiceList";
import InvoiceView from "./pages/InvoiceMaster/InvoiceView";
import ProjectSummaryReport from "./pages/reports/ProjectSummaryReport";
import ProjectReport from "./pages/reports/ProjectReport";
import LeaveReport from "./pages/reports/LeaveReport";
import EmployeeActivityReport from "./pages/reports/EmployeeActivityReport";
import AttendanceReport from "./pages/reports/EmployeeAttendanceReport";
import ProjectPaymentReport from "./pages/reports/ProjectPaymentReport";

import Configuration from "./pages/OtherMasters/ConfigurationMaster/Configuration";
import LabourWages from "./pages/OtherMasters/ConfigurationMaster/LabourWages";
import LabourWagesEdit from "./pages/OtherMasters/ConfigurationMaster/LabourWagesEdit";

import PaymentRequestList from "./pages/PaymentRequest/PaymentRequestList";

import storeMasterAdd from "./pages/OtherMasters/StoreMaster/StoreMasterAdd.jsx";
import StoreMasterAdd from "./pages/OtherMasters/StoreMaster/StoreMasterAdd.jsx";
import StoreMasterList from "./pages/OtherMasters/StoreMaster/StoreMasterList";
import StoreMasterEdit from "./pages/OtherMasters/StoreMaster/StoreMasterEdit";
import InvoiceEdit from "./pages/InvoiceMaster/InvoiceEdit";

const Layout = () => {
  return <Outlet />;
};

function App() {
  const [role, setRole] = useState("");
  const useRefApp = useRef(false);
  useEffect(() => {
    if (useRefApp.current === false) {
      const useData = JSON.parse(localStorage.getItem("user"));
      if (useData !== null) {
        setRole(useData.role);
      } else {
        if (
          ![FrontentUrl, FrontentUrl + "/adminlogin"].includes(
            window.location.origin
          )
        ) {
          // window.location.href = window.location.origin + "/"
        }
      }
    }
    return () => {
      useRefApp.current = true;
    };
  }, []);

  return (
    <Routes>
      {/* <Route path="*" component={PageNotFound} /> */}
      {/* <Route path="/" element={<Layout />}> */}
      <Route path="/" element={<Login />} />
      <Route path="adminlogin" element={<AdminLogin />} />
      {userData === null ? (
        <></>
      ) : (
        <>
          {userData.role_id === 36 ||
          userData.role_id === 34 ||
          userData.role_id === 35 ? (
            <Route path="user" element={<RequireAuth />}>
              <Route path="attendance" element={<UserAttendance />} />
              <Route path="labour-attandence" element={<LabourAttandence />} />
              <Route path="labour-attend" element={<LabourAttend />} />
              <Route path="payment/request" element={<PaymentRequest />} />
              <Route path="payment/list" element={<ListPaymentRequest />} />
              <Route path="apply-leave" element={<ApplyLeave />} />
            </Route>
          ) : (
            <></>
          )}

          {userData.role_id === 35 || userData.role_id === 34 ? (
            <>
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route path="announcement" element={<Announcement />}></Route>
              <Route path="employee" element={<RequireAuth />}>
                <Route path="add" element={<AddEmployee />} />
                <Route path="list" element={<ListEmployee />} />
                <Route path="view/:employeeid" element={<ViewEmployee />} />
              </Route>

              <Route path="advance-payment" element={<RequireAuth />}>
                {/* <Route path="add" element={<AdvancePayment />} /> */}
                <Route
                  path="edit/:paymentid"
                  element={<AdvancePaymentEdit />}
                />
                <Route
                  path="view/:paymentid"
                  element={<AdvancePaymentView />}
                />
                <Route path="list" element={<AdvancePaymentList />} />
              </Route>

              <Route path="Leave" element={<RequireAuth />}>
                <Route path="approval" element={<ApproveLeave />} />
              </Route>

              <Route path="project" element={<RequireAuth />}>
                <Route path="add" element={<ProjectMasterAdd />} />
                <Route path="list" element={<ProjectMasterList />} />
                <Route path="edit/:projectid" element={<ProjectMasterEdit />} />
                <Route
                  path="project-income/:projectid"
                  element={<ProjectIncome />}
                />
              </Route>

              <Route path="client" element={<RequireAuth />}>
                <Route path="add" element={<ClientMasterAdd />} />
                <Route path="list" element={<ClientMasterList />} />
                <Route path="edit/:clientid" element={<ClientMasterEdit />} />
              </Route>

              <Route path="store" element={<RequireAuth />}>
                <Route path="add" element={<StoreMasterAdd />} />
                <Route path="list" element={<StoreMasterList />} />
                <Route path="edit/:storeId" element={<StoreMasterEdit />} />
              </Route>

              <Route path="payment" element={<RequireAuth />}>
                <Route path="request" element={<PaymentRequest />} />
                <Route path="list" element={<ListPaymentRequest />} />
                <Route path="approval" element={<PaymentRequestApproval />} />
                <Route
                  path="admin/:status/:requestID"
                  element={<PaymentRequestView />}
                />
              </Route>

              <Route path="vendor" element={<RequireAuth />}>
                <Route path="create" element={<VendorCreate />} />
                <Route path="list" element={<VendorList />} />
                <Route path="edit/:vendorid" element={<VendorEdit />} />
              </Route>

              <Route path="purchase-order" element={<RequireAuth />}>
                <Route path="create" element={<PurchaseOrderCreate />} />
                <Route path="view/:poid" element={<PurchaseOrderView />} />
                <Route path="list" element={<PurchaseOrderList />} />
                <Route path="edit/:POid" element={<PurchaseOrderEdit />} />
              </Route>

              <Route path="config" element={<RequireAuth />}>
                <Route path="positions-master" element={<AddPositons />} />
                <Route path="departments-master" element={<AddDepartments />} />
                <Route path="configuration" element={<Configuration />} />
                <Route path="labour-wages" element={<LabourWages />} />
                <Route
                  path="labour-wages/edit/:labourId"
                  element={<LabourWagesEdit />}
                />
              </Route>
              <Route path="reports" element={<RequireAuth />}>
                <Route
                  path="project-summary"
                  element={<ProjectSummaryReport />}
                />
                <Route path="project-report" element={<ProjectReport />} />

                <Route path="leave-report" element={<LeaveReport />} />
                <Route
                  path="employee-activity-report"
                  element={<EmployeeActivityReport />}
                />
                <Route
                  path="employee-attendance-report"
                  element={<AttendanceReport />}
                />

                <Route
                  path="project-payment-report"
                  element={<ProjectPaymentReport />}
                />
              </Route>
              <Route path="termsandconditions" element={<RequireAuth />}>
                <Route path="add" element={<TermsConditions />} />
                <Route path="list" element={<TermsConditionsList />} />
                <Route path="edit/:termsID" element={<TermsConditionsEdit />} />
              </Route>

              <Route path="add-holiday" element={<AddHolidayDay />} />
              <Route path="payslip/:employee_id/:date" element={<PaySlip />} />
              <Route path="payslip-report" element={<PayslipReport />} />
              <Route path="manage-allowances" element={<ManageAllowances />} />
              <Route
                path="employee-attendance"
                element={<EmployeeAttendance />}
              />
              <Route
                path="employee-attendance-report/:id"
                element={<EmployeeAttendanceReport />}
              />
              <Route path="pagination" element={<Pagination />} />

              <Route path="manage-companies" element={<RequireAuth />}>
                <Route path="add" element={<ManageCoAdd />} />
                <Route path="list" element={<ManageCoList />} />
                <Route path="edit/:companyId" element={<ManageCoEdit />} />
              </Route>

              <Route path="invoice" element={<RequireAuth />}>
                <Route path="create" element={<Invoice />} />
                <Route path="list" element={<InvoiceList />} />
                <Route path="view/:poid" element={<InvoiceView />} />
                <Route path="edit/:IOid" element={<InvoiceEdit />} />
              </Route>

              <Route path="invoiceTermsAndConditions" element={<RequireAuth />}>
                <Route path="add" element={<InvoiceTermsCondition />} />
                <Route path="list" element={<InvoiceTermsConditionList />} />
                <Route
                  path="edit/:termsID"
                  element={<InvoiceTermsConditionEdit />}
                />
              </Route>

              <Route path="payment-request" element={<RequireAuth />}>
                <Route path="list" element={<PaymentRequestList />} />
              </Route>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {/* </Route> */}
    </Routes>
  );
}

export default App;
