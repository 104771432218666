import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  IconButton,
  Autocomplete,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  announcementAPI,
  config,
  departmentMasterAPI,
  manageCompaniesAPI,
  positionMasterAPI,
} from "../../../config";
import { _getDropdownListData } from "../../../service";
import axios from "axios";
import { format, toDate } from "date-fns";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import moment from "moment";

const validationSchema = Yup.object().shape({
  txtTitle: Yup.string().required("Title is required"),
  txtDescription: Yup.string().required("Description is required"),
});

export default function AddAnnouncement({
  loading,
  setLoading,
  alertRef,
  setTabValue,
  editingAnnouncement,
  fetchAnnouncement,
}) {
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [allPositions, setAllPositions] = useState([]); // Store all positions
  const [selectedImage, setSelectedImage] = useState(null);
  const isInitialRender = useRef(true);
  const [image, setImage] = useState(editingAnnouncement?.image || null);

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    control,
    trigger,
    watch,
  } = useForm({
    ...formOptions,
    defaultValues: {
      txtTitle: editingAnnouncement?.title || "",
      fromDate: editingAnnouncement
        ? dayjs(editingAnnouncement.from_date).toDate()
        : dayjs().toDate(),
      toDate: editingAnnouncement
        ? dayjs(editingAnnouncement.to_date).toDate()
        : dayjs().toDate(),
      txtCompany: editingAnnouncement?.company
        ? editingAnnouncement.company?.split(",").map(Number)
        : [],
      txtDepartment: editingAnnouncement?.department
        ? editingAnnouncement.department?.split(",").map(Number)
        : [],
      txtPosition: editingAnnouncement?.position
        ? editingAnnouncement.position?.split(",").map(Number)
        : [],
      txtDescription: editingAnnouncement?.description || "",
    },
  });

  const { errors } = formState;
  const selectedDepartments = watch("txtDepartment") || [];
  const quillOptions = {
    quillModules: {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline"],
        ["link"],
        ["clean"],
      ],
    },
    quillFormats: [
      "header",
      "font",
      "list",
      "bullet",
      "bold",
      "italic",
      "underline",
      "link",
    ],
  };

  // Getting list data List
  async function fetchDDData() {
    setLoading(true);
    try {
      let data;
      // >> company list
      data = {
        requestData: {
          intType: 5,
        },
        API: manageCompaniesAPI,
        setData: setCompanyList,
      };
      _getDropdownListData(data);

      // >> department list
      data = {
        requestData: {
          intType: 3,
        },
        API: departmentMasterAPI,
        setData: setDepartmentList,
      };
      _getDropdownListData(data);

      // >> position list
      data = {
        requestData: {
          intType: 44,
        },
        API: positionMasterAPI,
        setData: setPositionList,
      };
      _getDropdownListData(data);

      const payload = {
        intType: 44,
      };

      config.method = "POST";
      config.data = payload;
      config.url = positionMasterAPI;

      axios(config)
        .then(function (response) {
          setAllPositions(response.data.result);
          // filterPositions(response.data.result, selectedDepartments);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching list data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDDData();
  }, []);

  // Ensure all positions are loaded before running the logic
  useEffect(() => {
    if (!allPositions || allPositions.length === 0) return; // Prevent running if positions are not loaded

    let filteredPositions = allPositions.filter((position) =>
      selectedDepartments.includes(position.department_id)
    );

    console.log(
      "🚀💻 ~ file: Add.jsx:191 ~ useEffect ~ editingAnnouncement:",
      editingAnnouncement
    );
    // if (editingAnnouncement) {
    //   const editingPositions = editingAnnouncement?.position
    //     .split(",")
    //     .map(Number);

    //   const additionalPositions = allPositions.filter((position) =>
    //     editingPositions.includes(position.id)
    //   );

    //   // Add previously selected positions to filtered list
    //   filteredPositions = [
    //     ...filteredPositions,
    //     ...additionalPositions.filter(
    //       (position) => !filteredPositions.some((p) => p.id === position.id)
    //     ),
    //   ];

    //   // Only set initial values once, when editing starts
    //   if (isInitialRender.current) {
    //     setValue("txtPosition", editingPositions);
    //     isInitialRender.current = false;
    //   }
    // }

    if (editingAnnouncement) {
      // Safely handle when position is null or undefined
      const editingPositions = editingAnnouncement.position
        ? editingAnnouncement.position.split(",").map(Number)
        : []; // Use an empty array if position is null or undefined

      const additionalPositions = allPositions.filter((position) =>
        editingPositions.includes(position.id)
      );

      // Add previously selected positions to filtered list
      filteredPositions = [
        ...filteredPositions,
        ...additionalPositions.filter(
          (position) => !filteredPositions.some((p) => p.id === position.id)
        ),
      ];

      // Only set initial values once, when editing starts
      if (isInitialRender.current) {
        setValue("txtPosition", editingPositions);
        isInitialRender.current = false;
      }
    }

    setPositionList(filteredPositions);

    const currentSelectedPositions = watch("txtPosition") || [];

    // Remove positions whose departments have been removed
    const validPositionIds = filteredPositions.map((position) => position.id);
    const updatedSelectedPositions = currentSelectedPositions.filter(
      (positionId) => validPositionIds.includes(positionId)
    );

    if (updatedSelectedPositions.length !== currentSelectedPositions.length) {
      setValue("txtPosition", updatedSelectedPositions);
    }
  }, [selectedDepartments, allPositions, setValue, watch, editingAnnouncement]);

  useEffect(() => {
    if (editingAnnouncement?.image) {
      setSelectedImage({ preview: editingAnnouncement.image });
    }
  }, [editingAnnouncement]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  const removeImage = () => {
    setImage(null);
  };

  // -> submit
  async function onSubmit(data) {
    setLoading(true);
    try {
      let payload = {
        title: data.txtTitle,
        fromDate: moment(data.fromDate).format("YYYY-MM-DD"),
        toDate: moment(data.toDate).format("YYYY-MM-DD"),
        description: data.txtDescription,
        companyIds: data.txtCompany,
        departmentIds: data.txtDepartment,
        positionIds: data.txtPosition,
        image: image, // Use the image state variable
        intType: editingAnnouncement ? 3 : 1,
      };

      if (editingAnnouncement) {
        payload.id = editingAnnouncement.id;
      }

      config.method = "POST";
      config.data = payload;
      config.url = announcementAPI;

      axios(config)
        .then(function (response) {
          if (response.data.code === 200 && response.data.success === true) {
            alertRef.current.triggerAlert(
              JSON.stringify({
                message: response.data.message,
                messageType: "success",
              })
            );

            setTimeout(function () {
              fetchAnnouncement();
              setTabValue("1");
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <TextField
            name="title"
            type="text"
            label="Title"
            fullWidth
            {...register("txtTitle")}
            error={!!errors.txtTitle}
            helperText={errors.txtTitle?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Controller
            name="fromDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                label="From Date"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            )}
          />
          {errors.fromDate && (
            <Typography color="error">{errors.fromDate.message}</Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <Controller
            name="toDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                label="To Date"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            )}
          />
          {errors.toDate && (
            <Typography color="error">{errors.toDate.message}</Typography>
          )}
        </Grid>
        <Grid item className="form-label" xs={12}>
          <Controller
            name="txtDescription"
            control={control}
            render={({ field }) => (
              <ReactQuill
                {...field}
                onChange={(content) => {
                  field.onChange(content);
                  setValue("txtDescription", content);
                }}
                onBlur={() => {
                  trigger("txtDescription");
                }}
                modules={quillOptions.quillModules}
                formats={quillOptions.quillFormats}
                style={{
                  height: "200px",
                  overflowY: "hidden",
                  padding: "10px",
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}
                className="quill-editor"
              />
            )}
          />
          {errors.txtDescription && (
            <Typography sx={{ color: "#D32F2F", fontSize: "13px", ml: 2 }}>
              {errors.txtDescription.message}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography style={{ color: "#1565C0" }}>
            <strong>Choose to customize this announcement</strong>
          </Typography>
        </Grid>
        <Grid item className="form-label" xs={12} md={6} lg={4}>
          <FormControl fullWidth>
            <Controller
              name="txtCompany"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={companyList}
                  getOptionLabel={(option) => option.company_name || ""}
                  value={companyList.filter((company) =>
                    field.value.includes(company.id)
                  )}
                  onChange={(_, selectedOptions) =>
                    field.onChange(selectedOptions.map((option) => option.id))
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Company" variant="outlined" />
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((value, index) => (
                      <Chip
                        key={index}
                        label={value.company_name}
                        sx={{
                          backgroundColor: "#14553B",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  PaperProps={{
                    sx: {
                      maxHeight: 200,
                      overflowY: "auto",
                    },
                  }}
                />
              )}
            />
            <Typography className="invalid-feedback">
              {errors.txtCompany?.message}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item className="form-label" xs={12} md={6} lg={4}>
          <FormControl fullWidth>
            <Controller
              name="txtDepartment"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={departmentList}
                  getOptionLabel={(option) => option.department_name || ""}
                  value={departmentList.filter((department) =>
                    field.value.includes(department.id)
                  )}
                  onChange={(_, selectedOptions) =>
                    field.onChange(selectedOptions.map((option) => option.id))
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department"
                      variant="outlined"
                    />
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((value, index) => (
                      <Chip
                        key={index}
                        label={value.department_name}
                        sx={{
                          backgroundColor: "#AEB92F",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  PaperProps={{
                    sx: {
                      maxHeight: 200,
                      overflowY: "auto",
                    },
                  }}
                />
              )}
            />
            <Typography className="invalid-feedback">
              {errors.txtDepartment?.message}
            </Typography>
          </FormControl>
        </Grid>

        <Grid item className="form-label" xs={12} md={6} lg={4}>
          <FormControl fullWidth>
            {/* <InputLabel id="position-select-label">Position</InputLabel> */}
            <Controller
              name="txtPosition"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={positionList}
                  getOptionLabel={(option) => option.position_name || ""}
                  value={positionList.filter((position) =>
                    field.value.includes(position.id)
                  )}
                  onChange={(_, selectedOptions) =>
                    field.onChange(selectedOptions.map((option) => option.id))
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Position"
                      variant="outlined"
                    />
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((value, index) => (
                      <Chip
                        key={index}
                        label={value.position_name}
                        sx={{
                          backgroundColor: "#0D529F",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  PaperProps={{
                    sx: {
                      maxHeight: 200,
                      overflowY: "auto",
                    },
                  }}
                />
              )}
            />
            <Typography className="invalid-feedback">
              {errors.txtPosition?.message}
            </Typography>
          </FormControl>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography style={{ color: "#1565C0" }}>
            <strong>Choose Image</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #0D529F",
              borderRadius: "10px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
              position: "relative",
            }}
          >
            <input {...getInputProps()} />
            {image ? (
              <Box sx={{ position: "relative" }}>
                <Box
                  component="img"
                  src={image}
                  alt="Preview"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "150px",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            ) : (
              <Typography>
                {isDragActive
                  ? "Drop the image here ..."
                  : "Drag & drop or click to select image for announcement"}
              </Typography>
            )}
          </Box>
          {formState.errors.attachment && (
            <Typography sx={{ color: "#D32F2F", fontSize: "13px", mt: 1 }}>
              {formState.errors.attachment.message}
            </Typography>
          )}
        </Grid>

        {/* <Grid item xs={12}>
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #0D529F",
              borderRadius: "10px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
              position: "relative", // Ensure positioning for the icon
            }}
          >
            <input {...getInputProps()} />
            {selectedImage ? (
              <Box
                sx={{
                  position: "relative", // Ensure the cross icon is positioned relative to the image
                }}
              >
                <Box
                  component="img"
                  src={selectedImage.preview}
                  alt="Preview"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "150px",
                    marginTop: "10px",
                    borderRadius: "8px",
                  }}
                />
                <IconButton
                  onClick={removeImage}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "white",
                    backgroundColor: "rgba(255, 10, 84, 0.9)",
                    "&:hover": {
                      backgroundColor: "rgba(201, 24, 74, 1)",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <Typography>
                {isDragActive
                  ? "Drop the image here ..."
                  : "Drag & drop or click to select image for announcement"}
              </Typography>
            )}
          </Box>
          {formState.errors.attachment && (
            <Typography sx={{ color: "#D32F2F", fontSize: "13px", mt: 1 }}>
              {formState.errors.attachment.message}
            </Typography>
          )}
        </Grid> */}
      </Grid>
      <Grid container spacing={2} my={2} justifyContent="flex-end">
        <Grid item>
          {selectedImage && (
            <LoadingButton
              onClick={removeImage}
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "rgba(255, 10, 84, 0.9)",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "rgba(255, 10, 84, 0.9)",
                  scale: "1.01",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
                mr: 1,
              }}
              startIcon={<DeleteRoundedIcon />}
            >
              Remove image
            </LoadingButton>
          )}
        </Grid>
        <Grid item>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              "&:hover": {
                backgroundColor: "white",
                color: "primary.main",
                scale: "1.01",
              },
            }}
          >
            <Box display={"flex"} gap={1}>
              <CampaignRoundedIcon />
              {editingAnnouncement ? "Update " : "Submit"}
            </Box>
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

// -> departments based positions list from DB backend
// useEffect(() => {
//   if (selectedDepartments.length > 0) {
//     const fetchPositions = async () => {
//       setLoading(true);
//       try {
//         const data = {
//           intDepartmentIDs: selectedDepartments,
//           intType: 44,
//         };
//         const config = {
//           method: "POST",
//           data: JSON.stringify(data),
//           url: positionMasterAPI,
//           headers: { "Content-Type": "application/json" },
//         };
//         const response = await axios(config);
//         setPositionList(response.data.result || []); // Ensure positions are updated
//       } catch (error) {
//         console.error("Error fetching positions:", error);
//         setPositionList([]); // Reset positions if there's an error
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchPositions();
//   }
// }, [selectedDepartments]);
