import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import React from 'react';

const RequireAuth = () => {
    // const token = useSelector(selectCurrentToken)    
    const location = useLocation()
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        // <Outlet />
        user
            ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
    )
}
export default RequireAuth