import React, { useEffect, useRef, useState, useMemo } from "react";
import { Button, Grid, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { config, handleHrMasterAPI } from "../config";
import DataTable from "react-data-table-component";
import NotFound from "../components/NotFount";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

let field = "id",
  orderBy = "desc";

export default function Pagination() {
  const navigate = useNavigate();

  const [HrMasterList, setHrMasterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  const fetchUsers = async (page, rowsPerPage) => {
    const data = JSON.stringify({
      intLimit: rowsPerPage,
      intOffset: (page - 1) * rowsPerPage,
      intType: 2,
    });

    config.method = "POST";
    config.data = data;
    config.url = handleHrMasterAPI;
    axios(config)
      .then(function (response) {
        setHrMasterList(response.data["result"]);
        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!HrMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const handleEditRole = (id) => {
    navigate(`/employee/view/${id}`);
  };

  const columns = useMemo(() => [
    // {
    //   name: "ID",
    //   field: "id",
    //   sortable: true,
    //   cell: (row) => row.index,
    // },
    {
      name: "Employee No",
      field: "id",
      sortable: true,
      cell: (row) => row.employee_no,
    },
    {
      name: "Name",
      field: "id",
      sortable: true,
      cell: (row) => row.employee_name,
    },
    {
      name: "Phone",
      field: "id",
      sortable: true,
      cell: (row) => row.secondary_number,
    },
    {
      name: "Position",
      field: "id",
      sortable: true,
      cell: (row) => row.position_name,
    },
    {
      name: "Employee Pin",
      field: "id",
      sortable: true,
      cell: (row) => (row.pin ? row.pin : "-"),
    },
    {
      name: "Action",
      field: "id",
      sortable: true,
      cell: (row) => (
        <a href={row.title} onClick={() => handleEditRole(row.ID)} id="d1">
          Action
        </a>
      ),
    },
  ]);
  let defaultValues = {
    txtName: "",
    txtLOB: "",
    txtStatus: "",
  };
  const handleEdit = (id) => {
    // defaultValues.txtName = `Central`;
    // defaultValues.txtLOB = `1`;
    // defaultValues.txtStatus = `2`;
    // reset({ ...defaultValues });
    // setIsEdit(true);
  };

  return (
    <div className="App">
      <DataTable
        pagination
        paginationServer
        fixedHeader
        fixedHeaderScrollHeight="550px"
        columns={columns}
        data={HrMasterList}
        progressPending={loading}
        paginationTotalRows={totalCount}
        paginationDefaultPage={currentPage}
        paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
        paginationPerPage={rowsPerPage}
        onChangePage={handlePageChange}
        onSort={handleSort}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
}
