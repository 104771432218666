import React, { useEffect, useState } from "react";
import companyLogo from "../assets/images/Logo/logo.png";
import Layout from "../layout/Layout";
import AttendanceChart from "../../src/pages/Dashboard/attendanceChart.jsx";
import ProjectPaymentChart from "./Dashboard/projectPaymentChart";
import PaymentRequextChart from "./Dashboard/PaymentRequextChart.jsx";
import Attendance from "./Dashboard/Attendance.jsx";
import { Box, Card, Grid, MenuItem, Select, Typography } from "@mui/material";
import {
  config,
  dashboardAPI,
  manageCompaniesAPI,
  statusMasterAPI,
} from "../config";
import moment from "moment";
import { startOfMonth, endOfMonth, format } from "date-fns";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

import revenueImg from "../assets/images/dashboard/activity.svg";
import profileImg from "../assets/images/dashboard/profile.svg";
import infoImg from "../assets/images/dashboard/info.svg";

import { _getDropdownListData } from "../service";

function Dashboard() {
  const [totalRevenue, setTotalRevenue] = useState({});

  const [totalList, settotalList] = useState({});
  const [type, setType] = useState("Today");
  const [company, setCompany] = useState("All");

  const [fromDate, setfromDate] = useState(
    moment().utcOffset("+05:30").format("YYYY-MM-DD")
  );
  const [toDate, settoDate] = useState(
    moment().utcOffset("+05:30").format("YYYY-MM-DD")
  );

  const [companyList, setComanyList] = useState([]);
  const [companyID, setCompanyID] = useState(0);

  const [paymentRequestList, setPaymentRequestList] = useState([]);

  useEffect(() => {
    let fromDate1 = moment().utcOffset("+05:30").format("YYYY-MM-DD"),
      toDate1 = moment().utcOffset("+05:30").format("YYYY-MM-DD");

    setfromDate(moment().utcOffset("+05:30").format("YYYY-MM-DD"));
    settoDate(moment().utcOffset("+05:30").format("YYYY-MM-DD"));

    if (type === "Month") {
      const currentDate = new Date();
      setfromDate(format(startOfMonth(currentDate), "yyyy-MM-dd"));
      settoDate(format(endOfMonth(currentDate), "yyyy-MM-dd"));

      fromDate1 = format(startOfMonth(currentDate), "yyyy-MM-dd");
      toDate1 = format(endOfMonth(currentDate), "yyyy-MM-dd");
    } else if (type === "This Week") {
      const currentDate = moment();

      fromDate1 = currentDate.clone().startOf("week");
      toDate1 = currentDate.clone().endOf("week");
      setfromDate(fromDate1);
      settoDate(toDate1);
    } else if (type === "Last Week") {
      const currentDate = moment();

      // Calculate the start and end dates of the last week
      fromDate1 = currentDate.clone().subtract(1, "weeks").startOf("week");

      toDate1 = currentDate.clone().subtract(1, "weeks").endOf("week");

      // Set the state with the calculated dates
      setfromDate(fromDate1);
      settoDate(toDate1);
    } else if (type === "This Year") {
      const currentDate = moment();

      // Calculate the start and end dates of the current year
      fromDate1 = currentDate.clone().startOf("year");
      toDate1 = currentDate.clone().endOf("year");

      // Set the state with the calculated dates
      setfromDate(fromDate1);
      settoDate(toDate1);
    }

    let requestData = {
      intType: 3,
      intID: companyID,
    };

    config.method = "POST";
    config.data = JSON.stringify(requestData);
    config.url = dashboardAPI;
    axios(config)
      .then(function (response) {
        settotalList(response.data.result[0]);
      })
      .catch(function (error) {
        console.log(error);
      });

    requestData = {
      intType: 1,
      intStatus: 57,
      txtFromDate: fromDate1,
      txtToDate: toDate1,
      intID: companyID,
    };
    config.method = "POST";
    config.data = JSON.stringify(requestData);
    config.url = dashboardAPI;
    axios(config)
      .then(function (response) {
        setPaymentRequestList(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });

    //Project amount Summary
    requestData = {
      intType: 5,
      txtFromDate: fromDate1,
      txtToDate: toDate1,
      intID: companyID,
    };

    config.method = "POST";
    config.data = JSON.stringify(requestData);
    config.url = dashboardAPI;
    axios(config)
      .then(function (response) {
        let data = {
          total_amount: response.data.result[0].total_value
            ? response.data.result[0].total_value
            : 0,
          total_amount_spent: response.data.result[0].total_amount_spent
            ? response.data.result[0].total_amount_spent
            : 0,
          amount_spent: response.data.result[0].amout_spent
            ? response.data.result[0].amout_spent
            : 0,
        };

        setTotalRevenue(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [type, companyID]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  //Get company Names
  useEffect(() => {
    let data = {
      requestData: {
        intType: 6,
      },
      API: manageCompaniesAPI,
      setData: setComanyList,
    };

    _getDropdownListData(data);
  }, []);

  return (
    <Layout>
      {/* zIndex Changed*/}
      <Box
        className="top_sticky"
        sx={{ padding: "10px", zIndex: "99", marginTop: "10px" }}
        // display={"flex"}
        // alignItem={"center"}
        // justifyContent={"center"}
        xs={6}
        md={6}
        lg={6}
      >
        <Grid container spacing={1.5}>
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            className="text-center"
            // display={"flex"}
            // alignItem={"center"}
            // justifyContent={"center"}
          >
            <Typography
              // fontSize={{
              //   xs: "10px",
              //   sm: "20px",
              //   md: "30px",
              //   lg: "2.2rem",
              // }}
              fontSize={"1.8rem"}
              fontWeight={700}
              color={"#0c53a0"}
            >
              {fromDate === toDate
                ? moment(fromDate).format("DD/MM/YYYY")
                : moment(fromDate).format("DD/MM/YYYY") +
                  " to " +
                  moment(toDate).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid item>
            <Select
              className="button_elect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={companyID}
              label="State"
              onChange={(e) => {
                setCompanyID(e.target.value);
                setCompany(e.target.value);
              }}
            >
              <MenuItem value={0} key={0}>
                All Companies
              </MenuItem>
              {companyList.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.company_name}
                </MenuItem>
              ))}
            </Select>

            <Select
              className="button_elect"
              defaultValue={"Today"}
              onChange={handleChange}
            >
              <MenuItem value={"Today"}>Today</MenuItem>
              <MenuItem value={"This Week"}>This Week</MenuItem>
              <MenuItem value={"Last Week"}>Last Week</MenuItem>
              <MenuItem value={"Month"}>Current Month</MenuItem>
              <MenuItem value={"Last Month"}>Last Month</MenuItem>
              <MenuItem value={"This Year"}>This Year</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: "10px" }}>
        <Grid
          container
          spacing={0.5}
          sx={{ marginTop: { xs: "200px", md: "80px" } }}
        >
          <Grid item xs={12} md={4} lg={6}>
            <Grid className="dashboard-card-3 d-green" margin={1}>
              <Typography variant="p">Active Employees</Typography>
              <Typography variant="h2">{totalList.active_users}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} lg={6}>
            <Grid className="dashboard-card-3 d-green-1" margin={1}>
              <Typography variant="p">Active Projects</Typography>
              <Typography variant="h2">{totalList.active_projects}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid className="dashboard-card-3 d-red-1" margin={1}>
              <Typography variant="p">Total Projects</Typography>
              <Typography variant="h2">{totalList.all_projects}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid className="dashboard-card-3 d-pink" margin={1}>
              <Typography variant="p">Completed Projects</Typography>
              <Typography variant="h2">
                {totalList.completed_projects}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Grid className="dashboard-card-3 d-red" margin={1}>
              <Typography variant="p">Hold Projects</Typography>
              <Typography variant="h2">{totalList.hold_projects}</Typography>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={4} lg={4}>
            <Grid className="dashboard-card-3 d-green-1" margin={1}>
              <Typography variant="p">Assiged Cluster</Typography>
              <Typography variant="h2">
                {totalList.totalAssignedCluster}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid className="dashboard-card-3 d-red-1" margin={1}>
              <Typography variant="p">Un Assiged Cluster</Typography>
              <Typography variant="h2">
                {totalList.totalUnAssignedCluster}
              </Typography>
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
      <Box sx={{ padding: "10px" }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Box sx={{ padding: "10px" }}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} lg={8}>
                  <Typography
                    className="top-selling"
                    sx={{ marginBottom: "10px" }}
                  >
                    Attendance
                  </Typography>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        sx={{
                          borderRadius: 1,
                          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
                          height: 400, // Increased height to accommodate text
                          padding: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column", // Ensure content is centered vertically
                          backgroundColor: "#fff",
                        }}
                      >
                        <AttendanceChart />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box
                        sx={{
                          borderRadius: 1,
                          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
                          height: 400, // Increased height to accommodate text
                          padding: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column", // Ensure content is centered vertically
                          backgroundColor: "#fff",
                        }}
                      >
                        <Attendance
                          fromDate={fromDate}
                          toDate={toDate}
                          type={type}
                          companyID={companyID}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Typography
                    className="top-selling"
                    sx={{ marginBottom: "10px" }}
                  >
                    Completed Payment Requests ({type})
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: 400, // Increased height to match other boxes
                      overflowY: "auto",
                      borderRadius: 1,
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
                      padding: 2,
                      backgroundColor: "#fff",
                    }}
                  >
                    {paymentRequestList.length > 0 ? (
                      <Table>
                        <TableBody>
                          {paymentRequestList.map((obj, index) => (
                            <TableRow className="no-border" key={obj.name}>
                              <TableCell className="dashboard-td">
                                <Typography className="td_t">
                                  {obj.project_name}
                                </Typography>
                              </TableCell>
                              <TableCell
                                className="dashboard-td text-center"
                                sx={{ fontWeight: 800, width: 100 }}
                              >
                                <Typography sx={{ color: "rgb(16, 185, 129)" }}>
                                  {obj.formatted_amount}
                                </Typography>
                              </TableCell>
                              <TableCell className="dashboard-td text-center">
                                <Typography className="ranking">
                                  #{index + 1}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>No records found</Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ padding: "10px" }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              style={{ fontSize: "20px", fontWeight: 600, color: "black" }}
            >
              Projects Payment
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={1.5}>
              <Grid item xs={12} md={4} lg={4}>
                <Grid className="dashboard-card_2 pink">
                  <Grid>
                    <img
                      src={revenueImg}
                      alt="Ramcides"
                      width={`70px`}
                      height={`70px`}
                    />
                  </Grid>
                  <Grid style={{ margin: "0px 0px 0px 16px" }}>
                    <Typography className="dashborad-card-title">
                      Active Projects Value
                    </Typography>
                    <Typography className="dashborad-card-data">
                      {totalRevenue.total_amount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Grid className="dashboard-card_2 orange">
                  <Grid>
                    <img
                      src={profileImg}
                      alt="KG"
                      width={`70px`}
                      height={`70px`}
                    />
                  </Grid>
                  <Grid style={{ margin: "0px 0px 0px 16px" }}>
                    <Typography className="dashborad-card-title">
                      Total Amount Spent
                    </Typography>
                    <Typography className="dashborad-card-data">
                      {totalRevenue.total_amount_spent}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <Grid className="dashboard-card_2 light_green">
                  <Grid>
                    <img
                      src={infoImg}
                      alt="KG"
                      width={`70px`}
                      height={`70px`}
                    />
                  </Grid>

                  <Grid style={{ margin: "0px 0px 0px 16px" }}>
                    <Grid style={{ margin: "0px 0px 0px 16px" }}>
                      <Typography className="dashborad-card-title">
                        Amount Spend Today
                      </Typography>
                      <Typography className="dashborad-card-data">
                        {totalRevenue.amount_spent}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Grid
                  style={{
                    borderRadius: 5,
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                    height: 600,
                    padding: 15,
                  }}
                >
                  <ProjectPaymentChart
                    fromDate={fromDate}
                    toDate={toDate}
                    type={type}
                    companyID={companyID}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
            <Typography className="top-selling">
              Top Selling Products ({type})
            </Typography>
            <table>
              <tbody>
                {topSellingProduct.map((obj, index) => {
                  return (
                    <tr className="no-border">
                      <td className="dashboard-td" key={obj.name}>
                        <Typography className="td_t">{obj.name}</Typography>
                        <Typography className="td_d">{obj.p_type}</Typography>
                      </td>
                      <td
                        className="dashboard-td text-center"
                        style={{ fontWeight: 800 }}
                      >
                        <Typography style={{ color: "rgb(16, 185, 129)" }}>
                          {obj.total}
                        </Typography>
                        <Typography>sales</Typography>
                      </td>
                      <td className="dashboard-td  text-center">
                        <Typography className="ranking">
                          #{index + 1}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Grid> */}
        </Grid>
      </Box>

      {/* <Box sx={{ padding: "10px" }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={8} lg={8}>
            <Grid
              style={{
                borderRadius: 5,
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                height: 500,
                padding: 15,
              }}
            >
              <PaymentRequextChart
                fromDate={fromDate}
                toDate={toDate}
                type={type}
                companyID={companyID}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box> */}
    </Layout>

    // ! OLD
    // <Layout>
    //   {/* zIndex Changed*/}
    //   <Box
    //     className="top_sticky"
    //     sx={{ padding: "10px", zIndex: "99", marginTop: "10px" }}
    //     xs={6}
    //   >
    //     <Grid container spacing={1.5}>
    //       <Grid
    //         item
    //         xs={6}
    //         md={6}
    //         lg={6}
    //         className="text-center"
    //         display={"flex"}
    //         alignItem={"center"}
    //         justifyContent={"center"}
    //       >
    //         <Typography fontSize={"1.8rem"} fontWeight={700} color={"#0c53a0"}>
    //           {fromDate === toDate
    //             ? moment(fromDate).format("DD/MM/YYYY")
    //             : moment(fromDate).format("DD/MM/YYYY") +
    //               " to " +
    //               moment(toDate).format("DD/MM/YYYY")}
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={6} md={6} lg={6}>
    //         <Select
    //           className="button_elect"
    //           labelId="demo-simple-select-label"
    //           id="demo-simple-select"
    //           value={companyID}
    //           label="State"
    //           onChange={(e) => {
    //             setCompanyID(e.target.value);
    //             setCompany(e.target.value);
    //           }}
    //         >
    //           <MenuItem value={0} key={0}>
    //             All Companies
    //           </MenuItem>
    //           {companyList.map((row) => (
    //             <MenuItem value={row.id} key={row.id}>
    //               {row.company_name}
    //             </MenuItem>
    //           ))}
    //         </Select>
    //         <Select
    //           className="button_elect"
    //           defaultValue={"Today"}
    //           onChange={handleChange}
    //         >
    //           <MenuItem value={"Today"}>Today</MenuItem>
    //           <MenuItem value={"This Week"}>This Week</MenuItem>
    //           <MenuItem value={"Last Week"}>Last Week</MenuItem>
    //           <MenuItem value={"Month"}>Current Month</MenuItem>
    //           <MenuItem value={"Last Month"}>Last Month</MenuItem>
    //           <MenuItem value={"This Year"}>This Year</MenuItem>
    //         </Select>
    //       </Grid>
    //     </Grid>
    //   </Box>
    //   <Box sx={{ padding: "10px" }}>
    //     <Grid container spacing={0.5} marginTop={"35px"}>
    //       <Grid item xs={12} md={4} lg={6}>
    //         <Grid className="dashboard-card-3 d-green" margin={1}>
    //           <Typography variant="p">Active Employees</Typography>
    //           <Typography variant="h2">{totalList.active_users}</Typography>
    //         </Grid>
    //       </Grid>

    //       <Grid item xs={12} md={4} lg={6}>
    //         <Grid className="dashboard-card-3 d-green-1" margin={1}>
    //           <Typography variant="p">Active Projects</Typography>
    //           <Typography variant="h2">{totalList.active_projects}</Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid item xs={12} md={4} lg={4}>
    //         <Grid className="dashboard-card-3 d-red-1" margin={1}>
    //           <Typography variant="p">Total Projects</Typography>
    //           <Typography variant="h2">{totalList.all_projects}</Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid item xs={12} md={4} lg={4}>
    //         <Grid className="dashboard-card-3 d-pink" margin={1}>
    //           <Typography variant="p">Completed Projects</Typography>
    //           <Typography variant="h2">
    //             {totalList.completed_projects}
    //           </Typography>
    //         </Grid>
    //       </Grid>

    //       <Grid item xs={12} md={4} lg={4}>
    //         <Grid className="dashboard-card-3 d-red" margin={1}>
    //           <Typography variant="p">Hold Projects</Typography>
    //           <Typography variant="h2">{totalList.hold_projects}</Typography>
    //         </Grid>
    //       </Grid>
    //       {/* <Grid item xs={12} md={4} lg={4}>
    //         <Grid className="dashboard-card-3 d-green-1" margin={1}>
    //           <Typography variant="p">Assiged Cluster</Typography>
    //           <Typography variant="h2">
    //             {totalList.totalAssignedCluster}
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //       <Grid item xs={12} md={4} lg={4}>
    //         <Grid className="dashboard-card-3 d-red-1" margin={1}>
    //           <Typography variant="p">Un Assiged Cluster</Typography>
    //           <Typography variant="h2">
    //             {totalList.totalUnAssignedCluster}
    //           </Typography>
    //         </Grid>
    //       </Grid> */}
    //     </Grid>
    //   </Box>
    //   <Box sx={{ padding: "10px" }}>
    //     <Grid container spacing={1.5}>
    //       <Grid item xs={12}>
    //         <Box sx={{ padding: "10px" }}>
    //           <Grid container spacing={1.5}>
    //             <Grid item xs={12} lg={8}>
    //               <Typography
    //                 className="top-selling"
    //                 sx={{ marginBottom: "10px" }}
    //               >
    //                 Attendance
    //               </Typography>
    //               <Grid container spacing={1.5}>
    //                 <Grid item xs={12} md={6} lg={6}>
    //                   <Box
    //                     sx={{
    //                       borderRadius: 1,
    //                       boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
    //                       height: 400, // Increased height to accommodate text
    //                       padding: 2,
    //                       display: "flex",
    //                       justifyContent: "center",
    //                       alignItems: "center",
    //                       flexDirection: "column", // Ensure content is centered vertically
    //                       backgroundColor: "#fff",
    //                     }}
    //                   >
    //                     <AttendanceChart />
    //                   </Box>
    //                 </Grid>
    //                 <Grid item xs={12} md={6} lg={6}>
    //                   <Box
    //                     sx={{
    //                       borderRadius: 1,
    //                       boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
    //                       height: 400, // Increased height to accommodate text
    //                       padding: 2,
    //                       display: "flex",
    //                       justifyContent: "center",
    //                       alignItems: "center",
    //                       flexDirection: "column", // Ensure content is centered vertically
    //                       backgroundColor: "#fff",
    //                     }}
    //                   >
    //                     <Attendance
    //                       fromDate={fromDate}
    //                       toDate={toDate}
    //                       type={type}
    //                       companyID={companyID}
    //                     />
    //                   </Box>
    //                 </Grid>
    //               </Grid>
    //             </Grid>
    //             <Grid item xs={12} lg={4}>
    //               <Typography
    //                 className="top-selling"
    //                 sx={{ marginBottom: "10px" }}
    //               >
    //                 Completed Payment Requests ({type})
    //               </Typography>
    //               <Box
    //                 sx={{
    //                   maxHeight: 400, // Increased height to match other boxes
    //                   overflowY: "auto",
    //                   borderRadius: 1,
    //                   boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
    //                   padding: 2,
    //                   backgroundColor: "#fff",
    //                 }}
    //               >
    //                 {paymentRequestList.length > 0 ? (
    //                   <Table>
    //                     <TableBody>
    //                       {paymentRequestList.map((obj, index) => (
    //                         <TableRow className="no-border" key={obj.name}>
    //                           <TableCell className="dashboard-td">
    //                             <Typography className="td_t">
    //                               {obj.project_name}
    //                             </Typography>
    //                           </TableCell>
    //                           <TableCell
    //                             className="dashboard-td text-center"
    //                             sx={{ fontWeight: 800, width: 100 }}
    //                           >
    //                             <Typography sx={{ color: "rgb(16, 185, 129)" }}>
    //                               {obj.formatted_amount}
    //                             </Typography>
    //                           </TableCell>
    //                           <TableCell className="dashboard-td text-center">
    //                             <Typography className="ranking">
    //                               #{index + 1}
    //                             </Typography>
    //                           </TableCell>
    //                         </TableRow>
    //                       ))}
    //                     </TableBody>
    //                   </Table>
    //                 ) : (
    //                   <Typography>No records found</Typography>
    //                 )}
    //               </Box>
    //             </Grid>
    //           </Grid>
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </Box>

    //   <Box sx={{ padding: "10px" }}>
    //     <Grid container spacing={1.5}>
    //       <Grid item xs={12} md={12} lg={12}>
    //         <Typography
    //           style={{ fontSize: "20px", fontWeight: 600, color: "black" }}
    //         >
    //           Projects Payment
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={12} md={12} lg={12}>
    //         <Grid container spacing={1.5}>
    //           <Grid item xs={12} md={4} lg={4}>
    //             <Grid className="dashboard-card_2 pink">
    //               <Grid>
    //                 <img
    //                   src={revenueImg}
    //                   alt="Ramcides"
    //                   width={`70px`}
    //                   height={`70px`}
    //                 />
    //               </Grid>
    //               <Grid style={{ margin: "0px 0px 0px 16px" }}>
    //                 <Typography className="dashborad-card-title">
    //                   Active Projects Value
    //                 </Typography>
    //                 <Typography className="dashborad-card-data">
    //                   {totalRevenue.total_amount}
    //                 </Typography>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //           <Grid item xs={12} md={4} lg={4}>
    //             <Grid className="dashboard-card_2 orange">
    //               <Grid>
    //                 <img
    //                   src={profileImg}
    //                   alt="KG"
    //                   width={`70px`}
    //                   height={`70px`}
    //                 />
    //               </Grid>
    //               <Grid style={{ margin: "0px 0px 0px 16px" }}>
    //                 <Typography className="dashborad-card-title">
    //                   Total Amount Spent
    //                 </Typography>
    //                 <Typography className="dashborad-card-data">
    //                   {totalRevenue.total_amount_spent}
    //                 </Typography>
    //               </Grid>
    //             </Grid>
    //           </Grid>

    //           <Grid item xs={12} md={4} lg={4}>
    //             <Grid className="dashboard-card_2 light_green">
    //               <Grid>
    //                 <img
    //                   src={infoImg}
    //                   alt="KG"
    //                   width={`70px`}
    //                   height={`70px`}
    //                 />
    //               </Grid>

    //               <Grid style={{ margin: "0px 0px 0px 16px" }}>
    //                 <Grid style={{ margin: "0px 0px 0px 16px" }}>
    //                   <Typography className="dashborad-card-title">
    //                     Amount Spend Today
    //                   </Typography>
    //                   <Typography className="dashborad-card-data">
    //                     {totalRevenue.amount_spent}
    //                   </Typography>
    //                 </Grid>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //           <Grid item xs={12} md={12} lg={12}>
    //             <Grid
    //               style={{
    //                 borderRadius: 5,
    //                 boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
    //                 height: 600,
    //                 padding: 15,
    //               }}
    //             >
    //               <ProjectPaymentChart
    //                 fromDate={fromDate}
    //                 toDate={toDate}
    //                 type={type}
    //                 companyID={companyID}
    //               />
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       {/* <Grid item xs={12} md={6} lg={6}>
    //         <Typography className="top-selling">
    //           Top Selling Products ({type})
    //         </Typography>
    //         <table>
    //           <tbody>
    //             {topSellingProduct.map((obj, index) => {
    //               return (
    //                 <tr className="no-border">
    //                   <td className="dashboard-td" key={obj.name}>
    //                     <Typography className="td_t">{obj.name}</Typography>
    //                     <Typography className="td_d">{obj.p_type}</Typography>
    //                   </td>
    //                   <td
    //                     className="dashboard-td text-center"
    //                     style={{ fontWeight: 800 }}
    //                   >
    //                     <Typography style={{ color: "rgb(16, 185, 129)" }}>
    //                       {obj.total}
    //                     </Typography>
    //                     <Typography>sales</Typography>
    //                   </td>
    //                   <td className="dashboard-td  text-center">
    //                     <Typography className="ranking">
    //                       #{index + 1}
    //                     </Typography>
    //                   </td>
    //                 </tr>
    //               );
    //             })}
    //           </tbody>
    //         </table>
    //       </Grid> */}
    //     </Grid>
    //   </Box>

    //   {/* <Box sx={{ padding: "10px" }}>
    //     <Grid container spacing={1.5}>
    //       <Grid item xs={12} md={8} lg={8}>
    //         <Grid
    //           style={{
    //             borderRadius: 5,
    //             boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
    //             height: 500,
    //             padding: 15,
    //           }}
    //         >
    //           <PaymentRequextChart
    //             fromDate={fromDate}
    //             toDate={toDate}
    //             type={type}
    //             companyID={companyID}
    //           />
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </Box> */}
    // </Layout>
  );
}
export default Dashboard;
