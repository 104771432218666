import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import { borderRadius, display } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Bar,
  Pie,
  Line,
  // HorizontalBar,
  Radar,
  Doughnut,
  PolarArea,
  Bubble,
  Scatter,
  Area,
} from "react-chartjs-2";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import PieChartTwoToneIcon from "@mui/icons-material/PieChartTwoTone";
import RadarTwoToneIcon from "@mui/icons-material/RadarTwoTone";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import DonutLargeTwoToneIcon from "@mui/icons-material/DonutLargeTwoTone";
import DonutSmallTwoToneIcon from "@mui/icons-material/DonutSmallTwoTone";
import BubbleChartTwoToneIcon from "@mui/icons-material/BubbleChartTwoTone";
import DownloadForOfflineTwoToneIcon from "@mui/icons-material/DownloadForOfflineTwoTone";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import html2canvas from "html2canvas";

Chart.register(CategoryScale);
const Charts2 = ({ chartData }) => {
  const [userData, setUserData] = useState({
    labels: [],
    datasets: [],
  });

  // useEffect(() => {
  //   const newUserData = UserData.sort((a, b) => { return a.year - b.year })
  //   console.log(
  //     "newUserData", newUserData,

  //   );

  //   setUserData({
  //     labels: newUserData.map((data) => data.year).sort(),
  //     datasets: [{
  //       label: "users gained",
  //       data: newUserData.map((data) => data.userGain),

  //     }]
  //   })
  //   return () => {
  //   }
  // }, [])

  const [bar, setBar] = useState(false);
  const [pie, setPie] = useState(false);
  const [line, setLine] = useState(false);
  const [radar, setRadar] = useState(false);
  const [doughnut, setDoughnut] = useState(false);
  const [polarArea, setPolarArea] = useState(false);
  const [bubble, setBubble] = useState(false);

  const getTime = (timeStr) => {
    const [hoursStr, minutesStr, secondsStr] = timeStr.split(":");
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const seconds = parseInt(secondsStr, 10);
    const totalHours = hours + minutes / 60 + seconds / 3600;
    return totalHours;
  };

  const changeTimeFormat = (time) => {
    const [hours, minutes, seconds] = time.split(":");

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    const time12hr = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return time12hr;
  };
  const employeeNames = chartData.map((data) => data.employee_name);
  const employeeStartTime = chartData
    .map((data) => {
      if (data.start_time) {
        return data.start_time;
      } else {
        return "";
      }
    })
    .map((element) => element.split(" "))
    .map((innerArray) => innerArray[1])
    .join(", ")
    .split(", ")
    .map((data) => changeTimeFormat(data));

  const employeeEndTime = chartData
    .map((data) => {
      if (data.end_time) {
        return data.end_time;
      } else {
        return "";
      }
    })
    .map((element) => element.split(" "))
    .map((innerArray) => innerArray[1])
    .join(", ")
    .split(", ")
    .map((data) => changeTimeFormat(data));

  const employeeShift = chartData.map((data) => {
    if (data.Shift == 1) {
      return "Day Shift";
    } else if (data.Shift == 2) {
      return "Night Shift";
    } else {
      return "-";
    }
  });
  const employeeAttendanceStatus = chartData.map((data) => {
    if (data.attandence_status == 1) {
      return "Full Day";
    } else if (data.attandence_status == 2) {
      return "Half Day";
    } else if (data.attandence_status == 3) {
      return "Leave";
    } else {
      return "-";
    }
  });

  const changeDateFormat = (date) => {
    const parts = date.split("-");
    const changedFormat = `${parts[2]}-${parts[1]}-${parts[0]}`;

    return changedFormat;
  };

  // to make chart info:
  function generateRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const a = Math.random().toFixed(2); // Random alpha value between 0 and 1
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
  const backgroundColor = Array.from({ length: 101 }, () =>
    generateRandomColor()
  );
  //color array
  // [
  //   'rgba(54,162,235,0.6)',
  //   'rgba(255,99,132,0.6)',
  //   'rgba(255,206,86,0.6)',
  //   'rgba(75,192,192,0.6)',
  //   'rgba(153,102,255,0.6)',
  //   'rgba(255,159,64,0.6)',
  // ]

  const reportData = {
    // labels: chartData.map((data) => data.date).sort(),
    labels: chartData.map((data) => changeDateFormat(data.date)),
    datasets: [
      {
        label: "working hours",
        data: chartData.map((data) => {
          return getTime(data.working_hours);
        }),
        backgroundColor: backgroundColor,
      },
    ],
  };

  const gaugeBackground = {
    id: "gaugeBackground",
    beforeDraw(chart, args, pluginOptions) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
      } = chart;
      ctx.save();
      ctx.fillStyle = "#fff";
      ctx.fillRect(left, top, width, height);
      ctx.restore(); // Restore the context to its initial state after drawing the background
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    height: "800",
    width: "800",

    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "#331D2C",
        bodyColor: "#FBEEAC",
        bodyFontSize: "60",
        borderColor: "#35A29F",
        borderWidth: "2",
        padding: "15",
        titleColor: "#FBEEAC",
        // yAlign: 'top',

        callbacks: {
          // beforeTitle: function (context) {
          //   return ''
          // },
          title: function (context) {
            return `\n${context[0].label} \n> ${
              employeeNames[context[0].dataIndex]
            }`;
          },
          afterTitle: function (context) {
            return `\nStart time: ${
              employeeStartTime[context[0].dataIndex]
            }\nEnd time:${employeeEndTime[context[0].dataIndex]}`;
          },
          beforeBody: function (context) {
            return `~~~~~~~~~~~~~`;
          },
          // beforeLabel: function (context) {
          //   return 'before label'
          // },
          afterLabel: function (context) {
            // console.log(context)
            return `\nShift: ${employeeShift[context.dataIndex]}\nAttendance:${
              employeeAttendanceStatus[context.dataIndex]
            }`;
          },
          // afterBody: function (context) {
          //   return 'after body'
          // },
          // beforeFooter: function (context) {
          //   return 'before footer'
          // },
          // footerItem: function (context) {
          //   return "footer item"
          // },
          // afterFooter: function (context) {
          //   return 'after footer'
          // }
        },
      },

      legend: {
        position: "top",
      },
      // title: {
      //   display: 'true'
      // }
    },

    scales: {
      x: {
        title: {
          display: true,
          text: "Dates",
        },
        labels: employeeNames,
      },
      y: {
        title: {
          display: true,
          text: "Working hours",
        },
      },
    },
  };

  const chartContainerStyle = {
    //width: 'full', // Set your desired width
    height: "900px", // Set your desired height
    // marginTop: "4rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
  };

  const buttonStyle = {
    margin: "5px 3px",
    padding: "0.5rem",
    borderRadius: "0.3em",
  };

  const handleClick = (name) => {
    switch (name) {
      case "bar": {
        setPie(false);
        setLine(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setBar(true);
        break;
      }
      case "pie": {
        setBar(false);
        setLine(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setPie(true);
        break;
      }
      case "line": {
        setBar(false);
        setPie(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setLine(true);
        break;
      }
      case "radar": {
        setBar(false);
        setPie(false);
        setLine(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(false);
        setRadar(true);
        break;
      }
      case "doughnut": {
        setBar(false);
        setPie(false);
        setLine(false);
        setRadar(false);
        setPolarArea(false);
        setBubble(false);
        setDoughnut(true);
        break;
      }
      case "pool": {
        setBar(false);
        setPie(false);
        setLine(false);
        setRadar(false);
        setDoughnut(false);
        setBubble(false);
        setPolarArea(true);
        break;
      }
      case "bubble": {
        setBar(false);
        setPie(false);
        setLine(false);
        setRadar(false);
        setDoughnut(false);
        setPolarArea(false);
        setBubble(true);
        break;
      }

      default:
        break;
    }
  };

  let imageRef = useRef(null);
  const downloadChartImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.jpg";
    link.href = imageRef.current.toBase64Image();
    link.click();
  }, []);

  const chartRef = useRef(null);
  const barChartRef = useRef(null);
  const pieChartRef = useRef(null);
  const lineChartRef = useRef(null);
  const radarChartRef = useRef(null);
  const poolAreaChartRef = useRef(null);
  const doughnutChartRef = useRef(null);

  const [chartReady, setChartReady] = useState(false);

  const downloadChartAsImg = (type) => {
    // Get the chart container element
    const chartContainer = chartRef.current;

    // Create a new canvas element
    const canvas = document.createElement("canvas");
    canvas.width = chartContainer.offsetWidth;
    canvas.height = chartContainer.offsetHeight;

    const dateTime = getCurrentDateTime();

    // Draw the chart on the canvas
    html2canvas(chartContainer).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = `${type}-chart--image - ${dateTime}.jpg`;
      link.click();
    });
  };

  function getCurrentDateTime() {
    // Create a new Date object
    const currentDate = new Date();

    // Get the current date and time components
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    // Formatting the date as a string
    const formattedDate = `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${year}`;

    // Formatting the time as a string in AM/PM format
    let ampm = "AM";
    let formattedHours = hours;
    if (hours >= 12) {
      ampm = "PM";
      formattedHours = hours === 12 ? 12 : hours - 12;
    }
    formattedHours = formattedHours.toString().padStart(2, "0");

    const formattedTime = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;

    // Combining date and time
    const currentDateTime = `${formattedDate} -- ${formattedTime}`;

    return currentDateTime;
  }

  return (
    <>
      <Grid item xs={12} lg={12} md={12} textAlign="center">
        <Typography variant="h4" component="h4" style={{ marginTop: "2rem" }}>
          View options
        </Typography>

        <Grid
          item
          xs={12}
          lg={12}
          md={12}
          textAlign="center"
          className="chart-button-grid"
        >
          <Button
            size="small"
            variant="contained"
            color="secondary"
            className={bar ? "btn-changed" : "btn-primary"}
            onClick={() => handleClick("bar")}
            style={buttonStyle}
            startIcon={<BarChartTwoToneIcon />}
          >
            Bar Chart
          </Button>

          <Button
            size="small"
            variant="contained"
            color="secondary"
            className={pie ? "btn-changed" : "btn-primary"}
            onClick={() => handleClick("pie")}
            style={buttonStyle}
            startIcon={<PieChartTwoToneIcon />}
          >
            Pie Chart
          </Button>

          <Button
            size="small"
            variant="contained"
            color="secondary"
            className={line ? "btn-changed" : "btn-primary"}
            onClick={() => handleClick("line")}
            style={buttonStyle}
            startIcon={<TimelineTwoToneIcon />}
          >
            Line Chart
          </Button>

          <Button
            size="small"
            color="secondary"
            variant="contained"
            className={radar ? "btn-changed" : "btn-primary"}
            onClick={() => handleClick("radar")}
            style={buttonStyle}
            startIcon={<RadarTwoToneIcon />}
          >
            Radar Chart
          </Button>

          <Button
            size="small"
            color="secondary"
            variant="contained"
            className={doughnut ? "btn-changed" : "btn-primary"}
            onClick={() => handleClick("doughnut")}
            style={buttonStyle}
            startIcon={<DonutLargeTwoToneIcon />}
          >
            Doughnut Chart
          </Button>

          <Button
            size="small"
            color="secondary"
            variant="contained"
            className={polarArea ? "btn-changed" : "btn-primary"}
            onClick={() => handleClick("pool")}
            style={buttonStyle}
            startIcon={<DonutSmallTwoToneIcon />}
          >
            PoolArea Chart
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        textAlign="center"
        style={{ marginTop: "2rem" }}
      >
        {bar && (
          <>
            <h1>Bar Chart Result</h1>
            <Grid style={chartContainerStyle} id="chart" ref={chartRef}>
              <Bar
                data={reportData}
                ref={barChartRef}
                options={options}
                plugins={[gaugeBackground]}
              />
            </Grid>
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => downloadChartAsImg("Bar")}
              style={{ margin: "3rem 0 0 1rem" }}
            >
              Download
              <DownloadForOfflineTwoToneIcon />
            </Button>
          </>
        )}
        {pie && (
          <>
            <h1>Pie Chart Result</h1>
            <Grid style={chartContainerStyle} id="chart" ref={chartRef}>
              {(options["scales"]["x"]["title"]["display"] = false)}
              {(options["scales"]["y"]["title"]["display"] = false)}

              <Pie
                data={reportData}
                ref={pieChartRef}
                options={options}
                plugins={[gaugeBackground]}
              />
            </Grid>
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => downloadChartAsImg("Pie")}
              style={{ margin: "3rem 0 0 1rem" }}
            >
              Download
              <DownloadForOfflineTwoToneIcon />
            </Button>
          </>
        )}
        {line && (
          <>
            <h1>Line Chart Result</h1>
            <Grid style={chartContainerStyle} ref={chartRef}>
              <Line
                data={reportData}
                options={options}
                ref={lineChartRef}
                plugins={[gaugeBackground]}
              />
            </Grid>
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => downloadChartAsImg("Line")}
              style={{ margin: "3rem 0 0 1rem" }}
            >
              Download
              <DownloadForOfflineTwoToneIcon />
            </Button>
          </>
        )}
        {radar && (
          <>
            <h1>Radar Chart Result</h1>
            <Grid style={chartContainerStyle} ref={chartRef}>
              {(options["scales"]["x"]["title"]["display"] = false)}
              {(options["scales"]["y"]["title"]["display"] = false)}

              <Radar
                data={reportData}
                options={options}
                ref={radarChartRef}
                plugins={[gaugeBackground]}
              />
            </Grid>
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => downloadChartAsImg("Radar")}
              style={{ margin: "3rem 0 0 1rem" }}
            >
              Download
              <DownloadForOfflineTwoToneIcon />
            </Button>
          </>
        )}
        {doughnut && (
          <>
            <h1>Doughnut Chart Result</h1>
            <Grid style={chartContainerStyle} ref={chartRef}>
              {(options["scales"]["x"]["title"]["display"] = false)}
              {(options["scales"]["y"]["title"]["display"] = false)}
              <Doughnut
                data={reportData}
                options={options}
                ref={doughnutChartRef}
                plugins={[gaugeBackground]}
              />
            </Grid>
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => downloadChartAsImg("Doughnut")}
              style={{ margin: "3rem 0 0 1rem" }}
            >
              Download
              <DownloadForOfflineTwoToneIcon />
            </Button>
          </>
        )}
        {polarArea && (
          <>
            <h1>Pool Area Chart Result</h1>
            <Grid style={chartContainerStyle} ref={chartRef}>
              {(options["scales"]["x"]["title"]["display"] = false)}
              {(options["scales"]["y"]["title"]["display"] = false)}
              <PolarArea
                data={reportData}
                options={options}
                ref={poolAreaChartRef}
                plugins={[gaugeBackground]}
              />
            </Grid>
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => downloadChartAsImg("Pool-Area")}
              style={{ margin: "3rem 0 0 1rem" }}
            >
              Download
              <DownloadForOfflineTwoToneIcon />
            </Button>
          </>
        )}
      </Grid>
    </>
  );
};

export default Charts2;
