import React, { memo } from "react";

function UserAvatar(data) {
  //
  const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line: no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
  };

  const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
  };
  const [range, setRange] = React.useState(10);
  const [saturation, setSaturation] = React.useState(50);
  const [lightness, setLightness] = React.useState(50);

  const generateHSL = (name, saturationRange, lightnessRange) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, 0, 360);
    const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
    const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
    return [h, s, l];
  };

  const HSLtoString = (hsl) => {
    return [
      `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%, 20%)`,
      `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`,
    ];
  };

  const generateColorHsl = (id, saturationRange, lightnessRange) => {
    return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
  };

  const getInitials = (name) => {
    return Array.from(name)[0];
  };

  const setValue1 = (functionFor) => {
    return (e) => {
      const value = parseInt(e.target.value);
      functionFor(value);
    };
  };

  const getRange = (value, range) => {
    return [Math.max(0, value - range), Math.min(value + range, 100)];
  };

  const saturationRange = getRange(saturation, range);
  const lightnessRange = getRange(lightness, range);

  //
  const color = generateColorHsl(data.name, saturationRange, lightnessRange);
  const initials = getInitials(data.name);

  return (
    <div
      className={`avatarColor`}
      style={{ backgroundColor: color[0], color: color[1] }}
    >
      {initials}
    </div>
  );
}

export default memo(UserAvatar);
