import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import axios from "axios";
import Layout from "../../layout/Layout";
import { config, payslipAPI } from "../../config";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Grid,
  Button,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment/moment";
import { AlertMessage } from "../../components/AlertMessage";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import { MaterialReactTable } from "material-react-table";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useTheme } from "@emotion/react";

function PayslipReport() {
  const childRef = useRef();
  const navigate = useNavigate();

  const [fromDate, setFromDate] = React.useState(
    dayjs(new Date()).format("YYYY-MM")
  );

  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [payslipReportData, setPayslipReportData] = useState([]);
  const [totalNetPay, setTotalNetPay] = useState("");
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const theme = useTheme();

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const handleEditClick = (data) => {
    navigate(`/payslip/${data.employee_id}/${data.payslip_date}`);

    // setEditingAnnouncement(announcement);
    // setTabValue("2");
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "employee_name",
        header: "Employee Name",
        enableSorting: true,
        enableFiltering: true,
        size: 200,
        Cell: ({ cell, row }) =>
          `${row.original.employee_name} (${row.original.employee_no})`,
      },
      {
        accessorKey: "no_of_days_leave_taken",
        header: "Leave",
        enableSorting: true,
        enableFiltering: true,
        size: 50,
      },
      {
        accessorKey: "salary_advance",
        header: "Salary Advance",
        enableSorting: true,
        enableFiltering: true,
        size: 50,
      },
      {
        accessorKey: "personal_advance",
        header: "Personal Advance",
        enableSorting: true,
        enableFiltering: true,
        size: 70,
      },
      {
        accessorKey: "total_deductions",
        header: "Total Deductions",
        enableSorting: false,
        enableFiltering: true,
        size: 100,
      },
      {
        accessorKey: "basic_pay",
        header: "Basic Pay",
        enableSorting: true,
        enableFiltering: true,
        size: 100,
      },
      {
        accessorKey: "net_pay",
        header: "Net Pay",
        enableSorting: true,
        enableFiltering: true,
        size: 100,
      },

      {
        accessorKey: "ID",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <IconButton
            color="warning"
            onClick={() => handleEditClick(row.original)}
          >
            <VisibilityIcon />
          </IconButton>
        ),
        size: 50,
      },
    ],
    [navigate]
  );
  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, filters, sorting]);

  const fetchData = async (date) => {
    setLoading(true);
    const data = {
      intType: 3,
      txtPayslipFromDate: date ? date : fromDate,
      intLimit: pageSize,
      intOffset: pageIndex * pageSize,
      txtField: filters,
      txtOrderBy: sorting,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = payslipAPI;

    await axios(config)
      .then(function (response) {
        setLoading(false);

        if (response.data.result[0] && response.data.result[0].total_net_pay) {
          setTotalNetPay(response.data.result[0].total_net_pay);
        }

        setPayslipReportData(response.data.result);
        setTotalCount(response.data.totalCount || 0);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const downloadExcel = () => {
    console.log(fromDate);
    const data = JSON.stringify({
      intType: 4,
      txtPayslipFromDate: fromDate,
    });

    config.method = "POST";
    config.data = data;
    config.url = payslipAPI;

    axios(config)
      .then(function (response) {
        console.log(response.data["result"]);
        const newData = response.data["result"].map((row) => {
          delete row.total_net_pay;
          delete row.employee_id;
          return row;
        });

        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "students");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `KG-PaySlip-${moment(fromDate).format("MM-YYYY")}.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid
          textAlign="center"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
          <Typography className="page_title" textAlign="left">
            PaySlip Report
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography color="text.primary">payslip</Typography>
            <Typography color="text.primary">Report</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <div className="empty-space-40"></div>
      <Grid container className="form_design" spacing={2}>
        <Grid container xs={12} md={12} lg={12}>
          <Grid item xs={12} md={6} lg={4}></Grid>

          <Grid item xs={12} md={3} lg={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Select Month and Year"
                value={fromDate}
                views={["year", "month"]}
                inputFormat="MM/YYYY"
                maxDate={new Date()}
                // {...register("txtFromDate")}
                onChange={(newValue) => {
                  setFromDate(dayjs(newValue).format("YYYY-MM"));
                  fetchData(dayjs(newValue).format("YYYY-MM"));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    // helperText={errors.txtDob?.message}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3} lg={4}>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              className="align_button"
              textAlign="right"
            >
              <Button
                variant="primary"
                className={`btn-primary`}
                onClick={downloadExcel}
              >
                Export to Excel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="form_design" spacing={2}>
        <Grid item xs={12}>
          <MaterialReactTable
            columns={columns}
            data={payslipReportData}
            enableGlobalFilter={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            className="mat-react-tbl"
            state={{
              isLoading: loading,
              pagination: { pageIndex, pageSize },
              sorting,
              columnFilters: filters,
            }}
            manualPagination
            initialState={{
              columnPinning: lgUp ? { right: ["net_pay", "ID"] } : {},
            }}
            manualSorting
            manualFiltering
            rowCount={totalCount}
            onPaginationChange={(updater) => {
              const paginationState = updater({
                pageIndex,
                pageSize,
              });

              const { pageIndex: newPageIndex, pageSize: newPageSize } =
                paginationState;
              setPageIndex(newPageIndex);
              setPageSize(newPageSize);
            }}
            onSortingChange={setSorting}
            onColumnFiltersChange={setFilters}
            muiTableBodyCellProps={{
              sx: {
                padding: "3px 8px",
                border: "1px solid #ccc",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                padding: "8px",
                border: "1px solid #ccc",
                color: "#fff !important",
                fontWeight: "bold",
                "& svg": {
                  color: "#fff",
                },
                "& .MuiTableSortLabel-root": {
                  color: "#fff !important",
                  "&.Mui-active": {
                    color: "#fff !important",
                    "& .MuiTableSortLabel-icon": {
                      color: "#fff !important",
                    },
                  },
                },
                "& .MuiTableSortLabel-icon": {
                  color: "#fff !important",
                },
              },
            }}
            muiTableBodyRowProps={({ row }) => ({
              sx: {
                borderBottom: "1px solid #ccc",
                backgroundColor: row.index % 2 === 0 ? "#ffffff" : "#e3f2fd",
              },
            })}
            muiTableHeadCellFilterTextFieldProps={{
              sx: {
                "& input": {
                  color: "#fff !important", // Change text color
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#fff !important", // Change underline color
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#fff !important", // Hover underline color
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#fff !important", // Focused underline color
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default PayslipReport;
