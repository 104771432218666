import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, FormControl, Typography, Box } from "@mui/material";
import Layout from "../../../layout/Layout";
import {
  config,
  purchaseOrderAPI,
  purchaseOrderItemsAPI,
  projectMasterAPI,
  statusMasterAPI,
} from "../../../config";
import Pdf from "react-to-pdf";
import axios from "axios";
import NotFound from "../../../components/NotFount";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams, useNavigate } from "react-router-dom";
// import numberToWords from "number-to-words";
import moment from "moment";
import "moment/locale/en-in";
import { ToWords } from "to-words";
import { useReactToPrint } from "react-to-print";
import PoPdf from "./pdf/PoPdf";
import PurchaseOrderPDF from "./pdf/GeneratePdf";

import DOMPurify from "dompurify";

const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

const handleNumberToWOrdCurrency = (v) => {
  return toWords.convert(roundUpToInteger(v));
};

function roundUpToInteger(value) {
  const roundedValue = Math.ceil(value);
  const formattedValue = roundedValue.toFixed(2);
  return formattedValue;
}

function PurchaseOrderView() {
  const { poid } = useParams();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `P.O_${poid}_${moment().format("DD-MM-YYYY hh:mm a")}.pdf`,
    onAfterPrint: () => console.log("PDF generated successfully!"),
  });

  const [poDetails, setPODetails] = useState([]);
  const [paymentList, setPaymentList] = useState("");
  const [ClassificationData, setClassificationData] = useState("");
  let tempRef = useRef(false);

  // other all details
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intType: 2,
        intPOId: poid,
        intLimit: 1,
        intOffset: 0,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = purchaseOrderAPI;

      axios(config)
        .then(function (response) {
          console.log(
            "🚀💻 ~ file: PurchaseOrderView.jsx:75 ~ response:",
            response.data.result
          );

          setPODetails(response.data.result[1][0]);
          fetchProjectDetails(response.data.result[1][0].project_id);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);
  const get_purchase_classification = (statusId) => {
    const data = {
      intType: 11,
      intStatusId: statusId,
    };

    console.log("⛔ ➡️ file: PurchaseOrderView.jsx:109 ➡️ data:", data);

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = statusMasterAPI;

    axios(config)
      .then(function (response) {
        console.log(response.data.result[0].name);
        setClassificationData(response.data.result[0].name);
        // setPurchase(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [projectDetails, setProjectDetails] = useState([]);

  // Fetching project details
  const fetchProjectDetails = (projectID) => {
    if (projectID === 0) {
      return;
    }

    const data = {
      intType: 2,
      intProjectID: projectID,
      intLimit: 1,
    };

    console.log("⛔ ➡️ file: PurchaseOrderView.jsx:116 ➡️ data:", data);

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = projectMasterAPI;

    axios(config)
      .then((response) => {
        setProjectDetails(response.data.result[0]);
        console.log(
          "⛔ ➡️ file: PurchaseOrderView.jsx:129 ➡️ response.data:",
          response.data
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // purchase order items
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intPONo: poid,
        intType: 2,
        // intLimit: 1,
        // intOffset: 0,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = purchaseOrderItemsAPI;

      axios(config)
        .then(function (response) {
          // return;
          setPaymentList(response.data.result);
          console.log(
            "🚀💻 ~ file: PurchaseOrderView.jsx:120 ~ response.data.result:",
            response.data.result
          );
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  const ref = React.createRef();
  const setPOStatus = (status) => {
    const data = {
      intType: 2,
      intStatus: status,
      intPOId: poid,
    };
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = purchaseOrderAPI;

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
    return () => {
      tempRef.current = true;
    };
  };

  const twoDecimal = (x) => {
    // Convert to a float and format to two decimal places
    const num = Number.parseFloat(x).toFixed(2);

    // Split the integer and decimal parts
    const [integerPart, decimalPart] = num.split(".");

    // Format the integer part with a proper regex for Indian numbering system
    const formattedInteger = integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");

    // Return the formatted value with the decimal part
    return `${formattedInteger}.${decimalPart}`;
  };

  function roundUpToInteger(value) {
    const roundedValue = Math.ceil(value);
    const num = roundedValue.toFixed(2);
    // Split the integer and decimal parts
    const [integerPart, decimalPart] = num.split(".");

    // Format the integer part with a proper regex for Indian numbering system
    const formattedInteger = integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");

    // Return the formatted value with the decimal part
    return `${formattedInteger}.${decimalPart}`;
  }

  function calculateRoundingDifference(value) {
    const roundedValue = Math.ceil(value);
    const difference = roundedValue - value;

    return difference.toFixed(2);
  }

  const renderHTML = (htmlString) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  // const handlePdfDownload = () => {
  //   console.log(
  //     "🚀💻 ~ file: PurchaseOrderView.jsx:177 ~ handlePdfDownload ~ poDetails, paymentList:",
  //     poDetails,
  //     paymentList
  //   );
  //   generatePDF(poDetails, paymentList);
  // };

  const styles = {
    container: {
      display: "flex",
      // flexWrap: "wrap",
      gap: "20px",
      padding: "20px",
      justifyContent: "space-between", // Distribute space between items
    },
    section: {
      flex: "1 1 calc(100% - 20px)", // Full width on small screens
      padding: "15px",
      // border: "1px solid #ddd",
      borderRadius: "5px",
      // backgroundColor: "#f9f9f9",
      // minWidth: "300px", // Minimum width of each section
    },
    bold: {
      fontWeight: "bold",
      marginTop: "6px",
    },
    companyName: {
      fontSize: "0.9 rem",
      fontWeight: "bold",
      color: "#0047AB",
    },
  };

  // Fetching Logo based on Company Name
  // Define a mapping between company names and their respective logos
  const logoMap = {
    "KG BUILD TECH": require("./../../../assets/images/Logo/Kg_Logo.png"),
    "KG FURNTECH": require("./../../../assets/images/Logo/Kg_furn_logo.png"),
    "KG CONSTRUCTIONS": require("./../../../assets/images/Logo/kg_const_logo.png"),
    "KG TRADETECH": require("./../../../assets/images/Logo/kg_trade_logo.png"),
  };

  // Fetch the appropriate logo based on the company name
  const companyLogo = logoMap[poDetails.company_Name] || null; // Use null as a fallback if no match is found

  return (
    <Layout>
      <Grid className="login-text-background list_advance">
        <Typography variant="h5" textAlign="center">
          Purchase Order View
        </Typography>
      </Grid>
      <Grid container spacing={2} className={`bg-white`}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ height: "100%" }}
          ref={ref}
          className="container print-container"
        >
          <div className="print-content">
            <Grid className="element-to-po">
              <Grid className="page">
                <Typography
                  textAlign=""
                  className={``}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h1 style={{ color: "#0066cc" }}>
                    {poDetails.order_type == "1"
                      ? "Purchase Order"
                      : "Work Order"}
                  </h1>
                </Typography>
                <table className="tablepad">
                  <tr className="no-border">
                    <td colSpan={4}>
                      <tr>
                        <td className="no-border">
                          <Typography
                            textAlign="left"
                            className={`td text-bold`}
                            style={{ fontSize: "15px" }}
                          >
                            Purchase Order No: {poDetails.po_no}
                          </Typography>
                        </td>
                      </tr>

                      <hr />

                      <tr className="no-border">
                        <td className="no-border imgtd">
                          <img
                            src={companyLogo}
                            alt="KG"
                            width={`200px`}
                            height={`auto`}
                          />
                        </td>
                        <td width={`100%`} className="no-border">
                          <table>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  textAlign="right"
                                  // className="td text-bold"
                                  sx={{
                                    fontSize: "23px",
                                    fontWeight: 700,
                                    color: "#0066cc",
                                  }}
                                >
                                  {poDetails.company_Name}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  textAlign="right"
                                  // className={`td text-bold`}
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {poDetails
                                    ? poDetails.company_address
                                    : `Plot No 108, Perumal Koil Street, Alapakkam
                                Porur`}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  textAlign="right"
                                  // className={`td text-bold`}
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {poDetails
                                    ? `${poDetails.company_city} - ${poDetails.company_pincode}`
                                    : `Chennai - 600116`}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  textAlign="right"
                                  // className={`td text-bold`}
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {poDetails
                                    ? `${poDetails.company_state}, India`
                                    : `Tamil Nadu, India`}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              {/* <td className="no-border">
                                <Typography
                                  textAlign="right"
                                  className={`td text-bold`}
                                >
                                  {poDetails
                                    ? `${poDetails.company_primary_contact} / ${poDetails.company_secondary_contact}`
                                    : `044 2476 5544 / +91 95001 55544`}
                                </Typography>
                              </td> */}
                            </tr>
                            {/* <tr>
                              <td className="no-border">
                                <Typography
                                  textAlign="right"
                                  // className={`td text-bold`}
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {poDetails
                                    ? poDetails.company_emailId
                                    : `venkat@kgbuildtech.com`}
                                </Typography>
                              </td>
                            </tr> */}
                            <tr>
                              <td className="no-border">
                                <Typography
                                  textAlign="right"
                                  // className={`td text-bold`}
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {poDetails
                                    ? poDetails.company_website
                                    : `https://kgbuildtech.com`}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  textAlign="right"
                                  // className={`td text-bold`}
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {poDetails
                                    ? `GST NO: ${poDetails.gst_no}`
                                    : ``}
                                </Typography>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <hr></hr>
                      <div>
                        <div style={styles.container}>
                          <div
                            style={{
                              ...styles.section,
                              flex: "1 1 calc(33.333% - 20px)",
                            }}
                          >
                            <Typography
                              style={styles.bold}
                              sx={{
                                fontSize: "22px",
                              }}
                            >
                              Bill To :
                            </Typography>
                            <Typography
                              style={styles.companyName}
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {poDetails.vendor_name}
                            </Typography>
                            <Typography>{poDetails.vendor_mobile}</Typography>
                            {/* <Typography style={styles.bold}>
                              Place of Supply :
                            </Typography> */}
                            <Typography>{poDetails.vendor_street}</Typography>
                            <Typography>
                              {poDetails.vendor_pincode}{" "}
                              {poDetails.vendor_state}
                            </Typography>
                            <Typography>
                              {poDetails.vendor_city}, India
                            </Typography>
                            <Typography>
                              <span style={styles.bold}>GSTIN :</span>{" "}
                              {poDetails.vendor_gst}
                            </Typography>
                          </div>

                          <div
                            style={{
                              ...styles.section,
                              flex: "1 1 calc(33.333% - 20px)",
                            }}
                          >
                            <Typography
                              style={styles.bold}
                              sx={{
                                fontSize: "22px",
                              }}
                            >
                              Place of Supply :
                            </Typography>
                            <Typography
                              style={styles.companyName}
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {projectDetails.name}
                            </Typography>
                            <Typography style={styles.bold}>
                              Address :
                            </Typography>
                            <Typography>
                              {projectDetails.project_address}
                            </Typography>
                            <Typography>
                              {projectDetails.city} {projectDetails.state}
                            </Typography>
                            <Typography>
                              India, {projectDetails.pincode}
                            </Typography>
                          </div>

                          <div
                            style={{
                              ...styles.section,
                              flex: "1 1 calc(33.333% - 20px)",
                            }}
                          >
                            <Typography style={{ marginTop: "6px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Purchase Order No :
                              </span>{" "}
                              {poDetails.po_no}
                            </Typography>
                            <Typography style={{ marginTop: "6px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Mode/Terms of Payment :
                              </span>{" "}
                              {poDetails.terms_of_payment}
                            </Typography>
                            <Typography style={{ marginTop: "6px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Supplier Ref./Quotation No.:
                              </span>{" "}
                              {poDetails.quotation_no}
                            </Typography>
                            <Typography style={{ marginTop: "6px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Packing Charge :
                              </span>{" "}
                              {poDetails.packing_charge}
                            </Typography>
                            <Typography style={{ marginTop: "6px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Purchase Classification :
                              </span>{" "}
                              {get_purchase_classification(
                                poDetails.purchase_classfication
                              )}
                              {ClassificationData}
                            </Typography>
                            <Typography style={{ marginTop: "6px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Purchase Date :
                              </span>{" "}
                              {moment(poDetails.po_date).format("DD-MM-YYYY")}
                            </Typography>
                            <Typography style={{ marginTop: "6px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Site Engineer :
                              </span>{" "}
                              {poDetails.site_eng_name}
                            </Typography>
                            <Typography style={{ marginTop: "6px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Site Engineer Contact :
                              </span>{" "}
                              {poDetails.site_eng_contact}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table className="po-content-2">
                      <thead>
                        <th>
                          <Typography sx={{ fontWeight: "bold" }}>
                            S.No
                          </Typography>
                        </th>
                        <th>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Description of Goods{" "}
                          </Typography>
                        </th>
                        <th>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Quantity (nos.)
                          </Typography>
                        </th>
                        <th>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Amount (₹)
                          </Typography>
                        </th>
                        <th>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Total Amount (₹)
                          </Typography>
                        </th>
                        <th>
                          <Typography sx={{ fontWeight: "bold" }}>
                            GST{" "}
                          </Typography>
                        </th>
                        {poDetails.total_cgst == 0 &&
                        poDetails.total_sgst == 0 ? (
                          <th>
                            <Typography sx={{ fontWeight: "bold" }}>
                              IGST{" "}
                            </Typography>
                          </th>
                        ) : (
                          <>
                            <th>
                              <Typography sx={{ fontWeight: "bold" }}>
                                CGST{" "}
                              </Typography>
                            </th>
                            <th>
                              <Typography sx={{ fontWeight: "bold" }}>
                                SGST{" "}
                              </Typography>
                            </th>
                          </>
                        )}
                        <th>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Total Amount + GST (INR)
                          </Typography>
                        </th>
                      </thead>
                      <tbody className="pdf-tbl_body">
                        {paymentList.length > 0 ? (
                          paymentList.map((row, index) => (
                            <>
                              <tr className="table_padding">
                                <td className={`text-center`}>
                                  <Typography>{index + 1}</Typography>
                                </td>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {row.item_name}
                                  </Typography>
                                </td>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.quantity)} {row.unit}
                                  </Typography>
                                </td>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.amount)}
                                  </Typography>
                                </td>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.total_amount_without_gst)}
                                  </Typography>
                                </td>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {row.gst}%
                                  </Typography>
                                </td>

                                {row.igst != 0 ? (
                                  <>
                                    <td className="po_leble">
                                      <Typography textAlign="right">
                                        {twoDecimal(row.igst)}
                                      </Typography>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="po_leble">
                                      <Typography textAlign="right">
                                        {twoDecimal(row.cgst)}
                                      </Typography>
                                    </td>
                                    <td className="po_leble">
                                      <Typography textAlign="right">
                                        {twoDecimal(row.sgst)}
                                      </Typography>
                                    </td>
                                  </>
                                )}

                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.total_amount_with_gst)}
                                  </Typography>
                                </td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                      </tbody>
                      <tbody className="tbody_col">
                        <tr className="table_padding_40">
                          {poDetails.total_cgst != 0 &&
                          poDetails.total_sgst != 0 ? (
                            <>
                              <td className={`text-center`}>
                                <Typography></Typography>
                              </td>
                            </>
                          ) : (
                            <></>
                          )}
                          <td colSpan={7}>
                            <Typography fontWeight={`bold`}>Total</Typography>
                          </td>
                          <td>
                            <Typography>
                              {twoDecimal(poDetails.total_without_gst)}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="table_padding_40">
                          {poDetails.total_cgst != 0 &&
                          poDetails.total_sgst != 0 ? (
                            <>
                              <td className={`text-center`}>
                                <Typography></Typography>
                              </td>
                            </>
                          ) : (
                            <></>
                          )}
                          {poDetails.total_cgst == 0 &&
                          poDetails.total_sgst == 0 ? (
                            <>
                              <td colSpan={7}>
                                <Typography fontWeight={`bold`}>
                                  IGST{" "}
                                </Typography>
                              </td>
                              <td>
                                <Typography>
                                  {twoDecimal(poDetails.total_igst)}
                                </Typography>
                              </td>
                            </>
                          ) : (
                            <>
                              <td colSpan={7}>
                                <Typography fontWeight={`bold`}>
                                  CGST{" "}
                                </Typography>
                              </td>
                              <td>
                                <Typography>
                                  {twoDecimal(poDetails.total_cgst)}
                                </Typography>
                              </td>
                            </>
                          )}
                        </tr>

                        {poDetails.total_sgst != 0 && (
                          <>
                            <tr className="table_padding_40">
                              <td className={`text-center`}>
                                <Typography></Typography>
                              </td>
                              <td colSpan={7}>
                                <Typography fontWeight={`bold`}>
                                  SGST{" "}
                                </Typography>
                              </td>
                              <td>
                                <Typography>
                                  {twoDecimal(poDetails.total_sgst)}
                                </Typography>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td colSpan={2}>
                            <Typography fontWeight={`bold`}>
                              Transport GST
                            </Typography>
                          </td>

                          <td className="po_leble">
                            <Typography>{poDetails.transport_gst}% </Typography>
                          </td>
                          {poDetails.total_igst > 0 ? (
                            <td colSpan={3}>
                              <Typography fontWeight={`bold`}>
                                Transport Charge (INR)
                              </Typography>
                            </td>
                          ) : (
                            <td colSpan={4}>
                              <Typography fontWeight={`bold`}>
                                Transport Charge (INR)
                              </Typography>
                            </td>
                          )}

                          <td colSpan={2}>
                            <Typography>
                              {twoDecimal(poDetails.transport_charge)}{" "}
                            </Typography>
                          </td>
                        </tr>

                        <tr className="table_padding_40">
                          {poDetails.total_cgst != 0 &&
                          poDetails.total_sgst != 0 ? (
                            <>
                              <td className={`text-center`}>
                                <Typography></Typography>
                              </td>
                            </>
                          ) : (
                            <></>
                          )}
                          <td colSpan={7}>
                            <Typography fontWeight={`bold`}>
                              Total Amount With GST (INR)
                            </Typography>
                          </td>
                          <td>
                            <Typography>
                              {twoDecimal(poDetails.total_with_gst)}
                            </Typography>
                          </td>
                        </tr>

                        <tr className="table_padding_40">
                          {poDetails.total_cgst != 0 &&
                          poDetails.total_sgst != 0 ? (
                            <>
                              <td className={`text-center`}>
                                <Typography></Typography>
                              </td>
                            </>
                          ) : (
                            <></>
                          )}
                          <td colSpan={7}>
                            <Typography fontWeight={`bold`}>
                              Rounded Off{" "}
                            </Typography>
                          </td>
                          <td>
                            <Typography>
                              {calculateRoundingDifference(
                                poDetails.total_with_gst
                              )}
                            </Typography>
                          </td>
                        </tr>

                        <tr className="table_padding_40">
                          {poDetails.total_cgst != 0 &&
                          poDetails.total_sgst != 0 ? (
                            <>
                              <td className={`text-center`}>
                                <Typography></Typography>
                              </td>
                            </>
                          ) : (
                            <></>
                          )}
                          <td colSpan={7}>
                            <Typography fontWeight={`bold`}>
                              Total Payable Amount
                            </Typography>
                          </td>
                          <td>
                            <Typography fontWeight={`bold`}>
                              {roundUpToInteger(poDetails.total_with_gst)}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {/* Invoice terms and condition */}
                    <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                      <table>
                        <thead>
                          <th>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Purchase Order Terms & Condition(s){" "}
                            </Typography>
                          </th>
                        </thead>
                        <tbody>
                          {/* items form */}
                          <tr className="table_padding">
                            <td className="po_leble">
                              <Typography textAlign="center">
                                {poDetails.terms ? (
                                  <div
                                    dangerouslySetInnerHTML={renderHTML(
                                      poDetails?.terms
                                    )}
                                  />
                                ) : (
                                  "-"
                                )}
                              </Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>

                    {/* Amount in words 
                    <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                      <table>
                        <thead>
                          <b>TOTAL PURCHASE ORDER VALUE (IN WORDS)</b>
                        </thead>
                        <tr>
                          <h5
                            style={{
                              marginTop: "10px",
                              marginBottom: "1rem",
                              fontWeight: "normal",
                            }}
                          >
                            {poDetails &&
                              poDetails.total_with_gst > 0 &&
                              `${handleNumberToWOrdCurrency(
                                poDetails.total_with_gst
                              )}`}
                          </h5>
                        </tr>
                      </table>
                    </Grid>*/}
                    <Typography
                      sx={{
                        fontSize: "20px",
                        mt: 3,
                        fontWeight: 700,
                      }}
                    >
                      Prepared by &nbsp;&nbsp;&nbsp;
                      &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Approved by
                    </Typography>
                  </Grid>
                </table>
              </Grid>
            </Grid>
          </div>

          {/* Lower Buttons */}
          <div className="empty-space-20"></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {poDetails.status == 91 && (
              <div
                style={{
                  position: "absolute",
                  right: "100px",
                  paddingBottom: "2rem",
                  marginTop: "3rem",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#2F58CD",
                    marginRight: "10px",
                    color: "white",
                  }}
                  className="button"
                  onClick={() => setPOStatus(91)}
                >
                  Approve
                </Button>
                <Button
                  style={{ backgroundColor: "#2F58CD", color: "white" }}
                  className="button"
                  onClick={() => setPOStatus(92)}
                >
                  Deny
                </Button>
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={11}>
          <Box display={`flex`} justifyContent={`flex-end`} mr={2}>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handlePdfDownload}
            >
              Generate PDF
            </Button> */}

            <PurchaseOrderPDF
              poDetails={poDetails}
              projectDetails={projectDetails}
              paymentList={paymentList}
              companyLogo={companyLogo}
              classificationData={ClassificationData}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}
export default PurchaseOrderView;
