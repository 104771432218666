import React, { useEffect, useRef, useState } from "react";

//design imports from material
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";

//layout import
import Layout from "../../../layout/Layout";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

//form imports
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  config,
  _numberOnly,
  projectMasterAPI,
  statusMasterAPI,
  vendorAPI,
  purchaseOrderAPI,
  termsAndConditionsAPI,
  userData,
  manageCompaniesAPI,
  handleHrMasterAPI,
} from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import { getValue } from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// const names = ["KG Terms & Conditions", "Validity"];
let total = 0;
let cgst = 0;
let igst = 0;
let sgst = 0;
let totalAmount = 0;

function PurchaseOrder() {
  const childRef = useRef();
  const navigate = useNavigate();

  // add field
  const [serviceList, setServiceList] = useState([
    { txtItemName: "", txtUnits: "" },
  ]);
  const [totalWithoutGST, setTotalWithoutGST] = useState(0);
  const [totalCgst, settotalCgst] = useState(0);
  const [totalSgst, setTotalSgst] = useState(0);
  const [totalIgst, setTotalIgst] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);

  const [unitsFlag, setUnitsFlag] = useState(false);
  const [txtSiteEng, setTextSiteEng] = useState("");

  // const handleServiceRemove = (index) => {
  //   const list = [...serviceList];
  //   console.log("list", list)
  //   return;
  //   list.splice(index, 1);

  //   setServiceList(list);
  // };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { txtItemName: "", txtUnits: "" }]);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];

    // Get the item that you're going to remove
    const removedItem = list[index];

    // Calculate the values to subtract
    const subtractWithoutGST = removedItem.intLineItemTotalWithoutGST;
    const subtractCGST = removedItem.intCGST;
    const subtractSGST = removedItem.intSGST;
    const subtractIGST = removedItem.intIGST;
    const subtractTotalWithGST = removedItem.intLineItemTotalWithGST;

    // Update the state variables
    setTotalWithoutGST((prevTotal) => prevTotal - subtractWithoutGST);
    settotalCgst((prevTotalCgst) => prevTotalCgst - subtractCGST);
    setTotalSgst((prevTotalSgst) => prevTotalSgst - subtractSGST);
    setTotalIgst((prevTotalIgst) => prevTotalIgst - subtractIGST);
    setTotalAmount((prevTotalAmount) => prevTotalAmount - subtractTotalWithGST);

    // Remove the item from the list
    list.splice(index, 1);

    // Update the serviceList state
    setServiceList(list);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];

    list[index][name] = value;
    // list[index]["intLineItemTotalWithGST"] = 20;
    let tempGST = parseInt(list[index]["intGST"]) / 2;
    list[index]["intLineItemTotalWithoutGST"] =
      parseFloat(list[index]["intAmount"]) *
      parseFloat(list[index]["intQuantity"]);

    if (vendorDetails.state != "TamilNadu") {
      list[index]["intIGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"] +
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    } else {
      list[index]["intCGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
      list[index]["intSGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    }

    list[index]["intTGST"] = list[index]["intCGST"] + list[index]["intsGST"];

    // list[index]["intLineItemTotalWithGST"] =
    //   list[index]["intLineItemTotalWithoutGST"] +
    //   list[index]["intCGST"] +
    //   list[index]["intSGST"];

    if (vendorDetails.state != "TamilNadu") {
      list[index]["intLineItemTotalWithGST"] =
        list[index]["intLineItemTotalWithoutGST"] + list[index]["intIGST"];
    } else {
      list[index]["intLineItemTotalWithGST"] =
        list[index]["intLineItemTotalWithoutGST"] +
        list[index]["intCGST"] +
        list[index]["intSGST"];
    }

    total = 0;
    for (var i = 0; i < list.length; i++) {
      total = total + list[i]["intLineItemTotalWithoutGST"];
    }
    igst = 0;
    for (var i = 0; i < list.length; i++) {
      igst = igst + list[i]["intIGST"];
    }
    cgst = 0;
    for (var i = 0; i < list.length; i++) {
      cgst = cgst + list[i]["intCGST"];
    }
    sgst = 0;
    for (var i = 0; i < list.length; i++) {
      sgst = sgst + list[i]["intSGST"];
    }
    totalAmount = 0;
    for (var i = 0; i < list.length; i++) {
      totalAmount = totalAmount + list[i]["intLineItemTotalWithGST"];
    }

    setTotalWithoutGST(total);
    setServiceList(list);

    if (igst > 0) {
      settotalCgst(0);
      setTotalSgst(0);
      setTotalIgst(igst);
    } else if (cgst > 0 && sgst > 0) {
      setTotalIgst(0);
      settotalCgst(cgst);
      setTotalSgst(sgst);
    }
    // settotalCgst(cgst);
    // setTotalSgst(sgst);
    // setTotalIgst(igst);
    setTotalAmount(totalAmount + transportCharge);
  };

  const validationSchema = Yup.object().shape({
    txtOrderType: Yup.string().required("Order Type is required"),
    txtCompanyName: Yup.string().required("Company Name is required"),
    intVendorID: Yup.string().required("Vendor Name is required"),
    intProjectID: Yup.string().required("Project Name is required"),
    txtTermsOfPayments: Yup.string().required(
      "Mode/Terms of Payment is required"
    ),
    txtPurchaseClassify: Yup.string().required(
      "Purchase classification is required"
    ),
    txtSiteEng: Yup.string().required("Site Engineer is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);

  const { errors } = formState;

  const [PoDate, setPoDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  // Vendor List
  const [vendorList, setVendorList] = useState([]);

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 3,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = vendorAPI;

      axios(config)
        .then(function (response) {
          setVendorList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  let tempRefs = useRef(false);

  const [companyName, setCompanyName] = React.useState("");

  //Marital Details
  const [ProjectNameList, setProjectNameList] = useState([]);
  const [intProjectID, setProjectName] = useState(0);
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 6,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          setProjectNameList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  //Terms Of Payments
  const [termsofPayments, setTermsofPayments] = useState([]);
  const [txtTermsOfPayments, setTermsofPayment] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 59,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setTermsofPayments(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // dispatch
  const [dispatch, setDispatch] = useState([]);
  const [txtDispatchThrough, setDispatchThrough] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 65,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setDispatch(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // Purchase
  const [unitsList, setUnitsList] = useState([]);
  const [units, setUnits] = useState("");
  tempRefs.current = false;
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 88,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setUnitsList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // Purchase
  const [Purchase, setPurchase] = useState([]);
  const [txtPurchaseClassify, settxtPurchase] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 68,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setPurchase(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  tempRefs.current = false;

  const [siteEngineer, setSiteEngineer] = useState([]);

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 9,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          console.log(
            "🚀💻 ~ file: PurchaseOrder.jsx:408 ~ response:",
            response
          );

          setSiteEngineer(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const findPhoneNumber = (id) => {
    const contact = siteEngineer.find((person) => person.id === id);
    contact && setValue("txtSiteEngContact", contact.phone_no);
  };

  tempRefs.current = false;

  // Vendor Details
  const [vendorDetails, setVendorDetails] = useState({});
  const [intVendorID, setVendorID] = useState(0);

  const handleVendor = (e) => {
    setVendorID(e.target.value);
    setValue("intVendorID", e.target.value);

    const data = {
      intType: 7,
      intvendorAutoID: e.target.value,
      intLimit: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = vendorAPI;

    axios(config)
      .then(function (response) {
        setVendorDetails(response.data.result[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [projectDetails, setProjectDetails] = useState([]);
  const handleProject = (id) => {

    // setVendorID(e.target.value);
    // setValue("intVendorID", e.target.value);

    const data = {
      intType: 2,
      intProjectID: id,
      intLimit: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = projectMasterAPI;

    axios(config)
      .then(function (response) {
        console.log(response.data.result[0]);
        setProjectDetails(response.data.result[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // console.log({ full_Vendor: vendorDetails });
  // console.log({ selected_vendorId: intVendorID })

  tempRefs.current = false;

  // Purchase
  const [termsNames, setTermsNames] = useState([]);

  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 4,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = termsAndConditionsAPI;

      axios(config)
        .then(function (response) {
          setTermsNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  tempRefs.current = false;

  const [termsName, setTermsName] = useState("");
  const [terms, setTermsandConditions] = useState("");
  const [termsDescription, setTermsDescription] = useState(null);

  const handleChangeterm = (event) => {
    setTermsName(event.target.value);

    const data = {
      intType: 3,
      intID: event.target.value,
      intLimit: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = termsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        console.log("🚀💻 ~ file: PurchaseOrder.jsx:523 ~ response:", response);

        setTermsandConditions(response.data.result[0]);
        setTermsDescription(response.data.result[0].description);
        // window.tinymce.init({
        //   selector: "#textarea",
        //   width: "100%",
        //   menubar: false,
        //   plugins:
        //     "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
        //   toolbar:
        //     "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
        // });
        // setTimeout(function () {
        //   window.tinymce
        //     .get("textarea")
        //     .setContent(response.data.result[0].description);
        // }, 1150);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    // Initialize TinyMCE
    if (termsDescription !== null) {
      window.tinymce.init({
        selector: "#textarea",
        width: "100%",
        menubar: false,
        plugins:
          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
        toolbar:
          "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
      });

      setTimeout(function () {
        window.tinymce.get("textarea").setContent(termsDescription);
      }, 150);
      // window.tinymce.get("textarea").setContent(termsDescription);


      // Cleanup TinyMCE instance when the component unmounts
      return () => {
        window.tinymce.remove();
      };
    }
  }, [termsDescription]);

  // axios(config)
  // .then(function (response) {
  //   console.log(response.data.result[0].title);
  //   setTermsNames(response.data.result[0].title);
  //   window.tinymce.init({
  //     selector: "#textarea",
  //     width: "100%",
  //     menubar: false,
  //     plugins:
  //       "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
  //     toolbar:
  //       "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
  //   });
  //   setTimeout(function () {
  //     window.tinymce
  //       .get("textarea")
  //       .setContent(response.data.result[0].description);
  //   }, 1150);
  // })

  const [transportGST, setTransportGST] = useState(0);
  const [transportAmount, setTransportAmount] = useState(0);
  const [transportCharge, setTransportCharge] = useState(0);

  const handleTransportCharge = (price, v) => {
    if (v != "") {
      let GstValue = (price / 100) * parseInt(v);

      let total = isNaN(parseInt(GstValue) + parseInt(price))
        ? 0
        : parseInt(GstValue) + parseInt(price);

      setTransportCharge(total);
      setTotalAmount(totalAmount + total);
    } else {
      let GstValue = (price / 100) * parseInt(transportGST);

      let total = isNaN(parseInt(GstValue) + parseInt(price))
        ? 0
        : parseInt(GstValue) + parseInt(price);

      setTransportCharge(total);

      setTotalAmount(totalAmount + total);
    }
  };

  const [baseuploadagreement, setBaseUploadAgreement] = useState("");

  const uploadagreement = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseUploadAgreement(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [companyNames, setCompanyNames] = React.useState("");
  // getting all companies
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 5,
        // intParentId: 42,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = manageCompaniesAPI;

      axios(config)
        .then(function (response) {
          // console.log({ coData: response.data.result });
          setCompanyNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };
  function roundUpToInteger(value) {
    // Round up to the nearest integer
    const roundedValue = Math.ceil(value);

    // Append ".00" to the rounded value
    const formattedValue = roundedValue.toFixed(2);

    return formattedValue;
  }

  function calculateRoundingDifference(value) {
    // Round up to the nearest integer
    const roundedValue = Math.ceil(value);

    // Calculate the rounding difference
    const difference = roundedValue - value;

    return difference.toFixed(2);
  }

  // getting gst data
  // const [gstListData, setGstListData] = useState([])
  // useEffect(() => {
  //   // if (tempRefs.current === false) {
  //   const data = {
  //     intType: 2,
  //     intParentId: 77,
  //   };

  //   config.method = "POST";
  //   config.data = JSON.stringify(data);
  //   config.url = statusMasterAPI;

  //   axios(config)
  //     .then(function (response) {
  //       console.log({ gstData: response.data.result });
  //       // setGstListData(response.data.result)
  //       // setCompanyNames(response.data.result);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   return () => {
  //     tempRefs.current = true;
  //   };
  //   // }
  // }, []);

  const [PoTerm, setPOTerm] = React.useState(0);

  function onSubmit(data) {
    console.log("🚀💻 ~ file: PurchaseOrder.jsx:749 ~ onSubmit ~ data:", data);
    console.log(
      "🚀💻 ~ file: PurchaseOrder.jsx:751 ~ onSubmit ~ serviceList:",
      serviceList
    );
    if (serviceList.length <= 0) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "At least 1 PO Item is required",
          messageType: "error",
        })
      );
      return;
    } else {
      const invalidIndex = serviceList.findIndex((item) => {
        return (
          isNaN(item.intLineItemTotalWithGST) ||
          item.intLineItemTotalWithGST === ""
        );
      });

      if (invalidIndex !== -1) {
        // Handle the invalid case
        console.log(`Invalid item found at S.no ${invalidIndex + 1}`);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Fill all the details at S.no ${invalidIndex + 1}`,
            messageType: "error",
          })
        );
        return;
      }
    }

    if (PoTerm == 0) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Terms & Conditions is required",
          messageType: "error",
        })
      );
      return;
    }

    let poDesc = window.tinymce.get("textarea").getContent();

    if (!poDesc || poDesc.trim() === "") {
      throw new Error("Text area is empty. Please provide some content.");
    }

    let newPoDesc = poDesc.replaceAll("<p>", "");
    let finalPoDesc = newPoDesc.replaceAll("</p>", "");
    data.txtFile = baseuploadagreement;

    data.lineItems = serviceList;
    data.intTotalWithoutGST = totalWithoutGST;
    // data.intTotalIGST = totalIgst;
    // data.intTotalSGST = totalSgst;
    // data.intTotalCGST = totalCgst;

    if (totalIgst > 0) {
      data.intTotalSGST = 0;
      data.intTotalCGST = 0;
      data.intTotalIGST = twoDecimal(totalIgst);
    } else if (totalCgst > 0 && totalSgst > 0) {
      data.intTotalIGST = 0;
      data.intTotalSGST = twoDecimal(totalSgst);
      data.intTotalCGST = twoDecimal(totalCgst);
    }
    data.txtPOValidTo = PoDate;
    data.intTotalWithGST = twoDecimal(totalamount);
    data.intCreatedBy = userData.emp_id;
    data.intVendorID = intVendorID;
    data.intStatus = 98;
    data.intTransportGst = transportGST;
    data.intTransportAmount = transportAmount;
    data.intTransportCharge = transportCharge;
    data.txtTermsHeading = PoTerm;
    data.txtTerms = finalPoDesc;

    data.intType = 1;

    // console.log(data);
    // return;
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = purchaseOrderAPI;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          navigate(`/purchase-order/list`);
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [isCancelPopup, setIsCancelPopup] = useState(false);

  const handleCancelFile = async () => {
    setBaseUploadAgreement("");
    document.getElementById("agreementdocument").value = "";
    setIsCancelPopup(false);
  };

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Purchase Order
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Typography color="text.primary">purchase order</Typography>
                <Typography color="text.primary">create</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="secondary"
                className="button_first"
                href="/purchase-order/list"
              >
                List purchase order
              </LoadingButton>
              <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <div className="empty-space-40"></div>
        <Grid className="purchaseorder">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="popading" spacing={2}>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("txtOrderType")}
                    label="Order Type"
                    onChange={(e) => {
                      setValue("txtOrderType", e.target.value);
                    }}
                  >
                    <MenuItem value="1">Purchase Order</MenuItem>
                    <MenuItem value="2">Work Order</MenuItem>
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtOrderType?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Vendor Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={intVendorID}
                    label="Vendor Name"
                    onChange={(e) => {
                      handleVendor(e);
                    }}
                  >
                    {vendorList &&
                      vendorList.length > 0 &&
                      vendorList.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.intVendorID?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Company Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companyName}
                    label="Company Name"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setValue("txtCompanyName", e.target.value);
                    }}
                  >
                    {companyNames &&
                      companyNames.length > 0 &&
                      companyNames.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.company_name}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtCompanyName?.message}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Project
                  </InputLabel>
                  <Select
                    labelId="Select Project"
                    id="demo-simple-select"
                    value={intProjectID}
                    label="Select Project"
                    onChange={(e) => {
                      setProjectName(e.target.value);
                      setValue("intProjectID", e.target.value);
                      handleProject(e.target.value);
                    }}
                  >
                    {ProjectNameList.map((row, index) => (
                      <MenuItem value={row.id} key={index}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.intProjectID?.message}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="pomargin table_po">
              <Grid container spacing={2} className="po_leble">
                {/* Column 1: Vendor Details */}
                <Grid item xs={12} lg={3} md={3} className="form-lable">
                  {vendorDetails.hasOwnProperty("name") && (
                    <Grid className="info_details">
                      <Typography variant="h6" sx={{ fontSize: "20px" }}>
                        Bill to
                      </Typography>
                      <Typography className="companyna">
                        {vendorDetails.name}
                      </Typography>
                      <Typography>{vendorDetails.mobile_no}</Typography>
                      <Typography>
                        {vendorDetails.street_address}, {vendorDetails.city}
                      </Typography>
                      <Typography>
                        {vendorDetails.pincode} {vendorDetails.state}
                      </Typography>
                      <Typography>India</Typography>
                      <Typography>GSTIN {vendorDetails.gst}</Typography>
                    </Grid>
                  )}
                </Grid>

                {/* Column 2: Project Details */}
                <Grid item xs={12} lg={3} md={3} className="form-lable">
                  {projectDetails.hasOwnProperty("name") && (
                    <Grid className="info_details">
                      <Typography variant="h6" sx={{ fontSize: "20px" }}>
                        Place of Supply:
                      </Typography>
                      <Typography className="companyna">
                        {projectDetails.project_address}
                      </Typography>
                      <Typography>{projectDetails.city}</Typography>
                      <Typography>
                        {projectDetails.pincode} {projectDetails.state}
                      </Typography>
                      <Typography>India</Typography>
                    </Grid>
                  )}
                </Grid>

                {/* Column 3: Other Details */}
                <Grid item xs={12} lg={6} md={6} sx={{ border: "1px solid #000", mt: 2}}>
                  {/* Mode/Terms of Payment */}
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className="po_leble"
                  >
                    <Grid item xs={12} lg={5} md={5} className="lable_text">
                      <Typography>Mode/Terms of Payment:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={7} md={7}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={txtTermsOfPayments}
                          onChange={(e) => {
                            setTermsofPayment(e.target.value);
                            setValue("txtTermsOfPayments", e.target.value);
                          }}
                        >
                          {termsofPayments.map((row, index) => (
                            <MenuItem value={row.name} key={index}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography className="invalid-feedback">
                          {errors.txtTermsOfPayments?.message}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Supplier Ref./Quotation No. */}
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className="po_leble"
                  >
                    <Grid item xs={12} lg={5} md={5} className="lable_text">
                      <Typography>Supplier Ref./Quotation No.:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={7} md={7}>
                      <TextField
                        name="familyname"
                        type="text"
                        {...register("txtQuotationNo")}
                      />
                    </Grid>
                  </Grid>

                  {/* Purchase Classification */}
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className="po_leble"
                  >
                    <Grid item xs={12} lg={5} md={5} className="lable_text">
                      <Typography>Purchase Classification:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={7} md={7}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={txtPurchaseClassify}
                          onChange={(e) => {
                            settxtPurchase(e.target.value);
                            setValue("txtPurchaseClassify", e.target.value);
                          }}
                        >
                          {Purchase.map((row, index) => (
                            <MenuItem value={row.id} key={index}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography className="invalid-feedback">
                          {errors.txtPurchaseClassify?.message}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Site Engineer */}
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className="po_leble"
                  >
                    <Grid item xs={12} lg={5} md={5} className="lable_text">
                      <Typography>Site Engineer:</Typography>
                    </Grid>
                    <Grid item xs={12} lg={7} md={7}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={txtSiteEng}
                          onChange={(e) => {
                            setTextSiteEng(e.target.value);
                            findPhoneNumber(e.target.value);
                            setValue("txtSiteEng", e.target.value);
                          }}
                        >
                          {siteEngineer.length > 0 ? (
                            siteEngineer.map((row, index) => (
                              <MenuItem value={row.id} key={index}>
                                {row.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>No data found</MenuItem>
                          )}
                        </Select>
                        <Typography className="invalid-feedback">
                          {errors.txtSiteEng?.message}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Site Engineer Contact */}
                  {txtSiteEng && (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      className="po_leble"
                    >
                      <Grid item xs={12} lg={5} md={5} className="lable_text">
                        <Typography>Site Engineer Contact</Typography>
                      </Grid>
                      <Grid item xs={12} lg={7} md={7}>
                        <TextField
                          name="siteEngineerContact"
                          type="text"
                          {...register("txtSiteEngContact")}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item className="form-lable" xs={12} lg={12} md={12}>
                <table className={`table`}>
                  <thead>
                    <th>
                      <Typography>S. No.</Typography>
                    </th>
                    <th>
                      <Typography>Description</Typography>
                    </th>
                    <th>
                      <Typography>Units </Typography>
                    </th>
                    <th>
                      <Typography>Rate </Typography>
                    </th>
                    <th>
                      <Typography>Total Amount</Typography>
                    </th>
                    <th>
                      <Typography>GST </Typography>
                    </th>
                    {vendorDetails.state != "TamilNadu" ? (
                      <>
                        <th>
                          <Typography>IGST </Typography>
                        </th>
                      </>
                    ) : (
                      <>
                        <th>
                          <Typography>CGST </Typography>
                        </th>
                        <th>
                          <Typography>SGST </Typography>
                        </th>
                      </>
                    )}
                    <th>
                      <Typography>Total Amount + GST </Typography>
                    </th>
                    <th className="add_button">
                      <small style={{ fontSize: "16px", fontWeight: "100" }}>
                        Add New
                      </small>
                      <Button
                        variant="outlined"
                        onClick={handleServiceAdd}
                        startIcon={<AddIcon />}
                        color="success"
                      ></Button>
                    </th>
                  </thead>
                  <tbody>
                    {serviceList.map((singleService, index) => (
                      <tr className="table_padding">
                        <td className={`text-center`}>
                          <Typography>{index + 1}</Typography>
                        </td>
                        <td className="po_leble">
                          <input
                            name="txtItemName"
                            type="text"
                            id="txtItemName"
                            value={singleService.txtItemName}
                            onChange={(e) => handleServiceChange(e, index)}
                            required
                          />
                        </td>
                        <td className="po_leble">
                          <Select
                            name="txtUnits"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={singleService.txtUnits}
                            onChange={(e) => {
                              setUnitsFlag(true);
                              handleServiceChange(e, index);
                            }}
                          >
                            {unitsList.length > 0 &&
                              unitsList.map((row, index) => (
                                <MenuItem value={row.id} key={index}>
                                  {row.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {unitsFlag && (
                            <TextField
                              name="intQuantity"
                              type="number"
                              value={singleService.intQuantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              inputProps={{ min: 1 }}
                            />
                          )}
                        </td>
                        <td className="po_leble">
                          <TextField
                            name="intAmount"
                            type="number"
                            // {...register("intAmount")}
                            value={singleService.intAmount}
                            onChange={(e) => handleServiceChange(e, index)}
                          />
                        </td>
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithoutGST)
                              ? `0.00`
                              : singleService.intLineItemTotalWithoutGST}
                          </Typography>
                        </td>
                        <td className="po_leble">
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              name="intGST"
                              id="demo-simple-select"
                              value={singleService.intGST}
                              onChange={(e) => {
                                handleServiceChange(e, index);
                              }}
                            >
                              <MenuItem value={`0`} key={index}>
                                GST 0%
                              </MenuItem>
                              <MenuItem value={`5`} key={index}>
                                GST 5%
                              </MenuItem>
                              <MenuItem value={`12`} key={index}>
                                GST 12%
                              </MenuItem>
                              <MenuItem value={`18`} key={index}>
                                GST 18%
                              </MenuItem>
                              <MenuItem value={`28`} key={index}>
                                GST 28%
                              </MenuItem>
                            </Select>
                            <Typography className="invalid-feedback">
                              {errors.intGST?.message}
                            </Typography>
                          </FormControl>
                        </td>
                        {vendorDetails.state != "TamilNadu" ? (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intIGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intIGST)}
                              </Typography>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intCGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intCGST)}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intSGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intSGST)}
                              </Typography>
                            </td>
                          </>
                        )}
                        {/* <td className="po_leble">
                          <Typography>{singleService.intSGST}</Typography>
                        </td> */}
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithGST)
                              ? `0.00`
                              : twoDecimal(
                                  singleService.intLineItemTotalWithGST
                                )}
                          </Typography>
                        </td>
                        <td className="cancel_button">
                          {serviceList.length !== 1 && (
                            <>
                              <div
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={(e) => {
                                    handleServiceChange(e, index);
                                    handleServiceRemove(index);
                                  }}
                                  startIcon={<DeleteIcon />}
                                  color="error"
                                ></Button>

                                <small
                                  style={{ fontSize: "13px", fontWeight: "90" }}
                                >
                                  Remove
                                </small>
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className="tbody_col">
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography>Total</Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalWithoutGST}>
                          {isNaN(totalWithoutGST)
                            ? `0.00`
                            : twoDecimal(totalWithoutGST)}
                        </Typography>
                      </td>
                    </tr>
                    {vendorDetails.state != "TamilNadu" ? (
                      <>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography>IGST </Typography>
                          </td>
                          <td>
                            <Typography value={totalIgst}>
                              {isNaN(totalIgst)
                                ? `0.00`
                                : twoDecimal(totalIgst)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography>CGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={totalCgst}>
                              {isNaN(totalCgst)
                                ? `0.00`
                                : twoDecimal(totalCgst)}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography>SGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={totalSgst}>
                              {isNaN(totalSgst)
                                ? `0.00`
                                : twoDecimal(totalSgst)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={5}>
                        <Typography>Transport Charges</Typography>
                      </td>
                      <td className="po_leble">
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            name="intTransportGST"
                            id="demo-simple-select"
                            {...register("intTransportGST")}
                            onChange={(e) => {
                              setValue("intTransportGST", e.target.value);
                              setTransportGST(e.target.value);

                              if (transportAmount != 0) {
                                handleTransportCharge(
                                  transportAmount,
                                  e.target.value
                                );
                              }
                            }}
                          >
                            <MenuItem value={`0`} key={1}>
                              GST 0%
                            </MenuItem>
                            <MenuItem value={`5`} key={2}>
                              GST 5%
                            </MenuItem>
                            <MenuItem value={`12`} key={3}>
                              GST 12%
                            </MenuItem>
                            <MenuItem value={`18`} key={4}>
                              GST 18%
                            </MenuItem>
                            <MenuItem value={`28`} key={5}>
                              GST 28%
                            </MenuItem>
                          </Select>
                          <Typography className="invalid-feedback">
                            {errors.intGST?.message}
                          </Typography>
                        </FormControl>
                      </td>
                      <td className="po_leble">
                        <TextField
                          name="intTransportAmount"
                          type="number"
                          {...register("intTransportAmount")}
                          onChange={(e) => {
                            setTransportAmount(e.target.value);
                            handleTransportCharge(e.target.value, "");
                          }}
                        />
                      </td>
                      <td colSpan={5}>
                        <Typography value={transportCharge}>
                          {isNaN(transportCharge)
                            ? `0.00`
                            : twoDecimal(transportCharge)}
                        </Typography>
                      </td>
                    </tr>
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography>Total amount with GST </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalamount}>
                          {isNaN(totalamount)
                            ? `0.00`
                            : twoDecimal(totalamount)}
                        </Typography>
                      </td>
                    </tr>

                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography>Rounded off </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalamount}>
                          {isNaN(totalamount)
                            ? `0.00`
                            : calculateRoundingDifference(totalamount)}
                        </Typography>
                      </td>
                    </tr>
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Total Payable Amount (Rs.)
                        </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography
                          value={totalamount}
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          {isNaN(totalamount)
                            ? `0.00 /-`
                            : `${roundUpToInteger(totalamount)}/-`}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            <div className="empty-space-20"></div>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              textAlign="left"
              className="po_lebleterm"
            >
              <FormControl className="form_control" sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Terms & Conditions
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={termsName}
                  onChange={handleChangeterm}
                  input={<OutlinedInput label="Terms & Conditions" />}
                  MenuProps={MenuProps}
                >
                  {termsNames.map((terms) => (
                    <MenuItem key={terms.title} value={terms.id}>
                      <ListItemText
                        primary={terms.title}
                        onClick={() => {
                          setPOTerm(terms.id);
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {termsDescription !== null && (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                textAlign={`right`}
                display={`flex`}
                justifyContent={`flex-end`}
              >
                <TextField fullWidth id="textarea" />
              </Grid>
            )}

            <Grid></Grid>
            <Grid
              item
              className="form-lable"
              xs={12}
              lg={4}
              md={4}
              sx={{ mt: 4 }}
            >
              <label> Document : </label>
              <br />
              <small className="" style={{ fontSize: "12px" }}>
                Allowed Only *.jpeg, *.jpg, *.png, *.pdf
              </small>
              <br></br>
              <Box display={`flex`}>
                <Button variant="contained" component="label">
                  <input
                    id="agreementdocument"
                    name="agreementdocument"
                    type="file"
                    accept="image/jpeg,image/png,application/pdf"
                    fullWidth
                    onChange={(e) => {
                      uploadagreement(e);
                    }}
                  />
                </Button>
                {baseuploadagreement != "" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setIsCancelPopup(true)}
                    style={{ marginLeft: "10px" }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid></Grid>
            {baseuploadagreement != "" && (
              <Grid item className="form-lable" xs={12} lg={12} md={12}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6">Document</Typography>
                  {baseuploadagreement.endsWith(".pdf") ? (
                    <iframe
                      src={baseuploadagreement}
                      title="document"
                      width="100%"
                      height="600"
                    ></iframe>
                  ) : baseuploadagreement.startsWith(
                      "data:application/pdf;base64,"
                    ) ? (
                    <iframe
                      src={`data:application/pdf;base64,${
                        baseuploadagreement.split(",")[1]
                      }`}
                      title="document"
                      width="100%"
                      height="600"
                    ></iframe>
                  ) : (
                    <img
                      src={baseuploadagreement}
                      alt="document"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            <div className="empty-space-20"></div>
            <Grid item xs={12} md={12} lg={12} textAlign="right">
              <Button variant="primary" className={`btn-primary`} type="submit">
                Submit
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Dialog
        open={isCancelPopup}
        onClose={() => setIsCancelPopup(false)}
        maxWidth="xl"
      >
        <DialogTitle sx={{ backgroundColor: "#FFF2D7" }}>
          <Typography
            variant="h5"
            style={{
              color: "#FF6868",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Warning!!
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "16px" }}>
          <Typography variant="h6">
            Would you like to remove the attachment?
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#f44336", fontWeight: "bold" }}
          >
            **This step cannot be undone
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", padding: "16px" }}>
          <Button color="success" onClick={handleCancelFile}>
            Yes
          </Button>
          <Button color="error" onClick={() => setIsCancelPopup(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default PurchaseOrder;

// import React, { useEffect, useRef, useState } from "react";

// //design imports from material
// import {
//   Grid,
//   TextField,
//   FormControl,
//   Typography,
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogActions,
//   DialogContent,
//   Box,
// } from "@mui/material";

// //layout import
// import Layout from "../../../layout/Layout";
// import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import axios from "axios";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddIcon from "@mui/icons-material/Add";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import ListItemText from "@mui/material/ListItemText";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { LoadingButton } from "@mui/lab";

// //form imports
// import { set, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";

// import {
//   config,
//   _numberOnly,
//   projectMasterAPI,
//   statusMasterAPI,
//   vendorAPI,
//   purchaseOrderAPI,
//   termsAndConditionsAPI,
//   userData,
//   manageCompaniesAPI,
//   handleHrMasterAPI,
// } from "../../../config";
// import { AlertMessage } from "../../../components/AlertMessage";
// import { getValue } from "@mui/system";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
// // const names = ["KG Terms & Conditions", "Validity"];
// let total = 0;
// let cgst = 0;
// let igst = 0;
// let sgst = 0;
// let totalAmount = 0;

// function PurchaseOrder() {
//   const childRef = useRef();
//   const navigate = useNavigate();

//   // add field
//   const [serviceList, setServiceList] = useState([
//     { txtItemName: "", txtUnits: "" },
//   ]);
//   const [totalWithoutGST, setTotalWithoutGST] = useState(0);
//   const [totalCgst, settotalCgst] = useState(0);
//   const [totalSgst, setTotalSgst] = useState(0);
//   const [totalIgst, setTotalIgst] = useState(0);
//   const [totalamount, setTotalAmount] = useState(0);

//   const [unitsFlag, setUnitsFlag] = useState(false);
//   const [txtSiteEng, setTextSiteEng] = useState("");

//   // const handleServiceRemove = (index) => {
//   //   const list = [...serviceList];
//   //   console.log("list", list)
//   //   return;
//   //   list.splice(index, 1);

//   //   setServiceList(list);
//   // };

//   const handleServiceAdd = () => {
//     setServiceList([...serviceList, { txtItemName: "", txtUnits: "" }]);
//   };

//   const handleServiceRemove = (index) => {
//     const list = [...serviceList];

//     // Get the item that you're going to remove
//     const removedItem = list[index];

//     // Calculate the values to subtract
//     const subtractWithoutGST = removedItem.intLineItemTotalWithoutGST;
//     const subtractCGST = removedItem.intCGST;
//     const subtractSGST = removedItem.intSGST;
//     const subtractIGST = removedItem.intIGST;
//     const subtractTotalWithGST = removedItem.intLineItemTotalWithGST;

//     // Update the state variables
//     setTotalWithoutGST((prevTotal) => prevTotal - subtractWithoutGST);
//     settotalCgst((prevTotalCgst) => prevTotalCgst - subtractCGST);
//     setTotalSgst((prevTotalSgst) => prevTotalSgst - subtractSGST);
//     setTotalIgst((prevTotalIgst) => prevTotalIgst - subtractIGST);
//     setTotalAmount((prevTotalAmount) => prevTotalAmount - subtractTotalWithGST);

//     // Remove the item from the list
//     list.splice(index, 1);

//     // Update the serviceList state
//     setServiceList(list);
//   };

//   const handleServiceChange = (e, index) => {
//     const { name, value } = e.target;
//     const list = [...serviceList];

//     list[index][name] = value;
//     // list[index]["intLineItemTotalWithGST"] = 20;
//     let tempGST = parseInt(list[index]["intGST"]) / 2;
//     list[index]["intLineItemTotalWithoutGST"] =
//       parseFloat(list[index]["intAmount"]) *
//       parseFloat(list[index]["intQuantity"]);

//     if (vendorDetails.state != "TamilNadu") {
//       list[index]["intIGST"] =
//         (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"] +
//         (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
//     } else {
//       list[index]["intCGST"] =
//         (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
//       list[index]["intSGST"] =
//         (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
//     }

//     list[index]["intTGST"] = list[index]["intCGST"] + list[index]["intsGST"];

//     // list[index]["intLineItemTotalWithGST"] =
//     //   list[index]["intLineItemTotalWithoutGST"] +
//     //   list[index]["intCGST"] +
//     //   list[index]["intSGST"];

//     if (vendorDetails.state != "TamilNadu") {
//       list[index]["intLineItemTotalWithGST"] =
//         list[index]["intLineItemTotalWithoutGST"] + list[index]["intIGST"];
//     } else {
//       list[index]["intLineItemTotalWithGST"] =
//         list[index]["intLineItemTotalWithoutGST"] +
//         list[index]["intCGST"] +
//         list[index]["intSGST"];
//     }

//     total = 0;
//     for (var i = 0; i < list.length; i++) {
//       total = total + list[i]["intLineItemTotalWithoutGST"];
//     }
//     igst = 0;
//     for (var i = 0; i < list.length; i++) {
//       igst = igst + list[i]["intIGST"];
//     }
//     cgst = 0;
//     for (var i = 0; i < list.length; i++) {
//       cgst = cgst + list[i]["intCGST"];
//     }
//     sgst = 0;
//     for (var i = 0; i < list.length; i++) {
//       sgst = sgst + list[i]["intSGST"];
//     }
//     totalAmount = 0;
//     for (var i = 0; i < list.length; i++) {
//       totalAmount = totalAmount + list[i]["intLineItemTotalWithGST"];
//     }

//     setTotalWithoutGST(total);
//     setServiceList(list);

//     if (igst > 0) {
//       settotalCgst(0);
//       setTotalSgst(0);
//       setTotalIgst(igst);
//     } else if (cgst > 0 && sgst > 0) {
//       setTotalIgst(0);
//       settotalCgst(cgst);
//       setTotalSgst(sgst);
//     }
//     // settotalCgst(cgst);
//     // setTotalSgst(sgst);
//     // setTotalIgst(igst);
//     setTotalAmount(totalAmount + transportCharge);
//   };

//   const validationSchema = Yup.object().shape({
//     txtCompanyName: Yup.string().required("Company Name is required"),
//     intVendorID: Yup.string().required("Vendor Name is required"),
//     intProjectID: Yup.string().required("Project Name is required"),
//   });

//   const formOptions = { resolver: yupResolver(validationSchema) };
//   const { register, handleSubmit, reset, formState, setValue } =
//     useForm(formOptions);

//   const { errors } = formState;

//   const [PoDate, setPoDate] = React.useState(dayjs().format("YYYY-MM-DD"));

//   // Vendor List
//   const [vendorList, setVendorList] = useState([]);

//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 3,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = vendorAPI;

//       axios(config)
//         .then(function (response) {
//           setVendorList(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });

//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   let tempRefs = useRef(false);

//   const [companyName, setCompanyName] = React.useState("");

//   //Marital Details
//   const [ProjectNameList, setProjectNameList] = useState([]);
//   const [intProjectID, setProjectName] = useState(0);
//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 6,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = projectMasterAPI;

//       axios(config)
//         .then(function (response) {
//           setProjectNameList(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   //Terms Of Payments
//   const [termsofPayments, setTermsofPayments] = useState([]);
//   const [txtTermsOfPayments, setTermsofPayment] = useState("");
//   tempRefs.current = false;

//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 2,
//         intParentId: 59,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = statusMasterAPI;

//       axios(config)
//         .then(function (response) {
//           setTermsofPayments(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   // dispatch
//   const [dispatch, setDispatch] = useState([]);
//   const [txtDispatchThrough, setDispatchThrough] = useState("");
//   tempRefs.current = false;

//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 2,
//         intParentId: 65,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = statusMasterAPI;

//       axios(config)
//         .then(function (response) {
//           setDispatch(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   // Purchase
//   const [unitsList, setUnitsList] = useState([]);
//   const [units, setUnits] = useState("");
//   tempRefs.current = false;
//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 2,
//         intParentId: 88,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = statusMasterAPI;

//       axios(config)
//         .then(function (response) {
//           setUnitsList(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   // Purchase
//   const [Purchase, setPurchase] = useState([]);
//   const [txtPurchaseClassify, settxtPurchase] = useState("");
//   tempRefs.current = false;

//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 2,
//         intParentId: 68,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = statusMasterAPI;

//       axios(config)
//         .then(function (response) {
//           setPurchase(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   tempRefs.current = false;

//   const [siteEngineer, setSiteEngineer] = useState([]);

//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 9,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = handleHrMasterAPI;

//       axios(config)
//         .then(function (response) {
//           console.log(
//             "🚀💻 ~ file: PurchaseOrder.jsx:408 ~ response:",
//             response
//           );

//           setSiteEngineer(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   const findPhoneNumber = (id) => {
//     const contact = siteEngineer.find((person) => person.id === id);
//     contact && setValue("txtSiteEngContact", contact.phone_no);
//   };

//   tempRefs.current = false;

//   // Vendor Details
//   const [vendorDetails, setVendorDetails] = useState({});
//   const [intVendorID, setVendorID] = useState(0);

//   const handleVendor = (e) => {
//     setVendorID(e.target.value);
//     setValue("intVendorID", e.target.value);

//     const data = {
//       intType: 7,
//       intvendorAutoID: e.target.value,
//       intLimit: 1,
//     };

//     config.method = "POST";
//     config.data = JSON.stringify(data);
//     config.url = vendorAPI;

//     axios(config)
//       .then(function (response) {
//         setVendorDetails(response.data.result[0]);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };
//   // console.log({ full_Vendor: vendorDetails });
//   // console.log({ selected_vendorId: intVendorID })

//   tempRefs.current = false;

//   // Purchase
//   const [termsNames, setTermsNames] = useState([]);

//   tempRefs.current = false;

//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 4,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = termsAndConditionsAPI;

//       axios(config)
//         .then(function (response) {
//           setTermsNames(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   tempRefs.current = false;

//   const [termsName, setTermsName] = useState("");
//   const [terms, setTermsandConditions] = useState("");

//   const handleChangeterm = (event) => {
//     setTermsName(event.target.value);

//     const data = {
//       intType: 3,
//       intID: event.target.value,
//       intLimit: 1,
//     };

//     config.method = "POST";
//     config.data = JSON.stringify(data);
//     config.url = termsAndConditionsAPI;

//     axios(config)
//       .then(function (response) {
//         setTermsandConditions(response.data.result[0]);
//         window.tinymce.init({
//           selector: "#textarea",
//           width: "100%",
//           menubar: false,
//           plugins:
//             "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
//           toolbar:
//             "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
//         });
//         setTimeout(function () {
//           window.tinymce
//             .get("textarea")
//             .setContent(response.data.result[0].description);
//         }, 1150);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   // axios(config)
//   // .then(function (response) {
//   //   console.log(response.data.result[0].title);
//   //   setTermsNames(response.data.result[0].title);
//   //   window.tinymce.init({
//   //     selector: "#textarea",
//   //     width: "100%",
//   //     menubar: false,
//   //     plugins:
//   //       "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
//   //     toolbar:
//   //       "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
//   //   });
//   //   setTimeout(function () {
//   //     window.tinymce
//   //       .get("textarea")
//   //       .setContent(response.data.result[0].description);
//   //   }, 1150);
//   // })

//   const [transportGST, setTransportGST] = useState(0);
//   const [transportAmount, setTransportAmount] = useState(0);
//   const [transportCharge, setTransportCharge] = useState(0);

//   const handleTransportCharge = (price, v) => {
//     if (v != "") {
//       let GstValue = (price / 100) * parseInt(v);

//       let total = isNaN(parseInt(GstValue) + parseInt(price))
//         ? 0
//         : parseInt(GstValue) + parseInt(price);

//       setTransportCharge(total);
//       setTotalAmount(totalAmount + total);
//     } else {
//       let GstValue = (price / 100) * parseInt(transportGST);

//       let total = isNaN(parseInt(GstValue) + parseInt(price))
//         ? 0
//         : parseInt(GstValue) + parseInt(price);

//       setTransportCharge(total);

//       setTotalAmount(totalAmount + total);
//     }
//   };

//   const [baseuploadagreement, setBaseUploadAgreement] = useState("");

//   const uploadagreement = async (e) => {
//     const file = e.target.files[0];
//     const base64 = await convertBase64(file);
//     setBaseUploadAgreement(base64);
//   };

//   const convertBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.readAsDataURL(file);

//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };

//       fileReader.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };

//   const [companyNames, setCompanyNames] = React.useState("");
//   // getting all companies
//   useEffect(() => {
//     if (tempRefs.current === false) {
//       const data = {
//         intType: 5,
//         // intParentId: 42,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = manageCompaniesAPI;

//       axios(config)
//         .then(function (response) {
//           // console.log({ coData: response.data.result });
//           setCompanyNames(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs.current = true;
//       };
//     }
//   }, []);

//   const twoDecimal = (x) => {
//     return Number.parseFloat(x).toFixed(2);
//   };
//   function roundUpToInteger(value) {
//     // Round up to the nearest integer
//     const roundedValue = Math.ceil(value);

//     // Append ".00" to the rounded value
//     const formattedValue = roundedValue.toFixed(2);

//     return formattedValue;
//   }

//   function calculateRoundingDifference(value) {
//     // Round up to the nearest integer
//     const roundedValue = Math.ceil(value);

//     // Calculate the rounding difference
//     const difference = roundedValue - value;

//     return difference.toFixed(2);
//   }

//   // getting gst data
//   // const [gstListData, setGstListData] = useState([])
//   // useEffect(() => {
//   //   // if (tempRefs.current === false) {
//   //   const data = {
//   //     intType: 2,
//   //     intParentId: 77,
//   //   };

//   //   config.method = "POST";
//   //   config.data = JSON.stringify(data);
//   //   config.url = statusMasterAPI;

//   //   axios(config)
//   //     .then(function (response) {
//   //       console.log({ gstData: response.data.result });
//   //       // setGstListData(response.data.result)
//   //       // setCompanyNames(response.data.result);
//   //     })
//   //     .catch(function (error) {
//   //       console.log(error);
//   //     });
//   //   return () => {
//   //     tempRefs.current = true;
//   //   };
//   //   // }
//   // }, []);

//   const [PoTerm, setPOTerm] = React.useState(0);

//   function onSubmit(data) {
//     let poDesc = window.tinymce.get("textarea").getContent();

//     if (!poDesc || poDesc.trim() === "") {
//       throw new Error("Text area is empty. Please provide some content.");
//     }

//     let newPoDesc = poDesc.replaceAll("<p>", "");
//     let finalPoDesc = newPoDesc.replaceAll("</p>", "");
//     data.txtFile = baseuploadagreement;

//     data.lineItems = serviceList;
//     data.intTotalWithoutGST = totalWithoutGST;
//     // data.intTotalIGST = totalIgst;
//     // data.intTotalSGST = totalSgst;
//     // data.intTotalCGST = totalCgst;

//     if (totalIgst > 0) {
//       data.intTotalSGST = 0;
//       data.intTotalCGST = 0;
//       data.intTotalIGST = twoDecimal(totalIgst);
//     } else if (totalCgst > 0 && totalSgst > 0) {
//       data.intTotalIGST = 0;
//       data.intTotalSGST = twoDecimal(totalSgst);
//       data.intTotalCGST = twoDecimal(totalCgst);
//     }
//     data.txtPOValidTo = PoDate;
//     data.intTotalWithGST = twoDecimal(totalamount);
//     data.intCreatedBy = userData.emp_id;
//     data.intVendorID = intVendorID;
//     data.intStatus = 98;
//     data.intTransportGst = transportGST;
//     data.intTransportAmount = transportAmount;
//     data.intTransportCharge = transportCharge;
//     data.txtTermsHeading = PoTerm;
//     data.txtTerms = finalPoDesc;

//     data.intType = 1;

//     // console.log(data);
//     // return;
//     config.method = "POST";
//     config.data = JSON.stringify(data);
//     config.url = purchaseOrderAPI;

//     axios(config)
//       .then(function (response) {
//         childRef.current.triggerAlert(
//           JSON.stringify({
//             message: response.data.message,
//             messageType: "success",
//           })
//         );

//         setTimeout(function () {
//           navigate(`/purchase-order/list`);
//         }, 1000);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   }

//   const [isCancelPopup, setIsCancelPopup] = useState(false);

//   const handleCancelFile = async () => {
//     setBaseUploadAgreement("");
//     document.getElementById("agreementdocument").value = "";
//     setIsCancelPopup(false);
//   };

//   return (
//     <Layout>
//       <AlertMessage ref={childRef} />
//       <Grid className="employee-form">
//         <Grid item xs={12} lg={12} md={12}>
//           <Grid container spacing={3}>
//             <Grid
//               textAlign="center"
//               item
//               className="form-lable"
//               xs={12}
//               lg={6}
//               md={6}
//             >
//               <Typography className="page_title" textAlign="left">
//                 Purchase Order
//               </Typography>
//               <Breadcrumbs aria-label="breadcrumb">
//                 <Link underline="hover" color="inherit" href="/dashboard">
//                   Dashboard
//                 </Link>
//                 <Typography color="text.primary">purchase order</Typography>
//                 <Typography color="text.primary">create</Typography>
//               </Breadcrumbs>
//             </Grid>
//             <Grid
//               textAlign="right"
//               item
//               className="form-lable"
//               xs={12}
//               lg={6}
//               md={6}
//             >
//               <LoadingButton
//                 size="medium"
//                 variant="contained"
//                 color="secondary"
//                 className="button_first"
//                 href="/purchase-order/list"
//               >
//                 List purchase order
//               </LoadingButton>
//               <LoadingButton
//                 size="medium"
//                 variant="contained"
//                 color="primary"
//                 onClick={() => navigate(-1)}
//                 startIcon={<ArrowBackIosIcon />}
//               >
//                 Back
//               </LoadingButton>
//             </Grid>
//           </Grid>
//         </Grid>
//         <div className="empty-space-40"></div>
//         <Grid className="purchaseorder">
//           <form onSubmit={handleSubmit(onSubmit)}>
//             <Grid container className="popading" spacing={2}>
//               <Grid
//                 item
//                 className="form-lable po_lebleterm"
//                 xs={12}
//                 lg={3}
//                 md={3}
//               >
//                 <FormControl fullWidth>
//                   <InputLabel id="demo-simple-select-label">
//                     Order Type
//                   </InputLabel>
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     {...register("txtOrderType")}
//                     label="Order Type"
//                     onChange={(e) => {
//                       setValue("txtOrderType", e.target.value);
//                     }}
//                   >
//                     <MenuItem value="1">Purchase Order</MenuItem>
//                     <MenuItem value="2">Work Order</MenuItem>
//                   </Select>
//                   <Typography className="invalid-feedback">
//                     {errors.txtOrderType?.message}
//                   </Typography>
//                 </FormControl>
//               </Grid>
//               <Grid
//                 item
//                 className="form-lable po_lebleterm"
//                 xs={12}
//                 lg={3}
//                 md={3}
//               >
//                 <FormControl fullWidth>
//                   <InputLabel id="demo-simple-select-label">
//                     Vendor Name
//                   </InputLabel>
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={intVendorID}
//                     label="Vendor Name"
//                     onChange={(e) => {
//                       handleVendor(e);
//                     }}
//                   >
//                     {vendorList &&
//                       vendorList.length > 0 &&
//                       vendorList.map((row, index) => (
//                         <MenuItem value={row.id} key={index}>
//                           {row.name}
//                         </MenuItem>
//                       ))}
//                   </Select>
//                   <Typography className="invalid-feedback">
//                     {errors.intVendorID?.message}
//                   </Typography>
//                 </FormControl>
//               </Grid>
//               <Grid
//                 item
//                 className="form-lable po_lebleterm"
//                 xs={12}
//                 lg={3}
//                 md={3}
//               >
//                 <FormControl fullWidth>
//                   <InputLabel id="demo-simple-select-label">
//                     Company Name
//                   </InputLabel>
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={companyName}
//                     label="Company Name"
//                     onChange={(e) => {
//                       setCompanyName(e.target.value);
//                       setValue("txtCompanyName", e.target.value);
//                     }}
//                   >
//                     {companyNames &&
//                       companyNames.length > 0 &&
//                       companyNames.map((row, index) => (
//                         <MenuItem value={row.id} key={index}>
//                           {row.company_name}
//                         </MenuItem>
//                       ))}
//                   </Select>
//                   <Typography className="invalid-feedback">
//                     {errors.txtCompanyName?.message}
//                   </Typography>
//                 </FormControl>
//               </Grid>

//               <Grid
//                 item
//                 className="form-lable po_lebleterm"
//                 xs={12}
//                 lg={3}
//                 md={3}
//               >
//                 <FormControl fullWidth>
//                   <InputLabel id="demo-simple-select-label">
//                     Select Project
//                   </InputLabel>
//                   <Select
//                     labelId="Select Project"
//                     id="demo-simple-select"
//                     value={intProjectID}
//                     label="Select Project"
//                     onChange={(e) => {
//                       setProjectName(e.target.value);
//                       setValue("intProjectID", e.target.value);
//                     }}
//                   >
//                     {ProjectNameList.map((row, index) => (
//                       <MenuItem value={row.id} key={index}>
//                         {row.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                   <Typography className="invalid-feedback">
//                     {errors.intProjectID?.message}
//                   </Typography>
//                 </FormControl>
//               </Grid>
//             </Grid>
//             <Grid container className="pomargin table_po">
//               <Grid item className="form-lable" xs={12} lg={7} md={7}>
//                 {vendorDetails.hasOwnProperty("name") ? (
//                   <>
//                     <Grid className="info_details">
//                       <Typography>To</Typography>
//                       <Typography className="companyna">
//                         {vendorDetails.name}
//                       </Typography>
//                       <Typography>{vendorDetails.mobile_no}</Typography>
//                       <Typography>
//                         {vendorDetails.street_address}, {vendorDetails.city}
//                       </Typography>
//                       <Typography>
//                         {vendorDetails.pincode} {vendorDetails.state}
//                       </Typography>
//                       <Typography>India</Typography>
//                       <Typography>GSTIN {vendorDetails.gst}</Typography>
//                     </Grid>
//                   </>
//                 ) : (
//                   <></>
//                 )}
//               </Grid>
//               <Grid item className="po_table table_po" xs={12} lg={5} md={5}>
//                 {/* <Typography className="align_center">Employee No</Typography>
//                 <hr></hr> */}
//                 <Grid
//                   container
//                   spacing={2}
//                   justifyContent={`center`}
//                   className="po_leble"
//                 >
//                   <Grid item className="lable_text" xs={12} lg={5} md={5}>
//                     <Typography>Mode/Terms of Payment : </Typography>
//                   </Grid>
//                   <Grid item xs={12} lg={7} md={7}>
//                     <FormControl fullWidth>
//                       <Select
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={txtTermsOfPayments}
//                         onChange={(e) => {
//                           setTermsofPayment(e.target.value);
//                           setValue("txtTermsOfPayments", e.target.value);
//                         }}
//                       >
//                         {/* {termsofPayments && termsofPayments.length > 0 ? ( */}
//                         {termsofPayments.map((row, index) => (
//                           <MenuItem value={row.name} key={index}>
//                             {row.name}
//                           </MenuItem>
//                         ))}
//                         {/* ) : (
//                         <></>
//                       )} */}
//                       </Select>
//                       <Typography className="invalid-feedback">
//                         {errors.txtTermscondition?.message}
//                       </Typography>
//                     </FormControl>
//                   </Grid>
//                 </Grid>
//                 <Grid
//                   container
//                   spacing={2}
//                   justifyContent={`center`}
//                   className="po_leble"
//                 >
//                   <Grid item className="lable_text" xs={12} lg={5} md={5}>
//                     <Typography>Supplier Ref./Quotation No.:</Typography>
//                   </Grid>
//                   <Grid item xs={12} lg={7} md={7}>
//                     <TextField
//                       name="familyname"
//                       type="text"
//                       {...register("txtQuotationNo")}
//                     />
//                   </Grid>
//                 </Grid>
//                 <Grid
//                   container
//                   spacing={2}
//                   justifyContent={`center`}
//                   className="po_leble"
//                 >
//                   <Grid item className="lable_text" xs={12} lg={5} md={5}>
//                     <Typography>Purchase Classification : </Typography>
//                   </Grid>
//                   <Grid item xs={12} lg={7} md={7}>
//                     <FormControl fullWidth>
//                       <Select
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={txtPurchaseClassify}
//                         onChange={(e) => {
//                           settxtPurchase(e.target.value);
//                           setValue("txtPurchaseClassify", e.target.value);
//                         }}
//                       >
//                         {/* {Purchase && Purchase.length > 0 ? ( */}
//                         {Purchase.map((row, index) => (
//                           <MenuItem value={row.id} key={index}>
//                             {row.name}
//                           </MenuItem>
//                         ))}
//                         {/* ) : (
//                         <></>
//                       )} */}
//                       </Select>
//                       <Typography className="invalid-feedback">
//                         {errors.txtPurchaseClassify?.message}
//                       </Typography>
//                     </FormControl>
//                   </Grid>
//                 </Grid>

//                 <Grid
//                   container
//                   spacing={2}
//                   justifyContent={`center`}
//                   className="po_leble"
//                 >
//                   <Grid item className="lable_text" xs={12} lg={5} md={5}>
//                     <Typography>Site Engineer : </Typography>
//                   </Grid>
//                   <Grid item xs={12} lg={7} md={7}>
//                     <FormControl fullWidth>
//                       <Select
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={txtSiteEng}
//                         onChange={(e) => {
//                           setTextSiteEng(e.target.value);
//                           findPhoneNumber(e.target.value);
//                           setValue("txtSiteEng", e.target.value);
//                         }}
//                       >
//                         {siteEngineer && siteEngineer.length > 0 ? (
//                           siteEngineer.map((row, index) => (
//                             <MenuItem value={row.id} key={index}>
//                               {row.name}
//                             </MenuItem>
//                           ))
//                         ) : (
//                           <MenuItem>No data found</MenuItem>
//                         )}
//                       </Select>
//                       <Typography className="invalid-feedback">
//                         {errors.txtPurchaseClassify?.message}
//                       </Typography>
//                     </FormControl>
//                   </Grid>
//                 </Grid>

//                 {txtSiteEng != "" && (
//                   <Grid
//                     container
//                     spacing={2}
//                     justifyContent={`center`}
//                     className="po_leble"
//                   >
//                     <Grid item className="lable_text" xs={12} lg={5} md={5}>
//                       <Typography>Site Engineer Contact</Typography>
//                     </Grid>
//                     <Grid item xs={12} lg={7} md={7}>
//                       <TextField
//                         name="siteEngineerContact"
//                         type="text"
//                         {...register("txtSiteEngContact")}
//                       />
//                     </Grid>
//                   </Grid>
//                 )}

//                 {/* <Grid
//                   container
//                   spacing={2}
//                   justifyContent={`center`}
//                   className="po_leble"
//                 >
//                   <Grid item className="lable_text" xs={12} lg={5} md={5}>
//                     <Typography>Choose Valid upto Date :</Typography>
//                   </Grid>
//                   <Grid item xs={12} lg={7} md={7}>
//                     <LocalizationProvider
//                       dateAdapter={AdapterDayjs}
//                       format="DD-MM-YYYY"
//                     >
//                       <MobileDatePicker
//                         value={PoDate}
//                         inputFormat="DD/MM/YYYY"
//                         fullWidth
//                         {...register("PoDate")}
//                         onChange={(newValue) => {
//                           setPoDate(newValue);
//                           setValue(
//                             "PoDate",
//                             dayjs(newValue).format("YYYY-MM-DD")
//                           );
//                         }}
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             fullWidth
//                             helperText={errors.PoDate?.message}
//                           />
//                         )}
//                       />
//                     </LocalizationProvider>
//                   </Grid>
//                 </Grid> */}
//               </Grid>
//               <Grid item className="form-lable" xs={12} lg={12} md={12}>
//                 <table className={`table`}>
//                   <thead>
//                     <th>
//                       <Typography>S. No.</Typography>
//                     </th>
//                     <th>
//                       <Typography>Description</Typography>
//                     </th>
//                     <th>
//                       <Typography>Units </Typography>
//                     </th>
//                     <th>
//                       <Typography>Rate </Typography>
//                     </th>
//                     <th>
//                       <Typography>Total Amount</Typography>
//                     </th>
//                     <th>
//                       <Typography>GST </Typography>
//                     </th>
//                     {vendorDetails.state != "TamilNadu" ? (
//                       <>
//                         <th>
//                           <Typography>IGST </Typography>
//                         </th>
//                       </>
//                     ) : (
//                       <>
//                         <th>
//                           <Typography>CGST </Typography>
//                         </th>
//                         <th>
//                           <Typography>SGST </Typography>
//                         </th>
//                       </>
//                     )}
//                     <th>
//                       <Typography>Total Amount + GST </Typography>
//                     </th>
//                     <th className="add_button">
//                       <small style={{ fontSize: "16px", fontWeight: "100" }}>
//                         Add New
//                       </small>
//                       <Button
//                         variant="outlined"
//                         onClick={handleServiceAdd}
//                         startIcon={<AddIcon />}
//                         color="success"
//                       ></Button>
//                     </th>
//                   </thead>
//                   <tbody>
//                     {serviceList.map((singleService, index) => (
//                       <tr className="table_padding">
//                         <td className={`text-center`}>
//                           <Typography>{index + 1}</Typography>
//                         </td>
//                         <td className="po_leble">
//                           <input
//                             name="txtItemName"
//                             type="text"
//                             id="txtItemName"
//                             value={singleService.txtItemName}
//                             onChange={(e) => handleServiceChange(e, index)}
//                             required
//                           />
//                         </td>
//                         <td className="po_leble">
//                           <Select
//                             name="txtUnits"
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             value={singleService.txtUnits}
//                             onChange={(e) => {
//                               setUnitsFlag(true);
//                               handleServiceChange(e, index);
//                             }}
//                           >
//                             {unitsList.length > 0 &&
//                               unitsList.map((row, index) => (
//                                 <MenuItem value={row.id} key={index}>
//                                   {row.name}
//                                 </MenuItem>
//                               ))}
//                           </Select>
//                           {unitsFlag && (
//                             <TextField
//                               name="intQuantity"
//                               type="number"
//                               value={singleService.intQuantity}
//                               onChange={(e) => handleServiceChange(e, index)}
//                               inputProps={{ min: 1 }}
//                             />
//                           )}
//                         </td>
//                         <td className="po_leble">
//                           <TextField
//                             name="intAmount"
//                             type="number"
//                             // {...register("intAmount")}
//                             value={singleService.intAmount}
//                             onChange={(e) => handleServiceChange(e, index)}
//                           />
//                         </td>
//                         <td className="po_leble">
//                           <Typography textAlign="right">
//                             {isNaN(singleService.intLineItemTotalWithoutGST)
//                               ? `0.00`
//                               : singleService.intLineItemTotalWithoutGST}
//                           </Typography>
//                         </td>
//                         <td className="po_leble">
//                           <FormControl fullWidth>
//                             <Select
//                               labelId="demo-simple-select-label"
//                               name="intGST"
//                               id="demo-simple-select"
//                               value={singleService.intGST}
//                               onChange={(e) => {
//                                 handleServiceChange(e, index);
//                               }}
//                             >
//                               <MenuItem value={`0`} key={index}>
//                                 GST 0%
//                               </MenuItem>
//                               <MenuItem value={`5`} key={index}>
//                                 GST 5%
//                               </MenuItem>
//                               <MenuItem value={`12`} key={index}>
//                                 GST 12%
//                               </MenuItem>
//                               <MenuItem value={`18`} key={index}>
//                                 GST 18%
//                               </MenuItem>
//                               <MenuItem value={`28`} key={index}>
//                                 GST 28%
//                               </MenuItem>
//                             </Select>
//                             <Typography className="invalid-feedback">
//                               {errors.intGST?.message}
//                             </Typography>
//                           </FormControl>
//                         </td>
//                         {vendorDetails.state != "TamilNadu" ? (
//                           <>
//                             <td className="po_leble">
//                               <Typography textAlign="right">
//                                 {isNaN(singleService.intIGST)
//                                   ? `0.00`
//                                   : twoDecimal(singleService.intIGST)}
//                               </Typography>
//                             </td>
//                           </>
//                         ) : (
//                           <>
//                             <td className="po_leble">
//                               <Typography textAlign="right">
//                                 {isNaN(singleService.intCGST)
//                                   ? `0.00`
//                                   : twoDecimal(singleService.intCGST)}
//                               </Typography>
//                             </td>
//                             <td className="po_leble">
//                               <Typography textAlign="right">
//                                 {isNaN(singleService.intSGST)
//                                   ? `0.00`
//                                   : twoDecimal(singleService.intSGST)}
//                               </Typography>
//                             </td>
//                           </>
//                         )}
//                         {/* <td className="po_leble">
//                           <Typography>{singleService.intSGST}</Typography>
//                         </td> */}
//                         <td className="po_leble">
//                           <Typography textAlign="right">
//                             {isNaN(singleService.intLineItemTotalWithGST)
//                               ? `0.00`
//                               : twoDecimal(
//                                   singleService.intLineItemTotalWithGST
//                                 )}
//                           </Typography>
//                         </td>
//                         <td className="cancel_button">
//                           {serviceList.length !== 1 && (
//                             <>
//                               <div
//                                 style={{
//                                   textAlign: "center",
//                                   display: "flex",
//                                   flexDirection: "column",
//                                 }}
//                               >
//                                 <Button
//                                   variant="outlined"
//                                   onClick={(e) => {
//                                     handleServiceChange(e, index);
//                                     handleServiceRemove(index);
//                                   }}
//                                   startIcon={<DeleteIcon />}
//                                   color="error"
//                                 ></Button>

//                                 <small
//                                   style={{ fontSize: "13px", fontWeight: "90" }}
//                                 >
//                                   Remove
//                                 </small>
//                               </div>
//                             </>
//                           )}
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                   <tbody className="tbody_col">
//                     <tr className="table_padding_40">
//                       <td className={`text-center`}>
//                         <Typography></Typography>
//                       </td>
//                       <td colSpan={7}>
//                         <Typography>Total</Typography>
//                       </td>
//                       <td colSpan={7}>
//                         <Typography value={totalWithoutGST}>
//                           {isNaN(totalWithoutGST)
//                             ? `0.00`
//                             : twoDecimal(totalWithoutGST)}
//                         </Typography>
//                       </td>
//                     </tr>
//                     {vendorDetails.state != "TamilNadu" ? (
//                       <>
//                         <tr className="table_padding_40">
//                           <td className={`text-center`}>
//                             <Typography></Typography>
//                           </td>
//                           <td colSpan={7}>
//                             <Typography>IGST </Typography>
//                           </td>
//                           <td>
//                             <Typography value={totalIgst}>
//                               {isNaN(totalIgst)
//                                 ? `0.00`
//                                 : twoDecimal(totalIgst)}
//                             </Typography>
//                           </td>
//                         </tr>
//                       </>
//                     ) : (
//                       <>
//                         <tr className="table_padding_40">
//                           <td className={`text-center`}>
//                             <Typography></Typography>
//                           </td>
//                           <td colSpan={7}>
//                             <Typography>CGST </Typography>
//                           </td>
//                           <td colSpan={7}>
//                             <Typography value={totalCgst}>
//                               {isNaN(totalCgst)
//                                 ? `0.00`
//                                 : twoDecimal(totalCgst)}
//                             </Typography>
//                           </td>
//                         </tr>
//                         <tr className="table_padding_40">
//                           <td className={`text-center`}>
//                             <Typography></Typography>
//                           </td>
//                           <td colSpan={7}>
//                             <Typography>SGST </Typography>
//                           </td>
//                           <td colSpan={7}>
//                             <Typography value={totalSgst}>
//                               {isNaN(totalSgst)
//                                 ? `0.00`
//                                 : twoDecimal(totalSgst)}
//                             </Typography>
//                           </td>
//                         </tr>
//                       </>
//                     )}
//                     <tr className="table_padding_40">
//                       <td className={`text-center`}>
//                         <Typography></Typography>
//                       </td>
//                       <td colSpan={5}>
//                         <Typography>Transport Charges</Typography>
//                       </td>
//                       <td className="po_leble">
//                         <FormControl fullWidth>
//                           <Select
//                             labelId="demo-simple-select-label"
//                             name="intTransportGST"
//                             id="demo-simple-select"
//                             {...register("intTransportGST")}
//                             onChange={(e) => {
//                               setValue("intTransportGST", e.target.value);
//                               setTransportGST(e.target.value);

//                               if (transportAmount != 0) {
//                                 handleTransportCharge(
//                                   transportAmount,
//                                   e.target.value
//                                 );
//                               }
//                             }}
//                           >
//                             <MenuItem value={`0`} key={1}>
//                               GST 0%
//                             </MenuItem>
//                             <MenuItem value={`5`} key={2}>
//                               GST 5%
//                             </MenuItem>
//                             <MenuItem value={`12`} key={3}>
//                               GST 12%
//                             </MenuItem>
//                             <MenuItem value={`18`} key={4}>
//                               GST 18%
//                             </MenuItem>
//                             <MenuItem value={`28`} key={5}>
//                               GST 28%
//                             </MenuItem>
//                           </Select>
//                           <Typography className="invalid-feedback">
//                             {errors.intGST?.message}
//                           </Typography>
//                         </FormControl>
//                       </td>
//                       <td className="po_leble">
//                         <TextField
//                           name="intTransportAmount"
//                           type="number"
//                           {...register("intTransportAmount")}
//                           onChange={(e) => {
//                             setTransportAmount(e.target.value);
//                             handleTransportCharge(e.target.value, "");
//                           }}
//                         />
//                       </td>
//                       <td colSpan={5}>
//                         <Typography value={transportCharge}>
//                           {isNaN(transportCharge)
//                             ? `0.00`
//                             : twoDecimal(transportCharge)}
//                         </Typography>
//                       </td>
//                     </tr>
//                     <tr className="table_padding_40">
//                       <td className={`text-center`}>
//                         <Typography></Typography>
//                       </td>
//                       <td colSpan={7}>
//                         <Typography>Total amount with GST </Typography>
//                       </td>
//                       <td colSpan={7}>
//                         <Typography value={totalamount}>
//                           {isNaN(totalamount)
//                             ? `0.00`
//                             : twoDecimal(totalamount)}
//                         </Typography>
//                       </td>
//                     </tr>

//                     <tr className="table_padding_40">
//                       <td className={`text-center`}>
//                         <Typography></Typography>
//                       </td>
//                       <td colSpan={7}>
//                         <Typography>Rounded off </Typography>
//                       </td>
//                       <td colSpan={7}>
//                         <Typography value={totalamount}>
//                           {isNaN(totalamount)
//                             ? `0.00`
//                             : calculateRoundingDifference(totalamount)}
//                         </Typography>
//                       </td>
//                     </tr>
//                     <tr className="table_padding_40">
//                       <td className={`text-center`}>
//                         <Typography></Typography>
//                       </td>
//                       <td colSpan={7}>
//                         <Typography
//                           variant="subtitle1"
//                           sx={{
//                             fontWeight: "bold",
//                             fontSize: "15px",
//                           }}
//                         >
//                           Total Payable Amount
//                         </Typography>
//                       </td>
//                       <td colSpan={7}>
//                         <Typography
//                           value={totalamount}
//                           variant="subtitle1"
//                           sx={{
//                             fontWeight: "bold",
//                             fontSize: "15px",
//                           }}
//                         >
//                           {isNaN(totalamount)
//                             ? `0.00`
//                             : roundUpToInteger(totalamount)}
//                         </Typography>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </Grid>
//             </Grid>
//             <div className="empty-space-20"></div>
//             <Grid
//               item
//               xs={12}
//               md={4}
//               lg={4}
//               textAlign="left"
//               className="po_lebleterm"
//             >
//               <FormControl className="form_control" sx={{ m: 1, width: 300 }}>
//                 <InputLabel id="demo-multiple-checkbox-label">
//                   Terms & Conditions
//                 </InputLabel>
//                 <Select
//                   labelId="demo-multiple-checkbox-label"
//                   id="demo-multiple-checkbox"
//                   value={termsName}
//                   onChange={handleChangeterm}
//                   input={<OutlinedInput label="Terms & Conditions" />}
//                   MenuProps={MenuProps}
//                 >
//                   {termsNames.map((terms) => (
//                     <MenuItem key={terms.title} value={terms.id}>
//                       <ListItemText
//                         primary={terms.title}
//                         onClick={() => {
//                           setPOTerm(terms.id);
//                         }}
//                       />
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid
//               item
//               lg={12}
//               md={12}
//               sm={12}
//               textAlign={`right`}
//               display={`flex`}
//               justifyContent={`flex-end`}
//             >
//               <TextField fullWidth id="textarea" />
//             </Grid>
//             <Grid></Grid>
//             <Grid item className="form-lable" xs={12} lg={4} md={4}>
//               <label> Document : </label>
//               <br />
//               <small className="" style={{ fontSize: "12px" }}>
//                 Allowed Only *.jpeg, *.jpg, *.png, *.pdf
//               </small>
//               <br></br>
//               <Box display={`flex`}>
//                 <Button variant="contained" component="label">
//                   <input
//                     id="agreementdocument"
//                     name="agreementdocument"
//                     type="file"
//                     accept="image/jpeg,image/png,application/pdf"
//                     fullWidth
//                     onChange={(e) => {
//                       uploadagreement(e);
//                     }}
//                   />
//                 </Button>
//                 {baseuploadagreement != "" && (
//                   <Button
//                     variant="outlined"
//                     color="error"
//                     onClick={() => setIsCancelPopup(true)}
//                     style={{ marginLeft: "10px" }}
//                   >
//                     Remove
//                   </Button>
//                 )}
//               </Box>
//             </Grid>
//             <Grid></Grid>
//             {baseuploadagreement != "" && (
//               <Grid item className="form-lable" xs={12} lg={12} md={12}>
//                 <Grid item xs={12} sm={12}>
//                   <Typography variant="h6">Document</Typography>
//                   {baseuploadagreement.endsWith(".pdf") ? (
//                     <iframe
//                       src={baseuploadagreement}
//                       title="document"
//                       width="100%"
//                       height="600"
//                     ></iframe>
//                   ) : baseuploadagreement.startsWith(
//                       "data:application/pdf;base64,"
//                     ) ? (
//                     <iframe
//                       src={`data:application/pdf;base64,${
//                         baseuploadagreement.split(",")[1]
//                       }`}
//                       title="document"
//                       width="100%"
//                       height="600"
//                     ></iframe>
//                   ) : (
//                     <img
//                       src={baseuploadagreement}
//                       alt="document"
//                       style={{ maxWidth: "100%" }}
//                     />
//                   )}
//                 </Grid>
//               </Grid>
//             )}
//             <div className="empty-space-20"></div>
//             <Grid item xs={12} md={12} lg={12} textAlign="right">
//               <Button variant="primary" className={`btn-primary`} type="submit">
//                 Submit
//               </Button>
//             </Grid>
//           </form>
//         </Grid>
//       </Grid>
//       <Dialog
//         open={isCancelPopup}
//         onClose={() => setIsCancelPopup(false)}
//         maxWidth="xl"
//       >
//         <DialogTitle sx={{ backgroundColor: "#FFF2D7" }}>
//           <Typography
//             variant="h5"
//             style={{
//               color: "#FF6868",
//               fontWeight: "bold",
//               textAlign: "center",
//             }}
//           >
//             Warning!!
//           </Typography>
//         </DialogTitle>
//         <DialogContent style={{ padding: "16px" }}>
//           <Typography variant="h6">
//             Would you like to remove the attachment?
//           </Typography>
//           <Typography
//             variant="body2"
//             style={{ color: "#f44336", fontWeight: "bold" }}
//           >
//             **This step cannot be undone
//           </Typography>
//         </DialogContent>
//         <DialogActions style={{ justifyContent: "center", padding: "16px" }}>
//           <Button color="success" onClick={handleCancelFile}>
//             Yes
//           </Button>
//           <Button color="error" onClick={() => setIsCancelPopup(false)}>
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Layout>
//   );
// }

// export default PurchaseOrder;
