import React, { useEffect, useRef, useState } from "react";
//design imports from material
import {
  Grid,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
  InputLabel,
  Tooltip,
} from "@mui/material";
//layout import
import Layout from "../../layout/Layout";
//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import {
  config,
  contractorAPI,
  paymentRequestAPI,
  statusMasterAPI,
  userData,
  projectMasterAPI,
  purchaseOrderAPI,
  purchaseOrderItemsAPI,
} from "../../config";
import { AlertMessage } from "../../components/AlertMessage";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Container } from "@mui/system";
import { _getDropdownListData } from "../../service";
import { useNavigate } from "react-router-dom";
import NotFound from "../../components/NotFount";
import moment from "moment";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

import TaskAltSharpIcon from "@mui/icons-material/TaskAltSharp";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import RunningWithErrorsRoundedIcon from "@mui/icons-material/RunningWithErrorsRounded";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Tooltip as ReactTooltip } from "react-tooltip";

function PaymentRequest() {
  const limit = 10;
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    // intProjectID: Yup.string().required("Project Name is required"),
    // txtNatureofExpense: Yup.string().required("Nature of Expense is required"),
    // txtAmount: Yup.string().required("Amount is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const [lstNatureofExpense, setNatureofExpense] = useState([]);
  const [txtNatureofExpense, setNatureofExpenseValue] = useState(0);
  const [txtPoDetails, setTxtPoDetails] = useState("");
  const [txtPurchaseOrder, setPurchaseOrder] = useState(0);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);

  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([]);

  //Upload Supervisor Payment Request Documents
  const [paymentRequestDocs, setPaymentRequestDocs] = useState("");
  const [poTotalAmount, setPOTotalAmount] = useState(0);

  const uploadmeasurement = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setPaymentRequestDocs(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  useEffect(() => {
    if (tempRef.current === false) {
      let data = {
        requestData: {
          intType: 2,
          intParentId: 24,
        },
        API: statusMasterAPI,
        setData: setNatureofExpense,
      };
      _getDropdownListData(data);

      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  let tempRefs = useRef(false);

  const childRef = useRef();

  const [ProjectNameList, setProjectNameList] = useState([]);
  const [intProjectID, setProjectName] = useState(0);
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 6,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          setProjectNameList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const handlePurchaseOrderDetails = (poID) => {
    const data = {
      intPOId: poID,
      intType: 4,
    };
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = purchaseOrderAPI;

    axios(config)
      .then(function (response) {
        setPurchaseOrderDetails(response.data.result[1]);
        setPOTotalAmount(parseInt(response.data.result[1][0].total_with_gst));
      })
      .catch(function (error) {
        console.log(error);
      });
    return () => {
      tempRefs.current = true;
    };
  };

  const [poTransactionDetails, setPoTransactionDetails] = useState([]);
  const [totalPaidPOAmount, setTotalPaidPOAmount] = useState(0);

  const handlePurchaseOrder = (poID) => {
    const data = {
      intType: 8,
      txtPurchaseOrder: poID,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = paymentRequestAPI;
    axios(config)
      .then(function (response) {
        setTotalPaidPOAmount(response.data.result[2][0].total_amount);
        setPoTransactionDetails(response.data.result[1]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePO = (intProjectID) => {
    let data = {
      requestData: {
        intType: 3,
        intProjectID: intProjectID,
      },
      API: purchaseOrderAPI,
      setData: setPurchaseOrderList,
    };
    _getDropdownListData(data);
  };

  const [poMaterials, setPOMaterials] = useState([]);

  const handlePOMaterials = (poID) => {
    const data = {
      intPONo: poID,
      intType: 2,
    };
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = purchaseOrderItemsAPI;

    axios(config)
      .then(function (response) {
        setPOMaterials(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function onSubmit(data) {
    if (
      !data.intAmount ||
      !data.txtNatureofExpense ||
      !data.txtPurchaseOrder ||
      !data.intProjectID
    ) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Please Fill All the fields",
          messageType: "error",
        })
      );
      return false;
    }

    if (
      txtPoDetails == "Payment With PO" &&
      parseInt(data.intAmount) >
        parseInt(poTotalAmount) - parseInt(totalPaidPOAmount)
    ) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Amount should be less than Balance PO Amount",
          messageType: "error",
        })
      );
      return false;
    }

    data.intType = 1;
    data.intProjectID = data.intProjectID ? data.intProjectID : 0;
    data.intEmployeeID = userData.emp_id;
    data.intContractorID = data.intContractorID ? data.intContractorID : 0;
    data.txtPoDetails = data.txtPoDetails ? data.txtPoDetails : "";
    data.txtPurchaseOrder = data.txtPurchaseOrder ? data.txtPurchaseOrder : 0;
    data.txtNatureofExpense = data.txtNatureofExpense
      ? data.txtNatureofExpense
      : 0;
    data.intAmount = data.intAmount ? data.intAmount : 0;
    data.txtEmployeeDescription = data.txtEmployeeDescription
      ? data.txtEmployeeDescription
      : "";
    data.txtPaymentRequestDocs = paymentRequestDocs;

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = paymentRequestAPI;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
    setTimeout(function () {
      window.location.reload(true);
    }, 2000);
  }

  let tempRef = useRef(false);

  return (
    <Layout>
      <Grid className="employee-form">
        <AlertMessage ref={childRef} />
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Payment Request
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/user/payment/request"
              >
                Payment Request
              </Link>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} lg={12} md={12}>
              <Grid container spacing={2} justifyContent={`center`}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Project
                    </InputLabel>
                    <Select
                      labelId="Select Project"
                      id="demo-simple-select"
                      value={intProjectID}
                      label="Project Name"
                      onChange={(e) => {
                        setProjectName(e.target.value);
                        setValue("intProjectID", e.target.value);
                        handlePO(e.target.value);
                      }}
                    >
                      {ProjectNameList.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography className="invalid-feedback">
                      {errors.intProjectID?.message}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl
                    fullWidth
                    error={errors.txtNatureofExpense ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Nature of Expense
                    </InputLabel>
                    <Select
                      labelId="demo-txtEmployeeDescription
                          simple-select-label"
                      id="demo-simple-select"
                      value={txtNatureofExpense}
                      label="Nature of Expense"
                      {...register("txtNatureofExpense")}
                      onChange={(e) => {
                        setNatureofExpenseValue(e.target.value);
                        setValue("txtNatureofExpense", e.target.value);
                      }}
                      error={errors.txtNatureofExpense ? true : false}
                    >
                      {lstNatureofExpense.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.txtNatureofExpense?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl
                    fullWidth
                    error={errors.txtNatureofExpense ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Purchase Order
                    </InputLabel>
                    <Select
                      labelId="demo-txtEmployeeDescription
                          simple-select-label"
                      id="demo-simple-select"
                      value={txtPoDetails}
                      label="Purchase Order"
                      {...register("txtPoDetails")}
                      onChange={(e) => {
                        setTxtPoDetails(e.target.value);
                      }}
                      error={errors.txtPoDetails ? true : false}
                    >
                      <MenuItem value="Payment With PO">
                        Payment With PO
                      </MenuItem>
                      <MenuItem value="Payment Without PO">
                        Payment Without PO
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors.txtPoDetails?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {txtPoDetails == "Payment With PO" && (
                  <>
                    <Grid item className="form-lable" xs={12} lg={6} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Purchase Order
                        </InputLabel>
                        <Select
                          labelId="simple-select-label"
                          id="demo-simple-select"
                          value={txtPurchaseOrder}
                          label="Select Purchase Order"
                          {...register("txtPurchaseOrder")}
                          onChange={(e) => {
                            setPurchaseOrder(e.target.value);
                            handlePurchaseOrderDetails(e.target.value);
                            handlePurchaseOrder(e.target.value);
                            handlePOMaterials(e.target.value);
                          }}
                          // error={errors.txtPurchaseOrder ? true : false}
                        >
                          {purchaseOrderList[1].length > 0 ? (
                            purchaseOrderList[1].map((row, index) => (
                              <MenuItem value={row.id} key={index}>
                                {row.po_no}
                              </MenuItem>
                            ))
                          ) : (
                            <>
                              <MenuItem>No Data</MenuItem>
                            </>
                          )}
                        </Select>
                        <FormHelperText>
                          {errors.txtPurchaseOrder?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {txtPurchaseOrder !== 0 && (
                      <>
                        <Grid
                          item
                          className="form-lable"
                          xs={12}
                          lg={12}
                          md={12}
                          style={{ overflow: "auto" }}
                        >
                          <Grid textAlign={"center"}>
                            <Typography className="table-title">
                              PO Materials
                            </Typography>
                          </Grid>

                          <table className={`table`}>
                            <thead>
                              <th>
                                <Typography>S.No</Typography>
                              </th>
                              <th>
                                <Typography>Description of Goods </Typography>
                              </th>
                              <th>
                                <Typography>Quantity </Typography>
                              </th>
                              <th>
                                <Typography>Amount </Typography>
                              </th>
                              <th>
                                <Typography>Total Amount with GST</Typography>
                              </th>
                            </thead>
                            <tbody>
                              {poMaterials.map((material, index) => (
                                <tr>
                                  <td className={`text-center`}>
                                    <Typography>{index + 1}</Typography>
                                  </td>
                                  <td className={`text-center`}>
                                    <Typography>
                                      {material.item_name}
                                    </Typography>
                                  </td>
                                  <td className={`text-center`}>
                                    <Typography>{material.quantity}</Typography>
                                  </td>
                                  <td className={`text-center`}>
                                    <Typography>
                                      {material.amount.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 2 }
                                      )}
                                    </Typography>
                                  </td>
                                  <td className={`text-center`}>
                                    <Typography>
                                      {material.total_amount_with_gst.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 2 }
                                      )}
                                    </Typography>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Grid>
                        <div className="empty-space-40"></div>
                        <Grid
                          item
                          className="form-lable"
                          xs={12}
                          lg={12}
                          md={12}
                          style={{ overflow: "auto" }}
                        >
                          <Grid textAlign={"center"}>
                            <Typography className="table-title">
                              PO Based Payments
                            </Typography>
                          </Grid>
                          <table className={`table`}>
                            <thead>
                              <th>
                                <Typography>S.No</Typography>
                              </th>
                              <th>
                                <Typography>Paid Date</Typography>
                              </th>
                              <th>
                                <Typography>Payment Amount </Typography>
                              </th>
                              <th>
                                <Typography>Payment Status </Typography>
                              </th>
                            </thead>
                            <tbody>
                              {poTransactionDetails.length > 0 ? (
                                poTransactionDetails.map((row, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className={`text-center`}>
                                        <Typography>{index + 1}</Typography>
                                      </td>
                                      <td className={`text-center`}>
                                        <Typography>
                                          {moment
                                            .utc(row.accountant_paid_datetime)
                                            .utcOffset("+05:30")
                                            .format("MMM DD, YYYY, hh:mm A")}
                                        </Typography>
                                      </td>
                                      <td className={`text-center`}>
                                        <Typography>
                                          {row.amount.toLocaleString(
                                            undefined,
                                            { maximumFractionDigits: 2 }
                                          )}
                                        </Typography>
                                      </td>
                                      <td className={`text-center`}>
                                        {row.status === 56 && (
                                          <Typography className="icon_colorpending">
                                            <Tooltip title="Admin Approveded">
                                              <TaskAltSharpIcon />
                                            </Tooltip>
                                          </Typography>
                                        )}
                                        {row.status === 57 && (
                                          <Typography className="icon_coloraccept">
                                            <Tooltip title="Payment Request Completed">
                                              <DoneAllIcon />
                                            </Tooltip>
                                          </Typography>
                                        )}
                                        {row.status === 58 && (
                                          <Typography className="icon_colordeclined">
                                            <Tooltip title="Admin Declined">
                                              <HighlightOffRoundedIcon />
                                            </Tooltip>
                                          </Typography>
                                        )}
                                        {row.status === 55 && (
                                          <Typography className="icon_colorpending">
                                            <Tooltip title="Waiting for Admin Approval">
                                              <RunningWithErrorsRoundedIcon />
                                            </Tooltip>
                                          </Typography>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={10} className={`text-center`}>
                                    <NotFound />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item className="form-lable" xs={12} lg={6} md={6}>
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              fullWidth
                              label="PO Total Value"
                              value={poTotalAmount.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                              focused
                            />
                          </FormControl>
                        </Grid>
                        <Grid item className="form-lable" xs={12} lg={6} md={6}>
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              fullWidth
                              label="Balance Amount for the PO"
                              value={(
                                poTotalAmount - totalPaidPOAmount
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                              focused
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}

                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Amount"
                    error={errors.intAmount ? true : false}
                    {...register("intAmount")}
                    helperText={errors.intAmount?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    label="Description"
                    error={errors.txtEmployeeDescription ? true : false}
                    {...register("txtEmployeeDescription")}
                    helperText={errors.txtEmployeeDescription?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <Button variant="contained" component="label">
                    <input
                      name="measurementsheet "
                      type="file"
                      accept="image/jpeg,image/png,application/pdf"
                      fullWidth
                      onChange={(e) => {
                        uploadmeasurement(e);
                      }}
                    />
                  </Button>
                </Grid>
                <Grid
                  item
                  className="form-lable"
                  xs={12}
                  lg={12}
                  md={12}
                  marginTop="20px"
                  textAlign="right"
                >
                  <Button
                    // style={{ margin: "10px" }}
                    className={`btn-primary`}
                    type="submit"
                  >
                    Request Payment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
}

export default PaymentRequest;
