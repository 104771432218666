import React from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
const PaySlipPdf = ({ paySlip, companyLogo }) => {
  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 850]);
    const { width, height } = page.getSize();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    // Add Company Logo on the left side
    const logoImageBytes = await fetch(companyLogo).then((res) =>
      res.arrayBuffer()
    );
    const logoImage = await pdfDoc.embedPng(logoImageBytes);
    const logoDims = logoImage.scale(0.15);
    page.drawImage(logoImage, {
      x: 30,
      y: height - logoDims.height - 15,
      width: logoDims.width,
      height: logoDims.height,
    });
    // Add Company Name in the center
    const companyName = "K.G Build Tech";
    const textWidth = fontBold.widthOfTextAtSize(companyName, 18);
    page.drawText(companyName, {
      x: (width - textWidth) / 2,
      y: height - 33,
      size: 15,
      font: fontBold,
      color: rgb(0, 0.4, 0.8),
    });
    // Add Date on the right side
    page.drawText(`Date: ${paySlip.payslip_date}`, {
      x: width - 110,
      y: height - 30,
      size: 10,
      font,
      color: rgb(0, 0.4, 0.8),
    });
    page.drawLine({
      start: { x: 0, y: height - 50 },
      end: { x: width, y: height - 50 },
      thickness: 1,
      color: rgb(0, 0.4, 0.8),
    });
    // Employee details below the header
    const detailsY = height - 100;
    page.drawText(`Employee Name:`, {
      x: 30,
      y: detailsY,
      size: 13,
      font: fontBold,
    });
    page.drawText(paySlip.employee_name, {
      x: 160,
      y: detailsY,
      size: 12,
      font,
    });
    page.drawText(`Designation: `, {
      x: 30,
      y: detailsY - 20,
      size: 12,
      font: fontBold,
    });
    page.drawText(paySlip.position, {
      x: 160,
      y: detailsY - 20,
      size: 12,
      font,
    });
    page.drawText(`Company Name: `, {
      x: 30,
      y: detailsY - 40,
      size: 12,
      font: fontBold,
    });
    page.drawText("KG BUILD TECH", {
      x: 160,
      y: detailsY - 40,
      size: 12,
      font,
    });

    page.drawText(`Department: `, {
      x: 30,
      y: detailsY - 60,
      size: 12,
      font: fontBold,
    });
    page.drawText(paySlip.department_name, {
      x: 160,
      y: detailsY - 60,
      size: 12,
      font,
    });

    page.drawText(`Employee Number: `, {
      x: 30,
      y: detailsY - 80,
      size: 12,
      font: fontBold,
    });
    page.drawText(paySlip.employee_no, {
      x: 160,
      y: detailsY - 80,
      size: 12,
      font,
    });
    // Add Table

    // Draw rows for emoluments and deductions
    const emoluments = [
      { label: "House Rent Allowance", value: paySlip.house_allowance },
      { label: "Dearness Allowance", value: paySlip.dearness_allowance },
      { label: "Medical Allowance", value: paySlip.medical_allowance },
      { label: "Vehicle Allowance", value: paySlip.vehicle_allowance },
      { label: "Other Allowance", value: paySlip.other_allowance },
      { label: "Days Paid", value: paySlip.no_of_days_working },
      { label: "Basic Pay", value: paySlip.basic_pay },
      { label: " ", value: " " },
    ];
    const deductions = [
      { label: "Salary Advance", value: paySlip.salary_advance },
      { label: "Personal Advance", value: paySlip.personal_advance },
      { label: "RD /ESI /EPFO", value: paySlip.rd_esi },
      { label: "Life Insurance Corporation", value: paySlip.life_insurance },
      {
        label: "Postal Life Insurance",
        value: paySlip.personal_life_insurance,
      },
      { label: "Income Tax(TDS)", value: paySlip.income_tax },
      { label: "Loss of Pay", value: paySlip.leave_amount },
      {
        label: "No of Days leave taken",
        value: paySlip.no_of_days_leave_taken,
      },
    ];

    // Define table dimensions
    const tableX = 40; // Starting X position
    const tableY = height - 200; // Starting Y position
    const cellHeight = 20;
    const col1Width = 150; // Column 1 width
    const col2Width = 100; // Column 2 width
    const col3Width = 150; // Column 3 width
    const col4Width = 100; // Column 4 width

    const col1X = tableX;
    const col2X = col1X + col1Width;
    const col3X = col2X + col2Width;
    const col4X = col3X + col3Width;

    // Draw table headers
    page.drawRectangle({
      x: tableX,
      y: tableY - cellHeight,
      width: col4X + col4Width - tableX,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    page.drawText("Emoluments", {
      x: col1X + 5,
      y: tableY - 15,
      font: fontBold,
      size: 10,
    });

    page.drawText("Amount Rs.", {
      x: col2X + 5,
      y: tableY - 15,
      font: fontBold,
      size: 10,
    });

    page.drawText("Deductions", {
      x: col3X + 5,
      y: tableY - 15,
      font: fontBold,
      size: 10,
    });

    page.drawText("Amount Rs.", {
      x: col4X + 5,
      y: tableY - 15,
      font: fontBold,
      size: 10,
    });

    // Draw rows for emoluments and deductions
    emoluments.forEach((item, index) => {
      const yPos = tableY - (index + 2) * cellHeight;

      // Draw emoluments labels and values with borders
      page.drawText(item.label, {
        x: col1X + 5,
        y: yPos + 5,
        size: 10,
        font,
      });

      page.drawText(item.value.toLocaleString(), {
        x: col2X + 5,
        y: yPos + 5,
        size: 10,
        font,
      });

      page.drawRectangle({
        x: col1X,
        y: yPos,
        width: col2X - col1X,
        height: cellHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
      });

      page.drawRectangle({
        x: col2X,
        y: yPos,
        width: col3X - col2X,
        height: cellHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
      });
    });

    deductions.forEach((item, index) => {
      const yPos = tableY - (index + 2) * cellHeight;

      // Draw deductions labels and values with borders
      page.drawText(item.label, {
        x: col3X + 5,
        y: yPos + 5,
        size: 10,
        font,
      });

      page.drawText(item.value.toLocaleString(), {
        x: col4X + 5,
        y: yPos + 5,
        size: 10,
        font,
      });

      page.drawRectangle({
        x: col3X,
        y: yPos,
        width: col4X - col3X,
        height: cellHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
      });

      page.drawRectangle({
        x: col4X,
        y: yPos,
        width: col4Width,
        height: cellHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
      });
    });

    // Add Gross Pay, Total Deductions, and Net Pay
    const totalRows = Math.max(emoluments.length, deductions.length) + 1;
    const yPos = tableY - (totalRows + 1) * cellHeight;

    page.drawText("Gross Pay", {
      x: col1X + 5,
      y: yPos + 5,
      font: fontBold,
      size: 10,
    });

    page.drawText(paySlip.gross_pay.toLocaleString(), {
      x: col2X + 5,
      y: yPos + 5,
      font: fontBold,
      size: 10,
    });

    page.drawRectangle({
      x: col1X,
      y: yPos,
      width: col2X - col1X,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    page.drawRectangle({
      x: col2X,
      y: yPos,
      width: col3X - col2X,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    page.drawText("Total Deductions", {
      x: col3X + 5,
      y: yPos + 5,
      font: fontBold,
      size: 10,
    });

    page.drawText(paySlip.total_deductions.toLocaleString(), {
      x: col4X + 5,
      y: yPos + 5,
      font: fontBold,
      size: 10,
    });

    page.drawRectangle({
      x: col3X,
      y: yPos,
      width: col4X - col3X,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    page.drawRectangle({
      x: col4X,
      y: yPos,
      width: col4Width,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    // Net Pay (below Total Deductions)
    const netPayYPos = yPos - cellHeight;
    page.drawText("Net Pay", {
      // x: col1X + 5,
      x: col3X + 5,
      y: netPayYPos + 5,
      font: fontBold,
      size: 10,
    });

    page.drawText(paySlip.net_pay.toLocaleString(), {
      // x: col2X + 5,
      x: col4X + 5,
      y: netPayYPos + 5,
      font: fontBold,
      size: 10,
    });

    page.drawRectangle({
      x: col3X,
      y: yPos - 20,
      width: col4X - col3X,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    page.drawRectangle({
      x: col4X,
      y: yPos - 20,
      width: col4Width,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    page.drawRectangle({
      x: col1X,
      y: netPayYPos,
      width: col2X - col1X,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    page.drawRectangle({
      x: col2X,
      y: netPayYPos,
      width: col3X - col2X,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    // Draw borders for Gross Pay, Total Deductions, and Net Pay
    page.drawRectangle({
      x: col1X,
      y: yPos,
      width: col2X - col1X,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    page.drawRectangle({
      x: col2X,
      y: yPos,
      width: col3X - col2X,
      height: cellHeight,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
    });

    const drawFooter = async (page, pageNumber) => {
      const { width } = page.getSize();
      const margin = 30;
      const fontSize = 10;
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

      page.drawLine({
        start: { x: margin, y: margin },
        end: { x: width - margin, y: margin },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
      page.drawText(`KG Build Tech`, {
        x: width / 2 - 10,
        y: margin - 20,
        size: fontSize,
        font,
        color: rgb(0, 0.4, 0.8),
      });
      page.drawText(` • ${pageNumber} • `, {
        x: width - margin - 50,
        y: margin - 20,
        size: fontSize,
        font,
        color: rgb(0, 0.4, 0.8),
      });
    };

    drawFooter(page, 1);

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(
      blob,
      `payslip_${paySlip.employee_name}_${paySlip.payslip_date}.pdf`
    );
  };
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SimCardDownloadRoundedIcon />}
        onClick={generatePDF}
        sx={{
          backgroundColor: "#2196f3",
          fontWeight: "bold",
          transition: "background-color 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            backgroundColor: "#FFF",
            color: "#2196f3",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        Generate PDF
      </Button>
    </div>
  );
};
export default PaySlipPdf;
