import React, { useEffect, useState, useRef } from "react";
import Layout from "../../layout/Layout";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Avatar,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import { AlertMessage } from "../../components/AlertMessage";
import {
  config,
  userData,
  employeeAttandenceAPI,
  projectMasterAPI,
  attandenceAPI,
} from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import moment from "moment/moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UserAttendance() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);

  const [endShiftPopupOpen, setEndShiftPopupOpen] = React.useState(false);

  const handleEndshiftOpen = () => {
    setEndShiftPopupOpen(true);
  };

  const handleEndshiftClose = () => {
    setEndShiftPopupOpen(false);
  };

  const childRef = useRef();
  const [buttonHide, setButtonHide] = useState(false);

  const handleAttandece = async (type) => {
    let project_id =
      0 == txtProject
        ? parseInt(localStorage.getItem("projectID"))
        : txtProject;

    if (type == 1) {
      if (Shift == 0) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Night shift is only after 10 PM",
            messageType: "error",
          })
        );
        return false;
      }

      if (project_id == 0 || !project_id) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Please Select Project",
            messageType: "error",
          })
        );
        return false;
      }

      await handleProjectSelection(type);
    } else if (type == 2) {
      await handleProjectSelection(4);
    }

    var reqData = JSON.stringify({
      intEmployeeID: userData.emp_id,
      txtShift: Shift,
      txtStartTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      txtEndTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      txtDate: moment(new Date()).format("YYYY-MM-DD"),
      intType: type,
    });

    config.method = "POST";
    config.url = employeeAttandenceAPI;
    config.data = reqData;

    // debugger;
    await axios(config)
      .then(function (response) {
        if (response.data.message == "LoggedIn Successfully") {
          localStorage.setItem("userAttandenceLogin", true);
          localStorage.setItem("isProjectLoggedIn", "true");
        } else if (response.data.message == "Logged out Successfully") {
          localStorage.removeItem("userAttandenceLogin");
          localStorage.removeItem("isProjectLoggedIn");
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        } else if ("Shift Ended" == response.data.message) {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: response.data.message,
              messageType: "error",
            })
          );
          return false;
        }
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: error.data.message,
            messageType: "error",
          })
        );
      });
  };

  const handleProjectSelection = async (type) => {
    let project_id =
      0 == txtProject
        ? parseInt(localStorage.getItem("projectID"))
        : txtProject;
    if (project_id == 0 || !project_id) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Please Choose project to checkin",
          messageType: "error",
        })
      );
      return false;
    }
    const data1 = JSON.stringify({
      intEmployeeID: userData.emp_id,
      intProjectID: project_id,
      intProjectAutoID: parseInt(localStorage.getItem("projectAutoID")),
      txtStartTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      txtEndTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      intType: type,
    });

    config.method = "POST";
    config.data = data1;
    config.url = attandenceAPI;
    axios(config)
      .then(function (response) {
        let messageType = "success";
        if (response.data.code == 501) {
          messageType = "warning";
        } else if ("Project login data inserted" == response.data.message) {
          if (response.data.result[0]) {
            localStorage.setItem("isProjectLoggedIn", "true");
            localStorage.setItem(
              "projectID",
              response.data.result[0].project_id
            );
            localStorage.setItem(
              "projectAutoID",
              response.data.result[0].autoincrement_id
            );
            localStorage.setItem("projectName", response.data.result[0].name);
            localStorage.setItem(
              "projectCheckInTime",
              response.data.result[0].start_time
            );
          }
        } else if ("Project logged out Successfully" == response.data.message) {
          localStorage.setItem("isProjectLoggedIn", false);
          localStorage.removeItem("projectID");
          localStorage.removeItem("projectAutoID");
          localStorage.removeItem("projectName");
          localStorage.removeItem("projectCheckInTime");
          setOpen(false);
        } else if ("Shift Ended" == response.data.message) {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: response.data.message,
              messageType: "error",
            })
          );
          return false;
        }

        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: messageType,
          })
        );

        if (type == 1) {
          setTimeout(function () {
            setLoading(true);
            window.location.reload(true);
          }, 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [ProjectNameList, setProjectNameList] = useState([]);
  const [txtProject, setProjectName] = useState(0);
  const [txtWorkDescription, setWorkDescription] = useState("");

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 6,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          setProjectNameList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // 1=>Day Shift, 2=> Night Shift
  const [Shift, setShift] = useState("1");

  const handleShift = (e) => {
    if (e.target.value == "on") {
      var reqData = JSON.stringify({
        intType: 5,
        txtDate: moment(new Date()).format("YYYY-MM-DD"),
      });

      config.method = "POST";
      config.url = employeeAttandenceAPI;
      config.data = reqData;

      axios(config)
        .then(function (response) {
          if (response.data.result[0]) {
            if (response.data.result[0].night_shift_eligible == 0) {
              setShift("1");
              childRef.current.triggerAlert(
                JSON.stringify({
                  message: "Night Shift is only after 10 PM.",
                  messageType: "error",
                })
              );
            } else {
              setShift("2");
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      {userData.role_id === 36 ? (
        <Grid container className="useradmin" spacing={3}>
          <Grid className="title_add" xs={12} lg={12} md={12}>
            <h3>User Login</h3>
          </Grid>
          <Grid textAlign="center" item xs={6} lg={12} md={12}>
            <Typography>Project Name</Typography>
            <Typography>
              {"true" === localStorage.getItem("isProjectLoggedIn") ? (
                <>{localStorage.getItem("projectName")}</>
              ) : (
                <>-</>
              )}
            </Typography>
          </Grid>
          <Grid textAlign="center" item xs={6} lg={12} md={12}>
            <Typography>Check-In Time</Typography>
            <Typography>
              {"true" === localStorage.getItem("isProjectLoggedIn") ? (
                <>{localStorage.getItem("projectCheckInTime")}</>
              ) : (
                <>-</>
              )}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are You Sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you really want to Checkout from{" "}
            {localStorage.getItem("projectName")} Project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => handleProjectSelection(4)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={endShiftPopupOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleEndshiftClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are You Sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you really want to End the shift
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEndshiftClose}>No</Button>
          <Button onClick={() => handleAttandece(2)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Grid className="employee-form-struct">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} md={12}>
            {!localStorage.getItem("userAttandenceLogin") ? (
              <>
                <Grid className="projectlogin">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Project
                    </InputLabel>
                    <Select
                      labelId="Select Project"
                      id="demo-simple-select"
                      value={txtProject}
                      label="Maristal Status"
                      onChange={(e) => {
                        setProjectName(e.target.value);
                        localStorage.setItem("projectID", e.target.value);
                      }}
                    >
                      {ProjectNameList.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <div className="empty-space-20"></div>
                {txtProject == 98 ? (
                  <>
                    <Grid className="projectlogin">
                      <FormControl fullWidth>
                        <TextField
                          name="workdescription"
                          label="Work Description"
                          type="text"
                          fullWidth
                          value={txtWorkDescription}
                          onChange={(e) => {
                            setWorkDescription(e.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <Stack
                  direction="row"
                  spacing={1}
                  textAlign="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography>Night Shift : </Typography>
                  <Typography>Off</Typography>
                  <AntSwitch
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={(e) => {
                      handleShift(e);
                    }}
                  />
                  <Typography>On</Typography>
                </Stack>
                <div className="empty-space-20"></div>
                {!buttonHide && (
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <LoadingButton
                      size="large"
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => {
                        handleAttandece(1);
                        setButtonHide(true);
                      }}
                      className="align-cancelbutton"
                      fullWidth
                    >
                      Start Shift
                    </LoadingButton>
                  </Grid>
                )}
              </>
            ) : (
              <>
                {"true" === localStorage.getItem("isProjectLoggedIn") ? (
                  <>
                    <Grid item className="form-lable" xs={12} lg={6} md={6}>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        color="error"
                        type="submit"
                        defaultValue={2}
                        onClick={handleClickOpen}
                        className="align-cancelbutton"
                        fullWidth
                      >
                        Checkout
                      </LoadingButton>
                    </Grid>
                  </>
                ) : (
                  <>
                    <div className="empty-space-20"></div>
                    <Grid className="projectlogin">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Project
                        </InputLabel>
                        <Select
                          labelId="Select Project"
                          id="demo-simple-select"
                          value={txtProject}
                          label="Maristal Status"
                          onChange={(e) => {
                            setProjectName(e.target.value);
                          }}
                        >
                          {ProjectNameList.map((row, index) => (
                            <MenuItem value={row.id} key={index}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <div className="empty-space-20"></div>
                    <div className="empty-space-20"></div>
                    <Grid item className="form-lable" xs={12} lg={6} md={6}>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        type="submit"
                        defaultValue={2}
                        onClick={() => handleProjectSelection(1)}
                        className="align-cancelbutton"
                        fullWidth
                      >
                        Check-In
                      </LoadingButton>
                    </Grid>
                  </>
                )}
                <div className="empty-space-40"></div>
                {"true" == localStorage.getItem("isProjectLoggedIn") && (
                  <Grid item className="form-lable" xs={12} lg={6} md={6}>
                    <LoadingButton
                      size="large"
                      variant="contained"
                      color="error"
                      type="submit"
                      defaultValue={2}
                      onClick={() => handleEndshiftOpen()}
                      className="align-cancelbutton"
                      fullWidth
                    >
                      End Shift
                    </LoadingButton>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default UserAttendance;
