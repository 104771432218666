import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import Layout from "../../layout/Layout";
import { config, clientMasterAPI, manageCompaniesAPI } from "../../config";
import { useNavigate } from "react-router-dom";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Pagination = lazy(() => import("../../components/ReactPagination"));

let field = "id",
  orderBy = "desc";

function ManageCoList() {
  const navigate = useNavigate();

  let intOffset = 0;
  const limit = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [clientMasterList, setClientMasterList] = useState([]);

  const handleEditCompany = (id) => {
    navigate(`/manage-companies/edit/${id}`);
  };

  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (page, rowsPerPage) => {
    if (tempRefs.current === false) {
      const data = JSON.stringify({
        intLimit: rowsPerPage ? rowsPerPage : 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        intType: 3,
      });

      config.method = "POST";
      config.data = data;
      config.url = manageCompaniesAPI;
      axios(config)
        .then(function (response) {
          setClientMasterList(response.data["result"]);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true;
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!clientMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  // const handleSort = (column, sortDirection) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     field = column.field;
  //     orderBy = sortDirection;
  //     fetchUsers(1);
  //     setLoading(false);
  //   }, 100);
  // };

  // const handleEditRole = (id) => {
  //   navigate(`/employee/view/${id}`);
  // };

  const columns = useMemo(() => [
    {
      name: "Company Name",
      field: "id",
      sortable: true,
      width: "20%",
      selector: (row) => row.company_name,
      // cell: (row) => row.employee_no,
    },
    {
      name: "State",
      field: "id",
      sortable: true,
      width: "12%",
      selector: (row) => row.state,
      // cell: (row) => row.secondary_number,
    },
    {
      name: "City",
      field: "id",
      sortable: true,
      width: "12%",
      selector: (row) => row.city,
      // cell: (row) => row.secondary_number,
    },
    {
      name: "PIN Code",
      field: "id",
      selector: (row) => row.pincode,
      // cell: (row) => row.pincode,
      width: "10%",
    },
    {
      name: "PAN Number",
      field: "id",
      sortable: true,
      width: "12%",
      selector: (row) => row.pan_no,
      // cell: (row) => row.employee_name,
    },
    {
      name: "TAN Number",
      field: "id",
      sortable: true,
      width: "12%",
      selector: (row) => (
        <Tooltip title={row.tan_no} arrow>
          <span>{row.tan_no}</span>
        </Tooltip>
      ),
      // cell: (row) => row.position_name,
    },
    {
      name: "GST Number",
      field: "id",
      width: "15%",
      selector: (row) => row.gst_no,
      cell: (row) => row.gst_no,
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <IconButton
          color="success"
          onClick={() => handleEditCompany(row.id)}
          aria-label="add an alarm"
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              List Companies
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Company</Typography>
              <Typography color="text.primary">List</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="success"
              className="button_first"
              href="/manage-companies/add"
              startIcon={<AddIcon />}
            >
              Add Company
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <Grid>
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={clientMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ManageCoList;
