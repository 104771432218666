import React from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";
import { ToWords } from "to-words";
import { Button } from "@mui/material";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import moment from "moment";

const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

const twoDecimal = (x) => {
  return Number.parseFloat(x).toFixed(2);
};

const handleNumberToWOrdCurrency = (v) => {
  return toWords.convert(roundUpToInteger(v));
};

function roundUpToInteger(value) {
  const roundedValue = Math.ceil(value);
  const formattedValue = roundedValue.toFixed(2);
  return formattedValue;
}

function calculateRoundingDifference(value) {
  const roundedValue = Math.ceil(value);
  const difference = roundedValue - value;

  return difference.toFixed(2);
}

const PurchaseOrderPDF = ({
  poDetails,
  paymentList,
  companyLogo,
  storeDetails,
}) => {
  console.log(poDetails, paymentList, storeDetails);

  const [isIgstPdf, setIsIgstPdf] = React.useState(false);

  console.log(
    "🚀💻 ~ file: GeneratePdf.jsx:50 ~ PurchaseOrderPDF ~ paymentList:",
    paymentList
  );

  React.useEffect(() => {
    if (poDetails) {
      setIsIgstPdf(
        poDetails.total_cgst == 0 &&
          poDetails.total_sgst == 0 &&
          poDetails.total_igst > 0
      );
    }
  }, [poDetails]);

  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    let pageNumber = 1;
    let page = pdfDoc.addPage([650, 950]);
    const { width, height } = page.getSize();
    const fontSize = 10;
    const boldFontSize = 12;
    const largeFontSize = 15;
    const margin = 40;
    const smallBoldFontSize = 11;
    const smallFontSize = 9;
    const headerFooterMargin = 60;
    const headerHeight = 50;
    let currentY = height - headerFooterMargin;

    // Load fonts
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const logoBytes = await fetch(companyLogo).then((res) => res.arrayBuffer());
    const logoImage = await pdfDoc.embedPng(logoBytes);
    const logoDims = logoImage.scale(0.2);

    const drawHeader = (page) => {
      const { width, height } = page.getSize();
      const text =
        poDetails.invoice_type == "1"
          ? "INVOICE"
          : poDetails.invoice_type == "2"
          ? "PROFORMA INVOICE"
          : "CREDIT NOTE";

      // Measure the width of the text
      const textWidth = boldFont.widthOfTextAtSize(text, 18);

      // Calculate the x-coordinate for center alignment
      const x = (width - textWidth) / 2;

      page.drawText(text, {
        x: x,
        y: height - headerHeight + 10,
        size: 18,
        font: boldFont,
        color: rgb(0, 0.4, 0.8),
      });

      page.drawText(` ${poDetails.invoice_no} `, {
        x: width - margin - 50,
        y: height - headerHeight + 10,
        size: 11,
        boldFont,
        color: rgb(0, 0.4, 0.8),
      });
      page.drawLine({
        start: { x: 0, y: height - headerHeight },
        end: { x: width, y: height - headerHeight },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
    };

    const drawFooter = (page, pageNumber) => {
      const { width, height } = page.getSize();
      page.drawLine({
        start: { x: margin, y: margin },
        end: { x: width - margin, y: margin },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
      page.drawText(`KG Build Tech`, {
        x: width / 2 - 10,
        y: margin - 20,
        size: fontSize,
        font,
        color: rgb(0, 0.4, 0.8),
      });
      page.drawText(` • ${pageNumber} • `, {
        x: width - margin - 50,
        y: margin - 20,
        size: fontSize,
        boldFont,
        color: rgb(0, 0.4, 0.8),
      });
    };

    const drawText = (text, x, y, size, font, options = {}) => {
      page.drawText(text.toString(), { x, y, size, font, ...options });
    };

    const drawLine = (start, end, options = {}) => {
      page.drawLine({ start, end, ...options });
    };

    const addPage = () => {
      page = pdfDoc.addPage([650, 950]);
      const { width, height } = page.getSize();
      pageNumber++;
      drawHeader(page);
      drawFooter(page, pageNumber);
      currentY = height - headerFooterMargin - headerHeight - 20; // Adjust for header and margin
      return page;
    };

    const checkAndAddNewPage = (heightNeeded) => {
      if (currentY - heightNeeded < margin) {
        page = addPage();
        currentY = height - margin - headerHeight - 20; // Adjust for header and margin
      }
    };

    // Header
    // drawText(
    //   poDetails.order_type == "1" ? "Purchase Order" : "Work Order",
    //   width / 2 - 50,
    //   currentY,
    //   18,
    //   boldFont,
    //   {
    //     color: rgb(0, 0.4, 0.8),
    //   }
    // );
    // currentY -= 20;
    // drawLine(
    //   { x: 0, y: currentY },
    //   { x: width, y: currentY },
    //   { thickness: 1, color: rgb(0, 0, 0) }
    // );
    drawHeader(page);
    drawFooter(page, pageNumber);
    currentY -= 20;

    // currentY -= 20;

    // Company Logo
    page.drawImage(logoImage, {
      x: margin + 20,
      y: currentY - 80,
      width: logoDims.width + 20,
      height: logoDims.height + 10,
    });
    // currentY -= 120;

    // Company Info
    const companyInfoYStart = currentY;
    const companyInfoXStart = width - margin;

    const rightAlignText = (text, y, size, font) => {
      const textWidth = font.widthOfTextAtSize(text, size);
      drawText(text, companyInfoXStart - textWidth, y, size, font);
    };
    // rightAlignText("OFFICE ADDRESS", companyInfoYStart, fontSize, boldFont);
    rightAlignText(
      poDetails.company_name || "",
      companyInfoYStart - 10,
      fontSize,
      boldFont
    );
    rightAlignText(
      `${poDetails.address?.split(", ")[0]}`,
      companyInfoYStart - 30,
      fontSize,
      font
    );
    rightAlignText(
      `${poDetails.address?.split(", ")[1]}`,
      companyInfoYStart - 50,
      fontSize,
      font
    );
    rightAlignText(
      `${poDetails.city || ""} - ${poDetails.pincode || ""},${
        poDetails.state || "TamilNadu"
      }, India`,
      companyInfoYStart - 70,
      fontSize,
      font
    );
    // rightAlignText(
    //   (poDetails.state || "TamilNadu") + ", India",
    //   companyInfoYStart - 110,
    //   fontSize,
    //   font
    // );
    rightAlignText(
      poDetails.primary_contact || "" + "/" + poDetails.secondary_contact || "",
      companyInfoYStart - 90,
      fontSize,
      font
    );
    rightAlignText(
      poDetails.email_id || "",
      companyInfoYStart - 110,
      fontSize,
      font
    );
    rightAlignText(
      poDetails.website_link || "",
      companyInfoYStart - 130,
      fontSize,
      font
    );

    // drawText(
    //   poDetails.company_Name || "",
    //   companyInfoXStart,
    //   companyInfoYStart,
    //   fontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.company_address || "",
    //   companyInfoXStart,
    //   companyInfoYStart - 30,
    //   fontSize,
    //   font
    // );
    // drawText(
    //   `${poDetails.company_city || ""} - ${poDetails.company_pincode || ""}, ${
    //     poDetails.company_state || ""
    //   } India`,
    //   companyInfoXStart,
    //   companyInfoYStart - 50,
    //   fontSize,
    //   font
    // );
    // drawText(
    //   poDetails.company_primary_contact || "",
    //   companyInfoXStart,
    //   companyInfoYStart - 70,
    //   fontSize,
    //   font
    // );
    // drawText(
    //   poDetails.company_emailId || "",
    //   companyInfoXStart,
    //   companyInfoYStart - 90,
    //   fontSize,
    //   font
    // );
    // drawText(
    //   poDetails.company_website || "",
    //   companyInfoXStart,
    //   companyInfoYStart - 110,
    //   fontSize,
    //   font
    // );

    currentY -= 160;
    drawLine(
      { x: 0, y: currentY },
      { x: width, y: currentY },
      { thickness: 1, color: rgb(0, 0, 0) }
    );
    currentY -= 20;

    // Vendor Info
    const vendorYStart = currentY;
    const vendorXStart = margin;
    drawText(
      "Billed To:",
      vendorXStart,
      vendorYStart,
      smallBoldFontSize,
      boldFont
    );
    drawText(
      poDetails.client_name || "",
      vendorXStart,
      vendorYStart - 20,
      smallFontSize,
      boldFont,
      { color: rgb(0, 0.4, 0.8) }
    );

    drawText(
      poDetails.client_mobile || "",
      vendorXStart,
      vendorYStart - 40,
      smallFontSize,
      font
    );

    drawText(
      `${poDetails.client_address || ""},`,
      vendorXStart,
      vendorYStart - 60,
      smallFontSize,
      font
    );

    drawText(
      `${poDetails.client_pincode || ""}, ${poDetails.client_state || ""}`,
      vendorXStart,
      vendorYStart - 80,
      smallFontSize,
      font
    );

    drawText(
      `${poDetails.client_city || ""}, India`,
      vendorXStart,
      vendorYStart - 100,
      smallFontSize,
      font
    );

    if (storeDetails.store_name) {
      drawText(
        "Details Of Consignee :",
        vendorXStart,
        vendorYStart - 150,
        smallBoldFontSize,
        boldFont
      );

      drawText(
        `${poDetails.client_name || ""}`,
        vendorXStart,
        vendorYStart - 170,
        smallFontSize,
        boldFont,
        { color: rgb(0, 0.4, 0.8) }
      );

      drawText(
        `${storeDetails.store_name || ""}`,
        vendorXStart,
        vendorYStart - 190,
        fontSize,
        font
      );

      drawText(
        `${storeDetails.address || ""}, ${storeDetails.city || ""}`,
        vendorXStart,
        vendorYStart - 210,
        fontSize,
        font
      );

      drawText(
        `${storeDetails.state}-${storeDetails.pin_code}`,
        vendorXStart,
        vendorYStart - 230,
        smallBoldFontSize,
        font
      );
    } else {
      currentY += 180;
    }

    if (storeDetails.store_name) {
      drawText(
        poDetails.client_gst ? "GSTIN: " + (poDetails.client_gst || "") : "",
        vendorXStart,
        vendorYStart - 260,
        smallFontSize,
        font
      );
    } else {
      drawText(
        poDetails.client_gst ? "GSTIN: " + (poDetails.client_gst || "") : "",
        vendorXStart,
        vendorYStart - 120,
        smallFontSize,
        font
      );
    }

    // if (poDetails.invoice_type == "2") {
    //   drawText(
    //     "CIN: U63010TN1993PTC062664",
    //     vendorXStart,
    //     vendorYStart - 260,
    //     smallBoldFontSize,
    //     boldFont
    //   );

    //   drawText(
    //     "Work Order Date: 26-02-2023",
    //     vendorXStart,
    //     vendorYStart - 280,
    //     smallBoldFontSize,
    //     boldFont
    //   );
    // }

    // currentY -= 140;

    // Purchase Order Info
    const poInfoYStart = currentY;
    const poInfoXStart = width - margin - 250;

    if (poDetails.company_name == "KG FURNTECH") {
      rightAlignText(
        `FACTORY ADDRESS:`,
        companyInfoYStart - 190,
        fontSize,
        boldFont
      );

      rightAlignText(
        "S No 59/1B, NEELAVATHI GARDEN",
        companyInfoYStart - 210,
        fontSize,
        font
      );

      rightAlignText(
        "MARIMUTHU ROAD, CHETTIYAR AGARAM",
        companyInfoYStart - 230,
        fontSize,
        font
      );

      rightAlignText(
        "CHENNAI - 600 077",
        companyInfoYStart - 250,
        fontSize,
        font
      );
    } else if (poDetails.company_name == "KG TRADETECH") {
      rightAlignText(
        `SHOP ADDRESS:`,
        companyInfoYStart - 190,
        fontSize,
        boldFont
      );

      rightAlignText(
        "SHOP NO.2, 95/5, GROUND FLOOR",
        companyInfoYStart - 210,
        fontSize,
        font
      );

      rightAlignText(
        "KUNDRATHUR MAIN ROAD",
        companyInfoYStart - 230,
        fontSize,
        font
      );

      rightAlignText(
        "GERUGAMBAKKAM, CHENNAI - 600 122.",
        companyInfoYStart - 250,
        fontSize,
        font
      );
    }
    console.log(companyInfoXStart);
    // drawText(
    //   `Mode/Terms of Payment:`,
    //   poInfoXStart,
    //   poInfoYStart - 20,
    //   smallBoldFontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.invoice_terms_description || "Immediate",
    //   poInfoXStart + 150,
    //   poInfoYStart - 20,
    //   smallFontSize,
    //   font
    // );
    // drawText(
    //   `Supplier Ref./Quotation No:`,
    //   poInfoXStart,
    //   poInfoYStart - 40,
    //   smallBoldFontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.po_ref_no || "",
    //   poInfoXStart + 150,
    //   poInfoYStart - 40,
    //   smallFontSize,
    //   font
    // );
    // drawText(
    //   `Packing Charge:`,
    //   poInfoXStart,
    //   poInfoYStart - 60,
    //   smallBoldFontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.packing_charge || "0",
    //   poInfoXStart + 150,
    //   poInfoYStart - 60,
    //   smallFontSize,
    //   font
    // );
    // drawText(
    //   `Purchase Classification:`,
    //   poInfoXStart,
    //   poInfoYStart - 80,
    //   smallBoldFontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.purchase_classification || "",
    //   poInfoXStart + 150,
    //   poInfoYStart - 80,
    //   smallFontSize,
    //   font
    // );
    // drawText(
    //   `Purchase Date:`,
    //   poInfoXStart,
    //   poInfoYStart - 100,
    //   smallBoldFontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.created_at || "",
    //   poInfoXStart + 150,
    //   poInfoYStart - 100,
    //   smallFontSize,
    //   font
    // );
    // drawText(
    //   `Site Engineer:`,
    //   poInfoXStart,
    //   poInfoYStart - 120,
    //   smallBoldFontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.site_eng_name || "",
    //   poInfoXStart + 150,
    //   poInfoYStart - 120,
    //   smallFontSize,
    //   font
    // );
    // drawText(
    //   `Site Engineer Contact:`,
    //   poInfoXStart,
    //   poInfoYStart - 140,
    //   smallBoldFontSize,
    //   boldFont
    // );
    // drawText(
    //   poDetails.site_eng_contact || "",
    //   poInfoXStart + 150,
    //   poInfoYStart - 140,
    //   smallFontSize,
    //   font
    // );
    
    if (storeDetails.storeName) {
      currentY -= 300;
    } else {
      currentY -= 340;
    }

    // Table Header
    const tableYStart = currentY;
    console.log(companyInfoXStart);
    const tableHeaders = [
      "S.No",
      "Description of Goods",
      "Qty.(nos.)",
      "Rate(Rs)",
      "Amount.(Rs)",
      "GST",
      isIgstPdf ? "IGST" : "CGST",
      !isIgstPdf ? "SGST" : undefined,
      "Total Amt. + GST(Rs)",
    ];

    const tableXPositions = [
      margin - 30,
      margin + 10,
      margin + 160,
      margin + 220,
      margin + 280,
      margin + 360,
      isIgstPdf ? margin + 410 : margin + 410,
      !isIgstPdf ? margin + 450 : undefined,
      margin + (isIgstPdf ? 480 : 490),
    ];

    tableHeaders.forEach((header, index) => {
      if (header) {
        // Check to ensure header is not undefined
        drawText(
          header,
          tableXPositions[index],
          tableYStart,
          smallBoldFontSize,
          boldFont
        );
      }
    });

    drawLine(
      { x: 0, y: tableYStart - 10 },
      { x: width, y: tableYStart - 10 },
      { thickness: 2, color: rgb(0, 0, 0) }
    );
    currentY = tableYStart - 30;

    // Table Rows
    const tableRowHeight = 20;

    paymentList.forEach((item, index) => {
      checkAndAddNewPage(tableRowHeight);

      drawText(index + 1, tableXPositions[0], currentY, smallFontSize, font);

      drawText(
        item.item_name || "",
        tableXPositions[1],
        currentY,
        smallFontSize,
        font,
        { maxWidth: 120 }
      );

      drawText(
        twoDecimal(item.quantity) || "",
        tableXPositions[2],
        currentY,
        smallFontSize,
        font
      );

      const amountText = twoDecimal(item.amount) || "";
      const amountWidth = font.widthOfTextAtSize(amountText, smallFontSize);

      drawText(
        twoDecimal(item.rate) || "",
        tableXPositions[3] + 40 - amountWidth,
        currentY,
        smallFontSize,
        font
      );

      const totalWithoutGSTText = twoDecimal(item.total_amount_with_gst) || "";
      const totalWithoutGSTWidth = font.widthOfTextAtSize(
        totalWithoutGSTText,
        smallFontSize
      );

      drawText(
        twoDecimal(item.total_amount_without_gst) || "",
        tableXPositions[4] + 60 - totalWithoutGSTWidth,
        currentY,
        smallFontSize,
        font
      );

      drawText(
        `${item.gst}%` || "",
        tableXPositions[5],
        currentY,
        smallFontSize,
        font
      );

      if (isIgstPdf) {
        drawText(
          twoDecimal(item.igst) || "",
          tableXPositions[6],
          currentY,
          smallFontSize,
          font
        );

        // Adjust the position for Total Amount with GST
        const totalWithGSTText = twoDecimal(item.total_amount_with_gst) || "";
        const totalWithGSTWidth = font.widthOfTextAtSize(
          totalWithGSTText,
          smallFontSize
        );
        drawText(
          twoDecimal(item.total_amount_with_gst) || "",
          tableXPositions[8] + 90 - totalWithGSTWidth,
          currentY,
          smallFontSize,
          font
        );
      } else {
        drawText(
          twoDecimal(item.cgst) || "",
          tableXPositions[6], // Position for CGST
          currentY,
          smallFontSize,
          font
        );

        drawText(
          twoDecimal(item.sgst) || "",
          tableXPositions[7], // Position for SGST
          currentY,
          smallFontSize,
          font
        );

        // Adjust the position for Total Amount with GST
        const totalWithGSTText = twoDecimal(item.total_amount_with_gst) || "";
        const totalWithGSTWidth = font.widthOfTextAtSize(
          totalWithGSTText,
          smallFontSize
        );
        drawText(
          twoDecimal(item.total_amount_with_gst) || "",
          tableXPositions[8] + 90 - totalWithGSTWidth, // Adjusted position for Total Amount with GST in CGST/SGST case
          currentY,
          smallFontSize,
          font
        );
      }

      currentY -= tableRowHeight;
    });

    // Total Calculation Section
    checkAndAddNewPage(100);

    const totalSectionStartY = currentY - 10;
    drawLine(
      { x: margin, y: totalSectionStartY },
      { x: width - margin, y: totalSectionStartY },
      { thickness: 1, color: rgb(0, 0, 0) }
    );

    const totalAmountDetails = [
      {
        label: "Total Amount (Rs.):",
        value: `${twoDecimal(poDetails.total_without_gst) + "/-" || ""}`,
      },
      isIgstPdf
        ? {
            label: "IGST (Rs.):",
            value: `${twoDecimal(poDetails.total_igst) + "/-" || ""}`,
          }
        : {
            label: "CGST (Rs.):",
            value: `${twoDecimal(poDetails.total_cgst) + "/-" || ""}`,
          },
      !isIgstPdf
        ? {
            label: "SGST (Rs.):",
            value: `${twoDecimal(poDetails.total_sgst) + "/-" || ""}`,
          }
        : null,
      // {
      //   label: "Transport GST:",
      //   value: `${twoDecimal(poDetails.transport_gst) || ""}%`,
      // },
      // {
      //   label: "Transport Amount (Rs.):",
      //   value: `${twoDecimal(poDetails.transport_charge) + "/-" || ""}`,
      // },
      {
        label: "Total Amount with GST (Rs.):",
        value: `${twoDecimal(poDetails.total_with_gst) + "/-" || ""}`,
      },
      {
        label: "Rounded Off Value (Rs.):",
        value: `${calculateRoundingDifference(poDetails.total_with_gst)}/-`,
      },
      {
        label: "Total Payable Amount (Rs.):",
        value: `${roundUpToInteger(poDetails.total_with_gst)}/-`,
      },
    ].filter((detail) => detail !== null); // Remove null entries for non-applicable GST types

    // Adjust X positions if needed based on IGST or CGST/SGST
    const totalXPositions = [
      tableXPositions[4] + 10, // Label position for the first column
      tableXPositions[8] + 90, // Value position for the first column
    ];

    const rightAlignDetailsText = (text, xPosition, yPosition, size, font) => {
      const textWidth = font.widthOfTextAtSize(text, size);
      drawText(text, xPosition - textWidth, yPosition, size, font);
    };

    totalAmountDetails.forEach((detail, index) => {
      // Determine if the current item is the last one
      const isLastIndex = index === totalAmountDetails.length - 1;

      drawText(
        detail.label,
        totalXPositions[0],
        totalSectionStartY - 20 - index * 20,
        smallBoldFontSize,
        isLastIndex ? boldFont : boldFont // Change to regularFont if necessary
      );
      rightAlignDetailsText(
        detail.value.toString(),
        totalXPositions[1],
        totalSectionStartY - 20 - index * 20,
        smallBoldFontSize,
        isLastIndex ? boldFont : font // Apply bold font only to the last value
      );
    });

    currentY = totalSectionStartY - 140;

    // Terms and Conditions
    checkAndAddNewPage(200);
    // drawLine(
    //   { x: 0, y: currentY },
    //   { x: width, y: currentY },
    //   { thickness: 2, color: rgb(0, 0, 0) }
    // );
    currentY -= 30;
    function stripHtml(html) {
      let div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    }

    const plainTextDescription = poDetails.invoice_terms_description
      ? stripHtml(poDetails.invoice_terms_description) || "-"
      : "-";

    drawText("Terms and Conditions:", margin, currentY, boldFontSize, boldFont);
    currentY -= 20;
    drawText(`${plainTextDescription}`, margin, currentY - 10, fontSize, font, {
      maxWidth: width - 2 * margin,
    });

    currentY -= 20 * plainTextDescription.split("\n").length;

    drawText(
      "TOTAL INVOICE VALUE (IN WORDS)",
      margin,
      currentY - 20,
      largeFontSize,
      boldFont
    );

    drawText(
      `${handleNumberToWOrdCurrency(poDetails.total_with_gst)}`,
      margin,
      currentY - 40,
      fontSize,
      font,
      { maxWidth: width - 2 * margin }
    );
    const totalWordsYPosition = currentY - 60;

    drawText(`Proprietor:`, margin, currentY - 70, largeFontSize, boldFont, {
      maxWidth: width - 2 * margin,
    });

    drawText(
      `Please make all cheques payable to ${poDetails.company_name}`,
      margin,
      currentY - 85,
      fontSize,
      font,
      { maxWidth: width - 2 * margin }
    );

    drawText(
      `GST REGN NO: ${poDetails.gst_no}`,
      margin,
      currentY - 105,
      fontSize,
      font,
      { maxWidth: width - 2 * margin }
    );

    drawText(
      `PAN: ${poDetails.pan_no}`,
      margin,
      currentY - 125,
      fontSize,
      font,
      { maxWidth: width - 2 * margin }
    );
    console.log(currentY);
    if (currentY > 150 && currentY < 400) {
      addPage();
      drawText(`Bank Details:`, margin, currentY, largeFontSize, boldFont, {
        maxWidth: width - 2 * margin,
      });

      drawText(
        `Bank Name: ${poDetails.bank_name}`,
        margin,
        currentY - 20,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
      drawText(
        `Branch: ${poDetails.branch}`,
        margin,
        currentY - 35,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
      drawText(
        `Account Number: ${poDetails.account_number}`,
        margin,
        currentY - 50,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
      drawText(
        `Account Name: ${poDetails.account_name}`,
        margin,
        currentY - 65,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
      drawText(
        `IFSC code: ${poDetails.ifsc_code}`,
        margin,
        currentY - 80,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
    } else {
      drawText(
        `Bank Details:`,
        margin,
        currentY - 155,
        largeFontSize,
        boldFont,
        {
          maxWidth: width - 2 * margin,
        }
      );
      drawText(
        `Bank Name: ${poDetails.bank_name}`,
        margin,
        currentY - 175,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
      drawText(
        `Branch: ${poDetails.branch}`,
        margin,
        currentY - 190,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
      drawText(
        `Account Number: ${poDetails.account_number}`,
        margin,
        currentY - 205,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
      drawText(
        `Account Name: ${poDetails.account_name}`,
        margin,
        currentY - 220,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
      drawText(
        `IFSC code: ${poDetails.ifsc_code}`,
        margin,
        currentY - 235,
        fontSize,
        font,
        { maxWidth: width - 2 * margin }
      );
    }

    if (currentY < margin + 20) {
      page = addPage();
      currentY = height - headerFooterMargin;
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(blob, `Invoice_${poDetails.invoice_no}_${moment().format("DD-MM-YYYY hh:mm a")}.pdf`);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SimCardDownloadRoundedIcon />}
        onClick={generatePDF}
        sx={{
          backgroundColor: "#2196f3",
          fontWeight: "bold",
          transition: "background-color 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            backgroundColor: "#FFF",
            color: "#2196f3",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        Generate PDF
      </Button>
    </div>
  );
};

export default PurchaseOrderPDF;

// import { PDFDocument, rgb } from 'pdf-lib';

// export const generatePDF = async (poDetails, paymentList) => {
//   const pdfDoc = await PDFDocument.create();
//   const page = pdfDoc.addPage();
//   const { width, height } = page.getSize();
//   const fontSize = 12;

//   // Header
//   page.drawText(
//     poDetails.order_type === '1' ? 'Purchase Order' : 'Work Order',
//     {
//       x: 50,
//       y: height - 50,
//       size: 24,
//       color: rgb(0, 0.4, 0.8),
//     }
//   );

//   // Purchase Order Info
//   page.drawText(`Purchase Order No: ${poDetails.po_no}`, {
//     x: 50,
//     y: height - 100,
//     size: fontSize,
//   });

//   // Company Info
//   page.drawText(`${poDetails.company_Name}`, {
//     x: 400,
//     y: height - 100,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_address}`, {
//     x: 400,
//     y: height - 120,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_city} - ${poDetails.company_pincode}`, {
//     x: 400,
//     y: height - 140,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_state}, India`, {
//     x: 400,
//     y: height - 160,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_primary_contact} / ${poDetails.company_secondary_contact}`, {
//     x: 400,
//     y: height - 180,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_emailId}`, {
//     x: 400,
//     y: height - 200,
//     size: fontSize,
//   });

//   page.drawText(`${poDetails.company_website}`, {
//     x: 400,
//     y: height - 220,
//     size: fontSize,
//   });

//   // Vendor Info
//   page.drawText(`Vendor: ${poDetails.vendor_name}`, {
//     x: 50,
//     y: height - 250,
//     size: fontSize,
//   });

//   // Add more static text elements as necessary

//   // Table Headers
//   const headers = [
//     'S.No', 'Description of Goods', 'Quantity (nos.)', 'Amount (rs)',
//     'Total Amount (rs)', 'GST', poDetails.total_cgst == 0 && poDetails.total_sgst == 0 ? 'IGST' : 'CGST',
//     poDetails.total_cgst == 0 && poDetails.total_sgst == 0 ? '' : 'SGST', 'Total Amount + GST (INR)'
//   ];

//   let startY = height - 300;
//   let startX = 50;
//   let cellHeight = 20;
//   let cellPadding = 5;

//   headers.forEach((header, index) => {
//     if (header) {
//       page.drawText(header, { x: startX + (index * 70), y: startY, size: 10, color: rgb(0, 0, 0) });
//     }
//   });

//   // Table Rows
//   paymentList.forEach((row, rowIndex) => {
//     const y = startY - ((rowIndex + 1) * cellHeight);
//     const cells = [
//       (rowIndex + 1).toString(), row.item_name, `${twoDecimal(row.quantity)} ${row.unit}`,
//       twoDecimal(row.amount), twoDecimal(row.total_amount_without_gst), `${row.gst}%`,
//       row.igst != 0 ? twoDecimal(row.igst) : twoDecimal(row.cgst), row.igst != 0 ? '' : twoDecimal(row.sgst),
//       twoDecimal(row.total_amount_with_gst)
//     ];

//     cells.forEach((cell, cellIndex) => {
//       if (cell) {
//         page.drawText(cell, { x: startX + (cellIndex * 70), y: y - cellPadding, size: 10, color: rgb(0, 0, 0) });
//       }
//     });
//   });

//   // Add footer and other static text elements as necessary

//   const pdfBytes = await pdfDoc.save();
//   downloadPDF(pdfBytes);
// };

// const downloadPDF = (pdfBytes) => {
//   const blob = new Blob([pdfBytes], { type: 'application/pdf' });
//   const url = URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.href = url;
//   a.download = 'purchase_order.pdf';
//   a.click();
//   URL.revokeObjectURL(url);
// };

// // Helper function to format numbers with two decimals
// const twoDecimal = (num) => {
//   return num.toFixed(2);
// };