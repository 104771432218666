import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Grid,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import Layout from "../../../layout/Layout";
import { useNavigate } from "react-router-dom";
import { config, handleHrMasterAPI, hrMasterListAPI } from "../../../config";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import { MaterialReactTable } from "material-react-table";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useWindowSize } from "../../../hooks/useWindowSize";

let field = "id",
  orderBy = "desc";

function ListEmployee() {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const [HrMasterList, setHrMasterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]);

  const fetchUsers = async () => {
    setLoading(true);
    const data = {
      pageIndex,
      pageSize,
      filters,
      sorting,
    };
    try {
      const response = await axios.post(hrMasterListAPI, data);
      setHrMasterList(response.data.result || []);
      setTotalCount(response.data.totalCount || 0);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [pageIndex, pageSize, filters, sorting]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "employee_no",
        header: "Emp. No",
        enableSorting: true,
        enableFiltering: true,
        size: 100,
      },
      {
        accessorKey: "photo",
        header: "Profile Image",
        enableSorting: false,
        enableColumnFilter: false,
        size: 80,
        muiTableBodyCellProps: {
          sx: {
            textAlign: "center",
            padding: "3px",
            border: "1px solid #ccc",
          },
        },
        Cell: ({ cell }) => {
          const [open, setOpen] = useState(false);
          const photoUrl = cell.getValue();

          const handleClickOpen = () => {
            setOpen(true);
          };

          const handleClose = () => {
            setOpen(false);
          };

          return (
            <>
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  cursor: "pointer",
                }}
                onClick={handleClickOpen} // Open the dialog on click
              >
                <img
                  src={photoUrl}
                  alt="Employee"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>

              <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <img
                    src={photoUrl}
                    alt="Employee"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "80vh", // Limit the height for better visibility
                      objectFit: "contain",
                    }}
                  />
                </DialogContent>
              </Dialog>
            </>
          );
        },
      },
      {
        accessorKey: "employee_name",
        header: "Name",
        enableSorting: true,
        enableFiltering: true,
        size: 200,
      },
      {
        accessorKey: "company_name",
        header: "Company",
        enableSorting: false,
        enableFiltering: true,
        filterVariant: "select",
        filterSelectOptions: [
          {
            label: "K.G Build Tech",
            title: "K.G Build Tech",
            value: 20,
          },
          {
            label: "K.G Constructions",
            title: "K.G Constructions",
            value: 21,
          },
          {
            label: "K.G Furn Tech",
            title: "K.G Furn Tech",
            value: 22,
          },
          {
            label: "K.G TradeTech",
            title: "K.G TradeTech",
            value: 23,
          },
        ],
        size: 150,
      },
      {
        accessorKey: "position_name",
        header: "Position",
        enableSorting: true,
        enableFiltering: true,
        // size: 150,
      },
      {
        accessorKey: "pin",
        header: "Login Pin",
        enableSorting: true,
        enableFiltering: true,
        muiTableBodyCellProps: {
          sx: {
            textAlign: "center",
            border: "1px solid #ccc",
          },
        },
        muiTableHeadCellProps: {
          sx: {
            textAlign: "center",
          },
        },
        Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "-"),
        size: 90,
      },
      {
        accessorKey: "phone",
        header: "Contact",
        enableSorting: true,
        enableFiltering: true,
        size: 100,
      },
      {
        accessorKey: "present_employee",
        header: "Status",
        Cell: ({ cell }) => {
          const status = cell.getValue();
          const statusText = status === 3 ? "Inactive" : "Active";

          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                // className={status === 3 ? "inactive-status" : "active-status"}
                sx={{
                  fontSize: "14px",
                  width: "7rem",
                  textAlign: "center",
                  backgroundColor: status === 3 ? "#fae0e4" : "#d8f3dc",
                  color: status === 3 ? "#ff0a54" : "#006400",
                  fontWeight: "bold",
                  padding: "2px 1px",
                  borderRadius: "5px",
                }}
              >
                {statusText}
              </Typography>
            </div>
          );
        },
        filterVariant: "select",
        filterSelectOptions: [
          {
            label: "Active",
            title: "Active",
            value: 2,
          },
          {
            label: "Inactive",
            title: "Inactive",
            value: 3,
          },
        ],
        size: 100,
      },
      {
        accessorKey: "reason_for_relieve",
        header: "Reason",
        Cell: ({ cell }) => (
          <Typography
            // className={cell.getValue() ? "inactive-status" : "active-status"}
            style={{
              fontSize: "14px",
              width: "7rem",
              textAlign: "center",
              color: cell.getValue() ? "#ff0a54" : "#006400",
              fontWeight: "bold",
            }}
          >
            {cell.getValue() || "Present"}
          </Typography>
        ),
        filterVariant: "select",
        filterSelectOptions: [
          {
            label: "Active",
            title: "Active",
            value: "Active",
          },
          {
            label: "Relieved",
            title: "Relieved",
            value: "Relieved",
          },
          {
            label: "Terminated",
            title: "Terminated",
            value: "Terminated",
          },
        ],
        size: 100,
      },
      {
        accessorKey: "ID",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <IconButton
            color="warning"
            onClick={() => navigate(`/employee/view/${row.original.ID}`)}
          >
            <EditLocationOutlinedIcon />
          </IconButton>
        ),
        size: 50,
      },
    ],
    [navigate]
  );

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} lg={6} md={6}>
            <Typography className="page_title">List Employee</Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">List Employee</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} lg={6} md={6} textAlign="right">
            <LoadingButton
              size="medium"
              variant="contained"
              color="success"
              href="/employee/add"
              startIcon={<AddIcon />}
              sx={{ mr: 2 }}
            >
              Add Profile
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>

        <MaterialReactTable
          columns={columns}
          data={HrMasterList}
          enableGlobalFilter={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          className="mat-react-tbl"
          state={{
            isLoading: loading,
            pagination: { pageIndex, pageSize },
            sorting,
            columnFilters: filters,
          }}
          manualPagination
          initialState={{
            columnPinning: lgUp ? { right: ["present_employee", "ID"] } : {},
          }}
          manualSorting
          manualFiltering
          rowCount={totalCount}
          onPaginationChange={(updater) => {
            const paginationState = updater({
              pageIndex,
              pageSize,
            });

            console.log("Pagination change:", paginationState);

            const { pageIndex: newPageIndex, pageSize: newPageSize } =
              paginationState;
            setPageIndex(newPageIndex);
            setPageSize(newPageSize);
          }}
          onSortingChange={setSorting}
          onColumnFiltersChange={setFilters}
          muiTableBodyCellProps={{
            sx: {
              padding: "3px 8px",
              border: "1px solid #ccc",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              padding: "8px",
              border: "1px solid #ccc",
              color: "#fff !important",
              fontWeight: "bold",
              "& svg": {
                color: "#fff",
              },
              "& .MuiTableSortLabel-root": {
                color: "#fff !important",
                "&.Mui-active": {
                  color: "#fff !important",
                  "& .MuiTableSortLabel-icon": {
                    color: "#fff !important",
                  },
                },
              },
              "& .MuiTableSortLabel-icon": {
                color: "#fff !important",
              },
            },
          }}
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              borderBottom: "1px solid #ccc",
              backgroundColor: row.index % 2 === 0 ? "#ffffff" : "#e3f2fd",
            },
          })}
          muiTableHeadCellFilterTextFieldProps={{
            sx: {
              "& input": {
                color: "#fff !important", // Change text color
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "#fff !important", // Change underline color
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#fff !important", // Hover underline color
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#fff !important", // Focused underline color
              },
            },
          }}
        />
      </Grid>
    </Layout>
  );
}

export default ListEmployee;
