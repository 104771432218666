import React, { useEffect, useRef, useState } from "react";
//design imports from material
import { Grid, TextField, Typography, Button } from "@mui/material";
//layout import
import Layout from "../../layout/Layout";
//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { AlertMessage } from "../../components/AlertMessage";
import { Container } from "@mui/system";
import { config, LabourAttandenceAPI, userData } from "../../config";

function LabourAttandence() {
  const validationSchema = Yup.object().shape({
    // intNatureofExpense: Yup.string().required("Nature of Expense is required"),
    // txtAmount: Yup.string().required("Amount is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;
  const childRef = useRef();

  const [labourDetails, setLabourDetails] = useState([]);
  const [date, setDate] = useState([]);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 3,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = LabourAttandenceAPI;

      axios(config)
        .then(function (response) {
          setLabourDetails(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  function onSubmit(data) {
    data.intType = 1;
    data.intEmployeeID = userData.emp_id;
    data.txtDate = "";
    data.intNoOfLaboursFullDay = data.intNoOfLaboursFullDay;

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = LabourAttandenceAPI;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <form className="form_mobile" onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} lg={12} md={12}>
            <Grid className="employee-form-struct">
              <Grid className="login-text-background">
                <Typography variant="h5" textAlign="center">
                  Labour Attendance
                </Typography>
              </Grid>
              <Container>
                <Grid container spacing={2} justifyContent={`center`}>
                  {labourDetails.map((row, index) => (
                    <>
                      <Grid item className="form-lable" xs={4} lg={4} md={4}>
                        <h4>{row.name}</h4>
                        <TextField
                          type="text"
                          fullWidth
                          error={errors.intLabourType ? true : false}
                          {...register("intLabourType")}
                          helperText={errors.intLabourType?.message}
                        />
                      </Grid>
                      <Grid item className="form-lable" xs={4} lg={4} md={4}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Full"
                          error={errors.intNoOfLaboursFullDay ? true : false}
                          {...register("intNoOfLaboursFullDay")}
                          helperText={errors.intNoOfLaboursFullDay?.message}
                        />
                      </Grid>
                      <Grid item className="form-lable" xs={4} lg={4} md={4}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Half"
                          error={errors.intNoOfLaboursHalfDay ? true : false}
                          {...register("intNoOfLaboursHalfDay")}
                          helperText={errors.intNoOfLaboursHalfDay?.message}
                        />
                      </Grid>
                    </>
                  ))}

                  <Grid
                    item
                    className="form-lable"
                    xs={12}
                    lg={6}
                    md={6}
                    marginTop="20px"
                    textAlign="right"
                  >
                    <Button
                      style={{ margin: "10px" }}
                      className={`btn-primary`}
                      type="submit"
                    >
                      Submit Attendance
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
}

export default LabourAttandence;
