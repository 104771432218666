import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState, useRef } from "react";
import Header from "./Header";

import { Link } from "react-router-dom";

import LogoutIcon from "@mui/icons-material/Logout";
import companyLogo from "../assets/images/Logo/logo-only-kg.jpg";

import {
  Close as CloseIcon,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";
import "../assets/css/style.css";
import Sidenav, { SideNav } from "../layout/Sidenav";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { handleLogout } from "../commonFunctions/logout";

function Layout(props) {
  const navigate = useNavigate();

  // const handleLogout = () => {
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("projectID");
  //   navigate("/");
  // };

  const [menuOpenedFlag, setMenuOpenedFlag] = useState(false);
  const showMenuIcon = () => {
    setMenuOpenedFlag(!menuOpenedFlag);
  };
  const activeUrl = window.location.pathname;
  const userData = JSON.parse(localStorage.getItem("user"));

  return (
    <Box>
      <Grid container>
        <Grid className={`sidenav ${menuOpenedFlag ? `open` : ``}`}>
          <Grid textAlign={`right`}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={showMenuIcon}
              color="inherit"
              className="btn"
            >
              <CloseIcon sx={{ color: `white` }} />
            </IconButton>
          </Grid>
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <Box
                sx={{
                  minHeight: 48,
                  px: 2.5,
                  py: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  // backgroundColor: "#e9ecef",
                  borderRadius: "15px",
                }}
              >
                <img
                  src={companyLogo}
                  alt="KG"
                  width={`150px`}
                  height={`auto`}
                />
                {/* <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", color: "#0C53A0" }}
                >
                  KG
                </Typography> */}
              </Box>

              {/* {userData.role_id === 35 || userData.role_id === 34 ? (
                <>
                  <Grid className="left-menu">
                    <Grid textAlign="left" item xs={6} lg={12} md={12}>
                      <Typography>Project Name : {"true" === localStorage.getItem("isProjectLoggedIn") ? (
                        <>{localStorage.getItem("projectName")}</>
                      ) : (
                        <>-</>
                      )}</Typography>
                    </Grid>
                    <Grid textAlign="left" item xs={6} lg={12} md={12}>
                      <Typography>Check-In Time :   {"true" === localStorage.getItem("isProjectLoggedIn") ? (
                        <>{localStorage.getItem("projectCheckInTime")}</>
                      ) : (
                        <>-</>
                      )}</Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )} */}
              <Grid className="left-menu">
                <Sidenav />
              </Grid>
              <Link onClick={handleLogout} className={`logout-btn`}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    px: 2.5,
                    display: "flex",
                    gap: "15px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                    }}
                  >
                    <PowerSettingsNewIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
        </Grid>
        <Grid className={`topnav ${!menuOpenedFlag ? "full-width" : ""}`}>
          <Header menuAction={showMenuIcon} menuOpenedFlag={menuOpenedFlag} />
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Layout;
