import axios from "axios";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { config, dashboardAPI } from "../../config";
import Grid from "@material-ui/core/Grid";

function AttendanceStatus(props) {
  const [txtType, setType] = useState(props.type);

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    setType(props.type);
    let requestData = {
      intType: 2,
      txtFromDate: props.fromDate,
      txtToDate: props.toDate,
      intID: props.companyID,
    };

    config.method = "POST";
    config.data = JSON.stringify(requestData);
    config.url = dashboardAPI;
    axios(config)
      .then(function (response) {
        const dataPoint1 = response.data.result;

        console.log("🚀💻 ~ file: Attendance.jsx:29 ~ dataPoint1:", dataPoint1);

        const series1 = dataPoint1.map((item) => [
          item.pending_count && item.pending_count != null
            ? parseFloat(item.pending_count).toFixed(2)
            : 0,
          item.approved_count && item.pending_count != null
            ? parseFloat(item.approved_count).toFixed(2)
            : 0,
          item.completed_count && item.pending_count != null
            ? parseFloat(item.completed_count).toFixed(2)
            : 0,
          item.declined_count && item.pending_count != null
            ? parseFloat(item.declined_count).toFixed(2)
            : 0,
        ]);
        setSeries(series1.flat());
        const colors1 = ["#fdc74c", "#abc445", "#00a76d", "#d05359"];
        const labels1 = ["Pending ", "Approved ", "Completed ", "Declined "];

        setOptions({
          chart: {
            type: "radialBar",
            height: 200,
            offsetX: 0,
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                },
                value: {
                  fontSize: "16px",
                },
                total: {
                  show: true,
                  label: "Total",
                  formatter: function (w) {
                    return dataPoint1[0].count;
                  },
                },
              },
              inverseOrder: false,
              hollow: {
                margin: 25,
                size: "40%",
              },
            },
          },
          series: series1,
          labels: labels1,
          colors: colors1,
          stroke: {
            lineCap: "round",
          },
          title: {
            text: "Payment Request Status (" + txtType + ")",
            align: "left",
          },
          legend: {
            show: true,
            position: "bottom",
            offsetX: -30,
            offsetY: -10,
            formatter: function (val, opts) {
              return (
                val + " - " + opts.w.globals.series[opts.seriesIndex] + "%"
              );
            },
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props]);

  return (
    <div id="chart">
      <Grid item xs={12}>
        <ReactApexChart
          options={options}
          series={series}
          type="radialBar"
          width={450}
        />
      </Grid>
    </div>
  );
}

export default AttendanceStatus;
