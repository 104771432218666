import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import Layout from "../../../layout/Layout";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";

// FORM VALIDATION IMPORTS
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";

// // FORM POPUP
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";

import { AlertMessage } from "../../../components/AlertMessage";

import {
  advancePaymentAPI,
  config,
  advancePaymentDetailsAPI,
} from "../../../config";
import { Navigate, useNavigate, useParams } from "react-router-dom";

// table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

let defaultValues = {};

function AdvancePaymentView() {
  const navigate = useNavigate();
  const { paymentid } = useParams();
  const validationSchema = Yup.object().shape({
    intEmiAmount: Yup.number()
      .integer()
      .typeError("Please Enter Valid Amount")
      .positive("Please Enter Valid Amount"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  var lastDay = new Date(y, m + 1, 0);
  // const [endDate, setEndDate] = React.useState(dayjs(lastDay).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = React.useState("2023-07-31");

  let hrMasterDetailsRef = useRef(false);
  useEffect(function () {
    if (hrMasterDetailsRef.current === false) {
      const data = JSON.stringify({
        intType: 3,
        intAdvancePaymentID: paymentid,
      });
      config.method = "POST";
      config.data = data;
      config.url = advancePaymentAPI;

      axios(config)
        .then(function (response) {
          const responseTempData = response.data.result[0];
          handleFormReset(responseTempData);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        hrMasterDetailsRef.current = true;
      };
    }
  }, []);

  //Get paid Installment Details
  const paidInstallmentRef = useRef(false);
  const [paidInstallments, setPaidInstallments] = useState([]);
  const [editFlag, seteditFlag] = useState(false);
  const [paidInstallmentsCount, setPaidInstallmentsCount] = useState(0);

  useEffect(
    function () {
      if (paidInstallmentRef.current === false) {
        const data = JSON.stringify({
          intAdvancePaymentID: paymentid,
          intType: 2,
        });
        config.method = "POST";
        config.data = data;
        config.url = advancePaymentDetailsAPI;

        axios(config)
          .then(function (response) {
            setPaidInstallments(response.data.result);
            setPaidInstallmentsCount(response.data.result.length);
            response.data.result.map((row, index) => {
              if (row.due_date == endDate || row.balance_amount == 0) {
                seteditFlag(true);
              } else {
                seteditFlag(false);
              }
            });
          })

          .catch(function (error) {
            console.log(error);
          });

        return () => {
          paidInstallmentRef.current = true;
        };
      }
    },

    []
  );

  const handleFormReset = (data) => {
    defaultValues.intEmployeeNo = data.employee_no;
    defaultValues.txtEmployeeID = data.employee_id;
    defaultValues.intAmount = data.amount;
    defaultValues.intEmiAmount =
      data.emi_amount > data.balance_amount
        ? data.balance_amount
        : data.emi_amount;
    defaultValues.txtDateOfAdvance = data.start_date;
    defaultValues.intBalanceAmount = data.balance_amount;
    defaultValues.txtEmployeeName = data.username;
    defaultValues.txtPaymentType = data.payment_type;
    defaultValues.txtNoOfInstallments = data.no_of_installments;
    defaultValues.txtDueDate = endDate;
    defaultValues.txtCreatedBy = 15;
    defaultValues.txtRepayDate = data.date_of_repay;
    reset({ ...defaultValues });
  };

  //Popup
  const [open, setOpen] = React.useState(false);
  const [openFileds, setOpenFileds] = React.useState(false);
  const handleprofileClick = () => {
    setOpen(true);
  };

  const handleOpenFields = () => {
    setOpenFileds(true);

    const data1 = JSON.stringify({
      intType: 2,
    });
    config.method = "POST";
    config.data = data1;
    config.url = advancePaymentDetailsAPI;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
        handleFormReset();
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: error.data.message,
            messageType: "error",
          })
        );
      });

    setOpen(false);
  };

  const childRef = useRef();

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className={`details-section`}>
        <Grid row>
          <Grid container className="profile-edit" spacing={3}>
            <Grid item xs={12} lg={6} md={6} alignItems="center">
              <Grid container spacing={3}>
                <Grid
                  item
                  alignItems="center"
                  marginTop={`auto`}
                  marginBottom={`auto`}
                >
                  <Typography className={`title-text`}>
                    {defaultValues.txtName}
                  </Typography>
                  <Typography className={`details-text`}>
                    {defaultValues.txtPosition}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} md={6} alignItems="center">
              <Grid container className="align-editbutton" spacing={3}>
                <Button
                  className="align-cancelbutton"
                  variant="contained"
                  endIcon={<EditIcon />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            md={12}
            className="advance_pay"
            spacing={5}
          >
            <Typography textAlign="center" className={`sub-title-text`}>
              Advance Payment
            </Typography>
            <div className="empty-space-40"></div>
            <Grid container>
              <Grid item xs={12} lg={5} md={5} className="advance_paymenttable">
                <Typography variant="h6" textAlign="left">
                  Employee Details
                </Typography>
                <hr width="50px" className="hr_tag"></hr>
                <div className="empty-space-20"></div>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    Employee No: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp; {defaultValues.intEmployeeNo}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    Emplayee Name: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp; {defaultValues.txtEmployeeName}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    No of Installments: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp;{defaultValues.txtPaymentType}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    No of Installments: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp;{defaultValues.txtNoOfInstallments}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    Payment Start Date: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp;{defaultValues.txtDateOfAdvance}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    Payment End date: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp;{defaultValues.txtRepayDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={5} md={5} className="advance_paymenttable">
                <Typography variant="h6" textAlign="left">
                  Advance Payment Details
                </Typography>
                <hr width="50px" className="hr_tag"></hr>
                <div className="empty-space-20"></div>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    Total Amount: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp;{defaultValues.intAmount}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    Balance Amount: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp;{defaultValues.intBalanceAmount}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="span" className={`profile-title`}>
                    Scheduled Installment Amount: &nbsp;
                  </Typography>
                  <Typography variant="span" className={`details-text`}>
                    &nbsp;{defaultValues.intEmiAmount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid>
            <div className="empty-space-40"></div>
            <Grid className="employee-form-struct" spacing={3}>
              <Typography textAlign="center" className={`sub-title-text`}>
                Installment Details
              </Typography>
              <div className="empty-space-40"></div>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Layout>
  );
}

export default AdvancePaymentView;
