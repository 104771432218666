import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import Layout from "../../layout/Layout";
import NotFound from "../../components/NotFount";
import { Create } from "@mui/icons-material";
import { config, paymentRequestApprovalAPI } from "../../config";
const Pagination = lazy(() => import("../../components/ReactPagination"));

function PaymentRequestApproval() {
  let intOffset = 0;
  const limit = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [departmentMasterList, setdepartmentMasterList] = useState([]);

  const handleReportPagination = (e) => {
    intOffset =
      e.target.innerText === "1"
        ? 0
        : (parseInt(e.target.innerText) - 1) * limit;
  };

  let assetsRef = useRef(false);
  const childRef = useRef();

  useEffect(function () {
    if (assetsRef.current === false) {
      fetchData(1);
      return () => {
        assetsRef.current = true;
      };
    }
  }, []);

  //   const handleEditdepartments = (DepartmentID, DepartmentName, status) => {
  //     childRef.current.setData(
  //       JSON.stringify({
  //         departmentID: DepartmentID,
  //         txtDepartmentName: DepartmentName,
  //         status: status,
  //         intType: 3,
  //       })
  //     );
  //   };

  const fetchData = (action) => {
    if (action === 1) {
      intOffset = 0;
    }

    const data = JSON.stringify({
      intType: 2,
    });
    config.method = "POST";
    config.data = data;
    // config.url = paymentRequestApprovalAPI;

    axios(config)
      .then(function (response) {
        setTotalCount(Math.round(response.data.totalCount / limit));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Layout>
      <Grid
        container
        spacing={2}
        margin={`15px 0px`}
        padding={`10px 15px 0px 0px`}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          textAlign={`right`}
          display={`flex`}
          justifyContent={`flex-end`}
        >
          <Suspense fallback={<div></div>}>
            <Pagination
              totalCount={totalCount}
              handleReportPagination={handleReportPagination}
            />
          </Suspense>
        </Grid>
      </Grid>

      <div className={`table-responsive`}>
        <table className={`table`}>
          <thead>
            <th>
              <Typography variant="h6">S.No</Typography>
            </th>
            <th>
              <Typography variant="h6">Department Name</Typography>
            </th>
            <th>
              <Typography variant="h6">Status </Typography>
            </th>
            <th>
              <Typography variant="h6">Action </Typography>
            </th>
          </thead>
          <tbody>
            {departmentMasterList.length > 0 ? (
              departmentMasterList.map((row, index) => {
                return (
                  <tr key={index}>
                    <td className={`text-center`}>
                      <Typography>{index + 1}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography>{row.department_name}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography
                        className={`${
                          row.status === 2 ? `active-status` : `inactive-status`
                        } `}
                      >
                        {row.status === 2 ? `Active` : `Inactive`}
                      </Typography>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className={`text-center`}>
                  <NotFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default PaymentRequestApproval;
