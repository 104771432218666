import React, { useEffect, useRef, useState } from "react";
//design imports from material
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
//layout import
import Layout from "../../../layout/Layout";

//form imports
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import StateList, { stateList } from "../../../components/StateList";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

//date picker

import axios from "axios";
import {
  config,
  phoneRegExp,
  pincodeRegExp,
  _numberOnly,
  ifscCodeRegExp,
  panCodeRegExp,
  gstNumberRegExp,
  statusMasterAPI,
  vendorAPI,
} from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import { _getDropdownListData } from "../../../service";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function VendorCreate() {
  const navigate = useNavigate();

  const [lstCompany, setCompanyList] = useState([]),
    [lstVendorType, setVendorTypeList] = useState([]);
  const childRef = useRef();

  const [companyName, setCompanyName] = React.useState("");

  const [stateName, setStateName] = React.useState("");

  const validationSchema = Yup.object().shape({
    // intCompanyID: Yup.string().required("Project Name is required"),
    // txtMobileNo: Yup.string().matches(phoneRegExp, "Mobile No is not valid"),
    // intVendorType: Yup.string().required("Project Name is required"),
    txtName: Yup.string()
      .required("Project Name is required")
      .min(3, "Project Name must be at least 3 characters")
      .max(100, "Project Name cannot be more than 100 characters"),
    txtContactPerson: Yup.string()
      .required("Contact Person is required")
      .min(3, "Contact Person must be at least 3 characters")
      .max(100, "Contact Person cannot be more than 100 characters"),
    txtStreetAddress: Yup.string()
      .required("Street Address is required")
      .min(3, "Street Address must be at least 3 characters")
      .max(100, "Street Address cannot be more than 100 characters"),
    txtCity: Yup.string()
      .required("Town / City is required")
      .min(3, "Town / City must be at least 3 characters")
      .max(100, "Town / City cannot be more than 100 characters"),
    txtState: Yup.string()
      .required("State is required")
      .min(3, "State must be at least 3 characters")
      .max(100, "State cannot be more than 100 characters"),
    txtPincode: Yup.string()
      .required("Pincode issdfg345 required")
      .min(6, "Pincode must be at least 6 digits")
      .max(6, "Pincode cannot be more than 6 digits")
      .matches(pincodeRegExp, "Please enter a valid Pincode"),
    // txtBankName: Yup.string().required("Bank Name is required"),
    // txtIFSCCode: Yup.string()
    //   .required("IFSC Code is required")
    //   .min(11, "IFSC Code must be 11 digits")
    //   .max(11, "IFSC Code must be 11 digits")
    //   .matches(ifscCodeRegExp, "Please enter a valid IFSC Code"),
    // txtPan: Yup.string()
    //   .required("PAN Number is required")
    //   .matches(panCodeRegExp, "Please enter a valid PAN Number"),
    // txtGST: Yup.string()
    //   .required("PAN Number is required")
    //   .matches(gstNumberRegExp, "Please enter a valid GST Number"),
    // txtBranch: Yup.string().required("Branch Name is required"),
    // txtAccountNo: Yup.string().required("Account Number is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    data.intType = 1;
    data.intCompanyID = companyName;
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = vendorAPI;

    axios(config)
      .then(function (response) {
        const messageType = response.data.code === 500 ? "warning" : "success";
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: messageType,
          })
        );
        if (messageType == "success") {
          setTimeout(function () {
            navigate("/vendor/list");
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //

  let tempRefs = useRef(false);

  useEffect(() => {
    if (tempRefs.current === false) {
      // let requestData = {
      //   intType: 2,
      //   intParentId: 38,
      // };

      let data = {
        requestData: {
          intType: 2,
          intParentId: 38,
        },
        API: statusMasterAPI,
        setData: setVendorTypeList,
      };
      _getDropdownListData(data);

      // requestData = {
      //   intType: 2,
      //   intParentId: 42,
      // };
      data = {
        requestData: {
          intType: 2,
          intParentId: 42,
        },
        API: statusMasterAPI,
        setData: setCompanyList,
      };

      _getDropdownListData(data);

      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const options = stateList.map((option) => {
    const firstLetter = option.state_name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  return (
    <Layout>
      <Grid className="employee-form">
        <AlertMessage ref={childRef} />
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Vendor Create
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Vendor</Typography>
              <Typography color="text.primary">Create</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="secondary"
              className="button_first"
              href="/vendor/list"
            >
              List Vendor
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12} lg={12} md={12}>
            <Grid container spacing={2}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  label="Vendor Name"
                  fullWidth
                  {...register("txtName")}
                  error={errors.txtName ? true : false}
                  helperText={errors.txtName?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                {/* <FormControl
                  fullWidth
                  error={errors.txtCompanyName ? true : false}
                >
                  <InputLabel id="demo-simple-select-label">Company</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Company"
                    id="demo-simple-select"
                    {...register("intCompanyID")}
                  >
                    {lstCompany.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className="invalid-feedback">
                    {errors.txtCompanyName?.message}
                  </FormHelperText>
                </FormControl> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Company</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companyName}
                    label="Company"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setValue("intCompanyID", e.target.value);
                    }}
                  >
                    {lstCompany.map((row) => (
                      <MenuItem value={row.id} key={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.intCompanyID?.message}
                  </Typography>
                </FormControl>
                {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Company Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={companyName}
                      label="Company Name"
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        setValue("intCompanyID", e.target.value);
                      }}
                    >
                      <MenuItem value={51}>KG BuildTech</MenuItem>
                      <MenuItem value={52}>KG Construction</MenuItem>
                    </Select>
                  </FormControl> */}
              </Grid>
              {/* <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl
                    fullWidth
                    error={errors.intVendorType ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Vendor Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Vendor Type"
                      id="demo-simple-select"
                      {...register("intVendorType")}
                    >
                      {lstVendorType.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="invalid-feedback">
                      {errors.intVendorType?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid> */}

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  label="Mobile No"
                  fullWidth
                  {...register("txtMobileNo")}
                  error={errors.txtMobileNo ? true : false}
                  helperText={errors.txtMobileNo?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  label="Contact Person"
                  fullWidth
                  {...register("txtContactPerson")}
                  error={errors.txtContactPerson ? true : false}
                  helperText={errors.txtContactPerson?.message}
                />
              </Grid>

              {/* <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    label="Contact Person"
                    fullWidth
                    {...register("txtContactPerson")}
                    error={errors.txtContactPerson ? true : false}
                    helperText={errors.txtContactPerson?.message}
                  />
                </Grid> */}

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  label="Street Address"
                  fullWidth
                  {...register("txtStreetAddress")}
                  error={errors.txtStreetAddress ? true : false}
                  helperText={errors.txtStreetAddress?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  label="Town / City"
                  fullWidth
                  {...register("txtCity")}
                  error={errors.txtCity ? true : false}
                  helperText={errors.txtCity?.message}
                />
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stateName}
                    label="State"
                    onChange={(e) => {
                      setStateName(e.target.value);
                      setValue("txtState", e.target.value);
                    }}
                  >
                    {stateList.map((row) => (
                      <MenuItem value={row.state_name}>
                        {row.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  label="Pincode"
                  fullWidth
                  onKeyPress={_numberOnly}
                  {...register("txtPincode")}
                  error={errors.txtPincode ? true : false}
                  helperText={errors.txtPincode?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="PAN Number"
                  type="text"
                  label="PAN Number"
                  fullWidth
                  {...register("txtPan")}
                  error={errors.txtPan ? true : false}
                  helperText={errors.txtPan?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="GST Number"
                  type="text"
                  label="GST Number"
                  fullWidth
                  {...register("txtGST")}
                  error={errors.txtGST ? true : false}
                  helperText={errors.txtGST?.message}
                />
              </Grid>

              <Grid
                textAlign="center"
                item
                className="form-lable"
                xs={12}
                lg={12}
                md={12}
              >
                <h3>Bank Information</h3>
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="bankname"
                  type="text"
                  label="Bank Name"
                  fullWidth
                  {...register("txtBankName")}
                  error={errors.txtBankName ? true : false}
                  helperText={errors.txtBankName?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="accountName"
                  type="text"
                  label="Account Name"
                  fullWidth
                  {...register("txtAccountName")}
                  error={errors.txtAccountName ? true : false}
                  helperText={errors.txtAccountName?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="ifsccode"
                  type="text"
                  label="IFSC Code"
                  fullWidth
                  {...register("txtIFSCCode")}
                  error={errors.txtIFSCCode ? true : false}
                  helperText={errors.txtIFSCCode?.message}
                />
              </Grid>

              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="branch"
                  type="text"
                  label="Bank Branch"
                  fullWidth
                  {...register("txtBranch")}
                  error={errors.txtBranch ? true : false}
                  helperText={errors.txtBranch?.message}
                />
              </Grid>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <TextField
                  name="yearofcompletion"
                  type="text"
                  label="Bank Account Number"
                  fullWidth
                  {...register("txtAccountNo")}
                  error={errors.txtAccountNo ? true : false}
                  helperText={errors.txtAccountNo?.message}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12} textAlign="right">
                <Button
                  variant="primary"
                  className={`btn-primary`}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
}

export default VendorCreate;
