import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import Layout from "../../../layout/Layout";
import { config, purchaseOrderAPI } from "../../../config";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

function PurchaseOrderList() {
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState(0);
  const [clientMasterList, setClientMasterList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderType, setOrderType] = useState(0);
  const [columnName, setColumnName] = useState(0);
  const [sortDirection, setSortDirection] = useState(0);

  const [poTypeList, setPoTypeList] = useState([
    { label: "All", value: 0 },
    { label: "Purchase Order", value: 1 },
    { label: "Work Order", value: 2 },
  ]);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (
    page,
    rowsPerPage,
    columnName = "",
    sortDirection = ""
  ) => {
    // if (tempRefs.current === false) {
    // Set loading to true and clear the existing data
    setLoading(true);
    setClientMasterList([]);
    const data = JSON.stringify({
      txtOrderType: orderType,
      txtColumnName: columnName,
      txtSortDirection: sortDirection,
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      intType: 23,
    });

    console.log(data);

    config.method = "POST";
    config.data = data;
    config.url = purchaseOrderAPI;
    axios(config)
      .then(function (response) {
        console.log(
          "⛔ ➡️ file: PurchaceOrderList.jsx:61 ➡️ response:",
          response.data
        );

        setClientMasterList(response.data.data);
        console.log(response.data.total_count[0].totalCount);
        setTotalCount(response.data.total_count[0].total_count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false); // Set loading to false in case of error
      });
    // }
    // tempRefs.current = true;
  };

  const handleSort = (column, sortDirection) => {
    console.log("handleSort called", column, sortDirection); // Debugging line

    if (column.name) {
      let columnName = column.name.toLowerCase();
      setColumnName(columnName);
      setSortDirection(sortDirection);
      console.log(columnName, sortDirection);
      tempRefs.current = false;

      fetchUsers(1, rowsPerPage, columnName, sortDirection); // Ensure fetchUsers is being called
    }
  };

  // const fetchUsers = async (page, rowsPerPage) => {
  //   if (tempRefs.current === false) {
  //   const data = JSON.stringify({
  //     txtOrderType: orderType,
  //     intLimit: rowsPerPage ? rowsPerPage : 10,
  //     intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
  //     intType: 2,
  //   });

  //   config.method = "POST";
  //   config.data = data;
  //   config.url = purchaseOrderAPI;
  //   axios(config)
  //     .then(function (response) {
  //       console.log(
  //         "⛔ ➡️ file: PurchaceOrderList.jsx:61 ➡️ response:",
  //         response.data
  //       );

  //       setClientMasterList(response.data.result[1]);
  //       setTotalCount(response.data.totalCount);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   }
  //   tempRefs.current = true;
  // };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!clientMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  // const handleSort = (column, sortDirection) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     field = column.field;
  //     orderBy = sortDirection;
  //     fetchUsers(1);
  //     setLoading(false);
  //   }, 100);
  // };

  const handleEditRole = (id) => {
    navigate(`/purchase-order/view/${id}`);
  };
  const handleEditPO = (id) => {
    navigate(`/purchase-order/edit/${id}`);
  };

  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  function findType(invoiceType) {
    if (invoiceType == 1) {
      return "Purchase Order";
    } else if (invoiceType == 2) {
      return "Work Order";
    }
  }
  function roundUpToInteger(value) {
    // Round up to the nearest integer
    const roundedValue = Math.ceil(value);

    // Append ".00" to the rounded value
    const formattedValue = roundedValue.toFixed(2);

    return formattedValue;
  }

  const columns = useMemo(() => [
    {
      name: "PO No",
      field: "id",
      sortable: true,
      selector: (row) => <span>{row.po_no}</span>,
      width: "12%",
    },
    {
      name: "Order Type",
      field: "id",
      sortable: true,
      selector: (row) => <span>{findType(row.order_type)}</span>,
      width: "12%",
    },
    {
      name: "Vendor Name",
      field: "id",
      sortable: true,
      selector: (row) => <span>{row.vendor_name}</span>,
      width: "25%",
    },
    {
      name: "Company Name",
      field: "id",
      sortable: true,
      selector: (row) => <span>{row.company_Name}</span>,
      width: "15%",
    },
    {
      name: "Project Name",
      field: "id",
      sortable: true,
      selector: (row) => <span>{row.project_name}</span>,
      width: "28%",
    },
    // {
    //   name: "Vendor GST",
    //   field: "id",
    //   sortable: true,
    //   selector: (row) => row.vendor_gst,
    // },
    // {
    //   name: "Terms of Payment",
    //   field: "id",
    //   cell: (row) => row.terms_of_payment,
    //   width: "11%",
    // },
    // {
    //   name: "Purchase Classfication",
    //   field: "id",
    //   sortable: true,
    //   selector: (row) => row.purchase_clafication_name,
    // },
    {
      name: "PO Valid Upto",
      field: "id",
      cell: (row) => moment(row.po_validate_upto).format("DD-MM-YYYY"),
      width: "12%",
    },
    {
      name: "Amount (Rs.)",
      field: "id",
      // cell: (row) => twoDecimal(row.total_with_gst),
      cell: (row) => roundUpToInteger(row.total_with_gst),
      width: "12%",
    },
    {
      name: "View",
      field: "id",
      cell: (row) => (
        <IconButton color="success" onClick={() => handleEditRole(row.id)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      name: "Edit",
      field: "id",
      cell: (row) => (
        <IconButton color="warning" onClick={() => handleEditPO(row.id)}>
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);

  const handleClear = async () => {
    orderType != 0 && window.location.reload();
  };

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Purchase Order List
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Purchase Order</Typography>
              <Typography color="text.primary">List</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Order Type"
                    value={orderType}
                    onChange={(e) => setOrderType(e.target.value)}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end" sx={{ mr: 2 }}>
                    //       <IconButton onClick={handleClear}>
                    //         <ClearIcon sx={{ color: "#ff0a54" }} />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  >
                    {poTypeList.map((row, index) => (
                      <MenuItem value={row.value} key={index}>
                        {row.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                spacing={1}
                justifyContent="flex-start"
                alignItems={`center`}
              >
                <Grid item>
                  <LoadingButton
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      tempRefs.current = false;
                      fetchUsers(1);
                    }}
                  >
                    Search
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Add PO and Back buttons container */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  size="medium"
                  variant="contained"
                  color="success"
                  className="button_first"
                  href="/purchase-order/create"
                  startIcon={<AddIcon />}
                >
                  Add PO
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(-1)}
                  startIcon={<ArrowBackIosIcon />}
                >
                  Back
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="form_design">
          <Grid>
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={clientMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 5, 10, 25, 50, 100]}
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default PurchaseOrderList;
