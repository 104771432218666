import React, { useEffect, useRef, useState, useMemo, lazy } from "react";

import { Grid, Tooltip, Typography } from "@mui/material";

import axios from "axios";

import Layout from "../../../layout/Layout";

import { config, departmentMasterAPI } from "../../../config";
import { AddDepartments } from "../../../components/AddDepartment";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { AlertMessage } from "../../../components/AlertMessage";
import { Tooltip as ReactTooltip } from "react-tooltip";

let field = "id",
  orderBy = "desc";

function AddRoleMaster() {
  const navigate = useNavigate();
  let intOffset = 0;
  const limit = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [departmentMasterList, setdepartmentMasterList] = useState([]);

  const handleReportPagination = (e) => {
    intOffset =
      e.target.innerText === "1"
        ? 0
        : (parseInt(e.target.innerText) - 1) * limit;
  };

  let assetsRef = useRef(false);
  const childRef = useRef();

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  const handleEditdepartments = (DepartmentID, DepartmentName, status) => {
    childRef.current.setData(
      JSON.stringify({
        departmentID: DepartmentID,
        txtDepartmentName: DepartmentName,
        status: status,
        intType: 3,
      })
    );
  };

  const fetchData = (action) => {
    if (action === 1) {
      intOffset = 0;
    }
  };

  //   const data = JSON.stringify({
  //     intType: 2,
  //   });
  //   config.method = "POST";
  //   config.data = data;
  //   config.url = departmentMasterAPI;

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data["result"]);
  //       setdepartmentMasterList(response.data["result"]);
  //       // setTotalCount(response.data["count"]);
  //       setTotalCount(Math.round(response.data.totalCount / limit));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (page, rowsPerPage) => {
    if (tempRefs.current === false) {
      const data = JSON.stringify({
        intLimit: rowsPerPage ? rowsPerPage : 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        intType: 2,
      });

      config.method = "POST";
      config.data = data;
      config.url = departmentMasterAPI;

      axios(config)
        .then(function (response) {
          setdepartmentMasterList(response.data["result"]);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true;
  };

  const handleDeletedepartments = async (id) => {
    const confirm = window.confirm("Are you sure want to delete this?");
    if (!confirm) return;
    
    const data = JSON.stringify({
      intDepartmentID: id,
      intType: 4,
    });

    config.method = "POST";
    config.data = data;
    config.url = departmentMasterAPI;

    axios(config)
      .then(function (response) {
        window.location.reload(false);
        alert("Department Deleted");

        // fetchUsers(1);
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: error.data.message,
            messageType: "error",
          })
        );
      });
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!departmentMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const columns = useMemo(() => [
    {
      name: "Department Name",
      field: "id",
      sortable: true,
      selector: (row) => row.department_name,
      width: "40%",
    },
    {
      name: "Status",
      field: "id",
      cell: (row) => (
        <Typography
          className={`${
            row.status === 2 ? `active-status` : `inactive-status`
          } `}
        >
          {row.status === 2 ? `Active` : `Inactive`}
        </Typography>
      ),
      width: "30%",
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <Tooltip title={`Edit department`} arrow>
          <IconButton
            color="success"
            onClick={() =>
              handleEditdepartments(row.id, row.department_name, row.status)
            }
            aria-label="add an alarm"
          >
            <EditLocationOutlinedIcon />
          </IconButton>
        </Tooltip>
      ),
      width: "15%",
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <Tooltip title={`Delete department`} arrow>
          <IconButton
            color="error"
            onClick={() => handleDeletedepartments(row.id)}
            aria-label="add an alarm"
          >
            <DeleteTwoToneIcon />
          </IconButton>
        </Tooltip>
      ),
      width: "15%",
    },
  ]);

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form bold-header">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Add Department
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Department</Typography>
              <Typography color="text.primary">Add</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="success"
              className="button_first"
              href="/config/positions-master"
              startIcon={<AddIcon />}
            >
              Add Position
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <AddDepartments ref={childRef} fetchData={fetchData} />
          <div className="empty-space-20"></div>
          <Grid className="small_table">
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={departmentMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default AddRoleMaster;

{
  /* <Grid className="login-text-background list_advance">
          <Typography variant="h5" textAlign="center">
           Manage Departments
          </Typography>
        </Grid>
      <Grid
        container
        spacing={2}
        margin={`15px 0px`}
        padding={`10px 15px 0px 0px`}
      >
        <AddDepartments ref={childRef} fetchData={fetchData} />
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          textAlign={`right`}
          display={`flex`}
          justifyContent={`flex-end`}
        >
          <Suspense fallback={<div></div>}>
            <Pagination
              totalCount={totalCount}
              handleReportPagination={handleReportPagination}
            />
          </Suspense>
        </Grid>
      </Grid>
      <div className={`table-responsive`}>
        <table className={`table`}>
          <thead>
            <th>
              <Typography variant="h6">S.No</Typography>
            </th>
            <th>
              <Typography variant="h6">Department Name</Typography>
            </th>
            <th>
              <Typography variant="h6">Status </Typography>
            </th>
            <th>
              <Typography variant="h6">Action </Typography>
            </th>
          </thead>
          <tbody>
            {departmentMasterList.length > 0 ? (
              departmentMasterList.map((row, index) => {
                return (
                  <tr key={index}>
                    <td className={`text-center`}>
                      <Typography>{index + 1}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography>{row.department_name}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography
                        className={`${
                          row.status === 2 ? `active-status` : `inactive-status`
                        } `}
                      >
                        {row.status === 2 ? `Active` : `Inactive`}
                      </Typography>
                    </td>
                    <td className={`text-center`}>
                      <Tooltip title={`Edit Role`}>
                        <Create
                          onClick={
                            () =>
                            
                            handleEditdepartments(
                              row.id,
                              row.department_name,
                              row.status
                            )
                          }
                        />
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className={`text-center`}>
                  <NotFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div> */
}
