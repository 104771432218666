import React, { useEffect, useRef, useState } from "react";
import { AlertMessage } from "../../components/AlertMessage";
//design imports from material
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";

//layout import
import Layout from "../../layout/Layout";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

//form imports
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  config,
  _numberOnly,
  projectMasterAPI,
  statusMasterAPI,
  vendorAPI,
  purchaseOrderAPI,
  termsAndConditionsAPI,
  userData,
  clientMasterAPI,
  manageInvoiceAPI,
  manageInvoiceTermsAndConditionsAPI,
  manageCompaniesAPI,
  manageStoreMasterAPI,
} from "../../config";

import { getValue } from "@mui/system";
import { _getDropdownListData } from "../../service";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let total = 0;
let cgst = 0;
let igst = 0;
let sgst = 0;
let totalAmount = 0;

const Invoice = () => {
  const childRef = useRef();
  const navigate = useNavigate();
  let tempRefs = useRef(false);

  // add field
  const [serviceList, setServiceList] = useState([
    { txtItemName: "", txtUnits: "" },
  ]);
  const [totalWithoutGST, setTotalWithoutGST] = useState(0);
  const [totalCgst, settotalCgst] = useState(0);
  const [totalSgst, setTotalSgst] = useState(0);
  const [totalIgst, setTotalIgst] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);

  const [unitsFlag, setUnitsFlag] = useState(false);
  const [PoDate, setPoDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  const [dueDate, setdueDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  const [vendorList, setVendorList] = useState([]);
  const [companyName, setCompanyName] = React.useState("");
  const [companyNames, setCompanyNames] = React.useState("");
  const [typeQuantity, setTypeQuantity] = React.useState("");

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { txtItemName: "", txtUnits: "" }]);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];

    list[index][name] = value;
    // list[index]["intLineItemTotalWithGST"] = 20;

    let tempGST = parseInt(list[index]["intGST"]) / 2;
    list[index]["intLineItemTotalWithoutGST"] =
      parseFloat(list[index]["intAmount"]) *
      parseFloat(list[index]["intQuantity"]);

    // new code
    if (clientDetails.state != "TamilNadu") {
      list[index]["intIGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"] +
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    } else {
      list[index]["intCGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
      list[index]["intSGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    }

    // list[index]["intCGST"] =
    //   (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    // list[index]["intSGST"] =
    //   (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    list[index]["intTGST"] = list[index]["intCGST"] + list[index]["intsGST"];

    // list[index]["intIGST"] = list[index]["intCGST"] + list[index]["intSGST"];

    if (clientDetails.state != "TamilNadu") {
      list[index]["intLineItemTotalWithGST"] =
        list[index]["intLineItemTotalWithoutGST"] + list[index]["intIGST"];
    } else {
      list[index]["intLineItemTotalWithGST"] =
        list[index]["intLineItemTotalWithoutGST"] +
        list[index]["intCGST"] +
        list[index]["intSGST"];
    }

    // total = 0;
    // for (var i = 0; i < list.length; i++) {
    //   total = total + list[i]["intLineItemTotalWithoutGST"];
    // }
    // igst = 0;
    // for (var i = 0; i < list.length; i++) {
    //   igst = igst + list[i]["intIGST"];
    // }
    // cgst = 0;
    // for (var i = 0; i < list.length; i++) {
    //   cgst = cgst + list[i]["intCGST"];
    // }
    // sgst = 0;
    // for (var i = 0; i < list.length; i++) {
    //   sgst = sgst + list[i]["intSGST"];
    // }
    // totalAmount = 0;
    // for (var i = 0; i < list.length; i++) {
    //   totalAmount = totalAmount + list[i]["intLineItemTotalWithGST"];
    // }

    igst = 0;
    cgst = 0;
    sgst = 0;
    total = 0;
    totalAmount = 0;

    list.forEach((item) => {
      if (item.hasOwnProperty("intCGST")) {
        cgst = cgst + item["intCGST"];
      }

      if (item.hasOwnProperty("intSGST")) {
        sgst = sgst + item["intSGST"];
      }

      if (item.hasOwnProperty("intIGST")) {
        igst = igst + item["intIGST"];
      }

      if (item.hasOwnProperty("intLineItemTotalWithGST")) {
        totalAmount = totalAmount + item["intLineItemTotalWithGST"];
      }

      if (item.hasOwnProperty("intLineItemTotalWithoutGST")) {
        total = total + item["intLineItemTotalWithoutGST"];
      }
    });

    setTotalWithoutGST(twoDecimal(total));
    setServiceList(list);

    // if (igst > 0) {
    //   settotalCgst(0);
    //   setTotalSgst(0);
    //   setTotalIgst(igst);
    // } else if (cgst > 0 && sgst > 0) {
    //   setTotalIgst(0);
    //   settotalCgst(cgst);
    //   setTotalSgst(sgst);
    // }

    settotalCgst(cgst);
    setTotalSgst(sgst);
    setTotalIgst(igst);
    setTotalAmount(twoDecimal(totalAmount));
  };

  // const handleServiceRemove = (index) => {
  //   const list = [...serviceList];
  //   list.splice(index, 1);
  //   setServiceList(list);
  // };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];

    // Get the item that you're going to remove
    const removedItem = list[index];

    // Calculate the values to subtract
    const subtractWithoutGST = removedItem.intLineItemTotalWithoutGST;
    const subtractCGST = removedItem.intCGST;
    const subtractSGST = removedItem.intSGST;
    const subtractIGST = removedItem.intIGST;
    const subtractTotalWithGST = removedItem.intLineItemTotalWithGST;

    // Update the state variables
    setTotalWithoutGST((prevTotal) => prevTotal - subtractWithoutGST);
    settotalCgst((prevTotalCgst) => prevTotalCgst - subtractCGST);
    setTotalSgst((prevTotalSgst) => prevTotalSgst - subtractSGST);
    setTotalIgst((prevTotalIgst) => prevTotalIgst - subtractIGST);
    setTotalAmount((prevTotalAmount) => prevTotalAmount - subtractTotalWithGST);

    // Remove the item from the list
    list.splice(index, 1);

    // Update the serviceList state
    setServiceList(list);
  };

  const validationSchema = Yup.object().shape({
    txtInvoiceType: Yup.string().required("Invoice Type is required"),
    txtCompanyName: Yup.string().required("Company Name is required"),
    txtClientName: Yup.string().required("Client Name is required"),
    // txtStoreName: Yup.string().required("Store Name is required"),
    txtTermscondition: Yup.string().required(
      "Mode/Terms of Payment is required"
    ),
    txtPONumber: Yup.string().required("P.O/ W.O ref. no is required"),

    // txtCompanyName: Yup.string().required("Company Name is required"),
    // intClientID: Yup.string().required("Client Name is required"),
    // intProjectID: Yup.string().required("Project Name is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);

  const { errors } = formState;

  //Terms Of Payments
  const [termsofPayments, setTermsofPayments] = useState([]);
  const [txtTermsOfPayments, setTermsofPayment] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 59,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setTermsofPayments(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // getting all clients
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 5,
        // intParentId: 59,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = clientMasterAPI;

      axios(config)
        .then(function (response) {
          setVendorList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // getting all companies
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 5,
        // intParentId: 42,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = manageCompaniesAPI;

      axios(config)
        .then(function (response) {
          setCompanyNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  //Marital Details
  // const [ProjectNameList, setProjectNameList] = useState([]);
  // const [intProjectID, setProjectName] = useState(0);
  // useEffect(() => {
  //   if (tempRefs.current === false) {
  //     const data = {
  //       intType: 6,
  //     };

  //     config.method = "POST";
  //     config.data = JSON.stringify(data);
  //     config.url = projectMasterAPI;

  //     axios(config)
  //       .then(function (response) {
  //         setProjectNameList(response.data.result);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //     return () => {
  //       tempRefs.current = true;
  //     };
  //   }
  // }, []);

  // Purchase

  const [unitsList, setUnitsList] = useState([]);

  tempRefs.current = false;
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 88,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setUnitsList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  tempRefs.current = false;

  // Vendor Details
  const [clientDetails, setClientDetails] = useState({});
  const [intClientID, setClientID] = useState(0);

  const handleClient = (e) => {
    setClientID(e.target.value);
    setValue("intClientID", e.target.value);

    const data = {
      intType: 4,
      intClientID: e.target.value,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = clientMasterAPI;

    axios(config)
      .then(function (response) {
        setClientDetails(response.data.result[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  tempRefs.current = false;

  // Purchase
  const [termsNames, setTermsNames] = useState([]);

  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 4,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = manageInvoiceTermsAndConditionsAPI;

      axios(config)
        .then(function (response) {
          setTermsNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  tempRefs.current = false;

  const [termsName, setTermsName] = useState("");

  const handleChangeterm = (event) => {
    setTermsName(event.target.value);

    const data = {
      intType: 3,
      intID: event.target.value,
      intLimit: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = manageInvoiceTermsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        window.tinymce.init({
          selector: "#textarea",
          width: "100%",
          menubar: false,
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
          toolbar:
            "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
        });
        setTimeout(function () {
          window.tinymce
            .get("textarea")
            .setContent(response.data.result[0].description);
        }, 1150);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [invoiceTerm, setinvoiceTerm] = useState(0);

  const [baseuploadagreement, setBaseUploadAgreement] = useState("");
  const [invoiceFile, setInvoiceFile] = useState(null);

  const uploadagreement = async (e) => {
    const file = e.target.files[0];
    setInvoiceFile(file);
    const base64 = await convertBase64(file);

    setBaseUploadAgreement(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  // const roundToNearest = (x) => {
  //   const roundedValue = Math.ceil(x * 20) / 20; // Round to the nearest 0.05
  //   return Number.parseFloat(roundedValue).toFixed(2);
  // };

  function roundUpToInteger(value) {
    // Round up to the nearest integer
    const roundedValue = Math.ceil(value);

    // Append ".00" to the rounded value
    const formattedValue = roundedValue.toFixed(2);

    return formattedValue;
  }

  function calculateRoundingDifference(value) {
    // Round up to the nearest integer
    const roundedValue = Math.ceil(value);
    // Calculate the rounding difference
    const difference = roundedValue - value;
    return difference.toFixed(2);
  }

  const validateLineItems = (lineItems) => {
    for (let i = 0; i < lineItems.length; i++) {
      const item = lineItems[i];

      if (!item.txtItemName) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Item ${i + 1}: Item Name is required.`,
            messageType: "error",
          })
        );
        return false;
      }

      if (!item.txtUnits) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Item ${i + 1}: Units are required.`,
            messageType: "error",
          })
        );
        return false;
      }

      if (isNaN(item.intLineItemTotalWithoutGST)) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Item ${
              i + 1
            }: Line Item Total Without GST must be a valid number.`,
            messageType: "error",
          })
        );
        return false;
      }

      if (isNaN(item.intIGST)) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Item ${i + 1}: IGST must be a valid number.`,
            messageType: "error",
          })
        );
        return false;
      }

      if (isNaN(item.intTGST)) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Item ${i + 1}: TGST must be a valid number.`,
            messageType: "error",
          })
        );
        return false;
      }

      if (isNaN(item.intLineItemTotalWithGST)) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Item ${
              i + 1
            }: Line Item Total With GST must be a valid number.`,
            messageType: "error",
          })
        );
        return false;
      }
    }

    return true; // Validation passed
  };

  function onSubmit(data) {
    let invDesc = window.tinymce.get("textarea").getContent();
    let newInvDesc = invDesc.replaceAll("<p>", "");
    let finalInvDesc = newInvDesc.replaceAll("</p>", "");

    data.intInvTermsId = invoiceTerm;
    data.txtTermsDescription = finalInvDesc;
    data.txtFile = baseuploadagreement;
    data.lineItems = serviceList;
    data.intTotalWithoutGST = totalWithoutGST;
    data.intTotalIGST = twoDecimal(totalIgst);
    data.intTotalSGST = twoDecimal(totalSgst);
    data.intTotalCGST = twoDecimal(totalCgst);
    data.intTotalWithGST = twoDecimal(totalamount);
    data.intCreatedBy = userData.emp_id;
    data.intClientID = intClientID;
    data.PoDate = PoDate;
    data.dueDate = dayjs(dueDate).format("YYYY-MM-DD");
    data.intStatus = 90;
    data.intType = 1;

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = manageInvoiceAPI;

    console.log(data);
    // Call this function before form submission
    // if (!validateLineItems(data.lineItems)) {
    //   return ;
    // }

    if (data.lineItems.length <= 0) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "At least 1 PO Item is required",
          messageType: "error",
        })
      );
      return;
    } else {
      const invalidIndex = data.lineItems.findIndex((item) => {
        return (
          isNaN(item.intLineItemTotalWithGST) ||
          item.intLineItemTotalWithGST === ""
        );
      });

      if (invalidIndex !== -1) {
        // Handle the invalid case
        console.log(`Invalid item found at S.no ${invalidIndex + 1}`);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: `Fill all the details at S.no ${invalidIndex + 1}`,
            messageType: "error",
          })
        );
        return;
      }
    }

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          navigate(`/invoice/list`);
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    let data = {
      requestData: {
        intType: 5,
      },
      API: manageStoreMasterAPI,
      setData: setStoreList,
    };
    _getDropdownListData(data);
  }, []);

  const [isCancelPopup, setIsCancelPopup] = useState(false);

  const handleCancelFile = async () => {
    setBaseUploadAgreement("");
    document.getElementById("agreementdocument").value = "";
    setIsCancelPopup(false);
  };

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Invoice
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Typography color="text.primary">Invoice</Typography>
                <Typography color="text.primary">create</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="secondary"
                className="button_first"
                href="/invoice/list"
              >
                Invoice List
              </LoadingButton>
              <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <div className="empty-space-40"></div>
        <Grid className="purchaseorder">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="popading" spacing={2}>
              {/* invoice type */}
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Invoice Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("txtInvoiceType")}
                    label="Order Type"
                    onChange={(e) => {
                      setValue("txtInvoiceType", e.target.value);
                    }}
                  >
                    {/* <MenuItem value="1">Credit Note</MenuItem> */}
                    <MenuItem value="1">Invoice</MenuItem>
                    <MenuItem value="2">Proforma</MenuItem>
                    <MenuItem value="3">Credit Note</MenuItem>
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtInvoiceType?.message}
                  </Typography>
                </FormControl>
              </Grid>

              {/* company name */}
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Company Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companyName}
                    label="Company Name"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setValue("txtCompanyName", e.target.value);
                    }}
                  >
                    {companyNames &&
                      companyNames.length > 0 &&
                      companyNames.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.company_name}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtCompanyName?.message}
                  </Typography>
                </FormControl>
              </Grid>

              {/* client name */}
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Client Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={intClientID}
                    label="Client Name"
                    {...register("intClientID")}
                    onChange={(e) => {
                      handleClient(e);
                      setValue("txtClientName", e.target.value);
                    }}
                  >
                    {vendorList &&
                      vendorList.length > 0 &&
                      vendorList.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.client_name}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtClientName?.message}
                  </Typography>
                </FormControl>
              </Grid>

              {/* store name */}
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Store Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("txtStoreName")}
                    label="Store Name"
                    onChange={(e) => {
                      setValue("txtStoreName", e.target.value);
                    }}
                  >
                    {storeList &&
                      storeList.length > 0 &&
                      storeList.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.store_name}
                        </MenuItem>
                      ))}
                  </Select>
                  {/* <Typography className="invalid-feedback">
                    {errors.txtStoreName?.message}
                  </Typography> */}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className="pomargin table_po">
              {/* client details conditional rendering part */}
              <Grid item className="form-lable" xs={12} lg={7} md={7}>
                {clientDetails.hasOwnProperty("client_name") ? (
                  <>
                    <Grid className="info_details">
                      <Typography>To</Typography>
                      <Typography className="companyna">
                        {clientDetails.client_name}
                      </Typography>
                      <Typography>{clientDetails.primary_number}</Typography>
                      <Typography>
                        {clientDetails.address}, {clientDetails.city}
                      </Typography>
                      <Typography>
                        {clientDetails.pincode} {clientDetails.state}
                      </Typography>
                      <Typography>India</Typography>
                      <Typography>GSTIN {clientDetails.gst_no}</Typography>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>

              {/* right sub form */}
              <Grid item className="po_table table_po" xs={12} lg={5} md={5}>
                {/* <Typography className="align_center">Employee No</Typography>
                <hr></hr> */}
                <Grid
                  container
                  spacing={2}
                  justifyContent={`center`}
                  className="po_leble"
                >
                  <Grid item className="lable_text" xs={12} lg={5} md={5}>
                    <Typography>Mode of payment : </Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={txtTermsOfPayments}
                        onChange={(e) => {
                          setTermsofPayment(e.target.value);
                          setValue("txtTermscondition", e.target.value);
                        }}
                      >
                        {/* {termsofPayments && termsofPayments.length > 0 ? ( */}
                        {termsofPayments.map((row, index) => (
                          <MenuItem value={row.id} key={index}>
                            {row.name}
                          </MenuItem>
                        ))}
                        {/* ) : (
                        <></>
                      )} */}
                      </Select>
                      <Typography className="invalid-feedback">
                        {errors.txtTermscondition?.message}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent={`center`}
                  className="po_leble"
                >
                  <Grid item className="lable_text" xs={12} lg={5} md={5}>
                    <Typography>Due date:</Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    {/* due date */}
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      format="DD-MM-YYYY"
                    >
                      <MobileDatePicker
                        value={dueDate}
                        inputFormat="DD/MM/YYYY"
                        fullWidth
                        {...register("dueDate")}
                        onChange={(newValue) => {
                          setdueDate(newValue);
                          setValue(
                            "dueDate",
                            dayjs(newValue).format("YYYY-MM-DD")
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={errors.dueDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent={`center`}
                  className="po_leble"
                >
                  <Grid item className="lable_text" xs={12} lg={5} md={5}>
                    <Typography>P.O/ W.O ref. no : </Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    <TextField
                      name="poRefNo"
                      type="text"
                      {...register("poRefNo")}
                      fullWidth={true}
                      onChange={(e) => {
                        setValue("txtPONumber", e.target.value);
                      }}
                    />
                    <Typography className="invalid-feedback">
                      {errors.txtPONumber?.message}
                    </Typography>
                    {/* <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={txtPurchaseClassify}
                        onChange={(e) => {
                          settxtPurchase(e.target.value);
                          setValue("txtPurchaseClassify", e.target.value);
                        }}
                      >
                        {Purchase.map((row, index) => (
                          <MenuItem value={row.id} key={index}>
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography className="invalid-feedback">
                        {errors.txtPurchaseClassify?.message}
                      </Typography>
                    </FormControl> */}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent={`center`}
                  className="po_leble"
                >
                  <Grid item className="lable_text" xs={12} lg={5} md={5}>
                    <Typography>P.O/ W.O Date :</Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    {/* Po date */}
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      format="DD-MM-YYYY"
                    >
                      <MobileDatePicker
                        value={PoDate}
                        inputFormat="DD/MM/YYYY"
                        fullWidth
                        {...register("PoDate")}
                        onChange={(newValue) => {
                          setPoDate(dayjs(newValue).format("YYYY-MM-DD"));
                          setValue(
                            "PoDate",
                            dayjs(newValue).format("YYYY-MM-DD")
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={errors.PoDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>

              {/* Invoice main form */}
              <Grid item className="form-lable" xs={12} lg={12} md={12}>
                <table className={`table`}>
                  <thead>
                    <th>
                      <Typography>S. No.</Typography>
                    </th>
                    <th>
                      <Typography>Description</Typography>
                    </th>
                    <th>
                      <Typography>Qty </Typography>
                    </th>
                    <th>
                      <Typography>Rate </Typography>
                    </th>
                    {/* <th>
                      <Typography>Unit</Typography>
                    </th> */}
                    <th>
                      <Typography>Amount (INR) </Typography>
                    </th>
                    <th>
                      <Typography>GST </Typography>
                    </th>
                    {clientDetails.state != "TamilNadu" ? (
                      <>
                        <th>
                          <Typography>IGST </Typography>
                        </th>
                      </>
                    ) : (
                      <>
                        <th>
                          <Typography>CGST </Typography>
                        </th>
                        <th>
                          <Typography>SGST </Typography>
                        </th>
                      </>
                    )}
                    <th>
                      <Typography>Total Amount + GST (INR)</Typography>
                    </th>
                    <th className="add_button">
                      <small
                        style={{
                          fontSize: "16px",
                          fontWeight: "100",
                          marginRight: "6px",
                        }}
                      >
                        Add New
                      </small>
                      <Button
                        variant="outlined"
                        onClick={handleServiceAdd}
                        startIcon={<AddIcon />}
                        color="success"
                      ></Button>
                    </th>
                  </thead>

                  {/* body of adding items for making the invoice  */}
                  <tbody>
                    {serviceList.map((singleService, index) => (
                      <tr className="table_padding">
                        <td className={`text-center`}>
                          <Typography>{index + 1}</Typography>
                        </td>
                        {/* description */}
                        <td className="po_leble">
                          <input
                            name="txtItemName"
                            type="text"
                            id="txtItemName"
                            value={singleService.txtItemName}
                            onChange={(e) => handleServiceChange(e, index)}
                            required
                          />
                        </td>

                        {/* quantity */}
                        <td className="po_leble">
                          <Select
                            name="txtUnits"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={singleService.txtUnits}
                            // value={singleService.txtTypeOfUnit}
                            onChange={(e) => {
                              setUnitsFlag(true);
                              handleServiceChange(e, index);
                              // setTypeQuantity(e.target.value)
                            }}
                          >
                            {unitsList.length > 0 &&
                              unitsList.map((row, index) => (
                                <MenuItem value={row.id} key={index}>
                                  {row.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {unitsFlag && (
                            <TextField
                              name="intQuantity"
                              type="number"
                              value={singleService.intQuantity}
                              onChange={(e) => handleServiceChange(e, index)}
                            />
                          )}
                        </td>

                        {/* Rate */}
                        <td className="po_leble">
                          <TextField
                            name="intAmount"
                            type="number"
                            // {...register("intAmount")}
                            value={singleService.intAmount}
                            onChange={(e) => handleServiceChange(e, index)}
                          />
                        </td>

                        {/* Unit */}
                        {/* <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithoutGST)
                              ? `0.00`
                              : singleService.intLineItemTotalWithoutGST}
                            demo
                          </Typography>
                        </td> */}

                        {/* <td className="po_leble">
                          <Select
                            // name="txtUnits"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={unit}
                            onChange={(e) => {
                              setUnit(e.target.value);
                              // handleServiceChange(e, index);
                            }}
                          >

                            <MenuItem value={`1`} key={index}>
                              Unit 1
                            </MenuItem>
                            <MenuItem value={`2`} key={index}>
                              Unit 2
                            </MenuItem>
                            <MenuItem value={`3`} key={index}>
                              unit 3
                            </MenuItem>
                            <MenuItem value={`4`} key={index}>
                              Unit 4
                            </MenuItem>
                          </Select>
                        </td> */}

                        {/* Amount */}
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithoutGST)
                              ? `0.00`
                              : twoDecimal(
                                  singleService.intLineItemTotalWithoutGST
                                )}
                          </Typography>
                        </td>

                        {/* GST */}
                        <td className="po_leble">
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              name="intGST"
                              id="demo-simple-select"
                              value={singleService.intGST}
                              onChange={(e) => {
                                handleServiceChange(e, index);
                              }}
                            >
                              <MenuItem value={`0`} key={index}>
                                GST 0%
                              </MenuItem>
                              <MenuItem value={`5`} key={index}>
                                GST 5%
                              </MenuItem>
                              <MenuItem value={`12`} key={index}>
                                GST 12%
                              </MenuItem>
                              <MenuItem value={`18`} key={index}>
                                GST 18%
                              </MenuItem>
                              <MenuItem value={`28`} key={index}>
                                GST 28%
                              </MenuItem>
                            </Select>
                            <Typography className="invalid-feedback">
                              {errors.intGST?.message}
                            </Typography>
                          </FormControl>
                        </td>

                        {/* IGST , CGST , SGST */}
                        {clientDetails.state != "TamilNadu" ? (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intIGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intIGST)}
                              </Typography>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intCGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intCGST)}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intSGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intSGST)}
                              </Typography>
                            </td>
                          </>
                        )}
                        {/* <td className="po_leble">
                          <Typography>{singleService.intSGST}</Typography>
                        </td> */}
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithGST)
                              ? `0.00`
                              : twoDecimal(
                                  singleService.intLineItemTotalWithGST
                                )}
                          </Typography>
                        </td>

                        {/* conditional remove button */}
                        <td className="cancel_button">
                          {serviceList.length !== 1 && (
                            <>
                              <div
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={(e) => {
                                    handleServiceChange(e, index);
                                    handleServiceRemove(index);
                                  }}
                                  startIcon={<DeleteIcon />}
                                  color="error"
                                ></Button>

                                <small
                                  style={{ fontSize: "13px", fontWeight: "90" }}
                                >
                                  Remove
                                </small>
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  {/* lower part of the invoice form */}
                  <tbody className="tbody_col">
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td>
                        <Typography>Total</Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalWithoutGST}>
                          {isNaN(totalWithoutGST)
                            ? `0.00`
                            : twoDecimal(totalWithoutGST)}
                        </Typography>
                      </td>
                    </tr>
                    {clientDetails.state != "TamilNadu" ? (
                      <>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td>
                            <Typography>IGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={totalIgst}>
                              {isNaN(totalIgst)
                                ? `0.00`
                                : twoDecimal(totalIgst)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td>
                            <Typography>CGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={totalCgst}>
                              {isNaN(totalCgst)
                                ? `0.00`
                                : twoDecimal(totalCgst)}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td>
                            <Typography>SGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={totalSgst}>
                              {isNaN(totalSgst)
                                ? `0.00`
                                : twoDecimal(totalSgst)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>

                      {/* <td>
                        <Typography>Transport Charges</Typography>
                      </td>
                      <td className="po_leble">
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            name="intTransportGST"
                            id="demo-simple-select"
                            {...register("intTransportGST")}
                            onChange={(e) => {
                              setValue("intTransportGST", e.target.value);
                              setTransportGST(e.target.value);
                            }}
                          >
                            <MenuItem value={`5`} key={1}>
                              GST 5%
                            </MenuItem>
                            <MenuItem value={`12`} key={2}>
                              GST 12%
                            </MenuItem>
                            <MenuItem value={`18`} key={3}>
                              GST 18%
                            </MenuItem>
                            <MenuItem value={`28`} key={4}>
                              GST 28%
                            </MenuItem>
                          </Select>
                          <Typography className="invalid-feedback">
                            {errors.intGST?.message}
                          </Typography>
                        </FormControl>
                      </td>
                      <td className="po_leble">
                        <TextField
                          name="intTransportAmount"
                          type="number"
                          // {...register("intAmount")}
                          onChange={(e) =>
                            handleTransportCharge(e.target.value)
                          }
                        />
                      </td>
                      <td colSpan={6}>
                        <Typography value={transportCharge}>
                          {isNaN(transportCharge) ? `0.00` : transportCharge}
                        </Typography>
                      </td>
                    </tr>
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                       */}

                      <td>
                        <Typography>Total amount with GST </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalamount}>
                          {isNaN(totalamount)
                            ? `0.00`
                            : twoDecimal(totalamount)}
                        </Typography>
                      </td>
                    </tr>

                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td>
                        <Typography>Rounded off </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalamount}>
                          {isNaN(totalamount)
                            ? `0.00`
                            : calculateRoundingDifference(totalamount)}
                        </Typography>
                      </td>
                    </tr>
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Total Payable Amount (Rs.)
                        </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography
                          value={totalamount}
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          {isNaN(totalamount)
                            ? `0.00 /-`
                            : `${roundUpToInteger(totalamount)} /-`}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>

            {/* terms and conditions part and the rest */}
            <div className="empty-space-20"></div>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              textAlign="left"
              className="po_lebleterm"
            >
              <FormControl className="form_control" sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Terms & Conditions
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={termsName}
                  onChange={handleChangeterm}
                  input={<OutlinedInput label="Terms & Conditions" />}
                  MenuProps={MenuProps}
                  required={true}
                >
                  {termsNames.map((terms) => (
                    <MenuItem key={terms.title} value={terms.id}>
                      <ListItemText
                        primary={terms.title}
                        onClick={() => {
                          setinvoiceTerm(terms.id);
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              textAlign={`right`}
              display={`flex`}
              justifyContent={`flex-end`}
            >
              <TextField fullWidth id="textarea" />
            </Grid>
            <Grid></Grid>

            {/* file part */}
            <div className="empty-space-20"></div>
            <Grid item className="form-lable" xs={12} lg={4} md={4}>
              <label> Document : </label>
              <br />
              <small className="" style={{ fontSize: "12px" }}>
                Allowed Only *.jpeg, *.jpg, *.png, *.pdf
              </small>
              <br></br>
              <Box display={`flex`}>
                <Button variant="contained" component="label">
                  <input
                    id="agreementdocument"
                    name="agreementdocument"
                    type="file"
                    accept="image/jpeg,image/png,application/pdf"
                    fullWidth
                    {...register("txtFile")}
                    onChange={(e) => {
                      uploadagreement(e);
                    }}
                  />
                </Button>
                {baseuploadagreement != "" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setIsCancelPopup(true)}
                    style={{ marginLeft: "10px" }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Grid>
            {/* {
              baseuploadagreement && (
                <img className="" src={baseuploadagreement} style={{ width: "20em", padding: "20px" }} alt="" />
              )
            } */}

            <div className="empty-space-20"></div>
            <Grid item xs={12} md={12} lg={12} textAlign="right">
              <Button variant="primary" className={`btn-primary`} type="submit">
                Submit
              </Button>
            </Grid>

            {baseuploadagreement && (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{ my: 2 }}
              >
                <br />
                {baseuploadagreement.startsWith("data:image/") && (
                  <Grid item xs={12} lg={12}>
                    <img
                      src={baseuploadagreement}
                      alt="File"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "10px",
                        maxHeight: "500px", // Limit max height for large images
                        "@media (max-width: 768px)": {
                          height: "auto", // Adjust height for smaller screens
                          maxWidth: "100%",
                        },
                      }}
                    />
                  </Grid>
                )}
                {baseuploadagreement.startsWith("data:application/pdf") && (
                  <Grid item xs={12} lg={12}>
                    <embed
                      src={URL.createObjectURL(invoiceFile)}
                      type="application/pdf"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "500px",
                        "@media (max-width: 768px)": {
                          height: "300px", // Adjust height for smaller screens
                        },
                      }}
                    />
                  </Grid>
                )}
                <br />
              </Grid>
            )}
          </form>
        </Grid>
      </Grid>
      <Dialog
        open={isCancelPopup}
        onClose={() => setIsCancelPopup(false)}
        maxWidth="xl"
      >
        <DialogTitle sx={{ backgroundColor: "#FFF2D7" }}>
          <Typography
            variant="h5"
            style={{
              color: "#FF6868",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Warning!!
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "16px" }}>
          <Typography variant="h6">
            Would you like to remove the attachment?
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#f44336", fontWeight: "bold" }}
          >
            **This step cannot be undone
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", padding: "16px" }}>
          <Button color="success" onClick={handleCancelFile}>
            Yes
          </Button>
          <Button color="error" onClick={() => setIsCancelPopup(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Invoice;
