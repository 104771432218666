import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import { Grid, TextField, Typography, FormControl } from "@mui/material";

import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AlertMessage } from "../components/AlertMessage";

import { useAuthContext } from "../hooks/useAuthContext";
import "../assets/css/login.css";
import companyLogo from "../assets/images/Logo/logo.png";
import loginBackgroundImage from "../assets/images/login/login.jpg";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {
  config,
  loginAPI,
  resetPasswordEmailAPI,
  verifyOTPAPI,
} from "../config";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function AdminLogin() {
  const { dispatch } = useAuthContext();
  const [successLoginFlag, setSuccessLoginFlag] = React.useState(false);
  const [projectDetails, setProjectDetails] = React.useState([]);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const [isForgotPin, setIsForgotPin] = React.useState([]);

  const handleForgotPin = () => {
    setIsForgotPin(!isForgotPin);
  };

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isSendOTP, setIsSendOTP] = useState(false);
  const [intOTP, setOTP] = useState("");
  const [isVerifiedOTP, setIsVerifiedOTP] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setOTP("");
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user.role_id === 36) {
        navigate("/user/attendance");
      } else if (user.role_id === 34 || user.role_id === 35) {
        navigate("/dashboard");
      }
    }

    return () => true;
  }, []);

  const validationSchema = Yup.object().shape({
    txtPassword: Yup.string().required("Password is required"),
    txtEmail: Yup.string()
      .required("Email is required")
      .email("Please enter valid email format"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState } = useForm(formOptions);

  const { errors } = formState;
  const handleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
  };

  const handleSendOTP = () => {
    setErrMessage("");
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(forgotPasswordEmail)
    ) {
      setErrMessage("Please enter valid email");
      return false;
    }

    var reqData = JSON.stringify({
      txtUsername: forgotPasswordEmail,
      intType: 5,
    });
    config.method = "post";
    config.url = resetPasswordEmailAPI;
    config.data = reqData;

    // debugger;
    axios(config)
      .then(function (response) {
        if (response.data.message === "Invalid Email") {
          setErrMessage(response.data.message);
        } else {
          setIsSendOTP(!isSendOTP);
        }
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
        setErrMessage("Please Enter Valid Email ID");
      });
  };

  const handleVerifyOTP = () => {
    var reqData = JSON.stringify({
      txtUsername: forgotPasswordEmail,
      intType: 9,
      intOTP: intOTP,
    });
    config.method = "post";
    config.url = verifyOTPAPI;
    config.data = reqData;

    // debugger;
    axios(config)
      .then(function (response) {
        if (response.data.message === "Invalid Otp") {
          setErrMessage(response.data.message);
        } else {
          setIsVerifiedOTP(!isVerifiedOTP);
          setErrMessage("");
        }
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
        setErrMessage("Invalid Username or Password");
      });
  };

  const handleChangesubmit = async (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }

    if (index == 3) {
      const pinarr = [
        ...otp.map((d, idx) => (idx === index ? element.value : d)),
      ];
      let loginpin = "";
      pinarr.map(function (x) {
        loginpin += x;
      });

      const data = {
        intPin: parseInt(loginpin),
        txtDate: moment().format("YYYY-MM-DD"),
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = loginAPI;

      await axios(config)
        .then(async function (response) {
          if (response.data.message === "SUCCESS") {
            //Remove existing local storage
            localStorage.removeItem("isProjectLoggedIn");
            localStorage.removeItem("projectID");
            localStorage.removeItem("projectAutoID");
            localStorage.removeItem("projectName");
            localStorage.removeItem("projectCheckInTime");
            localStorage.removeItem("userAttandenceLogin");
            if (
              response.data.result[1] &&
              response.data.result[1].working_hours == ""
            ) {
              if (
                !response.data.result[1].end_time ||
                (response.data.result[1].end_time = "0000-00-00 00:00:00")
              ) {
                localStorage.setItem("isProjectLoggedIn", true);
                localStorage.setItem(
                  "projectID",
                  response.data.result[1].project_id
                    ? response.data.result[1].project_id
                    : 0
                );
                localStorage.setItem(
                  "projectAutoID",
                  response.data.result[1].id ? response.data.result[1].id : 0
                );
                localStorage.setItem(
                  "projectName",
                  response.data.result[1].project_name
                    ? response.data.result[1].project_name
                    : ""
                );
                localStorage.setItem(
                  "projectCheckInTime",
                  response.data.result[1].start_time
                    ? response.data.result[1].start_time
                    : ""
                );
                localStorage.setItem("userAttandenceLogin", "true");
              }
            }

            setProjectDetails(response.data.result[1]);
            setSuccessLoginFlag(true);

            let responseData = response.data.result[0];
            localStorage.setItem("user", JSON.stringify(responseData));
            dispatch({ type: "LOGIN", payload: "jsonData" });

            let pageURL = "/dashboard";
            if (responseData.role_id === 36) pageURL = "/user/attendance";
            window.location.href = pageURL;
          } else {
            setOtp([...otp.map((v) => "")]);
          }
        })
        .catch(function (error) {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "Wrong credentials",
              messageType: "error",
            })
          );
          setOtp([...otp.map((v) => "")]);
          console.log(error);
        });
    }
  };

  const childRef = useRef();

  return (
    <>
      <AlertMessage ref={childRef} />
      <Grid container className="full-height">
        <Grid
          className="padding-10 login-background"
          item
          xs={12}
          lg={3}
          md={3}
          alignItems="center"
        >
          <Grid textAlign="center">
            <img
              src={companyLogo}
              alt="LogRamcides Ticketing System (RTS)"
              className="logo-image"
            />
          </Grid>
          <Grid textAlign="Center">
            <img
              src={loginBackgroundImage}
              width={`130px`}
              alt="Login Background"
              className="login-background-img"
            />
          </Grid>
        </Grid>
        <Grid
          className="login-center userlogin"
          item
          xs={12}
          lg={9}
          md={9}
          alignItems="center"
        >
          <Grid className="inner-login-box inner-login-login">
            <Grid className="login-text-background">
              <Typography variant="h5" fontWeight="600" textAlign="center">
                User Login
              </Typography>
            </Grid>
            <div className="empty-space-40"></div>
            {isSendOTP ? (
              <>
                <Grid id className="login-text-background">
                  <Typography variant="h4" textAlign="center">
                    Enter OTP
                  </Typography>
                  <div className="empty-space-20"></div>
                </Grid>
                <Grid>
                  <TextField
                    name="txtEmail"
                    type="text"
                    label="Enter OTP"
                    fullWidth
                    value={intOTP}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                  <Typography className="invalid-feedback">
                    {errors.txtEmail?.message}
                  </Typography>
                </Grid>
                <Typography className="invalid-feedback">
                  {errMessage}
                </Typography>
                <div className="empty-space-20"></div>
                <FormControl
                  sx={{ m: 0, width: "-webkit-fill-available" }}
                  variant="standard"
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={6} md={6}>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={handleVerifyOTP}
                        disabled={formState.isSubmitting}
                      >
                        Verify OTP
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} textAlign="right">
                      <a
                        onClick={() => setIsSendOTP(!isSendOTP)}
                        className="pointer"
                      >
                        Change email
                      </a>
                    </Grid>
                  </Grid>
                </FormControl>
              </>
            ) : isForgotPin ? (
              <div className="row">
                <div className="col text-center">
                  <h4>Welcome to KG Build Tech...!</h4>
                  <div className="empty-space-40"></div>
                  <p>Login using 4 digit PIN</p>
                  <div className="empty-space-40"></div>
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="otp-field"
                        type="text"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        clearinput
                        onChange={(e) => handleChangesubmit(e.target, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}

                  {/* <p>PIN Entered - {otp.join("")}</p> */}
                  <div className="empty-space-30"></div>
                  <Grid item textAlign="center">
                    <a onClick={handleForgotPin} className="pointer">
                      Forgot Pin?
                    </a>
                  </Grid>
                </div>
              </div>
            ) : (
              <>
                <Grid>
                  <Typography variant="h5" textAlign="center">
                    Forgot Pin
                  </Typography>
                  <div className="empty-space-40"></div>
                  <h5>
                    Enter the email address associated with your KG Buildtech
                    account.
                  </h5>
                  <div className="empty-space-20"></div>
                </Grid>
                <Grid>
                  <TextField
                    name="txtEmail"
                    type="text"
                    label="Email"
                    fullWidth
                    value={forgotPasswordEmail}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  />
                  <Typography className="invalid-feedback">
                    {errors.txtEmail?.message}
                  </Typography>
                </Grid>

                <Typography className="invalid-feedback">
                  {errMessage}
                </Typography>
                <div className="empty-space-20"></div>
                <FormControl
                  sx={{ m: 0, width: "-webkit-fill-available" }}
                  variant="standard"
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={6} md={6}>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={handleSendOTP}
                        disabled={formState.isSubmitting}
                      >
                        Send OTP
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} textAlign="right">
                      <a onClick={handleForgotPin} className="pointer">
                        Cancel
                      </a>
                    </Grid>
                  </Grid>
                </FormControl>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
