import React, { useEffect, useRef } from "react";

//design imports from material
import {
  Grid,
  FormLabel,
  TextField,
  Typography,
  Button,
  FormControl,
} from "@mui/material";

//layout import
import Layout from "../../../layout/Layout";

//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import StateList, { stateList } from "../../../components/StateList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//date picker
import dayjs from "dayjs";

import axios from "axios";
import {
  config,
  clientMasterAPI,
  gstNumberRegExp,
  panCodeRegExp,
  pincodeRegExp,
  manageStoreMasterAPI,
} from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

import { useNavigate } from "react-router-dom";

function StoreMasterAdd() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    txtStoreName: Yup.string().required("Store Name is required"),
    // txtEmail: Yup.string().required("Emali ID is required"),
    // intMobileNumber: Yup.string().matches(
    //     "^[0-9]{10}$",
    //     "Please Enter Valid Mobile Number"
    // ),
    // intSecMobileNumber: Yup.string().matches(
    //   "^[0-9]{10}$",
    //   "Please Enter Valid Mobile Number"
    // ),
    // txtVendorCode: Yup.string().required("Vendor Code is required"),
    // txtClientType: Yup.string().required("Client Type is required"),
    // txtClientGST: Yup.string()
    //   .required("GST Number is required")
    //   .matches(gstNumberRegExp, "Please enter a valid GST Number"),
    // txtClientPAN: Yup.string()
    //   .required("PAN Number is required")
    //   .matches(panCodeRegExp, "Please enter a valid PAN Number"),
    txtAddress: Yup.string().required("Address is required"),
    txtCity: Yup.string().required("State / City is required"),
    intPincode: Yup.string()
      .required("Pincode is required")
      .min(6, "Pincode must be at least 6 digits")
      .max(6, "Pincode cannot be more than 6 digits")
      .matches(pincodeRegExp, "Please enter a valid Pincode"),
    txtState: Yup.string().required("State is required"),
  });

  const [stateName, setStateName] = React.useState("");

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    data.intType = 1;
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = manageStoreMasterAPI;

    axios(config)
      .then(function (response) {
        handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          navigate("/store/list");
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    handleFormReset();
  }, []);

  const handleFormReset = () => {
    let defaultValues = {};
    reset({ ...defaultValues });
  };

  const childRef = useRef();

  return (
    <Layout>
      <Grid className="employee-form">
        <AlertMessage ref={childRef} />
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Store Add
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Store</Typography>
              <Typography color="text.primary">Add</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="secondary"
              className="button_first"
              href="/store/list"
            >
              List Store
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="txtStoreName"
                type="text"
                label="Store Name"
                fullWidth
                {...register("txtStoreName")}
                error={errors.txtStoreName ? true : false}
                helperText={errors.txtStoreName?.message}
              />
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="address"
                type="text"
                label="Street address"
                fullWidth
                {...register("txtAddress")}
                error={errors.txtAddress ? true : false}
                helperText={errors.txtAddress?.message}
              />
            </Grid>
          </Grid>
          {/* <div className="empty-space-20"></div>
                    <Grid container spacing={2}>
                        <Grid item className="form-lable" xs={12} lg={6} md={6}>
                            <TextField
                                type="number"
                                fullWidth
                                label="Primary Mobile Number"
                                error={errors.intMobileNumber ? true : false}
                                {...register("intMobileNumber")}
                                helperText={errors.intMobileNumber?.message}
                            />
                        </Grid>
                        <Grid item className="form-lable" xs={12} lg={6} md={6}>
                            <TextField
                                type="number"
                                fullWidth
                                label="Secondary Mobile Number"
                                error={errors.intSecMobileNumber ? true : false}
                                {...register("intSecMobileNumber")}
                                helperText={errors.intSecMobileNumber?.message}
                            />
                        </Grid>
                    </Grid>
                    <div className="empty-space-20"></div>
                    <Grid container spacing={2}>
                        <Grid item className="form-lable" xs={12} lg={6} md={6}>
                            <TextField
                                type="number"
                                fullWidth
                                label="Vendor Code"
                                error={errors.txtVendorCode ? true : false}
                                {...register("txtVendorCode")}
                                helperText={errors.txtVendorCode?.message}
                            />
                        </Grid>
                        <Grid item className="form-lable" xs={12} lg={6} md={6}>
                            <TextField
                                type="text"
                                fullWidth
                                label="Client Type"
                                error={errors.txtClientType ? true : false}
                                {...register("txtClientType")}
                                helperText={errors.txtClientType?.message}
                            />
                        </Grid>
                    </Grid>
                    <div className="empty-space-20"></div>
                    <Grid container spacing={2}>
                        <Grid item className="form-lable" xs={12} lg={6} md={6}>
                            <TextField
                                type="text"
                                fullWidth
                                label="Client GST No. "
                                error={errors.txtClientGST ? true : false}
                                {...register("txtClientGST")}
                                helperText={errors.txtClientGST?.message}
                            />
                        </Grid>
                        <Grid item className="form-lable" xs={12} lg={6} md={6}>
                            <TextField
                                type="text"
                                fullWidth
                                label="Client PAN Number"
                                error={errors.txtClientPAN ? true : false}
                                {...register("txtClientPAN")}
                                helperText={errors.txtClientPAN?.message}
                            />
                        </Grid>
                    </Grid> */}
          <div className="empty-space-20"></div>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="address"
                type="text"
                label="Town / City"
                fullWidth
                {...register("txtCity")}
                error={errors.txtCity ? true : false}
                helperText={errors.txtCity?.message}
              />
            </Grid>

            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={stateName}
                  label="State"
                  onChange={(e) => {
                    setStateName(e.target.value);
                    setValue("txtState", e.target.value);
                  }}
                >
                  {stateList.map((row) => (
                    <MenuItem value={row.state_name}>{row.state_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className="empty-space-20"></div>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="Pincode"
                type="number"
                label="Pincode"
                fullWidth
                {...register("intPincode")}
                error={errors.intPincode ? true : false}
                helperText={errors.intPincode?.message}
              />
            </Grid>
          </Grid>

          <Grid
            item
            className="form-lable"
            xs={12}
            lg={12}
            md={12}
            marginTop="20px"
            textAlign="right"
          >
            <Button className={`btn-primary`} type="submit">
              Submit
            </Button>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
}

export default StoreMasterAdd;
