import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Breadcrumbs,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  config,
  reportsAPI,
  projectMasterAPI,
  leaveAPI,
  handleHrMasterAPI,
} from "../../config";
import DataTable from "react-data-table-component";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as XLSX from "xlsx/xlsx.mjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import DescriptionIcon from "@mui/icons-material/Description";
import MuiLink from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Tooltip as ReactTooltip } from "react-tooltip";
function EmployeeActivityReport() {
  const navigate = useNavigate();
  //const { employeeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  const handlePageChange = (page) => {
    tempRefs.current = false;
    getActivityReport(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    tempRefs.current = false;
    getActivityReport(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  // const handleSort = (column, sortDirection) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     field = column.field;
  //     orderBy = sortDirection;
  //     setLoading(false);
  //   }, 100);
  // };

  const columns = useMemo(
    () => [
      {
        name: "Employee Name",
        field: "id",
        sortable: true,
        selector: (row) => (
          <span>{row.employee_name ? row.employee_name : "-"}</span>
        ),
      },
      {
        name: "Project Name",
        field: "id",
        sortable: true,
        selector: (row) => (
          <span>{row.project_name ? row.project_name : "-"}</span>
        ),
      },
      {
        name: "Start Time",
        field: "id",
        selector: (row) =>
          row.start_time
            ? moment(row.start_time).format("DD-MM-YYYY hh:mm a")
            : "-",
        center: true,
      },
      {
        name: "End Time",
        field: "id",
        selector: (row) =>
          row.end_time
            ? moment(row.end_time).format("DD-MM-YYYY hh:mm a")
            : "-",
        center: true,
      },
      {
        name: "Hours of Working",
        field: "id",
        sortable: true,
        selector: (row) => (row.hours_of_working ? row.hours_of_working : "-"),
        center: true,
      },
    ],
    []
  );

  const downloadExcel = () => {
    const data = {
      intEmployeeID: employeeID,
      txtFromDate: fromDate,
      txtToDate: toDate,
      intLimit: rowsPerPage || 10,
      intOffset: rowsPerPage ? (currentPage - 1) * rowsPerPage : 0,
      intType: 8,
    };
    config.method = "POST";
    config.data = data;
    config.url = reportsAPI;

    axios(config)
      .then(function (response) {
        const newData = response.data["result"].map((row) => {
          // Format start_time
          if (row.start_time !== null) {
            row.start_time = moment(row.start_time).format(
              "DD-MM-YYYY h:mm:ss a"
            );
          }
          // Format end_time if it's not null
          if (row.end_time !== null) {
            row.end_time = moment(row.end_time).format("DD-MM-YYYY h:mm:ss a");
          }
          return row;
        });

        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "report");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `KG-employee-activity-report-${moment().format(
            "DD/MM/YYYY, h:mm:ss"
          )} hrs.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  const today = new Date();
  const [activityReport, setActivityReport] = useState([]);
  const [fromDate, setFromDate] = React.useState(dayjs());
  const [toDate, setToDate] = React.useState(dayjs);
  const [totalCount, setTotalCount] = React.useState(0);

  const getActivityReport = (page, rowsPerPage) => {
    const data = {
      intEmployeeID: employeeID,
      txtFromDate: fromDate,
      txtToDate: toDate,
      intLimit: rowsPerPage || 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      intType: 8,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = reportsAPI;

    axios(config)
      .then(function (response) {
        setActivityReport(response.data.result);
        calculateTotal(response.data.result[0]);
        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRefs1 = useRef(false);

  useEffect(() => {
    if (tempRefs1.current === false) {
      getActivityReport(1, rowsPerPage);
      return () => {
        tempRefs1.current = true;
      };
    }
  }, []);

  const [employeeID, setEmployeeID] = useState(0);
  const [EmployeeList, setEmployeeList] = useState([]);
  let tempRefs2 = useRef(false);

  useEffect(() => {
    if (tempRefs2.current === false) {
      const data = {
        intType: 7,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          setEmployeeList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs2.current = true;
      };
    }
  }, []);

  const [totalHours, setTotalHours] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [workingDays, setWorkingDays] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const calculateTotal = (data) => {
    if (data && data.total_time) {
      const duration = moment.duration(data.total_time); // create a Moment.js duration object
      const working_days = Math.floor(duration.asHours() / 8);

      const [total_hours, total_minutes, seconds] = data.total_time.split(":");
      setTotalHours(total_hours);
      setTotalMinutes(total_minutes);
      setWorkingDays(working_days);

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",
      });

      const formattedAmount = formatter.format(data.total_amount_spent);
      setTotalAmount(formattedAmount);
    }
  };
  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Employee Activity Report
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MuiLink underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </MuiLink>
                <Typography color="text.primary">Reports</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <div className="empty-space-40"></div>
        <Grid
          container
          spacing={2}
          margin={`15px 0px`}
          padding={`10px 15px 0px 0px`}
        >
          <Grid item xs={12} lg={3} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Employee List
              </InputLabel>
              <Select
                labelId="Select Project"
                id="demo-simple-select"
                value={employeeID}
                label="Maristal Status"
                onChange={(e) => {
                  setEmployeeID(e.target.value);
                }}
              >
                {EmployeeList.map((row, index) => (
                  <MenuItem value={row.id} key={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select From Date"
                value={fromDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setFromDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select To Date"
                value={toDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setToDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                getActivityReport(1, rowsPerPage);
              }}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid className="form_design">
          <Grid>
            {activityReport.length > 0 && (
              <>
                {/* <Grid
                  container
                  spacing={2}
                  margin={`15px 0px`}
                  padding={`10px 15px 0px 0px`}
                >
                  <Grid item xs={12} lg={4} md={4}>
                    <Typography>
                      Total Worked Time:{" "}
                      {`${totalHours} hours & ${totalMinutes} minutes`}
                    </Typography>
                    <Typography>{`${workingDays} days (8 hrs as 1 day)`}</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <Typography>Total Amount Spent: {totalAmount}</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4} md={4}>
                    <Typography>Total Amount Received: </Typography>
                  </Grid>
                </Grid> */}

                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6} md={6}></Grid>
                  <Grid item xs={12} lg={6} md={6} textAlign="right">
                    <Button
                      // className="btn-primary"
                      color="success"
                      startIcon={<DescriptionIcon />}
                      onClick={downloadExcel}
                      variant="contained"
                      sx={{
                        marginTop: "1rem",
                        borderRadius: "5px !important",
                      }}
                    >
                      Export to Excel
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}></Grid>
                </Grid>
              </>
            )}
            <DataTable
              pagination
              paginationServer
              fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={activityReport}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[
                1, 10, 25, 50, 100, 500, 1000, 10000, 50000, 100000,
              ]}
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default EmployeeActivityReport;
