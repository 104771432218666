import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
  FormHelperText,
} from "@mui/material";
import Layout from "../../layout/Layout";
import {
  statusMasterAPI,
  LabourAttandenceAPI,
  projectMasterAPI,
  labourWagesAPI,
} from "../../config";

//form imports
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

import axios from "axios";
import { config, _numberOnly } from "../../config";
import { AlertMessage } from "../../components/AlertMessage";
import { _getDropdownListData } from "../../service";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import { subDays, differenceInDays, startOfWeek, compareAsc } from "date-fns";
import * as XLSX from "xlsx";
import DescriptionIcon from "@mui/icons-material/Description";
import { Tooltip as ReactTooltip } from "react-tooltip";

import MuiLink from "@mui/material/Link";

const LabourAttend = () => {
  const navigate = useNavigate();

  const [employeeName, setemployeeName] = useState(0);
  const [employeeNames, setemployeeNames] = useState([]);
  const [employeeId, setEmployeeId] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [labourAttendanceData, setLabourAttendanceData] = useState([]);
  const [inputValues, setInputValues] = useState([
    {
      intNoOfLaboursFullDay: 0,
      intNoOfLaboursHalfDay: 0,
      intLabourType: 0,
      intAmount: 0,
      intLineTotal: 0,
    },
  ]);
  const [projectid, setProjectId] = useState(0);

  const [projectName, setProjectName] = useState(0);
  const [listProjects, setListProjects] = useState([]);
  const [fromDate, setFromDate] = React.useState(dayjs());
  const [toDate, setToDate] = React.useState(dayjs());
  const [date, setDate] = React.useState(dayjs());
  const [selectProject, setSelectProject] = useState(1);

  const [getReport, setGetReportData] = useState([]);

  const handleInputChange = (text, name, index) => {
    const newInputValues = [...inputValues];
    newInputValues[index][name] = text;
    setInputValues(newInputValues);
  };

  const childRef = useRef();

  const errors = "There was an error!";

  const onSubmit = async () => {
    const data = {
      txtEmployeeName: employeeName,
      intEmployeeID: employeeId,
      intProjectID: projectid,
      txtDate: date.format("YYYY-MM-DD"),
      txtAttendance: selectedEmployee,
      intType: 9,
    };

    // return;

    config.method = "POST";
    config.data = data;
    config.url = LabourAttandenceAPI;
    axios(config)
      .then(function (response) {
        const messageType = response.data.code === 500 ? "warning" : "success";
        if (response.data.message === "Labours attendance details updated") {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "Record has been updated successfully",
              messageType: messageType,
            })
          );
        } else {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: response.data.message,
              messageType: messageType,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onClickEmployee = async () => {
    const data = JSON.stringify({
      txtDate: date.format("YYYY-MM-DD"),
      intProjectID: projectid,
      intType: 8,
    });

    config.method = "POST";
    config.data = data;
    config.url = LabourAttandenceAPI;
    axios(config)
      .then(function (response) {
        if (response.data.result.length === 0) {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "No Records found",
              messageType: "warning",
            })
          );
        }
        if (response.data.message == "Labours attandence project details") {
        }
        setProjectId(response.data.result[0].project_id);

        fetchContractors();
        setSelectedEmployee(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const [lastFriday, setLastFriday] = useState("");
  // const [today, setToday] = useState("");
  // const selectDate = async () => {
  //   const today = new Date();
  //   const lastFriday = new Date(
  //     today.getTime() - ((today.getDay() + 2) % 7) * 24 * 60 * 60 * 1000
  //   );
  //   const date = new Date(lastFriday);
  //   console.log(date.toISOString().substr(0, 10));
  //   return date.toISOString().substr(0, 10);
  // };
  const selectDate = async () => {
    const today = new Date();
    const daysToFriday = (today.getDay() + 7 - 5) % 7; // days until last Friday
    const lastFriday = new Date(today.getTime() - daysToFriday * 86400000);
    return moment(lastFriday).format("YYYY-MM-DD");
  };

  const handleFromDate = async () => {
    setFromDate(await selectDate());
  };

  const calculateLineTotal = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += item["Line Total"];
    });
    return total;
  };

  const setReportData = (data) => {
    const lineTotal = calculateLineTotal(data);
    setGetReportData([...data, { "Line Total": lineTotal }]);
  };

  const onSubmitProject = async (value) => {
    const data = JSON.stringify({
      intProjectID: projectName,
      txtFromDate:
        value === 2 ? await selectDate() : fromDate.format("YYYY-MM-DD"),
      txtToDate:
        value === 2
          ? moment().format("YYYY-MM-DD")
          : toDate.format("YYYY-MM-DD"),
      txtDate: moment().format("YYYY-MM-DD"),
      intType: 10,
    });

    config.method = "POST";
    config.data = data;
    config.url = LabourAttandenceAPI;

    axios(config)
      .then(function (response) {
        console.log(
          "⛔ ➡️ file: labourAttendence.jsx:223 ➡️ response:",
          response
        );

        // setProjectId(response.data.result[0].project_id);

        setGetReportData(response.data.result);

        const messageType = response.data.code === 500 ? "warning" : "success";

        if (value === 2) {
          // if weekly report button is clicked
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "Weekly Report generated successfully",
              messageType: messageType,
            })
          );
        } else {
          // if search button is clicked
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "Search Result generated successfully",
              messageType: messageType,
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRefs = useRef(false);

  useEffect(() => {
    // if (tempRefs.current === false) {
    // let data = {
    //   requestData: {
    //     intType: 7,
    //     txtDate: moment().format("YYYY-MM-DD"),
    //   },
    //   API: LabourAttandenceAPI,
    //   setData: setemployeeNames,
    // };
    // _getDropdownListData(data);

    let projectNamesData = {
      requestData: {
        // txtDate: moment().format("YYYY-MM-DD"),
        intType: 6,
      },
      API: projectMasterAPI,
      setData: setListProjects,
    };
    _getDropdownListData(projectNamesData);

    return () => {
      tempRefs.current = true;
    };
  }, []);

  //Work Type
  const [workType, setWorkType] = useState([]);
  const [txtWorkType, setTxtWorkType] = useState("");

  let tempRef = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      let data = {
        requestData: {
          intType: 2,
          intParentId: 46,
        },
        API: statusMasterAPI,
        setData: setWorkType,
      };
      _getDropdownListData(data);
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // contractor name (labour wages)
  const [contractorNames, setContractorNames] = useState([]);

  useEffect(() => {
    if (tempRefs.current === false) {
      let data = {
        requestData: {
          intType: 3,
        },
        API: labourWagesAPI,
        setData: setContractorNames,
      };
      _getDropdownListData(data);
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);
  //   if (tempRefs.current === false) {
  //     const data = {
  //       intType: 3,
  //     };
  //     // console.log(data);
  //     config.method = "POST";
  //     config.data = JSON.stringify(data);
  //     config.url = labourWagesAPI;

  //     axios(config)
  //       .then(function (response) {
  //         console.log({ lbr_wage: response.data.result });
  //         setContractorNames(response.data.result)
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //     return () => {
  //       tempRefs.current = true;
  //     };
  //   }
  // }, [])

  const fetchContractors = (val) => {
    // console.log(val)
    let data = {
      requestData: {
        intType: 3,
        // intLabourType: val
      },
      API: labourWagesAPI,
      setData: setContractorNames,
    };
    _getDropdownListData(data);
  };

  const [salaryData, setSalaryData] = useState([]);
  const fetchContractorSalary = (val) => {
    let data = {
      requestData: {
        intType: 4,
        intID: val,
      },
      API: labourWagesAPI,
      setData: setSalaryData,
    };
    _getDropdownListData(data);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function handleWeeklyReport() {
    // setToDate(new Date())
    let lastDate = new Date();
    let fromDate = new Date(
      lastDate.getTime() - ((lastDate.getDay() + 2) % 7) * 24 * 60 * 60 * 1000
    );
    //setFromDate(lastFriday)
    // onSubmitProject(fromDate,lastDate);
  }

  const reportData = getReport.map((item) => {
    const { id, ...rest } = item;
    Object.keys(rest).forEach((key) => {
      if (key !== "Total Working Hours") {
        rest[moment(key).format("DD-MM-YYYY")] = rest[key];
        delete rest[key];
      } else {
        rest["Total Working Hours"] = moment
          .duration(rest["Total Working Hours"], "minutes")
          .format("h:mm", { trim: false });
      }
    });
    return rest;
  });

  const exportToExcel = () => {
    const reportData = getReport.map((item) => {
      const { id, ...rest } = item;
      const modifiedRest = Object.keys(rest).reduce((acc, key) => {
        const newKey = key.replace(/count/i, "");
        acc[newKey] = rest[key];
        return acc;
      }, {});
      modifiedRest.Date = moment(modifiedRest.Date).format("YY-MM-DD");
      return modifiedRest;
    });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "report");
    XLSX.writeFile(
      workbook,
      "KG-labour-attendance-report- " +
        moment().format("DD/MM/YYYY, h:mm:ss") +
        " hrs.xlsx"
    );
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTotal = () => {
    let total = 0;
    getReport.forEach((item) => {
      total += item["Line Total"] || 0;
    });
    return total;
  };

  //Disable the submit button
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleButtonClick = () => {
    setIsButtonClicked(true);
  };

  return (
    <Layout>
      <Grid className="employee-form bold-header">
        <AlertMessage ref={childRef} />
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Labour Attendance Report
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <MuiLink underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </MuiLink>
              <Typography color="text.primary">Reports</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>

          <Grid className="form_design">
            <Grid>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Edit Labour Attendance" value="1" />
                      <Tab label="Labour Attendance Report" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Grid
                      textAlign="center"
                      item
                      className="form-lable"
                      xs={12}
                      lg={12}
                      md={12}
                    ></Grid>
                    <Box width="100%">
                      <Container maxWidth="lg">
                        <Grid
                          container
                          spacing={3}
                          justifyContent="space-between"
                        >
                          <Grid item xs={12} lg={6} md={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Project
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={projectid}
                                label="Project"
                                onChange={(e) => {
                                  setProjectId(e.target.value);
                                }}
                              >
                                {listProjects.length !== 0 ? (
                                  listProjects.map((row) => {
                                    return (
                                      <MenuItem key={row.id} value={row.id}>
                                        {row.name}
                                      </MenuItem>
                                    );
                                  })
                                ) : (
                                  <MenuItem value="">
                                    No projects available
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={6} md={6}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              format="DD-MM-YYYY"
                            >
                              <MobileDatePicker
                                label="Date"
                                value={date}
                                inputFormat="DD/MM/YYYY"
                                fullWidth
                                onChange={(e) => {
                                  setDate(e);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid container justifyContent="flex-end">
                            <Grid item>
                              {isButtonClicked ? null : (
                                <Button
                                  style={{ marginTop: "20px" }}
                                  variant="primary"
                                  className={`btn-primary`}
                                  type="submit"
                                  onClick={(e) => {
                                    // handleButtonClick();
                                    setProjectId(e.target.value);
                                    onClickEmployee(e.target.value);
                                  }}
                                >
                                  Search
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                          {selectProject !== 0 &&
                            selectedEmployee.length > 0 &&
                            selectedEmployee.map((row, index) => {
                              console.log("rowww", row);
                              const handleHalfDayCountChange = (e) => {
                                const newSelectedEmployee = [
                                  ...selectedEmployee,
                                ];
                                newSelectedEmployee[index].half_day_count =
                                  parseInt(e.target.value);
                                setSelectedEmployee(newSelectedEmployee);
                              };

                              const handleFullDayCountChange = (e) => {
                                const newSelectedEmployee = [
                                  ...selectedEmployee,
                                ];

                                newSelectedEmployee[index].full_day_count =
                                  parseInt(e.target.value);

                                setSelectedEmployee(newSelectedEmployee);
                              };

                              const handleAmountCountChange = (e) => {
                                const newSelectedEmployee = [
                                  ...selectedEmployee,
                                ];
                                newSelectedEmployee[index].amount =
                                  e.target.value;
                                setSelectedEmployee(newSelectedEmployee);
                              };

                              const newWorkType = (value, index) => {
                                const newSelectedEmployee = [
                                  ...selectedEmployee,
                                ];
                                newSelectedEmployee[index].labour_type = value;
                                setSelectedEmployee(newSelectedEmployee);
                              };

                              const updateLineTotal = (e, t) => {
                                const newSelectedEmployee = [
                                  ...selectedEmployee,
                                ];

                                console.log(
                                  "newSelectedEmployee",
                                  newSelectedEmployee
                                );

                                // const lineTotal =
                                //   parseInt(newSelectedEmployee[index].amount) *
                                //     parseInt(
                                //       newSelectedEmployee[index].full_day_count
                                //     ) +
                                //   parseInt(
                                //     newSelectedEmployee[index].amount / 2
                                //   ) *
                                //     parseInt(
                                //       newSelectedEmployee[index].half_day_count
                                //     );

                                let lineTotal;

                                if (t == "half") {
                                  lineTotal =
                                    newSelectedEmployee[index].full_day_amount *
                                      newSelectedEmployee[index]
                                        .full_day_count +
                                    newSelectedEmployee[index].half_day_amount *
                                      e.target.value;
                                } else {
                                  lineTotal =
                                    newSelectedEmployee[index].full_day_amount *
                                      e.target.value +
                                    newSelectedEmployee[index].half_day_amount *
                                      newSelectedEmployee[index].half_day_count;
                                }

                                newSelectedEmployee[index].line_total =
                                  lineTotal;

                                setSelectedEmployee(newSelectedEmployee);
                              };

                              const handleContractorName = (value, index) => {
                                const newSelectedEmployee = [
                                  ...selectedEmployee,
                                ];
                                newSelectedEmployee[index].labour_name = value;
                                setSelectedEmployee(newSelectedEmployee);
                              };

                              return (
                                <>
                                  <Grid
                                    textAlign="center"
                                    item
                                    className="form-lable"
                                    xs={12}
                                    lg={12}
                                    md={12}
                                  ></Grid>
                                  <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Work Type
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={row.labour_type}
                                          label="Work Type"
                                          onChange={(e) => {
                                            setTxtWorkType(e.target.value);
                                            newWorkType(e.target.value, index);
                                          }}
                                        >
                                          {workType.map((option) => (
                                            <MenuItem
                                              key={option.id}
                                              value={option.id}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>

                                    {/* contractor */}
                                    <Grid item xs={3}>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Contractor name
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={row.labour_name}
                                          label="Contractor name"
                                          disabled
                                          onChange={(e) => {
                                            // setTxtWorkType(e.target.value);
                                            // newWorkType(e.target.value, index);
                                            handleContractorName(
                                              e.target.value,
                                              index
                                            );
                                            fetchContractorSalary(
                                              e.target.value
                                            );
                                          }}
                                        >
                                          {contractorNames.map((option) => (
                                            <MenuItem
                                              key={option.id}
                                              value={option.id}
                                            >
                                              {option.labour_name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>

                                      {/* <TextField
                                        value={row.labour_name}
                                        name="contractor-name"
                                        type="text"
                                        label="Contractor name"
                                        fullWidth
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        onChange={(e) => {
                                          // handleHalfDayCountChange(e);
                                          // updateLineTotal(e);
                                        }}
                                      /> */}
                                    </Grid>

                                    <Grid item xs={2}>
                                      <TextField
                                        value={row.half_day_count}
                                        name="half-day"
                                        type="number"
                                        label="Half Day"
                                        fullWidth
                                        onChange={(e) => {
                                          handleHalfDayCountChange(e);
                                          updateLineTotal(e, "half");
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <TextField
                                        value={row.full_day_count}
                                        name="full-day"
                                        type="number"
                                        label="Full Day"
                                        fullWidth
                                        onChange={(e) => {
                                          handleFullDayCountChange(e);
                                          updateLineTotal(e, "full");
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <TextField
                                        value={row.full_day_amount}
                                        name="amount"
                                        type="number"
                                        label="Full day amount"
                                        fullWidth
                                        disabled
                                        onChange={(e) => {
                                          // handleAmountCountChange(e);
                                          // updateLineTotal(e);
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <TextField
                                        value={row.half_day_amount}
                                        name="amount"
                                        type="number"
                                        label="Half day amount"
                                        disabled
                                        fullWidth
                                        onChange={(e) => {
                                          // handleAmountCountChange(e);
                                          // updateLineTotal(e);
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      {/* <TextField id="outlined-basic" label="Outlined"  /> */}
                                      <TextField
                                        value={row.line_total}
                                        type="text"
                                        label="Line Total"
                                        variant="outlined"
                                        disabled
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                          {selectedEmployee.length > 0 && (
                            <Grid
                              item
                              xs={12}
                              lg={12}
                              md={12}
                              textAlign="right"
                            >
                              <Button
                                variant="primary"
                                className={`btn-primary`}
                                type="submit"
                                onClick={(e) => {
                                  onSubmit();
                                }}
                              >
                                Submit
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Container>
                    </Box>
                  </TabPanel>

                  {/* labour attendance report */}
                  <TabPanel value="2">
                    <Grid container spacing={3}>
                      <Grid
                        textAlign="center"
                        item
                        className="form-lable"
                        xs={12}
                        lg={6}
                        md={6}
                      ></Grid>
                      <Grid
                        textAlign="right"
                        item
                        className="form-lable"
                        xs={12}
                        lg={6}
                        md={6}
                      ></Grid>

                      <Grid item xs={12} lg={6} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Project
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={projectName}
                            label="Project"
                            onChange={(e) => {
                              setProjectName(e.target.value);
                            }}
                          >
                            {listProjects.length !== 0 ? (
                              listProjects.map((row) => {
                                return (
                                  <MenuItem key={row.id} value={row.id}>
                                    {row.name}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem value="">
                                No projects available
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3} md={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          format="DD-MM-YYYY"
                        >
                          <MobileDatePicker
                            label="From Date"
                            value={fromDate}
                            inputFormat="DD/MM/YYYY"
                            fullWidth
                            onChange={(e) => {
                              setFromDate(e);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={3} md={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          format="YYYY-MM-DD"
                        >
                          <MobileDatePicker
                            label="To Date"
                            value={toDate}
                            inputFormat="DD/MM/YYYY"
                            fullWidth
                            onChange={(e) => {
                              setToDate(e);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12} lg={12} md={12} textAlign="right">
                        <Button
                          style={{ marginRight: "8px" }}
                          variant="primary"
                          className={`btn-primary`}
                          type="submit"
                          onClick={() => {
                            onSubmitProject(2);
                            handleFromDate();
                          }}
                        >
                          Weekly Report
                        </Button>

                        <Button
                          variant="primary"
                          className={`btn-primary`}
                          type="submit"
                          onClick={() => {
                            onSubmitProject(1);
                          }}
                        >
                          Search
                        </Button>
                      </Grid>
                      <Grid
                        className="excel_button"
                        item
                        xs={12}
                        lg={12}
                        md={12}
                      >
                        <Button
                          // className="btn-primary"
                          color="success"
                          variant="contained"
                          onClick={() => exportToExcel()}
                          startIcon={<DescriptionIcon />}
                          sx={{
                            marginTop: "1rem",
                            marginRight: "1rem",
                            borderRadius: "5px !important",
                          }}
                        >
                          Export to Excel
                        </Button>
                      </Grid>

                      <Grid
                        textAlign="center"
                        item
                        className="form-lable"
                        xs={12}
                        lg={12}
                        md={12}
                      ></Grid>

                      {getReport.length > 0 && (
                        <Grid>
                          <p style={{ fontWeight: "bold" }}>
                            Total Amount :{" "}
                            <span
                              style={{
                                color: "#155d27",
                                fontWeight: "bold",
                                backgroundColor: "#c9ffe2",
                                padding: "10px",
                                borderRadius: "10px",
                                textAlign: "center",
                              }}
                            >
                              ₹ {calculateLineTotal(getReport)}
                            </span>
                          </p>
                          <Grid overflow="scroll">
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  {getReport.map((item, index) => {
                                    return Object.keys(item).map((key) => {
                                      if (index === 0 && key !== "id") {
                                        return (
                                          <StyledTableCell>
                                            {key.replace(/\bcount\b/g, "")}
                                          </StyledTableCell>
                                        );
                                      }
                                    });
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {getReport.map((item) => (
                                  <TableRow key={item.id}>
                                    {Object.entries(item).map(
                                      ([key, value]) => {
                                        if (key !== "id") {
                                          return (
                                            <td
                                              key={key}
                                              className={
                                                dayjs(key).format(
                                                  "MMM DD, YYYY"
                                                ) !== "Invalid date"
                                                  ? "text-center"
                                                  : key ===
                                                    "Total Working Hours"
                                                  ? "text-center"
                                                  : ""
                                              }
                                            >
                                              {/* {value === null
                                            ? "-"
                                            : key === "half_day_count" 
                                            ? value + '+h'
                                            : key === "full_day_count" 
                                            && `${value}f`
                                          } */}
                                              {value === null
                                                ? "-"
                                                : moment(value)
                                                    .subtract(2.3, "hours")
                                                    .format("DD-MM-YYYY") ===
                                                    "01-01-1970" ||
                                                  moment(value)
                                                    .subtract(2.3, "hours")
                                                    .format("DD-MM-YYYY") ===
                                                    "Invalid date"
                                                ? value
                                                : moment(value)
                                                    .subtract(2.3, "hours")
                                                    .format("DD-MM-YYYY")}
                                            </td>
                                          );
                                        }
                                      }
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default LabourAttend;

// SET @sql = NULL;
// SELECT
//   GROUP_CONCAT(DISTINCT
//     CONCAT(
//       'MAX(IF(b.name = ''',
//       b.name,
//       ''', CONCAT(''('', half_day_count, ''H + '', full_day_count, ''F) '', ''x '', a.amount, '' RS'') , NULL)) AS "', b.name, ' count"'
//     )
//   ) INTO @sql
// FROM tbl_labour_attandence as a
// INNER JOIN tbl_status as b ON a.labour_type = b.id;

// SET @sql = CONCAT(
//   'SELECT c.name as "Project Name", a.created_at as Date, a.id, ',
//   @sql,
//   ', (a.line_total) as "Line Total" ',
//   'FROM tbl_labour_attandence as a ',
//   'INNER JOIN tbl_status as b ON a.labour_type = b.id ',
//   'INNER JOIN tbl_project_master as c ON a.project_id = c.id ',
//   'WHERE ((', intEmployeeID,' = created_by OR ', intEmployeeID,' = 0) AND (', intProjectID,' = project_id OR ', intProjectID,' = 0) AND (DATE(a.created_at) BETWEEN "', txtFromDate,'" AND "', txtToDate,'")) ',
//   'GROUP BY project_id'
// );

// PREPARE stmt FROM @sql;
// EXECUTE stmt;
// DEALLOCATE PREPARE stmt;
